import {HttpClient} from '@angular/common/http';
import {EventEmitter, Injectable} from '@angular/core';
import {Global} from '../../constants/global';
import { PopupService } from '../services/popup.service';
import * as fileSaver from 'file-saver';


export class ReportEvent {
  constructor(public name?: string) {
  }
}

@Injectable({
  providedIn: 'root'
})
export class ReportService 
{

  apiUrl          = Global.apiUrl;
  recurrenceArray = null;
  company         : any;
  reportEvent$    : EventEmitter<ReportEvent>;
  alarmsTypeArray : string[];
  allImports      : any[];

  constructor(
    private http         : HttpClient,
    private popupService : PopupService,
  ) {
    this.reportEvent$ = new EventEmitter();
  }

  loadReports()
  {
      let token = sessionStorage.getItem(Global.ATTRIBUTE_USER_TOKEN);
      let header    = 
        {
            headers: { 'Authorization': 'Bearer ' + token }
        };
      return this.http.get(this.apiUrl + `rev2/get/availablereports`, header);
  }

  loadReportsParams(report_name)
  {
    let token = sessionStorage.getItem(Global.ATTRIBUTE_USER_TOKEN);
    let header    = 
      {
          headers: { 'Authorization': 'Bearer ' + token }
      };

    return this.http.get(this.apiUrl + `rev2/get/params/` + report_name, header);
  }

  getPreview(report_name) {
    let token = sessionStorage.getItem(Global.ATTRIBUTE_USER_TOKEN);
    let header    = 
      {
          headers: { 'Authorization': 'Bearer ' + token }
      };

    return this.http.get(this.apiUrl + `rev2/get/preview/` + report_name, header);
  }

  getClass(className) {
    let token = sessionStorage.getItem(Global.ATTRIBUTE_USER_TOKEN);
    if (token == null)
        return null;
    let header    = 
      {
          headers: { 'Authorization': 'Bearer ' + token }
      };

    return this.http.get(this.apiUrl + `rev2/get/class/` + className, header);
  }

  storeRecurrenceClass(data) {
    let arr = [];
    data['values'].forEach(element => {
      arr.push(element);
    });
    this.recurrenceArray = arr;

  }

  getRecurrenceClass() {

    return this.recurrenceArray;
  }
  // recurrence, useCapConsumptionCombined,measuringPoints,useCapStepLine,mail,useBarConsumption,startDate
  createReport(
      company         : string,
      companyId       : string,
      description     : string,
      reportType      : string,
      recurrence      : string,
      measuringPoints : any[],
      startDate       : number,
      endDate         : number,
      mail            : any[],
      useBarConsumption         : boolean,
      useCapStepLine            : boolean,
      useCapConsumptionCombined : boolean
  ){

      let reportObj = {
        company        : company,
        companyId      : companyId,
        description    : description,
        reportType     : reportType,
        recurrence     : recurrence,
        measuringPoints: measuringPoints,
        startDate      : startDate,
        endDate        : endDate,
        mail           : mail,
        useBarConsumption        : useBarConsumption,
        useCapStepLine           : useCapStepLine,
        useCapConsumptionCombined: useCapConsumptionCombined
      }
      if (endDate == 0)
         delete reportObj.endDate;

      return this.http.post(this.apiUrl + `rev2/update/create/report`, reportObj,{responseType: 'blob'}).subscribe(result =>
      {
      fileSaver.saveAs(result, "report.pdf");
      this.reportEvent$.emit(new ReportEvent(Global.SIGNAL_REFRESH_REPORTS));
      }, () => {
          this.popupService.addPopupError("Fehler", "Der Bericht konnte nicht erstellt werden!")

      });
    }

  updateReportWithRepeat
  (
      companyId      : string,
      company        : string,
      repeatStartDate: number,
      repeatEndDate  : number,
      repeatPeriod   : number,  // jede x Woche
      repeatEvery    : string,  // recurrence
      name           : string,
      description    : string,
      active         : boolean,
      reportType     : string,
      recurrence     : string,
      startDate      : number,
      measuringPoints: any[],
      mail           : any[],
      useBarConsumption        : boolean,
      useCapStepLine           : boolean,
      useCapConsumptionCombined: boolean,
      costUnit : any

  )
  {
      let obj = {
        companyId      : companyId,
        company        : company,
        repeatStartDate: repeatStartDate,
        repeatEndDate  : repeatEndDate,
        repeatPeriod   : repeatPeriod,
        repeatEvery    : repeatEvery,
        name           : name,
        description    : description,
        active         : active,
        reportType     : reportType,
        recurrence     : recurrence,
        startDate      : startDate,
        measuringPoints: measuringPoints,
        mail           : mail,
        useBarConsumption        : useBarConsumption,
        useCapStepLine           : useCapStepLine,
        useCapConsumptionCombined: useCapConsumptionCombined
      }
      if ((reportType == 'Kostenübersicht') || (reportType == 'cost'))
      {
          obj['costUnit'] = costUnit;
          obj['useBarCost']  = useBarConsumption;
          obj['useBarConsumption'] = false;
      }
      return this.http.post(this.apiUrl + `rev2/update/report`, obj).subscribe(data =>
      {
        this.popupService.addPopupOk("Wiederholung wird angelegt",
            "Der Bericht wurde mit Wiederholung gespeichert. Die Erstellung selbst wird anhängig von der "
            + "Konfiguration gestartet. Sie können den fertigen Bericht in der Kategorie 'Aktivitäten' herunterladen.");
        this.reportEvent$.emit(new ReportEvent(Global.SIGNAL_REFRESH_REPORTS));
      }, () =>
      {
        this.popupService.addPopupError("Fehler", "Die Wiederholung konnte nicht angelegt werden!")
      });
  }
  
  changeReport(reportObj,companyId)
  {
    let report = {
      reportId        : reportObj.id,
      companyId       : companyId,
      name            : reportObj.name,
      description     : reportObj.description,
      repeatStartDate : reportObj.repeatStartDate,
      repeatEndDate   : reportObj.repeatEndDate,
      repeatPeriod    : reportObj.period,
      repeatEvery     : reportObj.repeatEvery,
      mail            : reportObj.mails,
      active          : reportObj.active,
      reportType      : reportObj.reportType,
    }
    if (reportObj.reportType =='cost')
    {
        report['costUnit'] = reportObj.costUnit;
    }
    else
    {
        report['measuringPoints'] = reportObj.measuringPoints;
    }
    return this.http.post(this.apiUrl + 'rev2/update/report/change',report).subscribe(data =>
        {
          this.popupService.addPopupOk("Erfolg","Der Bericht wurde erfolgreich geändert");
          this.reportEvent$.emit(new ReportEvent(Global.SIGNAL_REFRESH_REPORTS));
        }, () =>
        {
          this.popupService.addPopupError("Fehler", "Der Bericht konnte nicht geändert werden")
        }
    );
  }

  removeReportWithRepeat(reportObj,company){
    let report = {
      reportId : reportObj.id,
      name     : reportObj.name,
      company  : company
    }
    return this.http.post(this.apiUrl + 'rev2/update/report/delete', report).subscribe(data =>
    {

    }, () =>
    {
      this.popupService.addPopupError("Fehler", "Bericht konnte nicht gelöscht werden")
    });

  }


  loadAlarmType()
  {
    return this.http.get(this.apiUrl + `rev2/get/availablealarms`);
  }

  createAlarmByObject(obj : any) 
  {
    return this.http.post(this.apiUrl + `rev2/update/create/alarm`, obj).subscribe(data =>
        {
          this.popupService.addPopupOk("Erfolg","Alarm wurde erstellt");
          this.reportEvent$.emit(new ReportEvent(Global.SIGNAL_REFRESH_ALARMS));
        }, () =>
        {
          this.popupService.addPopupError("Fehler", "Alarm konnte nicht angelegt werden.")
        })
    
  }

  createAlarm(
      companyId      : string,
      company        : string,
      alarmType      : string,
      description    : string,
      recurrence     : string,
      startDate      : number,
      endDate        : number,
      warnLevel      : number,
      mailLevel      : number,
      daysBack       : number,
      measuringPoints: any[],
      mail           : any[],
  )
  {
    let alarmObj = {
      companyId      : companyId,
      company        : company,
      alarmType      : alarmType,
      description    : description,
      recurrence     : recurrence,
      startDate      : startDate,
      endDate        : endDate,
      warnLevel      : warnLevel,
      mailLevel      : mailLevel,
      daysBack       : daysBack,
      measuringPoints: measuringPoints,
      mail           : mail
    }
    if (alarmType == "Budgetkontrolle")
    {
       delete alarmObj['measuringPoints'];
       alarmObj['measuringPoint'] = measuringPoints[0];
    }
    if (alarmType == "Messwertkontrolle")
       alarmObj['maxHours'] = daysBack;
    return this.http.post(this.apiUrl + `rev2/update/create/alarm`, alarmObj).subscribe(data =>
    {
      this.popupService.addPopupOk("Erfolg","Alarm wurde erstellt");
      this.reportEvent$.emit(new ReportEvent(Global.SIGNAL_REFRESH_ALARMS));
    }, () =>
    {
      this.popupService.addPopupError("Fehler", "")
    })
  }

  removeAlarm(alarmObj,company){
    let alarm = {
      alarmId  : alarmObj.id,
      name     : alarmObj.title,
      company  : company
    }
    return this.http.post(this.apiUrl + 'rev2/update/alarm/delete', alarm).subscribe(data =>
    {

    }, () =>
    {
      this.popupService.addPopupError("Fehler", "Alarm konnte nicht gelöscht werden")
    });

  }

  changeAlarm(alarmObj,companyId)
  {
    return this.http.post(this.apiUrl + 'rev2/update/alarm/change',alarmObj).subscribe(data =>
        {
          this.popupService.addPopupOk("Erfolg","Der Alarm wurde erfolgreich geändert");
          this.reportEvent$.emit(new ReportEvent(Global.SIGNAL_REFRESH_ALARMS));
        }, () =>
        {
          this.popupService.addPopupError("Fehler", "Der Alarm konnte nicht geändert werden")
        }
    );
  }

  changeImport(importObj: any) 
  {
    this.http.post(Global.apiUrl + "rev2/update/import", importObj)
        .subscribe(() => {
            this.reportEvent$.emit(new ReportEvent(Global.SIGNAL_REFRESH_IMPORTS));
        })
  }

  getImportsForCurrentCompany(): any[] 
  {
      return this.allImports;
  }

  loadImportsForCurrentCompany() 
  {
      let company = JSON.parse(sessionStorage.getItem(Global.SESSION_COMPANY));
      this.http.get(Global.apiUrl + "rev2/list/import/" + company.id)
          .subscribe((data) =>
          {
              this.allImports = data['imports'];
              this.reportEvent$.emit(new ReportEvent(Global.SIGNAL_REFRESH_IMPORTS));
          },
          () => {
              alert("Error: Imports for Company")
          })
  }

  createCostReport(company: string, companyId: number, 
    description: string, title: any, 
    recurrence: string, costUnit: any, startDate: number, 
    mailArray: any[], 
    barConsumption: boolean, unitId: number)
    {
      let reportObj = {
        company        : company,
        companyId      : companyId,
        description    : description,
        reportType     : title,
        recurrence     : recurrence,
        costUnit       : costUnit,
        startDate      : startDate,
        mail           : mailArray,
        unitId         : unitId,
        useBarConsumption        : barConsumption,
      }
      return this.http.post(this.apiUrl + `rev2/update/create/reportcost`, reportObj,{responseType: 'blob'}).subscribe(result =>
      {
      fileSaver.saveAs(result, "report.pdf");
      this.reportEvent$.emit(new ReportEvent(Global.SIGNAL_REFRESH_REPORTS));
      }, () => {
          this.popupService.addPopupError("Fehler", "Der Bericht konnte nicht erstellt werden!")

      });
    }

}
