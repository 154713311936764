<div class="login-form" *ngIf="!passwordLost">
  <h2 class="form-signin-heading">Anmelden</h2>
    <div class="card">
      <div class="card-body">
        <form class="form-group" novalidate>
          <div class="alert alert-warning" *ngIf='invalidLogin'>{{errorMessage}}</div>
          <div class="alert alert-success" *ngIf='loginSuccess'>{{successMessage}}</div>
          <div class="form-group">
            <label for="email" class="sr-only">E-Mail-Adresse</label>
            <input type="email" class="form-control" id="email" [(ngModel)]="username" placeholder="mustermann@example.com"
              name="username" required>
          </div>
          <div class="form-group">
            <label for="pwd" class="sr-only">Passwort</label>
            <input type="password" class="form-control" [(ngModel)]="password" id="password" placeholder="Passwort"
              name="password" required>
          </div>
          <a href="#" (click)="passwordLost=true ">Passwort vergessen</a>
          <button (click)=handleLogin() class="btn btn-primary btn-block  en-btn">Anmelden</button>
        </form>
      </div>

    </div>
  </div>
    <app-password-lost *ngIf="passwordLost"></app-password-lost>
