<button type="button" id="sidebarCollapse" (click)=toggleNavbar() class="navbar-btn toggle-right"
        [ngClass]="{ 'rotate': navbarOpen || mobile}">

    <i class="fa fa-angle-left" aria-hidden="true"></i>

</button>
<nav id="sidebar" [ngClass]="{ 'active': navbarOpen || mobile}" >
    <ul class="list-unstyled nav-tabs nav" id="mySidebar" [ngClass]="{ 'left-tabs': !navbarOpen }">
        <li class="nav-item">
            <a class="nav-link active" href="#tab-panel-1"
               (click)="refreshEvents()"
               id="a-tab-panel-1" data-toggle="tab">
                <i class="fa fa-home" aria-hidden="true"></i>
                <span>Dashboard</span></a>
        </li>
        <li class="nav-item">
            <div class="nav-link pointer"
                (click)="jumpToOldSite()">
                <i class="fa fa-bar-chart" aria-hidden="true"></i>
                <span> Diagramme</span></div>
        </li>
        <li class="nav-item">
            <a class="nav-link" href="#tab-panel-3"
               id="a-tab-panel-3" data-toggle="tab">
                <i class="fa fa-list-alt" aria-hidden="true"></i>
                <span>Berichte</span></a>
        </li>
        <li class="nav-item">
            <a class="nav-link" href="#tab-panel-7" title="Alarms"
               id="a-tab-panel-7" (click)="setDefaultAlarmData()"
               data-toggle="tab">
                <i class="fa fa-bell-o" aria-hidden="true"></i>
                <span>Alarme</span></a>
        </li>
        <li class="nav-item">
            <a class="nav-link" href="#tab-panel-4"
               (click)="refreshEvents()"
               id="a-tab-panel-4" data-toggle="tab">
                <i class="fa fa-calendar" aria-hidden="true"></i>
                <span>Aktivitäten</span></a>
        </li>

        <li class="nav-item">
            <a class="nav-link" href="#tab-panel-6" title="Kosten"
               (click)="refreshCosts()"
               id="a-tab-panel-6" data-toggle="tab">
                <i class="fa fa-eur" aria-hidden="true"></i>
                <span> Kosten</span></a>

        </li>
        <li class="nav-item">
            <a class="nav-link" href="#tab-panel-8" title="Vorgaben"
               (click)="refreshTodos()"
               id="a-tab-panel-8" data-toggle="tab">
                <i class="fa fa-tasks" aria-hidden="true"></i>
                <span>Vorgaben</span></a>

        </li>
        <li class="nav-item" *ngIf="isModerator()">
            <a class="nav-link" href="#tab-panel-9" title="Import / Export"
               id="a-tab-panel-9" data-toggle="tab">
                <i class="fa fa-exchange" aria-hidden="true"></i>
                <span>Import / Export</span></a>

        </li>
        <li class="nav-item">
            <a class="nav-link" href="#tab-panel-5" title="Konfiguration"
               id="a-tab-panel-5" data-toggle="tab">
                <i class="fa fa-wrench" aria-hidden="true"></i>
                <span>Konfiguration</span></a>

        </li>
    </ul>

</nav>

