<div class="row">
    <div class="col-md-12">
        <h2 class="titel">Vorgaben</h2>
        <div *ngIf="overDueTodos.length > 0">
            <h6 class="titel-small">Überfällig</h6>
            <div *ngFor="let todo of overDueTodos">
                {{ getUntilDate(todo) }} - {{ todo.name }}
                <ul *ngIf='todo.deadline > currentTime()' style="margin-bottom: 5px" > 
                    <li *ngFor="let sub of filterOverDue(todo.todos)">
                        {{ getUntilDate(sub) }} - {{ sub.name }}
                    </li>
                </ul>
            </div>
            <br/>
        </div>
        <div *ngIf="dueTodos.length > 0">
            <h6 class="titel-small">Fällig</h6>
            <div *ngFor="let todo of dueTodos">
                {{ getUntilDate(todo) }} - {{ todo.name }}
                <ul *ngIf='todo.deadline > currentTime()' style="margin-bottom: 5px" > 
                    <li *ngFor="let sub of filterDue(todo.todos)">
                        {{ getUntilDate(sub) }} - {{ sub.name }}
                    </li>
                </ul>
            </div>
        </div>
        <div *ngIf="(dueTodos.length == 0) && (overDueTodos.length == 0)">
            <p>Momentan gibt es keine fälligen oder Überfälligen Vorgaben.</p>
            <p>Sie können neue Vorgaben unter <EM>Konfiguration</EM> erstellen.</p>
        </div>
    </div>
</div>