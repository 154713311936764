<div class="row">
    <div class="col-md-12">
        <div class="card">
            <div class="card-body">
                <h2 class="titel">Alle Berichte mit Wiederholungen</h2>
                <div>
                    <table class="table table-striped">
                        <thead>
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Description</th>
                            <th scope="col">Wiederholung</th>
                            <th scope="col">Startdatum / Zeit</th>
                            <th width="35px" scope="col">Aktiv</th>
                            <th width="120px" *ngIf="isModerator()">Aktion</th>
                        </tr>
                        </thead>
                        <tbody>

                        <tr *ngFor="let report of allReportsWithRepeat | paginate : {
                                    itemsPerPage: tableSize,
                                    currentPage: page,
                                    totalItems: count
                                   };
                        ">
                        <td xstyle="width: 200px">{{ report.name }}</td>
                        <td xstyle="width: 350px">{{ report.description }}</td>
                        <td xstyle="width: 150px">{{ report.repeatEvery }}</td>
                        <td xstyle="width: 170px">{{ report.repeatStartDate | date: 'dd.MM.yyyy HH:mm'}}</td>
                        <td><i *ngIf="report.active"
                               class="fa fa-check" aria-hidden="true"></i>
                             <i *ngIf="!report.active"
                             class="fa fa-close" aria-hidden="true"></i>
                        </td>
                        <td *ngIf="isModerator()">
                            <button role="link"
                                    (click)="openModal(report.id)"
                                    data-toggle="modal" data-target="#changeReportModal"
                                    data-whatever="report"
                                    class="btn btn-primary btn-sm en-btn">
                                <i class="fa fa-pencil" aria-hidden="true"></i>
                            </button>
                            &#160;
                            <button role="button"
                                    (click)="removeReportWithRepeat(report.id)"
                                    class="btn btn-primary btn-sm en-btn">
                                <i class="fa fa-trash-o " aria-hidden="true"></i>
                            </button>
                        </td>
                        </tr>
                        </tbody>
                    </table>

                    <div class="d-flex justify-content-center" *ngIf="allReportsWithRepeat.length > tableSize">
                        <pagination-controls class="en-pagination"
                                responsive="true"
                                previousLabel=""
                                nextLabel=""
                                (pageChange)="onTableDataChange($event)">
                        </pagination-controls>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<!-- Modal -->
<div class="modal fade" id="changeReportModal" tabindex="-1" role="dialog" aria-hidden="true" *ngIf="reportObj!=null">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title titel">Führen Sie die Änderungen durch</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="alert alert-danger" *ngIf='errorMessage'>{{errorMessage}}</div>
                        </div>

                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-5">
                                    <label>Name:</label>
                                </div>
                                <div class="col-md-7">
                                    <input type="text" [(ngModel)]="repeatName"
                                           name="name"
                                           style="width: 100%">

                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-5 recurrence">
                                    <label for="repeat">Wiederholungszeitraum:</label>
                                </div>
                                <div class="col-md-7">
                                    <ng-select [items]="recurrenceArray"
                                               id="repeat"
                                               [multiple]="false"
                                               [clearable]="false"
                                               [(ngModel)]="selectedPeriod"
                                               [ngModelOptions]="{standalone: true}">
                                    </ng-select>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-5">
                                    <label>Wiederhole von:</label>
                                </div>
                                <div class="col-md-7 input-group">
                                    <input class="form-control date"
                                           (click)="d.toggle()"
                                           name="repeat"
                                           [(ngModel)]="startRepeat"
                                           placeholder="TT.MM.JJJJ"
                                           required ngbDatepicker
                                           #d="ngbDatepicker"/>
                                    <div class="input-group-append">
                                         <span class="input-group-text" (click)="d.toggle()">
                                            <svg width="1em" height="1em" viewBox="0 0 16 16"
                                                 class="bi bi-calendar-date"
                                                 fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                 <path fill-rule="evenodd"
                                                       d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/>
                                                    <path d="M6.445 11.688V6.354h-.633A12.6 12.6 0 0 0 4.5 7.16v.695c.375-.257.969-.62 1.258-.777h.012v4.61h.675zm1.188-1.305c.047.64.594 1.406 1.703 1.406 1.258 0 2-1.066 2-2.871 0-1.934-.781-2.668-1.953-2.668-.926 0-1.797.672-1.797 1.809 0 1.16.824 1.77 1.676 1.77.746 0 1.23-.376 1.383-.79h.027c-.004 1.316-.461 2.164-1.305 2.164-.664 0-1.008-.45-1.05-.82h-.684zm2.953-2.317c0 .696-.559 1.18-1.184 1.18-.601 0-1.144-.383-1.144-1.2 0-.823.582-1.21 1.168-1.21.633 0 1.16.398 1.16 1.23z"/>
                                            </svg>
                                        </span>
                                    </div>
                                    <select [(ngModel)]="hour" class="ig-height"
                                        [ngModelOptions]="{standalone: true}">
                                        <option *ngFor="let val of listHour" value="{{val}}">{{val}}</option>
                                    </select>
                                    <div style="padding-top: 5px;">:</div>
                                    <select [(ngModel)]="minute"  class="ig-height"
                                        [ngModelOptions]="{standalone: true}">
                                        <option *ngFor="let val of listMinute">{{val}}</option>
                                    </select>

                                </div>
                            </div>

                        </div>

                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-5">
                                    <label style="width: 150px" for="mails">E-Mails:</label>
                                </div>
                                <div class="col-md-7">
                                    <ng-select [items]="allMails" id="mails"
                                               bindLabel="name"
                                               name="mails"
                                               [multiple]="true"
                                               [clearable]="true"
                                               [(ngModel)]="selectedMails"
                                               [ngModelOptions]="{standalone: true}">
                                    </ng-select>
                                </div>
                            </div>

                            <div class="row pad-top" *ngIf="reportObj.reportType !='cost'">
                                <div class="col-md-5">
                                    <label style="width: 150px"for="meter">Messpunkte :</label>
                                </div>
                                <div class="col-md-7">
                                    <ng-select [items]="allMeters" id="meter"
                                            bindLabel="values.user0"
                                            name="meters"
                                            #ngSelect
                                            [multiple]="true"
                                            [clearable]="true"
                                            [(ngModel)]="selectedMeters"
                                            [ngModelOptions]="{standalone: true}">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="row pad-top" *ngIf="reportObj.reportType =='cost'">
                                <div class="col-md-5">
                                    <label style="width: 150px"for="meter">Kostenstelle :</label>
                                </div>
                                <div class="col-md-7">
                                    <ng-select [(ngModel)]="selectedCostUnit"
                                            [ngModelOptions]="{standalone: true}">
                                            <ng-option *ngFor="let unit of allCostUnits" 
                                                [value]="unit.id">{{unit.name}}</ng-option>
                                    </ng-select>                                               
                                </div>
                            </div>

                        </div>


                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-5">
                                    <label>Aktiv :</label>
                                </div>
                                <div class="col-md-7">
                                    <input type="checkbox"
                                           name="check"
                                           style="vertical-align: middle; display: none"
                                           [checked]="active"
                                           #yes>
                                        <i (click)="yes.checked=true; active = true" *ngIf="!yes.checked"
                                            style="color: #2e2d5a" 
                                            class="fa fa-square-o"></i>
                                        <i (click)="yes.checked=false; active=false" *ngIf="yes.checked"
                                            style="color: #2e2d5a"
                                            class="fa fa-check-square"></i>
                                </div>
                            </div>
                        </div>

                    </div>
                </form>
            </div>
            <div class="modal-footer center">
                <button type="button"
                        class="submit-button"
                        (click)="changeReport()"
                >Ändern
                </button>
            </div>
        </div>
    </div>
</div>

