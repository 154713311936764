import { Component, OnInit } from '@angular/core';
import { LogeventsService, LogSignal } from '../../../../services/logevents.service';
import { Logevent } from 'src/app/classes/logevent';
import { HttpClient } from '@angular/common/http';
import { Global } from 'src/constants/global';
import * as fileSaver from 'file-saver';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit 
{
    public logevents  : Logevent[];
    public logactions : Logevent[];
    public data       : Blob;

  constructor(public logeventsService : LogeventsService,
    private http: HttpClient
    ) 
  { 
  }

  ngOnInit(): void 
  {
      this.subscribeToRefresh(this);
      this.logeventsService.loadEvents();
  }

  subscribeToRefresh(comp) : void
  {
      this.logeventsService.logEvent$
        .subscribe(function onSuccess(le : LogSignal)
        {
            comp.refreshEvents();      
        });
  }

  refreshEvents() : void
  {
    this.logevents  = this.logeventsService.currReports;
    this.logactions = this.logeventsService.currActions;
  }

  downloadReport(event : Logevent) : any
  {
      let company = JSON.parse(sessionStorage.getItem(Global.SESSION_COMPANY));
      let url = Global.apiUrl + 'rev2/download/report/' + company.name;
      event['service'] = this;

      this.http.post(url, event.action, {responseType: 'blob'}).subscribe(
        function onSuccess(data)
        {
            let blob:any = new Blob([data], { type: 'application/pdf' });
            // const url = window.URL.createObjectURL(blob);
            fileSaver.saveAs(blob, "report.pdf");
        },
        function onError(data)
        {
            alert("Fehler beim Download");
        }
      );
  }

  getLogEvents() : Logevent[]
  {
      this.refreshEvents();
      if (this.logevents != null)
        return this.logevents.slice(0, 2);      // Nur die ersten 3 Events anzeigen
      else return [];
  }

  getLogActions() : Logevent[]
  {
    this.refreshEvents();
    if (this.logactions != null)
        return this.logactions.slice(0, 2);
    else return [];
}

  jumpToEvents() : void
  {
      let actEl = document.getElementById('a-tab-panel-4');
      actEl.click();
  }
}
