import { Component, OnInit } from '@angular/core';
import {ConfigService} from '../../../services/config.service';

@Component({
  selector: 'app-current-company',
  templateUrl: './current-company.component.html',
  styleUrls: ['./current-company.component.scss']
})
export class CurrentCompanyComponent implements OnInit {
  name : string;
  logoUrl : string;
  constructor(
    public configService : ConfigService,
  ) { }

  ngOnInit(): void {
    let current = this.configService.getSelectedCompany();
    if(current != null){
      this.name = current.name;
      this.logoUrl = current.logo;
    }
  }

}
