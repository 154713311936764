import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Todo, TodoGroup } from 'src/app/classes/todo';
import { ConfigEvent, ConfigService } from 'src/app/services/config.service';
import { Global } from 'src/constants/global';

@Component({
  selector: 'app-todostate',
  templateUrl: './todostate.component.html',
  styleUrls: ['./todostate.component.scss']
})
export class TodostateComponent implements OnInit {

    configService : ConfigService;
    allTodos      : TodoGroup[] = [];        // Alle verfügbaren Todogruppen der Firma
    urgentTodos   : TodoGroup[] = [];        // Alle überfälligen Todos aus allTodos
    nextTodos     : TodoGroup[] = [];        // Fällig in den nächsten 2 Tagen
    otherTodos    : TodoGroup[] = [];        // Sonstige Todos
    doneTodos     : TodoGroup[] = [];        // Erledigt.
    closeTodos    : boolean[] = [];
    // Blättern
    pageNext      : number = 1;             // Seitennr für nextTodos
    pageUrgent    : number = 1;             // Seitennr für urgentTodos
    pageDone      : number = 1;             // Seitennr für doneTodos
    pageOther     : number = 1;             // Seitennr für restliche Todos
    tableSize = 5;

    constructor(cs : ConfigService) 
    { 
        this.configService = cs;
    }

    ngOnInit(): void 
    {
        this.configService.configEvent$
            .subscribe( 
                (data : ConfigEvent) => {
                    if (data.name == Global.SIGNAL_REFRESH_TODO)
                        this.refreshTodos();
                }        
            );
        this.configService.loadTodos();
    }


    refreshTodos() : void
    {
        this.allTodos    = this.configService.getTodos();
        this.urgentTodos = [];
        this.nextTodos   = [];
        this.otherTodos  = [];
        this.doneTodos   = [];
        
        for (let i = 0; i < this.allTodos.length; i++)
        {
            let group : TodoGroup = this.allTodos[i];
            group.changed = false;
            if (group.done)
                this.doneTodos.push(group);
            else
            if (this.configService.isGroupOverDue(group))
                this.urgentTodos.push(group);
            else
            if (this.configService.isGroupDue(group))
                this.nextTodos.push(group);
            else this.otherTodos.push(group);
        }
        this.closeTodos   = [];
        for (let i = 0; i < this.allTodos.length; i++)
        {
            this.closeTodos.push(false);
        }
    }

    getUntilDate(todo : TodoGroup)  : string
    {
        let d = new Date(todo.deadline);
        return formatDate(d, "dd.MM.yyyy", "en-US");
    }

    getEMails(emails : any[]) : string
    {
        if (emails == null)
            return "";
        if (emails.length ==1)
           return emails[0];
        return emails[0] + " ...";
    }

    toggleVisibility(index : number) : void
    {
        this.closeTodos[index] = !this.closeTodos[index];
    }

    getDoneFromTotal(todo : TodoGroup) : string
    {
        let count : number  = 0;
        for (let i = 0; i < todo.todos.length; i++)
        {
            if (todo.todos[i].done)
                count++;
        }
        return "" + count + " / " + todo.todos.length;
    }

    toggleDone(todo : Todo, parent? : Todo)
    {
        todo.done = !todo.done;
        todo.changed = true;
        if (parent != null)
           parent.changed = true;
    }

    saveTodoState(todo : TodoGroup) : void
    {
        // Wenn wir sub-todos haben, ist eines davon noch nicht
        // erledigt?
        for (let i = 0; i < todo.todos.length; i++)
        {
            if (!todo.todos[i].done)
                todo.done = false;  // Ja, damit ist auch die Group nicht erledigt.
        }
        this.configService.saveTodoGroup(todo);
    }

}
