
<div class="row">
    <div class="col-md-12">
        <div class="card">
            <div class="card-body">
                <div >
                    <h2 class="titel">Messcontainer
                        <i class="fa fa-info-circle help-icon" 
                                placement="bottom"
                                container="body"
                                popoverClass="help-popover-wide"
                                triggers="mouseenter:mouseleave"
                                [ngbPopover]="helpContainer" [popoverTitle]="helpContainerTitle"    
                                aria-hidden="true"></i>
                    </h2>
                </div>
                <div class="row">
                    <ul class="nav nav-tabs" role="tablist" style="width: 100%">
                        <li class="nav-item">
                            <a href="#page1" class="nav-link active" data-toggle="tab">Container</a>
                        </li>
                        <li class="nav-item">
                            <a href="#page2" class="nav-link" data-toggle="tab">Zugeordnete Messpunkte</a>
                        </li>
                    </ul>
                </div>
                <div class="tab-content">
                    <div class="tab-pane fade show active" id="page1">
                        <div class="row">
                            <div class="col-lg-12 top-padded">
                                <table class="table table-striped ">
                                <thead>
                                    <th></th>
                                    <th>Name</th>
                                    <th>Id</th>
                                    <th>Messpunkte</th>
                                    <th width="120px">Aktion</th>
                                </thead>
                                <tbody>
                                <tr *ngFor="let container of allContainer | paginate : { id: 'containerPager', 
                                    itemsPerPage: tableSize, currentPage: page}; let ind = index">
                                    <td>
                                        <i class="fa fa-circle-o color-blue" 
                                            *ngIf="!container['selected']"
                                            id="{{container.id}}"
                                            (click)="selectContainer(container)"
                                            aria-hidden="true"></i>
                                        <i class="fa fa-circle color-blue" 
                                            *ngIf="container['selected']"
                                            (click)="selectContainer(container)"
                                            id="{{container.id}}"
                                            aria-hidden="true"></i>
                                    </td>
                                    <td>{{ container.name }}</td>
                                    <td>{{ container.deviceId }} </td>
                                    <td></td>
                                    <td align="center">
                                        <button role="link" 
                                            placement="top" ngbTooltip="Ändern"
                                            (click)="editContainer(container)"
                                            data-toggle="modal" 
                                            data-target="#containerModal"
                                            data-whatever="report"
                                            class="btn btn-outline-primary btn-sm color-blue">
                                            <i class="fa fa-pencil color-blue" aria-hidden="true"></i>
                                        </button>
                                        &nbsp;
                                        <button role="button" 
                                            (click)="deleteContainer(container)"
                                            class="btn btn-outline-primary btn-sm">
                                            <i class="fa fa-trash-o color-blue" 
                                                aria-hidden="true"></i>
                                        </button>
                                    </td>
                                </tr>
                                </tbody>
                                </table>  
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-2" >
                                <button role="link"
                                    (click)="createContainer()"
                                            data-toggle="modal" 
                                            data-target="#containerModal"
                                            data-whatever="report"
                                            class="btn btn-primary">
                                    <i class="fa fa-plus" aria-hidden="true"></i>    
                                    Neu
                                </button>
                            </div>    
                            <div class="col-lg-8 d-flex justify-content-center">
                                <pagination-controls
                                        id="containerPager"
                                        responsive="true"
                                        autoHide="true"
                                        previousLabel=""
                                        nextLabel=""
                                        (pageChange)="inputName = ''">
                                </pagination-controls>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade show" id="page2">
                        <div class="row">
                            <div class="col-lg-12 top-padded">
                                <table class="table table-bordered table-striped ">
                                    <thead>
                                        <th>Aktiv ab</th>
                                        <th>Name</th>
                                        <th>Id</th>
                                        <th>Aktion</th>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Input-Fenster, Modal-->
<div class="modal fade" id="containerModal" tabindex="-1" role="dialog" 
    #modal aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title titel"> {{ displayTitle }} </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="alert alert-danger" *ngIf='errorMessage.length > 0'>{{errorMessage}}</div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-4">
                                    <label>Name:</label>
                                </div>
                                <div class="col-md-8">
                                    <input type="text" [(ngModel)]="inputName"
                                            class="form-control"
                                            name = "name"
                                           style="width: 100%">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="row" style="padding-top: 5px">
                                <div class="col-md-4">
                                    <label style="width: 150px">Geräte-Id:</label>
                                </div>
                                <div class="col-md-8">
                                    <div class="input-group mb-3" 
                                        *ngIf="currContainerId < 0">
                                        <input type="text"  
                                            class="form-control"
                                            [ngModelOptions]="{standalone: true}"
                                            [(ngModel)]="inputId">
                                        <div class="input-group-append">
                                            <button type="button"
                                                (click)="createID()"
                                                class="btn btn-outline-secondary">
                                                <i class="fa fa-asterisk" aria-hidden="true"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="input-group mb-3" 
                                        *ngIf="currContainerId >= 0">
                                        <input type="text" readonly
                                            class="form-control"
                                            [ngModelOptions]="{standalone: true}"
                                            [(ngModel)]="inputId">
                                        <div class="input-group-append">
                                            <button type="button disabled"
                                                class="btn btn-outline-secondary">
                                                <i class="fa fa-asterisk" aria-hidden="true"></i>
                                            </button>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="col-md-12">
                            <div class="row" style="padding-top: 5px">
                                <div class="col-md-4">
                                    <label style="width: 150px" for="mails">Aktiv:</label>
                                </div>
                                <div class="col-md-8">
                                    <input type="checkbox" aria-label="" 
                                        [ngModelOptions]="{standalone: true}"
                                        [(ngModel)]="inputActive">
                                </div>
                            </div>
                        </div>

                    </div>
                </form>
            </div>
            <div class="modal-footer center">
                <button type="button" 
                        (click)="submitContainer()"
                        class="submit-button">
                         {{ displayButton }}
                </button>
            </div>
        </div>
    </div>
</div>


<!-- Hilfetexte -->
<ng-template #helpContainer>
    <p>Ein Messcontainer enthält einen oder mehrere Messpunkte, aber
    zu einem gegebenen Zeitpunkt immer maximal einen.</p>
    <p>Wird ein Messpunkt durch z.B. durch einen neuen Zähler mit unterschiedlicher
       Konfiguration ersetzt, scheitert die Interpolation ohne Container, da sie immer 
       von einer Konfiguration pro Messpunkt ausgeht.
    </p>
    <p>Der Messcontainer umgeht dies, da er immer die korrekte Konfiguration für
       einen Zeitpunkt zurückgibt.
    </p>
    <p>Messcontainer stehen nicht für virtuelle Messpunkte zur Verfügung.</p>

</ng-template>
<ng-template #helpContainerTitle>Was ist ein <b>Messcontainer</b>?</ng-template>
    
<ng-template #helpNameContent>
    Der Name dient zur Identifizierung des Messcontainers und 
    sollte eindeutig sein.
</ng-template>
<ng-template #helpNameTitle>Containername</ng-template>