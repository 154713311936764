import { Injectable } from '@angular/core';  
import { Router, NavigationStart } from '@angular/router';  
import { Observable } from 'rxjs';  
import { Subject } from 'rxjs';  

@Injectable() export class ConfirmService {  
    private subject = new Subject<any>();  
    constructor() { }  

    confirmYesNo(title : string, message: string, yesFn: () => void, noFn?: () => void) 
    {  
        this.setConfirmation(title, message, yesFn, noFn);  
    }  

    setConfirmation(title: string, message: string, yesFn: () => void, noFn?: () => void) {  
        let that = this;  
        this.subject.next({  
            type: "confirm",  
            text: message,  
            title: title,
            yesFn:  
                function () {  
                    that.subject.next(); // Dialog schließen 
                    yesFn();  
                },  
            noFn: function () {  
                that.subject.next();  
                if (noFn)
                    noFn();  
            }  
        });  
  
    }  
  
    getMessage(): Observable<any> {  
        return this.subject.asObservable();  
    }  
}  

