import { Component, OnInit } from '@angular/core';
import {Global} from '../../../../../constants/global';
import {ConfigEvent, ConfigService} from '../../../../services/config.service';
import {NgbDate, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import {NgbDateCustomParserFormatter} from '../../../../../config/datepicker';
import {registerLocaleData} from '@angular/common';
import localeDe from '@angular/common/locales/de';
import {ReportService} from '../../../../services/report.service';
import {User} from "../../../../classes/user";
import { Meter } from 'src/app/classes/meter';

declare var $: any;

@Component({
  selector: 'app-alarms-config',
  templateUrl: './alarms-config.component.html',
  styleUrls: ['./alarms-config.component.scss'],
  providers: [
    {provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter}
  ]
})
export class AlarmsConfigComponent implements OnInit {

  //pagination
  page      = 1;
  count     = 0;
  tableSize = 7;

  allAlarms  : any[] = [];
  alarmObj   : any;
  alarmTitle : any;
  allMails   : string[];
  allReports : any[];
  recurrenceArray : any[];

  description         : any;
  selectedMails       : any;
  warnLevel           : number;
  mailLevel           : string;
  mailLevelsArray     = [ "immer" , "wenn Fehler auftreten" , "wenn alles ok ist" ] ;
  selectedMeters      : any[] = [];
  active              : any;
  // Ausreisser
  absolute            : any;
  medianActive        : any;
  perc                : any;

  minValue            : any;
  maxValue            : any;

  errorMessage   : string = "";
  allMeters      : Meter[] = [];
  selectedMeter1 : any;
  selectedMeter2 : any;
  selectedReport : any;
  diff           : any;
  // Budget
  budget         : string;
  budgetBase     : any = "0";
  allVmps        : Meter[] = [];
  selectedPeriod : any;
  selectedMeter  : any;
  // Messwert    
  maxHours       : any; // Max. Anzahl an Stunden



    constructor(private configService : ConfigService,
                private reportService : ReportService)
    {
      registerLocaleData(localeDe);
      this.configService.configEvent$.subscribe(data =>
      {
        if (data.name == Global.SIGNAL_REFRESH_ALARMS)
            this.refreshAlarms();

      });
      this.reportService.reportEvent$.subscribe((data =>
      {
        if (data.name == Global.SIGNAL_REFRESH_ALARMS)
        {
          this.configService.loadAlarmsForCompany();
          this.refreshAlarms();
        }
      }))
    }

    ngOnInit(): void 
    {
      this.configService.loadAlarmsForCompany();
      this.recurrenceArray    = this.reportService.getRecurrenceClass();

    }

    public testMask = {
        mask: Number,
        scale: 0,
        signed: false,
        normalizeZeros: false,
        min: 0,
        max: 36
    };
    

    public diffMask = {
        mask: Number,
        scale: 2,
        signed: false,
        padFractionalZeros: true,
        normalizeZeros: false,
        min: 0
    };

    public percMask = {
        mask: Number,
        scale: 2,
        signed: false,
        padFractionalZeros: true,
        normalizeZeros: false,
        min: 1,
        max: 100
    };
    
    

  refreshAlarms()
  {
    this.allAlarms = this.configService.getAlarmsForCurrentCompany();
    this.allMails  = this.configService.getUsersMailArray();
    this.allReports= this.configService.getReportsForCurrentCompany();
    this.allMeters = this.configService.metersForCurrentCompany;
    this.selectedMeters = [];
    this.selectedReport = null;
    this.allVmps = this.allMeters;
    let allMps = this.configService.vmpAndMetersForCurrentCompany;
    for (let i = 0; i < allMps?.length; i++)
    {
        if (allMps[i].className == ".VirtualMeasuringPoint")
            this.allVmps.push(allMps[i]);
    }

  }

  openModal(id)
  {
      this.errorMessage = "";
      this.findAlarmObj(id);
      this.refreshContentOfModal();
  }

  closeModal()
  {
    $("#changeAlarmModal").modal('hide');
  }

  findAlarmObj(id)
  {
    let alarm     = this.allAlarms.find(el => el.id === id);
    this.alarmObj = alarm;
  }

  refreshContentOfModal()
  {
    this.alarmTitle     = this.alarmObj.title;
    this.description    = this.alarmObj.description;
    this.warnLevel      = this.alarmObj.warnLevel;
    this.mailLevel      = this.mailLevelsArray[this.alarmObj.mailLevel];
    this.selectedMails  = this.alarmObj.mails;
    if ((this.alarmObj.alarmType != "Plausibilitätskontrolle") &&
        (this.alarmObj.alarmType != 'Budgetkontrolle') &&
        (this.alarmObj.alarmType != "Vorgabekontrolle"))
        this.selectedMeters = this.getSelectedMeters(this.alarmObj.measuringPoints);
    this.active         = this.alarmObj.active;
    // Ausreisser
    this.absolute       = this.alarmObj.absolute;
    this.medianActive   = this.alarmObj.medianActive == false;
    this.perc           = this.alarmObj.perc;
    // Plausibilität
    if (this.alarmObj.alarmType == "Plausibilitätskontrolle")
    {
        this.diff           = this.alarmObj.diff;
        this.selectedMeter1 = this.getSelectedMeters([this.alarmObj.meterId1]);
        this.selectedMeter1 = this.selectedMeter1[0];
        this.selectedMeter2 = this.getSelectedMeters([this.alarmObj.meterId2]);
        this.selectedMeter2 = this.selectedMeter2[0];
    }
    // Min/Max (= neuer Schwellwert)
    if ((this.alarmObj.alarmType == "Schwellwertkontrolle") ||
        (this.alarmObj.alarmType == 'Schwellwert mit Bericht'))
    {
        this.minValue = this.alarmObj.min;
        this.maxValue = this.alarmObj.max;
        this.selectedReport = this.getSelectedReport(this.alarmObj.reportId);
    }
    if (this.alarmObj.alarmType == 'Budgetkontrolle')
    {
        this.budgetBase = this.alarmObj.type;
        this.budget     = "" + this.alarmObj.budget;
        this.selectedPeriod = this.alarmObj.recurrence
        for (let i = 0; i < this.allVmps?.length; i++)
        {
            if (this.alarmObj.measuringPoint == this.allVmps[i].id)
            {
                this.selectedMeter  = this.allVmps[i];
                break;
            }

        }
    }
    if (this.alarmObj.alarmType == 'Messwertkontrolle')
    {
        this.maxHours = this.alarmObj.maxHours;
    }

  }

  getSelectedReport(id : number) : any
  {
      for (let i = 0; i < this.allReports?.length; i++)
      {
          if (this.allReports[i].id == id)
             return this.allReports[i];
      }
      return null;
  }

  
  removeAlarm(id)
  {
    let alarm  = this.allAlarms.find(el => el.id === id);
    let company = this.configService.getSelectedCompany();
    this.allAlarms = this.allAlarms.filter(item => item.id !== id);
    return this.reportService.removeAlarm(alarm,company.name)
  }

  onTableDataChange(event){
    this.page = event;
    this.allAlarms = this.configService.getAlarmsForCurrentCompany();
  }

  updateAlarmObj()
  {
    this.alarmObj = {
      alarmId     : this.alarmObj.id,
      title       : this.alarmTitle,
      daysBack    : 1,
      description : this.description ,
      mail        : this.selectedMails,
      mailLevel   : this.getSelectedMailLevel(),
      measuringPoints : this.getMeasuringPoints(),
      active      : this.active,
      companyId   : this.alarmObj.companyId,
      company     : this.alarmObj.company,
      startDate   : Date.now(),
      alarmType   : this.alarmObj.alarmType,
      lastOccurence : this.alarmObj.lastOccurence
    }
    // Parameter für einzelne Alarme eintragen
    if ((this.alarmObj.alarmType == 'Schwellwertkontrolle') ||
        (this.alarmObj.alarmType == 'Schwellwert mit Bericht'))
    {
        if (this.minValue)
            this.alarmObj['min'] = this.getFloat(this.minValue);
        if (this.maxValue)
            this.alarmObj['max'] = this.getFloat(this.maxValue);
        if (this.alarmObj.alarmType == 'Schwellwert mit Bericht')
        {
            this.alarmObj['reportId'] = this.selectedReport.id;
        }
    }
    if (this.alarmObj.alarmType == 'Plausibilitätskontrolle')
    {
        this.alarmObj['meterId1'] = this.selectedMeter1.id;
        this.alarmObj['meterId2'] = this.selectedMeter2.id;
        this.alarmObj['diff']     = this.getFloat(this.diff);
    }

    if (this.alarmObj.alarmType == 'Ausreisserkontrolle')
    {
        if (this.perc)
            this.alarmObj['perc'] = this.getFloat(this.perc);
        if (this.absolute)
            this.alarmObj['absolute'] = this.getFloat(this.absolute);
        this.alarmObj['medianActive'] = this.medianActive;
    }
    if (this.alarmObj.alarmType == 'Budgetkontrolle')
    {
        this.alarmObj['type'] = this.budgetBase;
        this.alarmObj['measuringPoint'] = this.selectedMeter.id;
        this.alarmObj['budget'] = this.getFloat(this.budget);
        this.alarmObj['recurrence'] = this.selectedPeriod;
        delete this.alarmObj['measuringPoints'];
    }
    if (this.alarmObj.alarmType == 'Messwertkontrolle')
    {
        this.alarmObj['maxHours'] = this.maxHours;
    }
  }

  getSelectedMeters(list : string[]) : Meter[]
  {
      let result : Meter[] = [];
      for (let i = 0; i < list.length; i++)
      {
          for (let j = 0; j < this.allMeters.length; j++)
          {
              if (this.allMeters[j].deviceId == list[i])
                 result.push(this.allMeters[j]);
          }
      }
      return result;
  }

  getSelectedMailLevel()
  {
    if (this.mailLevel == this.mailLevelsArray[0])
      return 0;
    else if (this.mailLevel == this.mailLevelsArray[1])
      return 1;
    else if (this.mailLevel == this.mailLevelsArray[2])
      return 2;
    else
      return 0;
  }

  getMeasuringPoints() : string[]
  {
      let result = [];
      for (let i = 0; i < this.selectedMeters.length; i++)
      {
          let m : Meter = this.selectedMeters[i];
          result.push(m.deviceId);
      }
      return result;
  }

  changeAlarm()
  {
    this.validateCreateAlarm();
    if (this.errorMessage?.length > 0)
        return;
    let companyId = this.configService.getSelectedCompany().id;
    this.updateAlarmObj();
    this.closeModal();
    return this.reportService.changeAlarm(this.alarmObj,companyId);

  }
  isAdmin() : boolean
  {
    let usrstr = sessionStorage.getItem(Global.ATTRIBUTE_USER_OBJECT);
    let user : User = JSON.parse(usrstr);
    if (user == null)
      return false;
    return user.admin;
  }

  // Ein Admin ist auch ein Moderator!
  isModerator() : boolean
  {
    let usrstr = sessionStorage.getItem("authenticatedUser");
    let user : User = JSON.parse(usrstr);
    if (user == null)
      return false;
    return user.admin || user.moderator;
  }

  validateCreateAlarm()
  {
      let t = this.alarmObj;
      this.errorMessage = "";
      if (t.alarmType == 'Schwellwertkontrolle')
         this.validateMinMaxAlarm();
      if (t.alarmType == 'Plausibilitätskontrolle')
         this.validatePlauseAlarm();
      if (t.alarmType == 'Vorgabekontrolle')
         this.validateTodoAlarm();
      if (t.alarmType == 'Ausreisserkontrolle')
         this.validateDeviationAlarm();     // Ausreisser
      if (t.alarmType == 'Messwertkontrolle')
         this.validateInputAlarm();
      if (t.alarmType == 'Budgetkontrolle')
         this.validateBudgetAlarm();
  }

  validateBudgetAlarm() : void
  {
      // 1. Name
      if ((this.description == null) || (this.description.length == 0))
      {
          this.errorMessage = "Bitte einen Namen eingeben."
          return ;
      }
      // 2. Messpunkt
      if (this.selectedMeter == null)
      {
          this.errorMessage = "Bitte geben Sie einen Messpunkt an."
          return ;
      }
      // 3. Differenz %
      if ((this.budget == null) || (this.budget.length == 0) || 
          (this.getFloat(this.budget) < 0) )
      {
          this.errorMessage = "Das Budget muss größer als 0 sein.";
          return ;
      }
      // 4. EMails
      if ((this.selectedMails == null) || (this.selectedMails.length == 0))
      {
          this.errorMessage = "Bitte mindestens eine E-Mail angeben!"
          return ;
      }
      // 5 Kontrollzeitraum
      if (this.selectedPeriod == null) 
      {
          this.errorMessage = "Bitte bestimmen Sie den Kontrollzeitraum."
          return;
      }

  }




  validatePlauseAlarm() : void
  {
      // 1. Name
      if ((this.description == null) || (this.description.length == 0))
      {
          this.errorMessage = "Bitte einen Namen eingeben."
          return ;
      }
      // 2. Messpunkte
      if (this.selectedMeter1 == null)
      {
          this.errorMessage = "Bitte geben Sie Messpunkt 1 an."
          return ;
      }
      if (this.selectedMeter2 == null)
      {
          this.errorMessage = "Bitte geben Sie Messpunkt 2 an."
          return ;
      }
      // Sicherstellen, dass gleichartige Messpunkte verglichen werden
      if ((this.selectedMeter1.values['obisid0'] !=
          this.selectedMeter2.values['obisid0']))
      {
          this.errorMessage = "Bitte zwei gleichartige Messpunkte vergleichen."
          return ;
      }

      // 3. Differenz %
      if ((this.diff == null) || (this.diff.length == 0) || 
          (this.getFloat(this.diff) < 0) || (this.getFloat(this.diff) > 100))
      {
          this.errorMessage = "Geben Sie bitte die max. Differenz in Prozent (0 - 100) an!"
          return ;
      }
      // 4. EMails
      if ((this.selectedMails == null) || (this.selectedMails.length == 0))
      {
          this.errorMessage = "Bitte mindestens eine E-Mail angeben!"
          return ;
      }

  }

  validateDeviationAlarm() : void
  {

      if ((!this.description) || (this.description.length == 0))
         this.errorMessage = "Geben Sie den Namen an";
      if ((this.selectedMails == null) || (this.selectedMails.length == 0))
      {
          this.errorMessage = "Bitte mindestens eine E-Mail angeben!"
          return;
      }
      if ((!this.selectedMeters) || (this.selectedMeters.length == 0))
      {
          this.errorMessage = "Bitte geben Sie mindestens einen Messpunkt an!";
          return
      }

      if (((this.perc == null)     || (this.perc.length == 0))     && 
          ((this.absolute == null) || (this.absolute.length == 0)) && 
          ((this.medianActive == null) || (!this.medianActive)))
      {
          this.errorMessage = "Aktivieren Sie entweder die prozentuale, absolute Kontrolle oder die Medianabweichung.";
          return ;
      }
      let p = this.getFloat(this.perc);
      if ((p < 0) || (p >= 100))
      {
          this.errorMessage ="Prozentualer Wert muss größer 0 und kleiner als 100 sein!";
          return ;
      }
      let a = this.getFloat(this.absolute);
      if (a < 0)
      {
          this.errorMessage = "Absolute Wert muss größer als 0 sein!";
          return ;
      }

  }

  validateInputAlarm() : void
  {
      if ((!this.description) || (this.description.length == 0))
         this.errorMessage = "Geben Sie den Namen an";
      if ((this.selectedMails == null) || (this.selectedMails.length == 0))
      {
          this.errorMessage = "Bitte mindestens eine E-Mail angeben!"
          return;
      }
      if ((!this.selectedMeters) || (this.selectedMeters.length == 0))
      {
          this.errorMessage = "Bitte geben Sie mindestens einen Messpunkt an!";
          return
      }
      if ((this.maxHours == null) || (this.maxHours < 0) || 
          (this.maxHours > 36))
      {
          this.errorMessage = "Bitte geben Sie an, wieviel Stunden max. Daten fehlen dürfen.";
          return;
      }
  }

  validateTodoAlarm() : void
  {
      if ((!this.description) || (this.description.length == 0))
         this.errorMessage = "Geben Sie den Namen an";
      if ((this.selectedMails == null) || (this.selectedMails.length == 0))
      {
          this.errorMessage = "Bitte mindestens eine E-Mail angeben!"
          return;
      }
  }

  validateMinMaxAlarm() : void
  {
      if ((!this.description) || (this.description.length == 0))
         this.errorMessage = "Geben Sie den Namen an";
      if ((this.selectedMails == null) || (this.selectedMails.length == 0))
      {
          this.errorMessage = "Bitte mindestens eine E-Mail angeben!"
          return;
      }
      if ((!this.selectedMeters) || (this.selectedMeters.length == 0))
      {
          this.errorMessage = "Bitte geben Sie mindestens einen Messpunkt an!";
          return
      }
   
      let minKO = (this.minValue == null) || (this.minValue.length == 0);
      let maxKO = (this.maxValue == null) || (this.maxValue.length == 0);
      if (minKO && maxKO)
      {
          this.errorMessage = "Es muss mindestens ein Wert (Min. oder Max.) festgelegt werden."
          return;
      }
      if (this.minValue && this.getFloat(this.minValue) <= 0)
      {
          this.errorMessage = "Der minimale Wert muss größer 0 sein.";
          return;
      }
      if (this.maxValue && this.getFloat(this.maxValue) <= 0)
      {
          this.errorMessage = "Der maximale Wert muss größer 0 sein.";
          return;
      }
      if (this.minValue && this.maxValue)
      {
          if (this.getFloat(this.maxValue) < this.getFloat(this.minValue))
          {
              this.errorMessage = "Der minimale Wert muss kleiner sein als der maximale Wert.";
              return;
          }
      }
  }

  getFloat(value : string) : number
  {
      if (value == null)
        return 0;
      value = value.replace(',', '.');
      return  parseFloat(value);

  }

  displayThresholds() : string[]
  {
      let result : string[] = [];
      if (this.alarmObj.medianActive)
         result.push("Ausreisser vom Median ist aktiviert.")
      if (this.getFloat(this.alarmObj.diff) > 0) 
          result.push("Eine Differenz von max." + this.alarmObj.diff + "% ist erlaubt." )
      if (this.getFloat(this.alarmObj.absolute) > 0)
         result.push("Max. absolute Abweichung zwischen zwei folgenden Messwerten: " + this.alarmObj.diff  )
      if (this.getFloat(this.alarmObj.perc) > 0)
         result.push("Max. prozentuale Abweichung zwischen zwei folgenden Messwerten: " + this.alarmObj.diff + "% " )
      if (result.length == 0)    
        result.push("Keine Schwellwerte konfiguriert.")
      return result;
  }

  addAllMeters() : void
  {
      this.selectedMeters = [];
      for (let i = 0; i < this.allMeters?.length; i++)
      {
          if (this.allMeters[i].className != ".VirtualMeasuringPoint")
             this.selectedMeters.push(this.allMeters[i]);
      }
  }
}
