<div class="row row1">
  <div class="col-md-8 margin-bottom">
    <div class="card-hover card">
      <div class="row row1">
        <div class="card-body col-lg-6 col-md-12 col-sm-12 logo center">
            <h3 style="padding-left: 13px;">{{ displayCompanyName }}</h3>
            <h5 style="padding-top: 10px; padding-left: 13px;">Herzlich willkommen {{ getSalutation() }}</h5> 
        </div>
        <div class="card-body col-lg-6 col-md-12 col-sm-12 logo">
            <img valign="top" [src]="getCompanyLogo()" alt="">
        </div>
      </div>

    </div>
  </div>
  <div class="col-md-4 margin-bottom">
    <div class="card-hover card">
      <div class="card-body datum">
        <div class="row">
            <h4 style="padding-left: 13px;">
            {{date | date: "dd.MM.yyyy"}}
            {{date | date: "HH:mm"}}
            </h4>
        </div>
        <div class="row">&nbsp;
        </div>

      </div>
    </div>
  </div>
</div>
<div class="row center row2">
  <div class="col-lg-4 col-md-6 margin-bottom">
    <div class="card-hover card">
      <div class="card-body">
        <app-events></app-events>
      </div>
    </div>
  </div>
  <div class="col-lg-4 col-md-6 margin-bottom">
    <div class="card-hover card">
      <div class="card-body">
        <app-todo-tile></app-todo-tile>
      </div>
    </div>
  </div>
  <div class="col-lg-4 col-md-6  margin-bottom">
    <div class="card-hover card">
      <div class="card-body diagramm">
        <app-weather></app-weather>
<!--
        <h4>Changelog V1.6.7</h4>
        <p>Die Webseite ist testfertig. Zuletzt geändert:</p>
        <ul>
            <li>Berichtsplanung nur mit ausgewählten Messpunkten möglich</li>
            <li>Firma ändern: Selektion springt auf 1. Firma zurück</li>
            <li>Firmenwechsel: Daten neu laden</li>
            <li>Popups zu oft angezeigt.</li>
            <li>Vorgabe: EMails wurden nicht korrekt angezeigt.</li>
        </ul>
-->                  
      </div>
    </div>
  </div>
<!-- 
  <div class="col-lg-4 col-md-6  margin-bottom">
    <div class="card-hover card">
      <div class="card-body diagramm">
        <h4>Diagramm</h4>
        <img src="assets/diagramm.png" alt="">
      </div>
    </div>
  </div>
-->  
</div>
