<div class="row">
    <div class="col-xl-10 col-md-12 col-12">
        <div class="card">
            <div class="card-body">
                <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">  
                    <p style="color: white">Lade und berechne Daten... </p>  
                </ngx-spinner>  
                <h2 class="titel">Kostenrechnung: {{ company?.name }}
                    <i class="fa fa-info-circle help-icon" 
                        placement="bottom"  
                        container="body"
                        popoverClass="help-popover-wide"
                        triggers="mouseenter:mouseleave"
                        [ngbPopover]="helpCostCalcContent" [popoverTitle]="helpCostCalcTitle"

                        aria-hidden="true"></i>                    
                </h2>
                <div style="text-align: center;">
                    <div class="btn-group" role="group" aria-label="Basic example">
                        <button type="button" class="btn btn-secondary en-active"
                            (click)="prevUnit()">
                            <i class="fa fa-chevron-circle-left" aria-hidden="true"></i>
                        </button>
                        <button type="button" [ngClass]="getClass(0)"
                            (click)="setUnit(0)">Tag</button> 
                        <button type="button" [ngClass]="getClass(1)"
                            (click)="setUnit(1)">Woche</button>
                        <button type="button" class="btn btn-secondary en-active"
                            (click)="d.toggle()" >
                            <i class="fa fa-calendar" aria-hidden="true"></i>
                        </button>
                        <button type="button" [ngClass]="getClass(2)"
                            (click)="setUnit(2)">Monat</button>
                        <button type="button" [ngClass]="getClass(3)"
                            (click)="setUnit(3)">Jahr</button>
                        <button type="button" class="btn btn-secondary en-active"
                            (click)="nextUnit()">
                            <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
                <p align="center">
                    Zeitraum: &nbsp;
                    <input class="date" placeholder="TT.MM.JJJJ" 
                    name="dp"  readonly (ngModelChange)="setDate($event)"
                    [(ngModel)]="costStartDate" style="visibility: hidden; border: 0px; width: 0px; height: 0px"
                    ngbDatepicker #d="ngbDatepicker"/>
                    {{ dateFrom }}
                    bis
                    {{ dateUntil }}                    
                </p>
                <p align="center">Kostenstelle: 
                    <select  class="selectpicker" 
                        [(ngModel)]="selectedCostUnit" (ngModelChange)="changeCostUnit($event)">
                        <option *ngFor="let cu of allCostUnits">{{ cu.name }}</option>
                    </select>
                </p>
                <div style="padding: 5px">
                    <table class="table table-bordered table-striped">
                        <tbody>
                        <tr>
                            <th scope="row">Messpunkt</th>
                            <th style="width: 25%;">Verbrauch</th>
                            <th style="width: 25%;">Kosten</th>
                        </tr>
                        <tr *ngFor="let cpm of costs.meters">
                            <td scope="row">{{ getMeterName(cpm.meterId) }}</td>
                            <td style="text-align: right; width: 25%;">{{ getConsumptionFormatted(cpm) }}</td>
                            <td style="text-align: right; width: 25%">{{ getCostPerMeterFormatted(cpm) }} &euro;</td>
                        </tr>
                        <tr>
                            <td scope="row"></td>
                            <td  style="width: 25%;">Summe</td>
                            <td style="width: 25%; text-align: right; border-top-width: 2px; border-top-color: black;">
                                {{ getDisplayTotal() }} €
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    </div>
</div>
<!-- Hilfetexte -->
<ng-template #helpCostCalcContent>
    <p>Im ersten Schritt müssen Sie zunächst eine Kostenstelle (Konfiguration -> 
       Kostenstelle) anlegen und ihr Messpunkte zuordnen.</p>
    <p>Im nächsten Schritt weisen Sie den Messpunkten Tarife zu (Konfiguration ->
        Tarife).</p>
    <p>Nun können Sie hier den Zeitraum und die Kostenstelle auswählen.
       Für jeden Tag im gewählten Zeitraum wird der Verbrauch und
       der Tarif für den ersten Messpunkt der Kostenstelle bestimmt
       und aufsummiert und der Betrag in der Tabelle angezeigt.
    </p>
    <p>Dies wird für jeden zugeordneten Messpunkt der Kostenstelle
       wiederholt und in jeweils in einer weiteren Zeile ausgegeben.
    </p>
    <p>Die Summe aller Beträge entspricht dann den Gesamtkosten, die in 
       der letzten Zeile der Rechnung ausgegeben wird. 
    </p>
</ng-template>
<ng-template #helpCostCalcTitle>Wie werden die Kosten berechnet?</ng-template>
