<h4 class="titel clickable" (click)="jumpToEvents()">Ereignisse</h4>
<h6 class="titel-small"
    *ngIf="getLogEvents()?.length > 0">Die aktuellsten Berichte</h6>
<div class="list-group">
    <div
        *ngFor="let ev of getLogEvents(); let indexOfelement=index" 
        (click)="downloadReport(ev)">
        <small>{{ ev.timestamp | date: 'dd.MM.yy HH:mm:ss'}}</small><br/>
        <div class="underline">{{ ev.text }}</div>
        <hr  class="ruler"/>
    </div>

</div>
<h6 class="titel-small"
    *ngIf="getLogActions()?.length > 0"
    >Die letzten Aktionen</h6>
<div class="list-group">
    <div
        *ngFor="let ev of getLogActions(); let indexOfelement=index">
        <small>{{ ev.timestamp | date: 'dd.MM.yy HH:mm:ss'}} 
            <span class="padleft">{{ ev.user }}</span></small><br/>
        <div class="padleft">{{ ev.text }}</div>
        <hr  class="ruler"/>
    </div>
</div>
<div *ngIf="(getLogEvents()?.length == 0) && (getLogActions()?.length == 0)">
    <p>Es liegen keine Berichte oder Aktivitäten vor!</p>
</div>
