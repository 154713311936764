<div class="company-list">
  <div class="row">
    <div class="col-lg-4 col-xl-3 col-md-6 col-sm-6 col-xs-12" *ngFor="let company of companiesArray">
      <div class="card text-center"
           [ngClass]="{'selectedCard': selectedCompany == company.id}"
           (click)=companySelect(company.id)>
        <div class="card-img-top">

          <div class="hover-overlay check-overlay" *ngIf="selectedCompany == company.id"
            [ngClass]="{'selected': selectedCompany == company.id}">
            <i class="fa fa-check" aria-hidden="false"></i>
          </div>
          <img [src]="company.logo" alt="" *ngIf="company.logo.length > 50">
          <small *ngIf="company.logo.length <= 50">{{ company.name }}</small>
        </div>
        <div class="card-body ">
          <a *ngIf="selectedCompany != company.id" (click)=companySelect(company.id)
             class="btn ">Auswählen</a>
          <a *ngIf="selectedCompany == company.id" (click)="changeCompany()"
             [ngClass]="{'en-active': selectedCompany == company.id}" class="btn">Ändern</a>


        </div>
      </div>
    </div>
    <div class="col-md-12 addCompany">
      <div class="card">
        <div class="card-body">
          <button type="submit" (click)="createCompany()" class="submit-button">
            Neue Firma erstellen
          </button>
        </div>
      </div>
    </div>

  </div>

</div>

