import {Component, OnInit, Input, ChangeDetectionStrategy} from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { CompanySelectComponent } from '../config/company-select/company-select.component';
import {ConfigEvent, ConfigService} from '../../../services/config.service';
import {ReportEvent, ReportService} from '../../../services/report.service';
import {Global} from '../../../../constants/global';
import { User } from 'src/app/classes/user';
import { HttpClient } from '@angular/common/http';
import { LogeventsService } from 'src/app/services/logevents.service';

@Component({
  selector: 'app-vertical-tabs',
  templateUrl: './vertical-tabs.component.html',
  styleUrls: ['./vertical-tabs.component.scss'],


})
export class VerticalTabsComponent implements OnInit {


  navbarOpen = false;
  mobile = false;

  //für ausgewählte Firma
  companySelect = false;
  companyName: String;
  companyLogo: String;

  get runChangeDetection() {
    return true;
  }

  constructor(
    breakpointObserver: BreakpointObserver,
    private configService : ConfigService,
    private reportService : ReportService,
    private logeventsService : LogeventsService,
    private http: HttpClient
  ) {
    breakpointObserver.observe([
      '(max-width: 1200px)'
    ]).subscribe(result => {
      if (result.matches) {
        this.mobile = true;
        this.navbarOpen = true;
      } else {
        // if necessary:
        this.mobile = false;
        this.navbarOpen = false;
      }
    });
  }

  ngOnInit() {

  }

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
    this.mobile = false;
  }

  update() {
    //this.cd.detectChanges();

  }

  jumpToOldSite() : void
  {
      this.http.get(Global.apiUrl + "rev2/get/quicktoken", {responseType: 'text'})
        .subscribe( data => {
            window.open(Global.legacyUrl +"account/quick?id=" + data, "_blank");
        },
        data => {
        });


  }

  setDefaultAlarmData()
  {
    this.reportService.reportEvent$.emit(new ReportEvent(Global.SIGNAL_DEFAULT_ALARMS));
  }

  refreshEvents() : void
  {
      this.logeventsService.loadEvents();
  }

  refreshTodos() : void
  {
      this.configService.loadTodos();
  }

  refreshCosts() : void
  {
    this.configService.configEvent$.emit(new ConfigEvent(Global.SIGNAL_REFRESH_COST_CALC))
  }

  isAdmin() : boolean
  {
      let usrstr = sessionStorage.getItem(Global.ATTRIBUTE_USER_OBJECT);
      let user : User = JSON.parse(usrstr);
      if (user == null)
         return false;
      return user.admin;
  }

  isModerator() : boolean
  {
      let usrstr = sessionStorage.getItem(Global.ATTRIBUTE_USER_OBJECT);
      let user : User = JSON.parse(usrstr);
      if (user == null)
         return false;
      return user.admin || user.moderator;
  }


}
