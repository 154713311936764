import { Component, OnInit } from '@angular/core';
import { PopupService, PopupEvent  } from '../../../services/popup.service';
import { ToastrService } from 'ngx-toastr';
import { interval } from 'rxjs';
import { ConstantPool } from '@angular/compiler';
import { formatCurrency, formatDate } from '@angular/common';
import { timeout } from 'rxjs/operators';
import { Popup } from 'src/app/classes/popup';
import { Global } from 'src/constants/global';
import { Router } from '@angular/router';
import { User } from 'src/app/classes/user';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    public count:number = 0;

  constructor(private popup : PopupService,
              private router: Router,
              private toastr: ToastrService ) 
  {
      popup.popupEvent$.subscribe((event : PopupEvent) => 
      {
          if (event.name == Global.SIGNAL_REFRESH_POPUPS)
             this.refreshBadge(event)
      }) ;  
  }

  ngOnInit(): void {
  }

  deleteAll() : void
  {
      this.popup.dismissAllPopups();
  }

  showAll() : void 
  {
    for (var i = 0; i < this.popup.popupNotifications.length; i++)
    {
        var el = this.popup.popupNotifications[i];
        el['visible'] = true;
        this.showPopup(el);
    }
  }

  showPopup(el : Popup) : void
  {
    if (el['visible'])
    {
        var t1 = null;
        var message = el['text'];
        message = "<small>" + this.formatDate(el['timestamp']) + "</small><br/>"  + message;
        var title   = el['title'];
        if (el.progress >= 0)
        {
            var perc = el['progress'];
            perc = perc - (perc % 5);
            message += '<div class="progress">';
            message += '<div class="progress-bar w' + perc;
            message += '" ></div>';
            message += '</div>';
        }
        if (el.added == true)
            return;
        el.added = true;
        if (el.type.toLowerCase() == 'success')
        {
            t1 = this.toastr.success(message, title,
                { closeButton: true,
                  timeOut: Global.POPUP_REFRESH_TIMEOUT,
                  enableHtml: true});
            
        }
        else
        if (el.type.toLowerCase()== 'info')
        {
            t1 = this.toastr.info(message, title,
                { closeButton: true,
                  timeOut: Global.POPUP_REFRESH_TIMEOUT,
                  enableHtml: true});
        }
        else t1 = this.toastr.error(message, title,
            { closeButton: true,
              timeOut: Global.POPUP_REFRESH_TIMEOUT,
              enableHtml: true});
        var obs = t1.onHidden.subscribe(function onHideToast2() {
            var el1 = this._parentSubscriber['dummy'];
            var service = this._parentSubscriber['service'];
            el1['newEntry'] = false;
            if ((el1['progress'] < 0) ||
                (el1['progress'] == 100))                            
            {
                // Auf dem Server dismissen
                service.dismissPopup(el1);
            }
            else
            {
                service.updatePopup(el1);
            }
        });
        obs['dummy'] = el;
        obs['service'] = this.popup;
        this.popup.updatePopup(el);
    }
  }

  refreshBadge(event)
  {
    this.count = this.popup.getBadgeCount();
    if (this.popup.getBadgeCount() > 0)
    {
        for (var i = 0; i < this.popup.popupNotifications.length; i++)
        {
            var el = this.popup.popupNotifications[i];
            this.showPopup(el);
        }
    }
  }

  formatDate(timestamp : number) : string
  {
      var pattern = "HH:mm:ss";
      if (Date.now() - timestamp > 24 *60 * 60 * 1000)
          pattern = "dd.MM.yy HH:mm";
      var d = new Date(timestamp);
      return formatDate(d, pattern, "en-US");
  }

  logout() : void
  {
      sessionStorage.clear();
      this.router.navigateByUrl("/");
  }

  getUserName() : string
  {
    let usrstr = sessionStorage.getItem(Global.ATTRIBUTE_USER_OBJECT);
    let user : User = JSON.parse(usrstr);    
    if (user == null)
       return "" ;
    return user.email;
  }
  
  isModerator() : boolean
  {
    let usrstr = sessionStorage.getItem(Global.ATTRIBUTE_USER_OBJECT);
    let user : User = JSON.parse(usrstr);    
    if (user == null)
       return false;      
    return user.moderator;
  }

  isAdmin() : boolean
  {
    let usrstr = sessionStorage.getItem(Global.ATTRIBUTE_USER_OBJECT);
    let user : User = JSON.parse(usrstr);    
    if (user == null)
       return false;      
    return user.admin;
  }

  isUser() : boolean
  {
    let usrstr = sessionStorage.getItem(Global.ATTRIBUTE_USER_OBJECT);
    let user : User = JSON.parse(usrstr);    
    if (user == null)
       return true;      
    return (!user.moderator) && (!user.admin);
  }


}
