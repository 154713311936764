import { Component, OnInit } from '@angular/core';
import { ConfigEvent, ConfigService } from 'src/app/services/config.service';
import { Global } from 'src/constants/global';
import { CostUnit } from 'src/app/classes/costunit';
import { Meter } from 'src/app/classes/meter';

declare var $: any;

@Component({
  selector: 'app-costunit',
  templateUrl: './costunit.component.html',
  styleUrls: ['./costunit.component.scss']
})
export class CostunitComponent implements OnInit 
{

    costUnits : CostUnit[] = [];
    configService : ConfigService;
    errorMessageModal : string ="";
    // Variablen fürs Formular
    unitId          : number = -1;
    unitName        : string = "";
    unitDescription : string = "";
    selectedMeters  : string[];
    allMeters       : Meter[];
    buttonText      : string = "";
    actionText      : string = "";


    constructor(cs : ConfigService) 
    { 
        this.configService = cs;
    }

    ngOnInit(): void 
    {
        this.configService.configEvent$.subscribe((ev : ConfigEvent) =>
            {
                if (ev.name == Global.SIGNAL_REFRESH_COSTUNIT)
                {
                    this.refreshCostUnits();
                }
                if (ev.name == Global.SIGNAL_REFRESH_METER)
                {
                    this.refreshMeters();
                }
            });
        this.configService.loadCostUnits();
        this.configService.loadMetersForCompany();
    }

    refreshCostUnits() : void
    {
        this.costUnits = this.configService.getCostUnits();
    }

    refreshMeters() : void
    {
        this.allMeters = this.configService.metersForCurrentCompany;
        this.selectedMeters = [];
    }

    validateModalData() : void
    {
        if ((this.unitName == null) || (this.unitName.length == 0))
        {
            this.errorMessageModal = "Bitte einen Namen eingeben!";
            return;
        }
        let cu : CostUnit = new CostUnit();
        cu.companyId = this.configService.getSelectedCompany().id;
        cu.name      = this.unitName;
        cu.meters    = this.selectedMeters;
        cu.description = this.unitDescription;
        cu.id          = this.unitId;
        this.configService.saveCostUnit(cu);
        $("#costUnitModal").modal('hide');
    }

    createCostUnit() : void
    {
        this.unitDescription = "";
        this.unitId          = -1;
        this.unitName        = "";
        this.selectedMeters  = [];
        this.buttonText      = "Erstellen";
        this.actionText      = "erstellen"
    }

    getMeterName(meter : string) : string
    {
        if (this.allMeters == null)
           return "?? " + meter;
        for (let i = 0; i < this.allMeters.length; i++)
        {
            if (this.allMeters[i].id == meter)
                return this.allMeters[i].values.user0;
        }
        return "?? " + meter;
    }

    removeCostUnit(cu : CostUnit) : void
    {
        this.configService.deleteCostUnit(cu);
    }

    openModal(cu : CostUnit) : void
    {
        this.unitId   = cu.id;
        this.unitDescription = cu.description;
        this.unitName = cu.name;
        this.actionText = "ändern";
        this.buttonText = "Ändern";
        this.selectedMeters = [];
        this.errorMessageModal = "";
        for (let i = 0; i < cu.meters.length; i++)
        {
            let am = cu.meters[i];
            for (let m = 0; m < this.allMeters.length; m++)
            {
                if (am == this.allMeters[m].id)
                    this.selectedMeters.push(am);

            }
        }
    }
}
