<div class="row">
    <div class="col-xl-10 col-md-12 col-12">
        <div class="card">
            <div class="card-body">
                <form class="form-detail" #reportForm="ngForm">
                    <div class="row form-left">
                        <h2 class="titel ">Erstellen Sie einen neuen Bericht
                            <i class="fa fa-info-circle help-icon" 
                                placement="top" 
                                container="body"
                                popoverClass="help-popover-wide"
                                triggers="mouseenter:mouseleave"
                                [ngbPopover]="helpReportContent" 
                                [popoverTitle]="helpReportTitle"
                                aria-hidden="true"></i>           
                        </h2>
                        <br>
                        <div class="errorLine">
                            <div class="alert alert-danger" *ngIf='errorMessage'>{{errorMessage}}</div>
                        </div>
                        <div class="form">
                            <div class="row form-group">
                                <div class="col-lg-6 col-md-12 col-sm-12">
                                    <label>Berichtstyp:
                                        <i class="fa fa-info-circle help-icon" 
                                            placement="top" 
                                            container="body"
                                            popoverClass="help-popover-wide"
                                            triggers="mouseenter:mouseleave"
                                            [ngbPopover]="helpTypeContent" 
                                            [popoverTitle]="helpTypeTitle"
                                            aria-hidden="true"></i>           
        
                                    </label>
                                    <ng-select [items]="reportsArray"
                                               bindLabel="title"
                                               (change)="getMaxCharts()"
                                               [multiple]="false"
                                               [clearable]="false"
                                               [(ngModel)]="selectedReport"
                                               [ngModelOptions]="{standalone: true}">
                                    </ng-select>
                                </div>
                                <div class="col-lg-6 col-md-12 col-sm-12">
                                    <label>Berichtszeitraum:
                                        <i class="fa fa-info-circle help-icon" 
                                            placement="bottom" 
                                            container="body"
                                            popoverClass="help-popover-wide"
                                            triggers="mouseenter:mouseleave"
                                            [ngbPopover]="helpTimeContent" 
                                            [popoverTitle]="helpTimeTitle"
                                            aria-hidden="true"></i>           
        
                                    </label>
                                    <ng-select [items]="recurrenceArray" id="recurrence"
                                               [multiple]="false"
                                               [clearable]="false"
                                               [(ngModel)]="selectedRecurrence"
                                               [ngModelOptions]="{standalone: true}"
                                               (change)="setDefaultStartDate()">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="row form-group">
                                <div class="col-md-6 input-group mb-3">
                                    <label>Startdatum:
                                        <i class="fa fa-info-circle help-icon" 
                                        placement="top" 
                                        container="body"
                                        popoverClass="help-popover-wide"
                                        triggers="mouseenter:mouseleave"
                                        [ngbPopover]="helpStartContent" 
                                        [popoverTitle]="helpStartTitle"
                                        aria-hidden="true"></i>                                                   
                                    </label>
                                    <input class="form-control date" placeholder="TT.MM.JJJJ"
                                           (click)="d.toggle()"
                                           (ngModelChange)="setChangedEndDate($event)"
                                           name="start"
                                           [(ngModel)]="tarDateS"
                                           [ngModelOptions]="{standalone: true}"
                                           required ngbDatepicker
                                           #d="ngbDatepicker"

                                    />
                                    <div class="input-group-append">
                                         <span class="input-group-text" (click)="d.toggle()"
                                               (ngModelChange)="setChangedEndDate($event)">
                                            <svg width="1em" height="1em" viewBox="0 0 16 16"
                                                 class="bi bi-calendar-date"
                                                 fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                 <path fill-rule="evenodd"
                                                       d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/>
                                                    <path d="M6.445 11.688V6.354h-.633A12.6 12.6 0 0 0 4.5 7.16v.695c.375-.257.969-.62 1.258-.777h.012v4.61h.675zm1.188-1.305c.047.64.594 1.406 1.703 1.406 1.258 0 2-1.066 2-2.871 0-1.934-.781-2.668-1.953-2.668-.926 0-1.797.672-1.797 1.809 0 1.16.824 1.77 1.676 1.77.746 0 1.23-.376 1.383-.79h.027c-.004 1.316-.461 2.164-1.305 2.164-.664 0-1.008-.45-1.05-.82h-.684zm2.953-2.317c0 .696-.559 1.18-1.184 1.18-.601 0-1.144-.383-1.144-1.2 0-.823.582-1.21 1.168-1.21.633 0 1.16.398 1.16 1.23z"/>
                                            </svg>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-6 input-group mb-3"
                                    *ngIf="selectedRecurrence == 'Individuell'">
                                    <label>Enddatum:</label>
                                    <input class="form-control date" placeholder="TT.MM.JJJJ" (click)="dd.toggle()"
                                           name="end"
                                           [(ngModel)]="endDateS"
                                           (ngModelChange)="validateEndDate($event)"
                                           [ngModelOptions]="{standalone: true}"
                                           [disabled]="selectedRecurrence!='Individuell'"
                                           required ngbDatepicker
                                           #dd="ngbDatepicker"/>
                                    <div class="input-group-append">
                                     <span class="input-group-text" (click)="dd.toggle()">
                                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-calendar-date"
                                             fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd"
                                                  d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/>
                                            <path d="M6.445 11.688V6.354h-.633A12.6 12.6 0 0 0 4.5 7.16v.695c.375-.257.969-.62 1.258-.777h.012v4.61h.675zm1.188-1.305c.047.64.594 1.406 1.703 1.406 1.258 0 2-1.066 2-2.871 0-1.934-.781-2.668-1.953-2.668-.926 0-1.797.672-1.797 1.809 0 1.16.824 1.77 1.676 1.77.746 0 1.23-.376 1.383-.79h.027c-.004 1.316-.461 2.164-1.305 2.164-.664 0-1.008-.45-1.05-.82h-.684zm2.953-2.317c0 .696-.559 1.18-1.184 1.18-.601 0-1.144-.383-1.144-1.2 0-.823.582-1.21 1.168-1.21.633 0 1.16.398 1.16 1.23z"/>
                                        </svg>
                                     </span>
                                    </div>
                                </div>
                            </div>
                            <div class="row form-group">
                                <div class="col-xl-3 col-md-4 col-sm-3 col-4">
                                    <label>Diagrammtypen:</label>
                                </div>
                            </div>
                            <div class="row form-group charts">
                                <div class="col-xl-3 col-md-4 col-sm-3 col-4 card-img-top"
                                     (click)="useBarConsumption()">
                                    <div class="hover-overlay" [ngClass]="{'selected': barConsumption}">
                                        <i class="fa fa-check" aria-hidden="false"
                                            placement="top"
                                            ngbTooltip="Balkendiagramm"
                                            >
                                        </i>
                                    </div>
                                    <img src="assets/chart_bar.png" alt="Balkendiagramm"
                                            placement="top"
                                            ngbTooltip="Balkendiagramm"
                                    >
                                </div>
                                <div class="col-xl-3 col-md-4 col-sm-3 col-4 card-img-top" (click)="useCapStepLine()">
                                    <div class="hover-overlay" [ngClass]="{'selected': capStepLine}">
                                        <i class="fa fa-check" aria-hidden="false"
                                           placement="top"
                                            ngbTooltip="Stepline-Diagramm"
                                        ></i>
                                    </div>
                                    <img src="assets/stepline.png" alt="Stepline"
                                         placement="top"
                                         ngbTooltip="Stepline-Diagramm"
                                        >
                                </div>
<!--
                                <div class="col-xl-3 col-md-4 col-sm-3 col-4 card-img-top"
                                     (click)="useCapConsumptionCombined()">
                                    <div class="hover-overlay" [ngClass]="{'selected': capConsumptionCombined}">
                                        <i class="fa fa-check" aria-hidden="false"></i>
                                    </div>
                                    <img src="assets/combined.png" alt="Combined">
                                </div>
-->                                                                
                            </div>
                            <div class="row form-group" *ngIf="selectedReport?.title != 'Kostenübersicht'">
                                <div class="col-md-12">
                                    <label for="meter">Wählen Sie die Messpunkte aus:
                                        <i class="fa fa-info-circle help-icon" 
                                        placement="top" 
                                        container="body"
                                        popoverClass="help-popover-wide"
                                        triggers="mouseenter:mouseleave"
                                        [ngbPopover]="helpMPContent" 
                                        [popoverTitle]="helpMPTitle"
                                        aria-hidden="true"></i>                                            
                                    </label>
                                    <ng-select [items]="allMeters" id="meter"
                                               bindLabel="values.user0"
                                               name="meters"
                                               #ngSelect
                                               [multiple]="true"
                                               [clearable]="true"
                                               [(ngModel)]="selectedMeters"
                                               [ngModelOptions]="{standalone: true}">
                                               <ng-template ng-option-tmp ng-label-tmp let-item="item">
                                                <label class="icon-width">
                                                    <i *ngIf="item.values.obisid0 == '1-0:1.8.0*255'"
                                                        class="fa fa-bolt icon-width"></i>
                                                    <i *ngIf="item.values.obisid0 == '8-0:1.0.0*255'"
                                                        class="fa fa-tint icon-width"></i>
                                                    <i *ngIf="item.values.obisid0 == '6-0:1.0.0*255'"
                                                        class="fa fa-fire icon-width"></i> <!-- red -->
                                                    <i *ngIf="item.values.obisid0 == '7-0:3.0.0*255'"
                                                        class="fa fa-fire icon-width"></i> <!-- blue -->
                                                    <i *ngIf="item.values.obisid0 == '6-0:2.0.0*255'"
                                                        class="fa fa-tint icon-width"></i>
                                                    <i *ngIf="item.values.obisid0 == '8-0:1.0.0*255'"
                                                        class="fa fa-plug icon-width"></i>
                                                    <i *ngIf="item.values.obisid0 == null"
                                                        class="fa fa-question"></i>
                                                </label>
                                                {{ item.values.user0 }}       
                                            </ng-template>                                 
                                    </ng-select>                                               
                                </div>
                            </div>
                            <div class="row form-group" *ngIf="selectedReport?.title == 'Kostenübersicht'">
                                <div class="col-md-12">
                                    <label for="meter">Wählen Sie eine Kostenstelle aus:
                                        <i class="fa fa-info-circle help-icon" 
                                        placement="top" 
                                        container="body"
                                        popoverClass="help-popover-wide"
                                        triggers="mouseenter:mouseleave"
                                        [ngbPopover]="helpMPContent" 
                                        [popoverTitle]="helpMPTitle"
                                        aria-hidden="true"></i>                                            
                                    </label>
                                    <!-- Hier select-->
                                    <ng-select [(ngModel)]="selectedCostUnit"
                                            [ngModelOptions]="{standalone: true}">
                                            <ng-option *ngFor="let unit of allCostUnits" 
                                                [value]="unit.id">{{unit.name}}</ng-option>
                                    </ng-select>                                               

                                </div>
                            </div>
                            <div *ngIf="isModerator()">
                                <div class="row form-group" >
                                    <div class="col-md-12 changeRepeat">
                                        <span>Wiederholung: 
                                            <i class="fa fa-info-circle help-icon" 
                                            placement="top" 
                                            container="body"
                                            popoverClass="help-popover-wide"
                                            triggers="mouseenter:mouseleave"
                                            [ngbPopover]="helpRepeatContent" 
                                            [popoverTitle]="helpRepeatTitle"
                                            aria-hidden="true"></i>    
                                            &nbsp;
                                        </span>
                                        <button class="btn btn-primary" 
                                              (click)="validateAndShowModal()">aktivieren</button>
                                    </div>
                                </div>
                                <div *ngIf="false" class="form-group mb-3 row repeat">
                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <label>Name:
                                        <i class="fa fa-info-circle help-icon"
                                            placement="top"
                                            container="body"
                                            popoverClass="help-popover-wide"
                                            triggers="mouseenter:mouseleave"
                                            [ngbPopover]="helpNameContent"
                                            [popoverTitle]="helpNameTitle"
                                            aria-hidden="true"></i></label>
                                        <input readonly type="text" [(ngModel)]="repeatName"
                                               name="name"
                                               style="height: 36px;width: 100%">

                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <label>Wiederholungszeitraum:
                                            <i class="fa fa-info-circle help-icon"
                                            placement="top"
                                            container="body"
                                            popoverClass="help-popover-wide"
                                            triggers="mouseenter:mouseleave"
                                            [ngbPopover]="helpWZContent"
                                            [popoverTitle]="helpWZTitle"
                                            aria-hidden="true"></i><
                                        </label>
                                        <ng-select [items]="recurrenceArray"
                                                   [multiple]="false"
                                                   [clearable]="false"
                                                   #ngSelect       
                                                   [disabled]="true"
                                                   [(ngModel)]="selectedPeriod"
                                                   [ngModelOptions]="{standalone: true}">
                                        </ng-select>
                                    </div>
                                    <div class="col-md-6 input-group mb-3 pad-top">
                                        <label>Wiederhole von:
                                            <i class="fa fa-info-circle help-icon"
                                               placement="top"
                                               container="body"
                                               popoverClass="help-popover-wide"
                                               triggers="mouseenter:mouseleave"
                                               [ngbPopover]="helpStartRepeatContent"
                                               [popoverTitle]="helpStartRepeatTitle"
                                               aria-hidden="true"></i></label>
                                        <input class="form-control date"
                                               readonly
                                               name="repeat"
                                               [(ngModel)]="startRepeat"
                                               placeholder="TT.MM.JJJJ"
                                               required ngbDatepicker
                                               #d="ngbDatepicker"/>
                                        <div class="input-group-append">
                                         <span class="input-group-text ig-height" (click)="d.toggle()">
                                            <svg width="1em" height="1em" viewBox="0 0 16 16"
                                                 class="bi bi-calendar-date"
                                                 fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                 <path fill-rule="evenodd"
                                                       d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/>
                                                    <path d="M6.445 11.688V6.354h-.633A12.6 12.6 0 0 0 4.5 7.16v.695c.375-.257.969-.62 1.258-.777h.012v4.61h.675zm1.188-1.305c.047.64.594 1.406 1.703 1.406 1.258 0 2-1.066 2-2.871 0-1.934-.781-2.668-1.953-2.668-.926 0-1.797.672-1.797 1.809 0 1.16.824 1.77 1.676 1.77.746 0 1.23-.376 1.383-.79h.027c-.004 1.316-.461 2.164-1.305 2.164-.664 0-1.008-.45-1.05-.82h-.684zm2.953-2.317c0 .696-.559 1.18-1.184 1.18-.601 0-1.144-.383-1.144-1.2 0-.823.582-1.21 1.168-1.21.633 0 1.16.398 1.16 1.23z"/>
                                            </svg>
                                        </span>
                                        </div> &nbsp;
                                        <select [(ngModel)]="hour" class="ig-height"
                                            disabled
                                                [ngModelOptions]="{standalone: true}">
                                            <option *ngFor="let val of listHour" value="{{val}}">{{val}}</option>
                                        </select>
                                        <div style="padding-top: 5px;">:</div>
                                        <select [(ngModel)]="minute"  class="ig-height"
                                            disabled
                                                [ngModelOptions]="{standalone: true}">
                                            <option *ngFor="let val of listMinute">{{val}}</option>
                                        </select>

                                    </div>
                                    <div class="col-md-6 input-group mb-3">
                                        <label class="pad-top">Wiederhole bis:</label>
                                        <input class="form-control date" placeholder="TT.MM.JJJJ" 
                                               name="dp"
                                               readonly
                                               [(ngModel)]="endRepeat"
                                               required ngbDatepicker
                                               #dd="ngbDatepicker"/>
                                        <div class="input-group-append">
                                     <span class="input-group-text">
                                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-calendar-date"
                                             fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd"
                                                  d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/>
                                            <path d="M6.445 11.688V6.354h-.633A12.6 12.6 0 0 0 4.5 7.16v.695c.375-.257.969-.62 1.258-.777h.012v4.61h.675zm1.188-1.305c.047.64.594 1.406 1.703 1.406 1.258 0 2-1.066 2-2.871 0-1.934-.781-2.668-1.953-2.668-.926 0-1.797.672-1.797 1.809 0 1.16.824 1.77 1.676 1.77.746 0 1.23-.376 1.383-.79h.027c-.004 1.316-.461 2.164-1.305 2.164-.664 0-1.008-.45-1.05-.82h-.684zm2.953-2.317c0 .696-.559 1.18-1.184 1.18-.601 0-1.144-.383-1.144-1.2 0-.823.582-1.21 1.168-1.21.633 0 1.16.398 1.16 1.23z"/>
                                        </svg>
                                     </span>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="center">
                        <button (click)="handleCreateOrUpdate()"
                                class="submit-button"
                        >Bericht erstellen
                        </button>
                    </div>

                </form>
            </div>
        </div>
    </div>
</div>
<!-- Modal -->
    <div class="modal fade" id="reportModal" tabindex="-1" role="dialog" aria-hidden="true" #reportModal>
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title titel">Legen Sie die Wiederholung an</h5>
                    <button type="button" class="close" (click)="closeModal()"
                        data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <form #modalForm="ngForm">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="alert alert-danger" *ngIf='errorMessageModal'>{{errorMessageModal}}</div>
                            </div>
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-5">
                                        <label>Name:
                                            <i class="fa fa-info-circle help-icon"
                                            placement="bottom"
                                            container="body"
                                            popoverClass="help-popover-wide"
                                            triggers="mouseenter:mouseleave"
                                            [ngbPopover]="helpNameContent"
                                            [popoverTitle]="helpNameTitle"
                                            aria-hidden="true"></i></label>
                                    </div>
                                    <div class="col-md-7">
                                        <input type="text" [(ngModel)]="repeatName"
                                               name="name"
                                               style="width: 100%">

                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-5">
                                        <label>Beschreibung:</label>
                                    </div>
                                    <div class="col-md-7">
                                        <textarea name="desc"
                                                  rows="5"
                                                  [(ngModel)]="description"
                                                  style="width: 100%;padding: 7px"
                                        ></textarea>

                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-5 recurrence">
                                        <label for="repeat">Wiederholungsintervall:</label>
                                    </div>
                                    <div class="col-md-7">
                                        <ng-select [items]="recurrenceArray"
                                                   id="repeat"
                                                   [multiple]="false"
                                                   [clearable]="false"
                                                   [(ngModel)]="selectedPeriod"
                                                   [ngModelOptions]="{standalone: true}">
                                        </ng-select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-5">
                                        <label>Wiederhole ab:
                                            <i class="fa fa-info-circle help-icon" 
                                                placement="top"
                                                container="body"
                                                popoverClass="help-popover-wide"
                                                triggers="mouseenter:mouseleave"
                                                [ngbPopover]="helpStartRepeatContent" 
                                                [popoverTitle]="helpStartRepeatTitle"
                                                aria-hidden="true"></i>
                                        </label>
                                    </div>
                                    <div class="col-md-7 input-group">
                                        <input class="form-control date"
                                               (click)="m.toggle()"
                                               name="repeat"
                                               [(ngModel)]="startRepeat"
                                               placeholder="TT.MM.JJJJ"
                                               required ngbDatepicker
                                               #m="ngbDatepicker"/>
                                        <div class="input-group-append">
                                         <span class="input-group-text" (click)="m.toggle()">
                                            <svg width="1em" height="1em" viewBox="0 0 16 16"
                                                 class="bi bi-calendar-date"
                                                 fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                 <path fill-rule="evenodd"
                                                       d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/>
                                                    <path d="M6.445 11.688V6.354h-.633A12.6 12.6 0 0 0 4.5 7.16v.695c.375-.257.969-.62 1.258-.777h.012v4.61h.675zm1.188-1.305c.047.64.594 1.406 1.703 1.406 1.258 0 2-1.066 2-2.871 0-1.934-.781-2.668-1.953-2.668-.926 0-1.797.672-1.797 1.809 0 1.16.824 1.77 1.676 1.77.746 0 1.23-.376 1.383-.79h.027c-.004 1.316-.461 2.164-1.305 2.164-.664 0-1.008-.45-1.05-.82h-.684zm2.953-2.317c0 .696-.559 1.18-1.184 1.18-.601 0-1.144-.383-1.144-1.2 0-.823.582-1.21 1.168-1.21.633 0 1.16.398 1.16 1.23z"/>
                                            </svg>
                                        </span>
                                        </div> &nbsp;
                                        <select [(ngModel)]="hour" 
                                            [ngModelOptions]="{standalone: true}">
                                            <option *ngFor="let val of listHour" value="{{val}}">{{val}}</option>
                                        </select>
                                        <div style="padding-top: 5px;">:</div>
                                        <select [(ngModel)]="minute"
                                            [ngModelOptions]="{standalone: true}">
                                            <option *ngFor="let val of listMinute">{{val}}</option>
                                        </select>
                                    </div>
                                </div>

                            </div>
                            <div class="col-md-12">
                                <div class="row pad-top">
                                    <div class="col-md-5">
                                        <label style="width: 180px">E-Mails:</label>
                                    </div>
                                    <div class="col-md-7">
                                        <ng-select [items]="allMails"
                                                   bindLabel="name"
                                                   name="mails"
                                                   [multiple]="true"
                                                   [clearable]="true"
                                                   [(ngModel)]="selectedMails"
                                                   [ngModelOptions]="{standalone: true}">
                                        </ng-select>
                                    </div>
                                </div>
                                <div class="row pad-top">
                                    <div class="col-md-5">
                                        <label style="width: 180px">E-Mail hinzufügen:
                                            <i class="fa fa-info-circle help-icon" 
                                                placement="top" 
                                                container="body"
                                                popoverClass="help-popover-wide"
                                                triggers="mouseenter:mouseleave"
                                                [ngbPopover]="helpAddMailContent" 
                                                [popoverTitle]="helpAddMailTitle"
                                                aria-hidden="true"></i>                                            
                                        </label>
                                    </div>
                                    <div class="col-md-7 input-group">
                                        <input type="email" placeholder="email@example.com"
                                                style="height:35px"
                                                [ngModelOptions]="{standalone: true}"
                                                [(ngModel)]="addMail">
                                        <div class="input-group-append">
                                            <button class="btn btn-primary btn-sm"(click)="addNewMail()"
                                                style="height: 35px; margin-left: -3px;">+</button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-5">
                                        <label>Aktiv:</label>
                                    </div>
                                    <div class="col-md-7">
                                        <input type="checkbox"
                                               name="check"
                                               style="vertical-align: middle; display: none"
                                               [checked]="active"
                                               #yes>
                                        <i (click)="yes.checked=true" *ngIf="!yes.checked"
                                        style="color: #2e2d5a" 
                                        class="fa fa-square-o"></i>
                                        <i (click)="yes.checked=false" *ngIf="yes.checked"
                                            style="color: #2e2d5a"
                                            class="fa fa-check-square"></i>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </form>
                </div>
                <div class="modal-footer center">
                    <button type="button"
                            class="submit-button"
                            (click)="validateModalData()"
                    >Wiederholung anlegen
                    </button>

                </div>
            </div>
        </div>
    </div>
<!-- Hilfepopovers -->
<ng-template #helpStartRepeatContent>
    <p>... legt fest, wann die Berichtserstellung das erste Mal startet.</p>
    <p>Die angegebene Uhrzeit bestimmt, um wieviel Uhr an diesem Tag die
       Berichtserstellung anläuft. Bitte beachten Sie, dass der Server nur
       alle 5 min prüft, ob eine Berichtserstellung ansteht. 
    </p>
    <p>Damit wird der Bericht erst erstellt, wenn die erste Prüfung <i>nach</i>
       der angegebenen Zeit anläuft.</p>
</ng-template>
<ng-template #helpStartRepeatTitle>Wiederholung von...</ng-template>

<ng-template #helpAddMailContent>
    <p>Wenn Sie die Berichte an Personen verschicken wollen, deren E-Mail nicht
       in der Auswahlliste erscheinen, so können Sie über dieses Eingabefeld
       hinzufügen.</p>
    <p>Dies geschieht nur für diesen Bericht und ist nicht allgemeingültig
       für andere Berichte und / oder E-Mail-Selektionen.
    </p>
</ng-template>
<ng-template #helpAddMailTitle>E-Mail-Adresse hinzufügen</ng-template>


<ng-template #helpNameContent>
    <p>Dient zum einen der eindeutigen Identifizierung des Berichts, 
       zum anderen wird der Name als Betreff der versendeten E-Mail gesetzt.
    </p>
    Sie können <i>einen</i> der folgenden Platzhalter in den Titel einsetzen:
        <UL>
            <li><kbd>$&#123;DATUM}</kbd><br/>
                Wird durch das aktuelle Datum ersetzt: 02.01.2021
            </li>
            <li><kbd>$&#123;KW}</kbd><br/>
                Kalenderwoche z.B. 40/2020.
            </li>
            <li><kbd>$&#123;JAHR}</kbd><br/>
                Wird durch das aktuelle Jahr ersetzt: 2021
            </li>
            <li><kbd>$&#123;QUARTAL}</kbd><br/>
                Wird durch das aktuelle Quartal ersetzt: 01/2021
            </li>
            <li><kbd>$&#123;MONAT}</kbd><br/>
                Wird durch den aktuellen Monat ersetzt: März 2021
            </li>
            <li><kbd>$&#123;ZEIT}</kbd><br/>
                Wird durch die aktuelle Zeit ersetzt: 20:15
            </li>
        </UL>

</ng-template>
<ng-template #helpNameTitle>Der Name...</ng-template>

<ng-template #helpReportContent>
    <p>Erstellen Sie einen Bericht Ihrer Wahl entweder einmalig (sofort) oder
       wiederholt.
    </p>
</ng-template>
<ng-template #helpReportTitle>Berichtserstellung</ng-template>

<ng-template #helpTypeContent>
    <p>Wählen Sie hier aus, welchen Bericht Sie erstellen möchten.
    </p>
</ng-template>
<ng-template #helpTypeTitle>Berichtstyp</ng-template>

<ng-template #helpTimeContent>
    <p>Wählen Sie hier den Berichtszeitraum aus. Durch den Zeitraum legen Sie fest,
     für wieviele Tage (Wochen, Monate) Daten für den Bericht berücksichtigt werden. 
            Der maximale Zeitraum beträgt ein Jahr.
    </p>
</ng-template>
<ng-template #helpTimeTitle>Berichtszeitraum</ng-template>

<ng-template #helpStartContent>
    <p>Ab diesem Datum werden die Daten für den Berichtszeitraum ermittelt.       
    </p>
    <p><EM>Beispiel:</EM><br/>Ist der Berichtszeitraum "Wöchentlich" und das Startdatum der 01.04.2021, so werden 
       für den Bericht Daten vom 01.04.2021 bis zum einschließlich 07.04.2021 ermittelt.
    </p>
</ng-template>
<ng-template #helpStartTitle>Startdatum</ng-template>

<ng-template #helpMPContent>
    <p>Hier wählen Sie die Messpunkte aus, die im gewählten Bericht berücksichtigt werden.
       Für jeden Messpunkt wird eine Seite im Bericht erstellt.       
    </p>
</ng-template>
<ng-template #helpMPTitle>Messpunkte</ng-template>

<ng-template #helpRepeatContent>
    <p>Wenn Sie möchten, dass der Bericht regelmäßig erstellt wird, dann klicken Sie hier und
       es öffnet sich ein Dialog mit Einstellungsmöglichkeiten.       
    </p>
    <p>Möchten Sie den Bericht nur einmalig erstellen, dann klicken Sie einfach auf "Bericht erstellen". </p>
</ng-template>
<ng-template #helpRepeatTitle>Wiederholung</ng-template>

<ng-template #helpCTContent>
    <p>Bitte wählen Sie aus, welche(n) Diagrammtyp(en) Sie im Bericht nutzen wollen.
    </p>
</ng-template>
<ng-template #helpCTTitle>Diagrammtypen</ng-template>

<ng-template #helpWZContent>
    <p>Dieser Zeitraum bestimmt, wieviel Tage (Wochen, Monate) nach der Erstellung 
        des letzten Berichtes ein neuer Bericht erstellt wird.
    </p>
</ng-template>
<ng-template #helpWZTitle>Wiederholungsintervall</ng-template>

