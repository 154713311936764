import {Component, OnInit} from '@angular/core';
//import {Router, ActivatedRoute} from '@angular/router';
import {ConfigEvent, ConfigService} from '../../../../services/config.service';
import {NodeService} from '../../../../services/node.service';
import {NgSelectConfig} from '@ng-select/ng-select';
import {SignalEvent,SignalService} from '../../../../services/signal.service';
import {Global} from '../../../../../constants/global';
import {isEmpty} from 'rxjs/operators';
import {isEmptyExpression} from '@angular/compiler';
import { Popup } from 'src/app/classes/popup';
import { PopupService } from 'src/app/services/popup.service';

@Component({
  selector: 'app-firm-info',
  templateUrl: './firm-info.component.html',
  styleUrls: ['./firm-info.component.scss']
})
export class FirmInfoComponent implements OnInit {

  id: number;
  company: string;
  zip: string;
  description: string;
  street: string;
  country: "Deutschland";
  city: string;
  mail: string;


  error = false;
  success = false;
  errorMessage = 'Invalid Credentials';
  successMessage = 'Successful.';
  companySelectedId;
  isImageSaved: boolean;
  cardImageBase64: string;

  selectedNodes;
  selectedCompany;
  unassigned_nodes;
  assigned_nodes;
  bindValue;
  firmChange = false;


  constructor(
    private configService: ConfigService,
    private nodeService: NodeService,
    private config: NgSelectConfig,
    private signalService : SignalService,
    private popupService  : PopupService
  ) {
    this.configService.configEvent$.subscribe(data =>
    {
      if (data.name == Global.SIGNAL_REFRESH_COMPANY_INFO) {
        if(this.selectedCompany != null){
          this.updateSessionStorage();
        }
        this.refreshCompanyInfo();
      }
    });
  }

  ngOnInit(): void {
    this.country = "Deutschland"
    this.refreshCompanyInfo();
  }

  refreshCompanyInfo()
  {
    let company = this.configService.getSelectedCompany();
    this.selectedCompany = company;
    if (company != null) {
      this.companySelectedId = company.id;
      this.id = company.id;
      this.company = company.name;
      this.mail = company.mail;
      this.description = company.description;
      this.zip = company.zip;
      this.city = company.city;
      this.street = company.street;
      this.cardImageBase64 = company.logo;
      this.loadUnassignedNodes(company.id);

    } else {
      this.id = -1;
      this.loadUnassignedNodes(-1);
    }
  }

  updateCompany() {
    let obj = this.configService.updateCompany(
      this.id, this.company, this.mail, this.description, this.street, this.zip, this.city, this.country, this.cardImageBase64
    ).subscribe(result => {
      this.success = true;
      if(this.selectedNodes != null)
      {
          this.id = result['id'];
          this.connectNodesWithCompany();
      }
      this.configService.configEvent$.emit(new ConfigEvent(Global.SIGNAL_REFRESH_COMPANY_INFO));
      this.popupService.addPopupOk('Erfolg',
      'Die geänderten Firmendaten wurden gespeichert');
    }, () => {
      this.error = true;
      this.success = false;
    });


    return obj;
  }

  isButtonDisabled()
  {
    if (!this.companySelectedId)
    {
      if (this.company)
        return false;
      return true;
    }else {
      if (this.isCompanyInfoChanged())
        return false;
      return true;
    }

  }

  isCompanyInfoChanged() :boolean
  {
    if (this.selectedCompany.name        == this.company          &&
        this.selectedCompany.mail        == this.mail             &&
        this.selectedCompany.country     == this.country          &&
        this.selectedCompany.street      == this.street           &&
        this.selectedCompany.zip         == this.zip              &&
        this.selectedCompany.city        == this.city             &&
        this.selectedCompany.description == this.description      &&
        this.assigned_nodes              == this.selectedNodes    &&
        this.selectedCompany.logo        == this.cardImageBase64
       )
    {
      return false;
    }else
      return true;

  }


  updateSessionStorage(){
    this.selectedCompany.name         = this.company;
    this.selectedCompany.mail         = this.mail;
    this.selectedCompany.description  = this.description;
    this.selectedCompany.street       = this.street;
    this.selectedCompany.zip          = this.zip;
    this.selectedCompany.city         = this.city;
    this.selectedCompany.country      = this.country;
    this.selectedCompany.logo         = this.cardImageBase64;
    sessionStorage.setItem(Global.SESSION_COMPANY, JSON.stringify(this.selectedCompany))
  }


  // Gibt Bild in Base64 Format als String zurück
  handleInputChange(e) {
    let file      = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    let reader    = new FileReader();
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);

  }

  _handleReaderLoaded(e) {
    let reader = e.target;
    this.cardImageBase64 = reader.result;
    if (this.cardImageBase64 != null) {
      this.isImageSaved = true;
    }
  }

  loadUnassignedNodes(company_id) 
  {
    return this.nodeService.getConnections(company_id).subscribe(data => {
      this.unassigned_nodes = data['unassigned'];
      this.unassigned_nodes.sort(this.sortNodesByName);
      let arr = data['assigned'];
      arr.sort(this.sortNodesByName);
      if( arr != null){
        this.selectedNodes  = arr;
        this.assigned_nodes = arr;
      }

    });
  }

  sortNodesByName(n1 : any, n2 : any) : number
  {
      if (n1.name.toLowerCase() < n2.name.toLowerCase())
          return -1;
      else
      if (n1.name.toLowerCase() > n2.name.toLowerCase())
          return 1;
      else return 0;
  }


  connectNodesWithCompany() 
  {
    return this.nodeService.connectNodesWithCompany(this.id, this.getIdArray())
        .subscribe(result => {

    }, (e) => {
        this.popupService.addPopupError("Fehler", "Konnte die Topknoten nicht der Firma zuordnen!")
    });
  }

  getIdArray(){
    let arr = [];
    this.selectedNodes.forEach(element => {
      arr.push(element.id);
    });
    return arr;
  }
}
