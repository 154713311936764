import { Meter } from './meter';

export class CostUnit
{
    id    : number;         // Id in der DB
    name  : string;         // Name der Kostenstelle
    description : string;   // Beschreibung, optional
    companyId   : number;   // Id der Kostenstelle
    nodes : any;            // Ungenutzt: Welchem Knoten ist die KST zugeordnet?
    meters: string[];        // Welche Messpunkte sind der Kostenstelle zugeordnet (IDs)
                            // Können nur Container oder phys. Messpunkte sein
}
