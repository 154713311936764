import { Component, OnInit } from '@angular/core';
import {ReportService} from '../../../../../services/report.service';
import {ConfigService} from '../../../../../services/config.service';
import {Global} from '../../../../../../constants/global';

@Component({
  selector: 'app-todo-validation',
  templateUrl: './todo-validation.component.html',
  styleUrls: ['./todo-validation.component.scss']
})
export class TodoValidationComponent implements OnInit {

  allMails          : any[];
  mail              : any;
  alarmTypeArray    : any[];
  errorMessage      : string;

  selectedAlarmType  : any;
  selectedMails      : any[];
  inputValidation  : boolean = false;
  isActive         : boolean = true;
  
  constructor(
      private reportService : ReportService,
      private configService : ConfigService
  ) {
    this.configService.configEvent$.subscribe(data =>
    {
      this.refreshAlarmData();
    });

  }

  ngOnInit(): void {
    this.loadAlarmData();
    this.refreshAlarmData();
  }
  refreshAlarmData()
  {
    this.allMails  = this.configService.getUsersMailArray();
  }

  loadAlarmData() :void
  {
    let arr = []
    this.reportService.loadAlarmType().subscribe(data =>
    {
      data['alarms'].forEach(element => {
        arr.push(element);
      });
      this.alarmTypeArray    = arr;
      this.selectedAlarmType = arr[1];
      this.mail              = JSON.parse(sessionStorage.getItem('authenticatedUser')).email;
      this.selectedMails     = [this.mail];


    })

  }
  isInputValidation()
  {
    if (this.selectedAlarmType.name == "input_validation")
      this.inputValidation = true;
    else this.inputValidation = false;
  }

  createAlarm()
  {
    let company = this.configService.getSelectedCompany();
    let alarm = this.reportService.createAlarm(
        company.id,
        company.name,
        this.selectedAlarmType.title,
        null,
        null,
        1,
        1,
        0,                      //TODO
        0,                       //TODO
        0,                       //TODO
        null,
        this.selectedMails
    )
  }
}
