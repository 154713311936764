import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../services/auth.service';

//import { LoginPageComponent } from '../login-page.component'

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {
  id : String;
  transactionCode : String;
  password        : String;
  passwordCheck   : String;
  invalidPassword = false;
  passwordSuccess = false;
  errorMessage = 'Invalid Credentials';
  successMessage = 'Successful.';
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService) {

     }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
      this.transactionCode = params.get('transactionCode');
    })

  }
  resetPassword() {
    this.authenticationService.resetPassword(this.id, this.transactionCode, this.password).subscribe((result) => {
      this.invalidPassword = false;
      this.passwordSuccess = true;
      alert("Sie haben erfolgreich Ihr Passwort zurückgesetzt");
      this.router.navigate(['/login']);
    }, () => {
      this.invalidPassword = true;
      this.passwordSuccess = false;
    });
  }

}
