import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginPageComponent } from './components/login-page/login-page.component';
import { LoginComponent } from './components/login-page/login/login.component';
import { ImpressumComponent } from './components/login-page/impressum/impressum.component';
import { DatenschutzComponent } from './components/login-page/datenschutz/datenschutz.component';
import { DashboardComponent } from './components/main-page/dashboard/dashboard.component';
import { AuthenticationService } from './services/auth.service';
import { HttpInterceptorService } from './services/http-interceptor.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { PasswordLostComponent } from './components/login-page/password-lost/password-lost.component';
import { PasswordResetComponent } from './components/login-page/password-reset/password-reset.component';
import { ActivationComponent } from './components/login-page/activation/activation.component';
import { HeaderComponent } from './components/main-page/header/header.component';
import { VerticalTabsComponent } from './components/main-page/vertical-tabs/vertical-tabs.component';
import { MainPageComponent } from './components/main-page/main-page.component';
import { ConfigComponent } from './components/main-page/config/config.component';
import { FirmInfoComponent } from './components/main-page/config/firm-info/firm-info.component';
import { CompanySelectComponent } from './components/main-page/config/company-select/company-select.component';
import { ReportsComponent } from './components/main-page/reports/reports.component';
import { MeterComponent } from './components/main-page/config/meter/meter.component';
import { CurrentCompanyComponent } from './components/main-page/current-company/current-company.component';
import { PopupService } from './services/popup.service';
import { LogeventsService } from './services/logevents.service';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ToastrModule } from 'ngx-toastr';
import { CostComponent } from './components/main-page/cost/cost.component';
import { TariffComponent } from './components/main-page/config/tariff/tariff.component';
import {NgSelectModule} from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';

import { ConfirmDialogComponent } from './components/common/confirm-dialog/confirm-dialog.component';
import { ConfirmService } from './services/confirm.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import {IMaskModule} from 'angular-imask';
import { EventsComponent } from './components/main-page/dashboard/events/events.component';
import { EventlogComponent } from './components/main-page/eventlog/eventlog.component';
import { ReportsConfigComponent } from './components/main-page/config/reports-config/reports-config.component';
import { ContainerComponent } from './components/main-page/config/container/container.component';
import { TodoComponent } from './components/main-page/config/todo/todo.component';
import { TodoTileComponent } from './components/main-page/dashboard/todo-tile/todo-tile.component';
import { AlarmsComponent } from './components/main-page/reports/alarms/alarms.component';
import { AlarmsConfigComponent } from './components/main-page/config/alarms-config/alarms-config.component';
import { TodoValidationComponent } from './components/main-page/reports/alarms/todo-validation/todo-validation.component';
import { TodostateComponent }  from './components/main-page/todostate/todostate.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { CostunitComponent } from './components/main-page/config/costunit/costunit.component';
import { ExportComponent } from './components/main-page/export/export.component';
import { NgxSpinnerModule } from "ngx-spinner"; 
import { ErrorHandler } from '@angular/core';
import { ErrorService } from './services/error.service';
import { WeatherComponent } from './components/main-page/dashboard/weather/weather.component';
import { ImportConfigComponent } from './components/main-page/config/imports/import-config/import-config.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginPageComponent,
    LoginComponent,
    ImpressumComponent,
    DatenschutzComponent,
    DashboardComponent,
    PasswordLostComponent,
    PasswordResetComponent,
    ActivationComponent,
    HeaderComponent,
    VerticalTabsComponent,
    MainPageComponent,
    ConfigComponent,
    FirmInfoComponent,
    CompanySelectComponent,
    ReportsComponent,
    MeterComponent,
    CurrentCompanyComponent,
    CostComponent,
    TariffComponent,
    ConfirmDialogComponent,
    EventsComponent,
    EventlogComponent,
    ReportsConfigComponent,
    ContainerComponent,
    TodoComponent,
    TodoTileComponent,
    AlarmsComponent,
    AlarmsConfigComponent,
    TodoValidationComponent,
    TodostateComponent,
    CostunitComponent,
    ExportComponent,
    WeatherComponent,
    ImportConfigComponent
  ],
    imports: [
        AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        CommonModule,
        FormsModule,
        HttpClientModule,
        IMaskModule,
        NgbModule,
        NgSelectModule,
        NgxPaginationModule,
        ToastrModule.forRoot(),
        NgxSpinnerModule  
    ],
    providers: [PopupService,
        LogeventsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    },
    { provide: ErrorHandler, useClass: ErrorService},
    ConfirmService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
