<div class="row">
    <div class="col-xl-10 col-md-12 col-12">
        <div class="card">
            <div class="card-body">
                <h2 class="titel">Übersicht</h2>
                <div>
                    <p>Auf dieser Seite finden Sie eine Übersicht über die Aktionen,
                        die wir für Ihre Firma registriert oder durchgeführt haben.
                    </p>
                    <div>
                        <div style="padding-bottom: 1.25rem">
                            <ul class="nav nav-tabs" role="tablist" style="width: 100%">
                                <li class="nav-item">
                                    <a href="#home" class="nav-link active" data-toggle="tab">Berichte</a>
                                </li>
                                <li class="nav-item">
                                    <a href="#active" class="nav-link" data-toggle="tab">Aktivitäten</a>
                                </li>
                                <li class="nav-item">
                                    <a href="#alarm" class="nav-link" data-toggle="tab">Alarme</a>
                                </li>
                                <li class="nav-item" *ngIf="isAdmin()">
                                    <a href="#admin" class="nav-link" data-toggle="tab">Administrator</a>
                                </li>
                            </ul>
                        </div>

                        <div class="tab-content">
                            <div class="tab-pane fade show active" id="home">
                                <p>Diese Berichte wurden innerhalb der letzten 7 Tage
                                    automatisch erstellt. Klicken Sie auf einen Bericht, um
                                    ihn herunterzuladen.
                                </p>
                                <div class="input-group">
                                    <input type="text" class="form-control input-sm"
                                           (keyup)="refreshFilteredReports()"

                                           [(ngModel)]="filterReport">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary btn-sm"
                                                (click)="clearFilterReport()" id="filter1">
                                            <i class="fa fa-times-circle pos-left "></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="list-group">
                                    <div
                                            *ngFor="let ev of filteredReports; let indexOfelement=index"
                                            (click)="downloadReport(ev)">
                                        <small>{{ ev.timestamp | date: 'dd.MM.yy HH:mm:ss'}}</small><br/>
                                        <div class="clickable">{{ ev.text }}</div>
                                        <hr class="ruler"/>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade show pad-left" id="active">
                                <p>Folgende Benutzeraktivitäten wurden registriert.
                                </p>
                                <div class="input-group">
                                    <input type="text" class="form-control input-sm"
                                           (keyup)="refreshFilteredActions()"
                                           [(ngModel)]="filterAction">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary btn-sm"
                                                (click)="clearFilterAction()" id="filter1">
                                            <i class="fa fa-times-circle pos-left "></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="list-group">
                                    <div
                                            *ngFor="let ev of filteredActions; let indexOfelement=index">
                                        <small>{{ ev.timestamp | date: 'dd.MM.yy HH:mm:ss'}}
                                               {{ ev.user}}
                                        </small><br/>
                                        <div> {{ ev.text }}</div>
                                        <hr class="ruler"/>
                                    </div>
                                </div>

                            </div>
                            <div class="tab-pane fade show pad-left" id="alarm">
                                <p>Hier sehen Sie die Ergebnisse der Alarme, die
                                    für Ihre Firma aktiv sind.
                                </p>
                                <div class="input-group">
                                    <input type="text" class="form-control input-sm"
                                           (keyup)="refreshFilteredProblems()"
                                           [(ngModel)]="filterProblem">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary btn-sm"
                                                (click)="clearFilterProblem()" id="filter1">
                                            <i class="fa fa-times-circle pos-left "></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="list-group">
                                    <div
                                            *ngFor="let ev of filteredProblems; let indexOfelement=index">
                                        <small>{{ ev.timestamp | date: 'dd.MM.yy HH:mm:ss'}}</small><br/>
                                        <div> {{ ev.text }}</div>
                                        <hr class="ruler"/>
                                    </div>
                                </div>

                            </div>
                            <div class="tab-pane fade show pad-left" id="admin"
                                 *ngIf="isAdmin()">
                                <p>Dies sind interne Probleme, die aufgelaufen sind.
                                    Hier muss die EDV nachbessern.</p>
                                <div class="input-group">
                                    <input type="text" class="form-control input-sm"
                                           (keyup)="refreshFilteredProblems()"
                                           [(ngModel)]="filterProblem">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary btn-sm"
                                                (click)="clearFilterProblem()" id="filter1">
                                            <i class="fa fa-times-circle pos-left "></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="list-group">
                                    <div
                                            *ngFor="let ev of filteredProblems; let indexOfelement=index">
                                        <small>{{ ev.timestamp | date: 'dd.MM.yy HH:mm:ss'}}</small><br/>
                                        <div> {{ ev.text }}</div>
                                        <hr class="ruler"/>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>    

