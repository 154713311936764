import { Component, OnInit, Input, ChangeDetectorRef} from '@angular/core';
import {ConfigEvent, ConfigService} from '../../../../services/config.service';
import { LogeventsService } from '../../../../services/logevents.service';
import { NodeService} from '../../../../services/node.service';
import { SignalService, SignalEvent} from '../../../../services/signal.service';


import { Global } from '../../../../../constants/global';
import {Meter} from '../../../../classes/meter';
import { User } from 'src/app/classes/user';
import { Router } from '@angular/router';

//import { VerticalTabsComponent } from './../../vertical-tabs/vertical-tabs.component'


@Component({
  selector: 'app-company-select',
  templateUrl: './company-select.component.html',
  styleUrls: ['./company-select.component.scss']
})

export class CompanySelectComponent implements OnInit {


  company;
  selectedCompany  : number;
  companiesArray;
  editCompany      : boolean = false;
  public isSelected: boolean = false;


  constructor(
    public  configService  : ConfigService,
    private ref            : ChangeDetectorRef,
    private nodeService    : NodeService,
    private signalService  : SignalService,
    private router         : Router,
    public logeventService : LogeventsService
  ) {

    if (this.configService.getUser().moderator)
      this.companySelect(this.configService.getUser().companyId);

    this.configService.configEvent$.subscribe(data =>
    {
      if (data.name == Global.SIGNAL_REFRESH_COMPANY_INFO) 
      {
          this.configService.loadCompaniesArray();
//          this.companiesArray = this.configService.getCompaniesArray();
          this.companySelect(this.companiesArray[this.companiesArray.length-1].id);
      }
      if (data.name == Global.SIGNAL_REFRESH_COMPANY)
      {
          this.refreshCompanyData();
          this.selectedCompany = this.configService.getSelectedCompany()?.id;
      }

    });

    this.refreshCompanyData();
  }


  ngOnInit(): void 
  {
    //this.companiesArray = arr;
    let selected_company = this.configService.getSelectedCompany();
    if (selected_company != null) 
    {
        this.companiesArray  = this.configService.getCompaniesArray();
        this.selectedCompany = selected_company.id;
        this.configService.loadCompanyRelatedData();
        this.logeventService.loadEvents();
    }




  }

  refreshCompanyData() : void
  {
      this.companiesArray = this.configService.getCompaniesArray();
  }

  /*
  loadAllCompanies(obj) 
  {
    this.configService.loadCompanies().subscribe(data =>
    {
      obj.companiesArray = this.configService.getCompaniesArray();
      if (obj.selectedCompany === undefined || obj.selectedCompany == null)
      {
          if (this.isAdmin())
            this.companySelect(obj.companiesArray[0].id);
          else 
          {
              let usrstr = sessionStorage.getItem("authenticatedUser");
              let user : User = JSON.parse(usrstr);

              if (user.companyId > 0)
                 this.companySelect(user.companyId);
              else 
              {
                  sessionStorage.clear();
                  this.router.navigateByUrl("/");
              }
          }
        //this.selectedCompany = obj.companiesArray[0].id;
      }

    });

  }
*/
  isAdmin() : boolean
  {
      let usrstr = sessionStorage.getItem("authenticatedUser");
      let user : User = JSON.parse(usrstr);
      return user.admin;
  }


  getCompaniesArray() {
    return this.configService.getCompaniesArray();
  }

  jumpToDashboard() : void
  {
    let actEl = document.getElementById('a-tab-panel-1');
    actEl.click();
  }

  companySelect(id) 
  {
    let company = this.getCompaniesArray().find(o => o.id === id);
    sessionStorage.setItem(Global.SESSION_COMPANY, JSON.stringify(company));
    this.configService.configEvent$.emit(new ConfigEvent(Global.SIGNAL_COMPANY_SELECTED));
    let selected_company = company;
    this.selectedCompany = selected_company.id;
    this.isSelected = true;
    // Neue Firma, also lade deren Tarife und Meter
    this.configService.loadCompanyRelatedData();
    this.logeventService.loadEvents();
  }

  getMeters(obj){
    this.nodeService.getConnections(this.selectedCompany).subscribe(data => {
      obj.signalService.signalEvent$.emit(new SignalEvent('refresh_company', true));
    })
  }

  changeCompany() {
    this.configService.configEvent$.emit(new ConfigEvent(Global.SIGNAL_SET_COMPANY_CHANGE_ACTIVE));
  }

  
  createCompany() {
    this.configService.configEvent$.emit(new ConfigEvent(Global.SIGNAL_SET_COMPANY_CHANGE_ACTIVE));
    this.remove();
  }

  remove() {
    this.configService.removeSelectedCompany();
    this.selectedCompany = null;
  }



}
