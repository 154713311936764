<div class="row ">
    <div class="col-lg-12" >
        <div class="card">
            <div class="card-body">
            <h2 class="titel">Vorgaben verwalten
                <i class="fa fa-info-circle help-icon" 
                placement="bottom" 
                container="body"
                popoverClass="help-popover-wide"
                triggers="mouseenter:mouseleave"
                [ngbPopover]="helpTodoContent" 
                [popoverTitle]="helpTodoTitle"
                aria-hidden="true"></i>                                             
            </h2>
            <div>
                <table class="table table-striped" 
                    xxstyle="padding-left: 5px; padding-right: 5px; width: 95%;">
                    <thead>
                        <th width="30px" scope="col"></th>
                        <th width="25%" scope="col">Name</th>
                        <th scope="col">Bis</th>
                        <th scope="col">Benachrichtige</th>
                        <th scope="col">Erledigt</th>
                        <th width="150px">-</th>
                    </thead>
                    <tbody >
                        <tr *ngFor="let todo of allTodos |paginate : { id: 'todoPager', 
                            itemsPerPage: tableSize, currentPage: page}; let ind=index" >
                            <td>
                                <i class="fa fa-caret-right" 
                                    (click)="toggleVisibility(ind)"
                                    *ngIf="todo.todos.length > 0 && (!closeTodos[ind])"
                                    aria-hidden="true"></i>
                                <i class="fa fa-caret-down" 
                                    (click)="toggleVisibility(ind)"
                                    *ngIf="todo.todos.length > 0 && closeTodos[ind]"
                                    aria-hidden="true"></i>
        
                            </td>
                            <td  width="25%"  class="table-content-left"> 
                                <div style="padding-bottom: 10px;">
                                    {{ todo.name }}
                                </div>
                                <div  style="padding-top: 10px" 
                                    [style.display]="(closeTodos[ind] ? 'contents' :'none')">
                                    <div *ngFor="let val of todo.todos; let subind=index"
                                        style="height: 35px;">
                                        {{ val.name}}
                                    </div><br *ngIf="subind < todo.todos.length"/>
                                </div>
                            </td>
                            <td class="table-content-right"> 
                                <div style="padding-bottom: 10px;">
                                    {{ getUntilDate(todo) }}
                                </div>
                                <div style="padding-top: 10px" 
                                    [style.display]="(closeTodos[ind] ? 'contents' :'none')">
                                    <div *ngFor="let val of todo.todos; let subind=index"
                                        style="height: 35px;">
                                        {{ getUntilDate(val) }}
                                    </div><br *ngIf="subind < todo.todos.length"/>
                                </div>
                            </td>
                            <td class="table-content-left">
                                <div style="padding-bottom: 10px;">
                                    {{ getEMails(todo.emails) }}
                                </div>
                                <div style="padding-top: 10px" 
                                    [style.display]="(closeTodos[ind] ? 'contents' :'none')">
                                    <div *ngFor="let val of todo.todos; let subind=index"
                                        style="height: 35px;">
                                        {{ getEMails(val.emails) }}
                                    </div><br *ngIf="subind < todo.todos.length"/>
                                </div>
                            </td>
                            <td>
                                <div style="padding-bottom: 10px;">
                                    <i *ngIf="todo.done" class="fa fa-check-square" aria-hidden="true"></i> 
                                    <i *ngIf="!todo.done" class="fa fa-square-o" aria-hidden="true"></i>
                                </div>
                                <div  
                                    [style.display]="(closeTodos[ind] ? 'contents' :'none')">
                                    <div *ngFor="let val of todo.todos; let subind=index"
                                        style="height: 35px;">
                                        <i *ngIf="val.done" class="fa fa-check-square" aria-hidden="true"></i> 
                                        <i *ngIf="!val.done" class="fa fa-square-o" aria-hidden="true"></i>
                                    </div><br *ngIf="subind < todo.todos.length"/>
                                </div>

                            </td>
                            <td>
                                <div style="padding-bottom: 5px;">
                                    <button role="link"
                                        (click)="editTodoGroup(todo)"
                                        data-toggle="modal" data-target="#todoModal"
                                        data-whatever="report"
                                        class="btn btn-primary btn-sm en-btn">
                                        <i class="fa fa-pencil" aria-hidden="true"></i>
                                    </button>                            
                                    &nbsp;
                                    <button role="link"
                                        (click)="createTodoInGroup(todo)"
                                        data-toggle="modal" data-target="#todoModal"
                                        data-whatever="report"
                                        class="btn btn-primary btn-sm en-btn">
                                        <i class="fa fa-plus" aria-hidden="true"></i>
                                    </button>                            
                                    &nbsp;
                                    <button role="link"
                                        (click)="deleteTodoGroup(todo)"
                                        class="btn btn-primary btn-sm en-btn">
                                        <i class="fa fa-trash-o" aria-hidden="true"></i>
                                    </button>                            
                                </div>
                                <div 
                                    [style.display]="(closeTodos[ind] ? 'contents' :'none')">
                                    <div *ngFor="let val of todo.todos; let subind=index"
                                        style="height: 35px;">
                                        <button role="link"
                                            (click)="editTodoGroup(val)"
                                            data-toggle="modal" data-target="#todoModal"
                                            data-whatever="report"
                                            class="btn btn-primary btn-sm en-btn">
                                            <i class="fa fa-pencil" aria-hidden="true"></i>
                                        </button> &nbsp;                         
                                        <button role="link"
                                            (click)="deleteTodoGroup(val)"
                                            class="btn btn-primary btn-sm en-btn">
                                            <i class="fa fa-trash-o" aria-hidden="true"></i>
                                        </button>                                           
                                    </div><br *ngIf="subind < todo.todos.length"/>
                                </div>

                            </td>
                        </tr>
                    </tbody>
                </table>   
            </div>
            <div class="row">
                <div class="col-lg-2" >
                    <button role="link"
                        (click)="createTodoGroup()"
                                data-toggle="modal" data-target="#todoModal"
                                data-whatever="report"
                                class="btn btn-primary">
                        <i class="fa fa-plus" aria-hidden="true"></i>    
                        Neu
                    </button>
                </div>
                <div class="col-lg-8 d-flex justify-content-center">
                    <pagination-controls
                            id="todoPager"
                            responsive="true"
                            previousLabel=""
                            nextLabel=""
                            autoHide="true"
                            (pageChange)="onTableDataChange($event)">
                    </pagination-controls>
                </div>
            </div>
        </div>
    </div>
    
</div>
<!-- Input-Fenster, Modal-->
<div class="modal fade" id="todoModal" tabindex="-1" role="dialog" 
    #modal aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title titel">{{ displayTitle }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="alert alert-danger" *ngIf='errorMessage.length > 0'>{{errorMessage}}</div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-5">
                                    <label>Name:</label>
                                </div>
                                <div class="col-md-7">
                                    <input type="text" [(ngModel)]="inputName"
                                           name = "name"
                                           style="width: 100%">

                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-5">
                                    <label>Beschreibung:</label>
                                </div>
                                <div class="col-md-7">
                                        <textarea name="desc"
                                                  [(ngModel)]="inputDesc"
                                                  rows="5"
                                                  style="width: 100%;padding: 7px"
                                        ></textarea>

                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-5">
                                    <label>Zu erledigen bis :</label>
                                </div>
                                <div class="col-md-7 input-group">
                                    <input class="form-control date"
                                           (click)="d.toggle()"
                                           name="repeat"
                                           [(ngModel)]="inputDeadline"
                                           placeholder="TT.MM.JJJJ"
                                           required ngbDatepicker
                                           #d="ngbDatepicker"/>
                                    <div class="input-group-append">
                                         <span class="input-group-text" (click)="d.toggle()">
                                            <svg width="1em" height="1em" viewBox="0 0 16 16"
                                                 class="bi bi-calendar-date"
                                                 fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                 <path fill-rule="evenodd"
                                                       d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/>
                                                    <path d="M6.445 11.688V6.354h-.633A12.6 12.6 0 0 0 4.5 7.16v.695c.375-.257.969-.62 1.258-.777h.012v4.61h.675zm1.188-1.305c.047.64.594 1.406 1.703 1.406 1.258 0 2-1.066 2-2.871 0-1.934-.781-2.668-1.953-2.668-.926 0-1.797.672-1.797 1.809 0 1.16.824 1.77 1.676 1.77.746 0 1.23-.376 1.383-.79h.027c-.004 1.316-.461 2.164-1.305 2.164-.664 0-1.008-.45-1.05-.82h-.684zm2.953-2.317c0 .696-.559 1.18-1.184 1.18-.601 0-1.144-.383-1.144-1.2 0-.823.582-1.21 1.168-1.21.633 0 1.16.398 1.16 1.23z"/>
                                            </svg>
                                        </span>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="col-md-12">
                            <div class="row" style="padding-top: 5px">
                                <div class="col-md-5">
                                    <label style="width: 150px" for="mails">E-Mails :</label>
                                </div>
                                <div class="col-md-7">
                                    <ng-select id="mails" [items]="allEMails"
                                               bindLabel="name"
                                               name="mails"
                                               [multiple]="true"
                                               [clearable]="true"
                                               [(ngModel)]="selectedEMails"
                                               [ngModelOptions]="{standalone: true}">
                                    </ng-select>
                                </div>
                            </div>
                        </div>

                    </div>
                </form>
            </div>
            <div class="modal-footer center">
                <button type="button" 
                        (click)="submitData()"
                        class="submit-button">
                        {{ displayButton }}
                </button>
            </div>
        </div>
    </div>
</div>
<ng-template #helpTodoContent>
    <p>Eine Vorgabe ist eine Aufgabe, die bis zu einem festgelegten Datum erledigt sein muss.</p>
    <p>Falls gewünscht, kann eine Vorgabe beliebig viele Unterpunkte haben.
    </p>
    <p>Sind es weniger als zwei Tage bis zum festgelegten Datum, so wird die Vorgabe als <em>Fällig</em>
        eingestuft. Ist das Datum verstrichen, so ist die Vorgabe <em>Überfällig</em>.
    </p>
    <p>Sie können den Status einer Vorgabe im Reiter <em>Vorgaben</em> umstellen.</p>
</ng-template>
<ng-template #helpTodoTitle>Vorgaben</ng-template>


