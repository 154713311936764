<div class="row">
    <div class="col-xl-10 col-md-12 col-12">
        <div class="card">
            <div class="card-body">
                <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
                    <p *ngIf="!export" style="color: white">Importiere Daten und starte Berechnung... </p>
                    <p *ngIf="export" style="color: white">Exportiere Daten... </p>
                </ngx-spinner>

                <h4>Import und Export</h4>
                <p>Der Reiter "Export" erlaubt Ihnen die Struktur der Messpunkte und Knoten
                    in einem Format Ihrer Wahl zu exportieren.
                </p>
                <p>Unter den Reitern "(manueller) Import" können Sie Lastgangsdaten in das System
                    mittels ".csv" Dateien oder manueller Eingabe importieren.
                </p>
                <div class="pad-top">
                    <ul class="nav nav-tabs" role="tablist" style="width: 100%">
                        <li class="nav-item">
                            <a href="#export" class="nav-link active" data-toggle="tab">Export
                                <i class="fa fa-info-circle icon-width" placement="bottom" container="body"
                                    popoverClass="help-popover-wide" triggers="mouseenter:mouseleave"
                                    [ngbPopover]="helpExportContent" [popoverTitle]="helpExportTitle"
                                    aria-hidden="true"></i> </a>
                        </li>
                        <li class="nav-item">
                            <a href="#exportdb" class="nav-link" data-toggle="tab">Export in Datenbank
                                <i class="fa fa-info-circle icon-width" placement="bottom" container="body"
                                    popoverClass="help-popover-wide" triggers="mouseenter:mouseleave"
                                    [ngbPopover]="helpExportDBContent" [popoverTitle]="helpExportDBTitle"
                                    aria-hidden="true"></i> </a>
                        </li>
                        <li class="nav-item">
                            <a href="#import" class="nav-link" data-toggle="tab">Import
                                <i class="fa fa-info-circle icon-width" placement="bottom" container="body"
                                    popoverClass="help-popover-wide" triggers="mouseenter:mouseleave"
                                    [ngbPopover]="helpImportContent" [popoverTitle]="helpImportTitle"
                                    aria-hidden="true"></i> </a>
                        </li>
                        <li class="nav-item">
                            <a href="#importman" class="nav-link" (click)="initManualImport()"
                                data-toggle="tab">manueller Import
                                <i class="fa fa-info-circle icon-width" placement="bottom" container="body"
                                    popoverClass="help-popover-wide" triggers="mouseenter:mouseleave"
                                    [ngbPopover]="helpManualContent" [popoverTitle]="helpManualTitle"
                                    aria-hidden="true"></i> </a>
                        </li>
                        <li class="nav-item">
                            <a href="#dbimport" *ngIf="isModerator()"
                               class="nav-link" data-toggle="tab">Datenbank-Import
                                <i class="fa fa-info-circle icon-width" placement="bottom" container="body"
                                    popoverClass="help-popover-wide" triggers="mouseenter:mouseleave"
                                    [ngbPopover]="helpImportDBContent" [popoverTitle]="helpImportDBTitle"
                                    aria-hidden="true"></i> </a>
                        </li>

                    </ul>
                </div>
                <div class="row">
                    <div class="col-lg-12 tab-content">
                        <div class="tab-pane fade show active pad-left" id="export">
                            <br />
                            <h5>Datenpunktliste exportieren</h5>
                            <p>Die Datenpunktliste enthält hinterlegte Firmenstruktur, wie z.B.
                                Werke, Standorte oder Hallen und die ihnen zugeordneten Messpunkte.
                            </p>
                            <p>Sie können die Daten entweder im XML, Json oder CSV-Format exportieren.</p>
                            <p>
                                Den Export von Messwerten in verschiedenen Formaten finden Sie unter
                                dem Punkt "Diagramme" und dort unter dem Menüpunkt "Exportieren".
                            </p>
                            <button type="button" (click)="downloadList(true)"
                                class="btn btn-primary export-button">Export als XML</button>
                            &nbsp;
                            <button type="button" (click)="downloadList(false)"
                                class="btn btn-primary export-button">Export als Json</button>
                            &nbsp;
                            <button type="button" (click)="downloadListasCSV()"
                                class="btn btn-primary export-button">Export als CSV</button>
                        </div>
                        <div class="tab-pane fade pad-left" id="exportdb">
                            <br />
                            <h5>Daten exportieren</h5>
                            <p>Wählen Sie zunächst einen Messpunkt aus und bestimmen dann die Startzeit
                               ab der Daten gesammelt und in die Zieldatenbank exportiert werden sollen. 
                            <div class="row" style="padding-left: 15px;">
                                <div class="col-lg-3 pad-top">
                                    Messpunkte:
                                </div>
                                <div class="col-lg-7 pad-top">
                                    <ng-select [items]="allMeters" id="meter"
                                    bindLabel="values.user0"
                                    [multiple]="true"
                                    [clearable]="true"
                                    (change)="meterChanged($event)"
                                    [(ngModel)]="selectedMetersDB" 
                                    [ngModelOptions]="{standalone: true}">
                                    <ng-template ng-option-tmp ng-label-tmp let-item="item">
                                        <label>
                                            <i *ngIf="item.values.obisid0 == '1-0:1.8.0*255'"
                                                class="fa fa-bolt"></i>
                                            <i *ngIf="item.values.obisid0 == '8-0:1.0.0*255'"
                                                class="fa fa-tint"></i>
                                            <i *ngIf="item.values.obisid0 == '6-0:1.0.0*255'"
                                                class="fa fa-fire"></i> <!-- red -->
                                            <i *ngIf="item.values.obisid0 == '7-0:3.0.0*255'"
                                                class="fa fa-fire"></i> <!-- blue -->
                                            <i *ngIf="item.values.obisid0 == '6-0:2.0.0*255'"
                                                class="fa fa-tint"></i>
                                            <i *ngIf="item.values.obisid0 == '8-0:1.0.0*255'"
                                                class="fa fa-plug"></i>
        
                                        </label>
                                        {{ item.values.user0 }}
                                    </ng-template>
                                </ng-select>
                                        </div>
                            </div>
                            <div class="row" style="padding-left: 15px;">
                                <div class="col-lg-3 pad-top">
                                    Exportiere:
                                </div>
                                <div class="col-lg-7 pad-top">
                                    <select (click)="changeDelimeter()" [(ngModel)]="dbOrigin">
                                        <option value="0">Orginaldaten</option>
                                        <option value="1">Interpolierte Werte</option>
                                    </select>                                
                                </div>
                            </div>           
                            <div class="row" style="padding-left: 15px;">
                                <div class="col-lg-3 pad-top">
                                    Berücksichtige Daten ab:
                                </div>
                                <div class="col-lg-7 pad-top">
                                    <div class="input-group">
                                        <input class="form-control date" style="width: 80px" placeholder="TT.MM.JJJJ"
                                            (click)="dd.toggle()" name="end" [(ngModel)]="editDate"
                                            [ngModelOptions]="{standalone: true}" required ngbDatepicker
                                            #dd="ngbDatepicker" />
                                        <div class="input-group-append">
                                            <span class="input-group-text" (click)="dd.toggle()">
                                                <svg width="1em" height="1em" viewBox="0 0 16 16"
                                                    class="bi bi-calendar-date" fill="currentColor"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd"
                                                        d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
                                                    <path
                                                        d="M6.445 11.688V6.354h-.633A12.6 12.6 0 0 0 4.5 7.16v.695c.375-.257.969-.62 1.258-.777h.012v4.61h.675zm1.188-1.305c.047.64.594 1.406 1.703 1.406 1.258 0 2-1.066 2-2.871 0-1.934-.781-2.668-1.953-2.668-.926 0-1.797.672-1.797 1.809 0 1.16.824 1.77 1.676 1.77.746 0 1.23-.376 1.383-.79h.027c-.004 1.316-.461 2.164-1.305 2.164-.664 0-1.008-.45-1.05-.82h-.684zm2.953-2.317c0 .696-.559 1.18-1.184 1.18-.601 0-1.144-.383-1.144-1.2 0-.823.582-1.21 1.168-1.21.633 0 1.16.398 1.16 1.23z" />
                                                </svg>
                                            </span>
                                        </div>
                                        <div class="input-group-append">&nbsp;
                                            <select [(ngModel)]="hour" class="ig-height"
                                            [ngModelOptions]="{standalone: true}">
                                            <option *ngFor="let val of listHour" value="{{val}}">{{val}}</option>
                                        </select>
                                        <div style="padding-top: 5px;">:</div>
                                        <select [(ngModel)]="minute" class="ig-height"
                                            [ngModelOptions]="{standalone: true}">
                                            <option *ngFor="let val of listMinute">{{val}}</option>
                                        </select>
                                        </div>
                                    </div>

                                </div>
                            </div>           
                            <div class="row" style="padding-left: 15px;">
                                <div class="col-lg-3 pad-top">
                                    &nbsp;
                                </div>
                                <div class="col-lg-7 pad-top" *ngIf="isExportDBValid()">
                                    <button class="btn btn-primary" (click)="exportToDB()">
                                        Exportieren
                                    </button>
                                </div>
                            </div>           



                        </div>

                        <div class="tab-pane fade pad-left" id="import">
                            <p></p>
                            <h5>Lastgangsdaten importieren</h5>
                            <div id="page_1" *ngIf="page==0">
                                <p>An dieser Stelle können Sie Lastgangsdaten im csv-Format importieren.
                                    Wählen Sie im ersten Schritt bitte die gewünscht Datei aus.
                                </p>
                                <div class="row" style="padding-left: 15px;">
                                    <div *ngIf="fileName != null && fileName.length > 0">
                                        {{ fileName }} &nbsp;
                                    </div>
                                    <button class="btn btn-primary" (click)="openBrowser()">
                                        Öffnen
                                    </button>
                                </div>
                                <input type="file" (change)="changeListener($event)" #input id="fileButton"
                                    style="display: none;" />
                                <p><br />
                                    <label class="btn btn-primary" (click)="showPage(1)" *ngIf="fileName?.length > 0"
                                        [ngClass]="{disabled: fileName?.length == 0}">Weiter</label>
                                </p>
                            </div>
                            <div *ngIf="page==1">
                                <p>
                                    Bitte bestimmen Sie die Anzahl an Kommentarzeilen und legen
                                    danach fest, welche Daten in welchen Spalten zu finden sind.
                                </p>
                                <p><em>Bitte beachten Sie:</em><br/>
                                    Bitte geben Sie pro Import-Vorgang immer nur je eine Spalte für
                                    Messwert, Zeit und Messpunkt an!
                                </p>
                                <div class="row">
                                    <div class="col-lg-10">
                                        <pre class="import-data">{{ displayFirstLines()}}</pre>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-10">
                                        <div class="row">
                                            <div class="col-lg-5 pad-top">
                                                Trennzeichen:
                                            </div>
                                            <div class="col-lg-5 pad-top">
                                                <select (click)="changeDelimeter()" [(ngModel)]="delimeter">
                                                    <option value=",">Komma</option>
                                                    <option value=";">Semikolon</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-5 pad-top">
                                                Daten in Anführungszeichen:
                                            </div>
                                            <div class="col-lg-5 pad-top">
                                                <input type="checkbox" [(ngModel)]="quoted">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-5 pad-top">
                                                Kommentarzeilen:
                                            </div>
                                            <div class="col-lg-5 pad-top">
                                                <input type="number" min="0" size="3" [(ngModel)]="commentLines">
                                            </div>
                                        </div>
                                        <div class="row" *ngFor="let c of selectedColFormats; let ind = index">
                                            <div class="col-lg-5 pad-top">Spalte {{ ind +1 }}</div>
                                            <div class="col-lg-5 pad-top">
                                                <select id="sel-col{{ind}}" (change)="updateInputComplete($event, ind)">
                                                    <option value="">-- Ignorieren --</option>
                                                    <option value="dd.MM.yyyy HH:mm:ss">Datum + Uhrzeit (TT.MM.JJJJ
                                                        hh:mm:ss)</option>
                                                    <option value="dd.MM.yyyy HH:mm">Datum + Uhrzeit (TT.MM.JJJJ hh:mm)
                                                    </option>
                                                    <option value="yyyy-MM-dd HH:mm:ss">Datum + Uhrzeit (JJJJ-MM-TT
                                                        hh:mm:ss)</option>
                                                    <option value="dd.MM.yyyy">Datum (TT.MM.JJJJ)</option>
                                                    <option value="yyyy-MM-dd">Datum (JJJJ-MM-TT)</option>
                                                    <option value="HH:mm:ss">Uhrzeit (hh:mm:ss)</option>
                                                    <option value="HH:mm">Uhrzeit (hh:mm)</option>
                                                    <option value="value">Wert</option>
                                                    <option value="id">Messpunkt (Id)</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <label class="btn btn-primary" (click)="showPage(0)">Zurück</label>
                                &nbsp;
                                <label class="btn btn-primary" (click)="showPage(2)"
                                    *ngIf="isInputComplete()">Weiter</label>

                            </div>
                            <div *ngIf="page==2">
                                <div *ngIf="!hasMP">
                                    <p>Da Ihre Lastgangdaten keine Spalte mit der Messpunkt-Id
                                        oder dem Messpunktnamen enthalten, legen Sie bitte den
                                        Messpunkt hier fest:
                                    </p>
                                    <ng-select [items]="availableMeters" bindLabel="values.user0" bindValue="id"
                                        [multiple]="false" [(ngModel)]="selectedMeters"
                                        [ngModelOptions]="{standalone: true}">
                                        <ng-template ng-option-tmp ng-label-tmp let-item="item">
                                            <label>
                                                <i *ngIf="item.values.obisid0 == '1-0:1.8.0*255'"
                                                    class="fa fa-bolt"></i>
                                                <i *ngIf="item.values.obisid0 == '8-0:1.0.0*255'"
                                                    class="fa fa-tint"></i>
                                                <i *ngIf="item.values.obisid0 == '6-0:1.0.0*255'"
                                                    class="fa fa-fire"></i> <!-- red -->
                                                <i *ngIf="item.values.obisid0 == '7-0:3.0.0*255'"
                                                    class="fa fa-fire"></i> <!-- blue -->
                                                <i *ngIf="item.values.obisid0 == '6-0:2.0.0*255'"
                                                    class="fa fa-tint"></i>
                                                <i *ngIf="item.values.obisid0 == '8-0:1.0.0*255'"
                                                    class="fa fa-plug"></i>
                                            </label>
                                            {{ item.values.user0 }} ({{ item.id}})
                                        </ng-template>
                                    </ng-select>
                                </div>
                                <div class="pad-top">
                                    <p *ngIf="selectedMeters?.length > 0 || hasMP">
                                        Alle benötigten Daten für den Import wurden bestimmt, klicken
                                        Sie nun auf <kbd>Importieren</kbd> um den Import zu starten.
                                    </p>
                                    <p>Bitte beachten Sie, dass abhängig von der Dateigrösse ein
                                        Import einige Minuten dauern kann. Warten Sie bitte, bis
                                        die Webseite den Import bestätigt hat, bevor Sie weitere
                                        Aktionen durchführen.
                                    </p>
                                    <label class="btn btn-primary" (click)="showPage(1)">Zurück</label> &nbsp;
                                    <label class="btn btn-primary" (click)="showPage(3)"
                                        *ngIf="selectedMeters?.length > 0">Importieren</label>
                                </div>
                            </div>
                            <div *ngIf="page == 3">
                                <div *ngIf="errorMessage?.length > 0">

                                </div>
                                <div *ngIf="errorMessage?.length ==0">
                                    <p>Die Daten wurden erfolgreich importiert und werden nun
                                        interpoliert. Dieser Vorgang kann einige Minuten dauern,
                                        bitte haben Sie etwas Geduld.
                                    </p>
                                    <label class="btn btn-primary" (click)="newImport()" *ngIf="restart">Nächster
                                        Import</label>

                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade show pad-left" id="importman">
                            <br />
                            <h5>Manueller Import</h5>
                            <p>Hier können Sie manuell Daten für einen <em>physikalischen </em> Messpunkt erfassen, 
                                falls die Daten nicht in einem für den Import geeigneten Format vorliegen.
                            </p>
                            <p>Damit die hier erfassten Daten auch in den Diagrammen angezeigt werden können, 
                                müssen sie zunächst interpoliert werden. Dabei werden auch die Werte der virtuellen
                                Messpunkte neu berechnet, die den gewählten Messpunkt beinhalten.
                                Klicken Sie dafür abschließend bitte auf <kbd>Interpolieren</kbd>.</p>
                            <hr width="75%" />
                            <div class="row">
                                <div class="col-lg-2">
                                    <label>Messpunkt:</label>
                                </div>
                                <div class="col-lg-8">
                                    <ng-select [items]="availableMeters" id="meter" bindLabel="values.user0"
                                        bindValue="id"
                                        name="meters" (change)="resetData()" #ngSelect [clearable]="true"
                                        [(ngModel)]="selectedMeter" [ngModelOptions]="{standalone: true}">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="row pad-top" style="height:200px">
                                <table class="table table-striped"
                                    style="margin: 0 auto; width:90%; vertical-align: top;">
                                    <thead>
                                        <th style="vertical-align: top; padding-bottom: 5px; height: 24px">Datum</th>
                                        <th style="vertical-align: top; padding-bottom: 5px;">Wert</th>
                                        <th style="width: 115px;vertical-align: top; padding-bottom: 5px;">Aktion</th>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let v of allValues | paginate : {
                                        itemsPerPage: tableSize,
                                        currentPage: tabPage,
                                        totalItems: allValues.length }; let ind=index" style="padding: 3px">
                                            <td style="padding-top: 3px; padding-bottom: 3px;">
                                                {{getFormattedDate(v.timestamp)}}</td>
                                            <td style="padding-top: 3px; padding-bottom: 3px;">
                                                {{getFormattedValue(v.value)}}</td>
                                            <td style="padding-top: 3px; padding-bottom: 3px;">
                                                <button role="link" (click)="editManualValue(ind)"
                                                    data-whatever="report" class="btn btn-primary btn-sm en-btn">
                                                    <i class="fa fa-pencil" aria-hidden="true"></i>
                                                </button> &nbsp;
                                                <button role="link" (click)="deleteManualValue(ind)"
                                                    class="btn btn-primary btn-sm en-btn">
                                                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="d-flex justify-content-center pad-top" style="width: 100%"
                                    *ngIf="allValues.length > tableSize">
                                    <pagination-controls class="en-pagination" responsive="true" previousLabel=""
                                        nextLabel="" (pageChange)="onTablePageChange($event)">
                                    </pagination-controls>
                                </div>
                            </div>
                            <div class="row pad-top">
                                <div class="col-lg-2">
                                    Datum:
                                </div>
                                <div class="col-lg-6">
                                    <div class="input-group">
                                        <input class="form-control date" style="width: 80px" placeholder="TT.MM.JJJJ"
                                            (click)="dd1.toggle()" name="date" [(ngModel)]="editDate"
                                            [ngModelOptions]="{standalone: true}" required ngbDatepicker
                                            #dd1="ngbDatepicker" />
                                        <div class="input-group-append">
                                            <span class="input-group-text" (click)="dd1.toggle()">
                                                <svg width="1em" height="1em" viewBox="0 0 16 16"
                                                    class="bi bi-calendar-date" fill="currentColor"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd"
                                                        d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
                                                    <path
                                                        d="M6.445 11.688V6.354h-.633A12.6 12.6 0 0 0 4.5 7.16v.695c.375-.257.969-.62 1.258-.777h.012v4.61h.675zm1.188-1.305c.047.64.594 1.406 1.703 1.406 1.258 0 2-1.066 2-2.871 0-1.934-.781-2.668-1.953-2.668-.926 0-1.797.672-1.797 1.809 0 1.16.824 1.77 1.676 1.77.746 0 1.23-.376 1.383-.79h.027c-.004 1.316-.461 2.164-1.305 2.164-.664 0-1.008-.45-1.05-.82h-.684zm2.953-2.317c0 .696-.559 1.18-1.184 1.18-.601 0-1.144-.383-1.144-1.2 0-.823.582-1.21 1.168-1.21.633 0 1.16.398 1.16 1.23z" />
                                                </svg>
                                            </span>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="row pad-top">
                                <div class="col-lg-2">
                                    Uhrzeit:
                                </div>
                                <div class="col-lg-6 ">
                                    <span class="row" style="padding-left: 15px;">
                                        <select [(ngModel)]="hour" class="ig-height"
                                            [ngModelOptions]="{standalone: true}">
                                            <option *ngFor="let val of listHour" value="{{val}}">{{val}}</option>
                                        </select>
                                        <div style="padding-top: 5px;">:</div>
                                        <select [(ngModel)]="minute" class="ig-height"
                                            [ngModelOptions]="{standalone: true}">
                                            <option *ngFor="let val of listMinute">{{val}}</option>
                                        </select>
                                    </span>
                                </div>
                            </div>
                            <div class="row pad-top">
                                <div class="col-lg-2">
                                    Wert:
                                </div>
                                <div class="col-lg-6">
                                    <input type="text" [(ngModel)]="value" [imask]="testMask"> &nbsp;
                                    <button class="btn btn-primary btn-sm" *ngIf="isValidValue()" (click)="saveValue()">
                                        <i class="fa fa-floppy-o"></i>
                                    </button>
                                </div>
                            </div>
                            <div class="row pad-top">
                                <div class="col-lg-2">
                                    &nbsp;
                                </div>
                                <div class="col-lg-6">
                                    <button class="btn btn-primary " *ngIf="allValues.length > 0" (click)="interpolate()">
                                        Interpolieren
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade show pad-left" id="dbimport">
                            <br />
                            <h5>Daten aus Datenbank übernehmen</h5>
                            <div class="row">
                                <p style="padding-left: 10px">Bitte wählen Sie eine Tabelle aus: &nbsp;</p>
                                <ng-select style="width: 250px; " [items]="allTables"
                                    [multiple]="false"
                                    [clearable]="false"
                                    (change)="selectTable($event)"
                                    #ngSelect       
                                    [(ngModel)]="selectedTable"
                                    [ngModelOptions]="{standalone: true}">
                                </ng-select> 
                            </div>                                                          
                            <div class="row" *ngIf="errorMessage?.length > 0">
                                <div class="col-md-12 alert alert-danger">
                                    {{ errorMessage }}
                                </div>
                            </div>
        
                            <table width="95%">
                                <tr>
                                    <th style="width: 100px;">Inhalt</th>
                                    <th>Spalte</th>
                                    <th>Format</th>
                                </tr>
                                <tr class="pad-top">
                                    <td style="width: 100px;">Zeitpunkt</td>
                                    <td>
                                        <ng-select style="width: 250px; " [items]="allCols"
                                           bindLabel="name" 
                                           [multiple]="false"
                                           [clearable]="false"
                                            #ngSelect       
                                            [(ngModel)]="colTime"
                                           [ngModelOptions]="{standalone: true}">
                                    </ng-select> 
                                    </td>
                                    <td>
                                        <ng-select style="width: 250px; " [items]="allTimes"
                                           bindLabel="name" 
                                            [multiple]="false"
                                            [clearable]="false"
                                             #ngSelect       
                                            [(ngModel)]="colTimeFormat"
                                           [ngModelOptions]="{standalone: true}">
                                           </ng-select>
                                    </td>
                                </tr>
                                <tr class="pad-top">
                                    <td  style="width: 100px;">
                                        Messpunkt                                        
                                    </td>
                                    <td>
                                        <ng-select style="width: 250px; " [items]="allCols"
                                            bindLabel="name" 
                                            [multiple]="false"
                                            [clearable]="true"
                                            (change)="selectMPColumn($event)"
                                            #ngSelect       
                                            [(ngModel)]="colMP"
                                            [ngModelOptions]="{standalone: true}">      
                                        </ng-select>                 
                                    </td>
                                    <td><div class="row" style="padding-left: 16px; width: 570px">
                                        <ng-select style="width: 250px; " [items]="allTypes"
                                            [multiple]="false"
                                            [clearable]="false"
                                            #ngSelect       
                                            [(ngModel)]="mpType"
                                            [ngModelOptions]="{standalone: true}">      
                                        </ng-select> &nbsp;         
                                        <ng-select [items]="availableMeters" bindLabel="values.user0" bindValue="id"
                                            [multiple]="false" [(ngModel)]="selectedMeter"
                                            style="width: 45%"
                                            *ngIf="mpType=='Messpunkt auswählen'"
                                            [ngModelOptions]="{standalone: true}">
                                            <ng-template ng-option-tmp ng-label-tmp let-item="item">
                                                <label>
                                                    <i *ngIf="item.values.obisid0 == '1-0:1.8.0*255'"
                                                        class="fa fa-bolt"></i>
                                                    <i *ngIf="item.values.obisid0 == '8-0:1.0.0*255'"
                                                        class="fa fa-tint"></i>
                                                    <i *ngIf="item.values.obisid0 == '6-0:1.0.0*255'"
                                                        class="fa fa-fire"></i> <!-- red -->
                                                    <i *ngIf="item.values.obisid0 == '7-0:3.0.0*255'"
                                                        class="fa fa-fire"></i> <!-- blue -->
                                                    <i *ngIf="item.values.obisid0 == '6-0:2.0.0*255'"
                                                        class="fa fa-tint"></i>
                                                    <i *ngIf="item.values.obisid0 == '8-0:1.0.0*255'"
                                                        class="fa fa-plug"></i>
                                                </label>
                                                {{ item.values.user0 }} ({{ item.id}})
                                            </ng-template>
                                        </ng-select></div>

                                    </td>
                                </tr>
                                <tr class="pad-top">
                                    <td style="width: 100px;" >Wert</td>
                                    <td>
                                        <ng-select style="width: 250px; " [items]="allCols"
                                            bindLabel="name" 
                                            [multiple]="false"
                                            [clearable]="false"
                                            #ngSelect       
                                            [(ngModel)]="colVal"
                                            [ngModelOptions]="{standalone: true}">      
                                    </ng-select>                 

                                    </td>
                                    <td></td>
                                </tr>
                                <tr class="pad-top">
                                    <td style="width: 100px;" >Wiederholung</td>
                                    <td>
                                            <ng-select style="width: 250px; " [items]="allRecs"
                                                bindLabel="name" 
                                                [multiple]="false"
                                                [clearable]="false"
                                                #ngSelect       
                                                [(ngModel)]="colRec"
                                                [ngModelOptions]="{standalone: true}">      
                                            </ng-select>         
                                    </td>
                                    <td></td>
                                </tr>
                                <tr class="pad-top" *ngIf="colRec != 'Einmalig'">
                                    <td style="width: 100px">
                                        E-Mail:
                                    </td>
                                    <td>
                                        <ng-select [items]="allMails" id="mails"
                                            bindLabel="name"
                                            name="mails"
                                            [multiple]="true"
                                            [clearable]="true"
                                            [(ngModel)]="selectedMails"
                                            [ngModelOptions]="{standalone: true}">
                                        </ng-select>                                    
                                    </td>
                                    <td></td>
                                </tr>
                            </table>
                            <table style="width: 95%" *ngIf="isValidDBImport()">
                                <tr class="pad-top">
                                    <td class="pad-top" colspan="3" style="width: 600px">
                                        Übertragen Sie die Daten aus der definierten Tabelle in die 
                                        Rohdaten-Tabelle von EnMonitor &nbsp;
                                        <button class="btn btn-primary"
                                            (click)="startCopy()">Daten importieren</button>                                    
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Hilfetexte -->
<ng-template #helpExportContent>
    <p>Hier können Sie Datenpunktlisten im JSON, XML oder CSV-Format exportiert werden.
    </p>
</ng-template>
<ng-template #helpExportTitle>Datenpunktliste exportieren</ng-template>

<ng-template #helpExportDBContent>
    <p>Hier können Sie Daten in eine externe Datenbank exportieren.
    </p>
</ng-template>
<ng-template #helpExportDBTitle>Daten in Datenbank exportieren</ng-template>

<ng-template #helpImportContent>
    <p>Hier können Sie im CSV-Format vorliegende Daten importieren. Dabei führt Sie das
        System schrittweise durch den Import-Prozess.
    </p>
</ng-template>
<ng-template #helpImportTitle>CSV-Dateien importieren</ng-template>

<ng-template #helpImportDBContent>
    <p>Hier können Sie Daten aus der Importdatenbank importieren. Die Importdatenbank enthält
       eine oder mehrere Tabellen, die Ihr Unternehmen mit Daten beschicken kann. Aus dieser
       Tabelle können Sie hier Daten importieren.
    </p>
    <p>Diese Tabelle(n) müssen wir einmalig einrichten und Ihre EDV muss die Daten dann in 
        diese Tabelle(n) exportieren. Sprechen Sie bitte unseren Support an, um Tabellen und
        Zugangsdaten einzurichten.
    </p>
</ng-template>
<ng-template #helpImportDBTitle>Daten aus Datenbank importieren</ng-template>

<ng-template #helpManualContent>
    <p>Hier können Sie Rohdaten für einen Messpunkt Ihrer Wahl eintragen und interpolieren lassen.
    </p>
</ng-template>
<ng-template #helpManualTitle>Manueller Datenimport</ng-template>