import {HttpClient} from '@angular/common/http';
import {Injectable, EventEmitter} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Global} from '../../constants/global';
import {Tariff} from '../classes/tariff';
import {PopupService} from './popup.service';
import { Meter } from '../classes/meter';
import { CostPair, CostPerCostUnit } from '../classes/cost';
import { Todo, TodoGroup } from '../classes/todo';
import { MContainer } from '../classes/container'
import { CostUnit } from '../classes/costunit';
import { User } from '../classes/user';
import { ManualValue } from '../classes/manualvalue';


export class ConfigEvent {
    constructor(public name: string, 
                public success?: boolean) {
    }
}

@Injectable({
    providedIn: 'root'
})
export class ConfigService 
{
    apiUrl = Global.apiUrl;
    companyArray;
    reportsForCurrentCompany;
    alarmsForCurrentCompany;
    tariffs            : Tariff[];
    public configEvent$: EventEmitter<ConfigEvent>;
    public metersForCurrentCompany: Meter[];                // nur phys. Messpunkte
    public vmpAndMetersForCurrentCompany : Meter[];         // Alle Meter (phys. und virtuell)
    private companyObj;
    public todoGroups  : TodoGroup[];
    public costs       : CostPerCostUnit;
    public allContainer: MContainer[] = [];
    costUnits          : CostUnit[] = [];
    weather            : any;
    allTables          : string[] = [];
    allCols            : any[];

    constructor(
        private http: HttpClient,
        private popupService: PopupService,
        private route: ActivatedRoute,
        private router: Router,
    ) {
        this.configEvent$ = new EventEmitter();
    }


    updateCompany(
        id          : number,
        company     : string,
        mail        : string,
        description : string,
        street      : string,
        zip         : string,
        city        : string,
        country     : string,
        logo        : string
    ) {
        let companyObj = {
            id          : id,
            company     : company,
            mail        : mail,
            description : description,
            street      : street,
            zip         : zip,
            city        : city,
            country     : country,
            logo        : logo

        };

        //console.log(companyObj);
        return this.http.post(this.apiUrl + `rev2/update/company`, companyObj);

    }

    getCompaniesArray() {
        return this.companyArray;
    }

    loadCompaniesInt(service : ConfigService)
    {
        let arr = [];
        let token   = sessionStorage.getItem(Global.ATTRIBUTE_USER_TOKEN);
        if (token == null)
        {
            console.log("Token ist NULL!!!!!")
            // this.router.navigate(['home']);
            var err = new Error();
            console.log( err.stack);
            return;
        }
        let header    = 
        {
            headers: { 'Authorization': 'Bearer ' + token }
        };
        this.http.get(this.apiUrl + 'rev2/list/companies', header)
            .subscribe(data =>
        {
            data['companies'].forEach(element => {
                arr.push(element);
            })
            service.companyArray = arr.sort(service.orderCompaniesById);;
            service.setSelectedCompany();
            service.loadCompanyRelatedData();
            service.configEvent$.emit(new ConfigEvent(Global.SIGNAL_REFRESH_COMPANY));
        },
        data => 
        {
        })
    }

    loadCompaniesArray()
    {
        this.loadCompaniesInt(this);
    }

    isCompanySelected() {
        return sessionStorage.getItem(Global.SESSION_COMPANY) != null;
    }

    getSelectedCompany() {
        return JSON.parse(sessionStorage.getItem(Global.SESSION_COMPANY));
    }

    getCompany() {
        return this.http.get(this.apiUrl + `rev2/company`);
    }

    removeSelectedCompany() {
        sessionStorage.removeItem(Global.SESSION_COMPANY);
    }

    orderCompaniesById(m1 : any, m2 : any) : number
    {
        if (m1.id < m2.id)
            return -1;
        else
        if(m1.id > m2.id)
            return 1;
        else return 0;
    }

    getUsersMailArray()
    {
        let str     = sessionStorage.getItem(Global.ATTRIBUTE_USER_OBJECT);
        let usr : User  = JSON.parse(str);

        let company = this.getSelectedCompany();
        let arr = [];
        company.users?.forEach(user =>
        {
            if(user!=null)
            {
                arr.push(user.email);
            }
        })
        if (!arr.includes(usr.email))
            arr.push(usr.email);
        arr = arr.sort();
        return arr;
    }
    // Tariffs

    saveTariff(data: Tariff) {
        this.saveTariffInt(data, this.popupService);
    }

    saveTariffInt(data: Tariff, service: PopupService) {
        return this.http.post(this.apiUrl + 'rev2/update/tariff', data)
            .subscribe(() => {
                    service.addPopupOk('Erfolg', 'Tarif wurde erfolgreich gespeichert.');
                    // Und Tarife neu laden
                    this.loadTariffs();
                },
                function onError() {
                    service.addPopupError('Fehler beim Speichern',
                        'Der Tarif \'' + data.name + '\' konnte nicht gespeichert werden.');
                });
    }

    getTariffs(): Tariff[] {
        return this.tariffs;
    }


    loadTariffs() {
        this.loadTariffsInt(this);
    }

    loadTariffsInt(service: ConfigService) 
    {
        let cstr    = sessionStorage.getItem(Global.SESSION_COMPANY);
        if ((cstr == null) || (cstr.length == 0))
        {
            service.tariffs = [];
            return;
        }
        let company = JSON.parse(cstr);
        return this.http.get(this.apiUrl + 'rev2/get/tariffs/' + company.id)
            .subscribe(function onDataLoaded(data) {
                    service.tariffs = data['tariffs'];
                    service.configEvent$.emit(new ConfigEvent(Global.SIGNAL_REFRESH_TARIFF));
                },
                function onError() {
                    service.tariffs = [];
                    service.configEvent$.emit(new ConfigEvent(Global.SIGNAL_REFRESH_TARIFF_KO));
                });
    }

    getMetersForCompany(): Meter[] {
        return this.metersForCurrentCompany;
    }

    loadMetersForCompany() {
        this.loadMetersForCompanyInt(this);
    }

    /*
        Vergleichsroutine für die Sortierung nach
        Name.
    */
    compareMetersByName(m1 : Meter, m2 : Meter) : number
    {
        if (m1.values.user0 < m2.values.user0)
            return -1;
        else 
        if(m1.values.user0 > m2.values.user0)
            return 1;
        else return 0;
    }

    /**
     * Wirft das Laden der Messpunkte der aktuellen Firma vom Server an
     * @param service  Der Configservice (eigentlich this), wird im subscribe
     *                 gebraucht, da dort this das Observable und nicht der
     *                 Service ist
     */
    loadMetersForCompanyInt(service: ConfigService) 
    {
        let cstr    = sessionStorage.getItem(Global.SESSION_COMPANY);
        if ((cstr == null) || (cstr.length == 0))
        {
            service.metersForCurrentCompany = [];
            return;
        }
        
        let company = JSON.parse(sessionStorage.getItem(Global.SESSION_COMPANY));
        this.http.get(this.apiUrl + 'rev2/get/meter-company/' + company.id)
            .subscribe(function onSuccess(data: Meter[]) {
                    service.metersForCurrentCompany = data.sort(service.compareMetersByName);
                    service.configEvent$.emit(new ConfigEvent(Global.SIGNAL_REFRESH_METER));
                },
                function onError() {
                    service.metersForCurrentCompany = [];
                });
    }

    loadVMPsForCompany() : void
    {
        let cstr    = sessionStorage.getItem(Global.SESSION_COMPANY);
        if ((cstr == null) || (cstr.length == 0))
        {
            this.vmpAndMetersForCurrentCompany = [];
            return;
        }
        
        let company = JSON.parse(sessionStorage.getItem(Global.SESSION_COMPANY));
        this.http.get(this.apiUrl + 'rev2/get/allmeters-company/' + company.id)
            .subscribe((data: Meter[]) => 
                {
                    this.vmpAndMetersForCurrentCompany = data.sort(this.compareMetersByName);
                    this.configEvent$.emit(new ConfigEvent(Global.SIGNAL_REFRESH_VMP));
                },
                function onError(err) {
                    this.vmpAndMetersForCurrentCompany = [];
                });
    }

    deleteTariff(tariff: Tariff): void {
        this.deleteTariffInt(tariff, this);
    }

    deleteTariffInt(tariff: Tariff, service: ConfigService): void {
        this.http.post(this.apiUrl + 'rev2/update/tariff/delete', tariff)
            .subscribe(function onSuccess() 
               {
                   service.loadTariffs();

                    service.popupService.addPopupOk('Erfolg',
                        'Der Tarif \'' + tariff.name + '\' wurde gelöscht.');
                },
                function onError() {
                    service.popupService.addPopupError('Fehler',
                        'Der Tarif \'' + tariff.name + '\' konnte nicht gelöscht werden.');
                }
            );
    }

    //Reports

    loadReportsWithRepeatInt(service: ConfigService)
    {
        let cstr    = sessionStorage.getItem(Global.SESSION_COMPANY);
        if ((cstr == null) || (cstr.length == 0))
        {
            service.reportsForCurrentCompany = [];
            return;
        }
        let company = JSON.parse(cstr);
        this.http.get(this.apiUrl + 'rev2/list/report/' + company.id).subscribe(data =>
        {
            service.reportsForCurrentCompany = data['reports'];
            service.configEvent$.emit(new ConfigEvent(Global.SIGNAL_REFRESH_REPORTS));

        },() => {
            service.reportsForCurrentCompany = [];
        });
    }
    loadReportsForCurrentCompany()
    {
        this.loadReportsWithRepeatInt(this);
    }
    getReportsForCurrentCompany()
    {
        return this.reportsForCurrentCompany;
    }

    //alarms

    loadAlarmsForCompanyInt(service)
    {
        let cstr    = sessionStorage.getItem(Global.SESSION_COMPANY);
        if ((cstr == null) || (cstr.length == 0))
        {
            service.alarmsForCurrentCompany = [];
            return;
        }

        let company = JSON.parse(cstr);
        this.http.get(this.apiUrl + 'rev2/list/alarm/' + company.id).subscribe(data =>
        {
            service.alarmsForCurrentCompany = data['alarms'];
            service.configEvent$.emit(new ConfigEvent(Global.SIGNAL_REFRESH_ALARMS));

        },() => {
            service.alarmsForCurrentCompany = [];
        });
    }
    loadAlarmsForCompany()
    {
        this.loadAlarmsForCompanyInt(this);
    }
    getAlarmsForCurrentCompany()
    {
        return this.alarmsForCurrentCompany;
    }

    //Cost


    loadCostDataForCostUnit(cu : CostUnit, meas : string, from : number, to : number) : void
    {
        let token   = sessionStorage.getItem(Global.ATTRIBUTE_USER_TOKEN);
        let company = JSON.parse(sessionStorage.getItem(Global.SESSION_COMPANY));
        let data  = {
            cuId:    cu.id,
            start:   from,
            end:     to,
            measurand: meas,
            companyId: company.id,
            headers: { 'Authorization': 'Bearer ' + token }
        };
        this.http.post(Global.apiUrl + "rev2/get/cost", data)
            .subscribe((response : any) => 
            {
                this.costs = response;
                // this.costs.peaksMonth = response.peaksMonth.sort(this.sortPeaksByMonth);
                this.configEvent$.emit(new ConfigEvent(Global.SIGNAL_REFRESH_COST));
            } );

    }

    getCosts() : CostPerCostUnit
    {
        return this.costs;
    }

    loadTodos() : void
    {
        let cstr    = sessionStorage.getItem(Global.SESSION_COMPANY);
        if ((cstr == null) || (cstr.length == 0))
        {
            this.todoGroups = [];
            return;
        }

        let token   = sessionStorage.getItem(Global.ATTRIBUTE_USER_TOKEN);
        let company = JSON.parse(cstr);
        let data    = 
        {
            headers: { 'Authorization': 'Bearer ' + token }
        };
        this.http.get(Global.apiUrl + "rev2/list/todo/" +company.id, data)
            .subscribe((response : TodoGroup[]) => 
            {
                this.todoGroups = response;
                this.configEvent$.emit(new ConfigEvent(Global.SIGNAL_REFRESH_TODO));
            },
            () => {} );       
    }

    saveTodo(todo : Todo) : boolean
    {
        this.http.post(Global.apiUrl + "rev2/update/todo", todo)
        .subscribe(() =>
        {
            this.loadTodos();
            this.popupService.addPopupOk("Erfolg", "Die Vorgabe '" + 
                todo.name +"' wurde gespeichert." );
            this.configEvent$.emit(new ConfigEvent(Global.SIGNAL_REFRESH_TODO));
        },
        () => {
            this.popupService.addPopupError("Fehler", "Die Vorgabe '" + 
                todo.name +"' konnte nicht gespeichert werden." );
        });
        return true;
    }

    saveTodoGroup(todo : TodoGroup) : boolean
    {
        this.http.post(Global.apiUrl + "rev2/update/todogroup", todo)
            .subscribe(() =>
            {
                this.loadTodos();
                this.popupService.addPopupOk("Erfolg", "Die Vorgabe '" + 
                    todo.name +"' wurde gespeichert." );
                this.configEvent$.emit(new ConfigEvent(Global.SIGNAL_REFRESH_TODO));
            },
            () => {
                this.popupService.addPopupError("Fehler", "Die Vorgabe '" + 
                    todo.name +"' konnte nicht gespeichert werden." );
            });
        return true;
    }

    getTodos() : TodoGroup[]
    {
        return this.todoGroups;
    }

    deleteTodoGroup(todo : TodoGroup) : void
    {
        let url = Global.apiUrl +"rev2/update/todo/delete";
        this.http.post(url, todo)
            .subscribe( () => {
                this.loadTodos();
            },
            () => {
                this.popupService.addPopupError("Fehler", 
                    "Löschen der Vorgabe '" + todo.name +"' gescheitert." );
            }
            );
    }

    getContainer() : MContainer[]
    {
        return this.allContainer;
    }

    loadContainer() : void
    {
        let company = this.getSelectedCompany();
        if (company == null)
           return;
        this.http.get(this.apiUrl + 'rev2/list/container/' + company.id)
            .subscribe((data : any) =>
            {
                this.allContainer = data.container;
                this.configEvent$.emit(new ConfigEvent(Global.SIGNAL_REFRESH_CONTAINER));
            });
    }

    loadTablesForCompany() : void
    {
        let cstr  = sessionStorage.getItem(Global.SESSION_COMPANY);
        if ((cstr == null) || (cstr.length == 0))
        {
            this.allTables = [];
            return;
        }
        let company = JSON.parse(cstr);
        this.http.get(this.apiUrl + 'rev2/list/tables/' + company.id)
            .subscribe((data) => {
                    this.allTables = data['tables']
                    this.configEvent$.emit(new ConfigEvent(Global.SIGNAL_REFRESH_TABLES));
                }, () =>
                {
                    this.allTables = [];
                });

    }

    loadCompanyRelatedData() : void
    {
        this.loadTodos();
        this.loadWeatherForCompany();
        this.loadTariffs();
        this.loadMetersForCompany();
        this.loadVMPsForCompany();
        this.loadReportsForCurrentCompany();
        this.loadAlarmsForCompany();
        this.loadContainer();
        this.loadCostUnits();
        this.loadTablesForCompany();
    }

    saveContainer(container: MContainer)
    {
        this.http.post(Global.apiUrl + "rev2/update/container", container)
            .subscribe(() =>
            {
                this.loadContainer();
                this.configEvent$.emit(new ConfigEvent(Global.SIGNAL_REFRESH_CONTAINER));
            },
            () => {
                this.popupService.addPopupError("Fehler", "Der Messcontainer '" + 
                    container.name +"' konnte nicht gespeichert werden." );
            });
        return true;
    }

    checkValidityOfId(inputId: any)
    {
        let company = JSON.parse(sessionStorage.getItem(Global.SESSION_COMPANY));
        this.http.get(Global.apiUrl + "rev2/unique/meter/" + inputId)
            .subscribe(() => 
            {
                this.configEvent$.emit(new ConfigEvent(Global.SIGNAL_METER_ID, true))
            },() =>
            {
                this.configEvent$.emit(new ConfigEvent(Global.SIGNAL_METER_ID, false));
            })
    }

    deleteContainer(container: MContainer)
    {
        this.http.post(Global.apiUrl + "rev2/update/container/delete", container )
            .subscribe(() => {
                this.configEvent$.emit(new ConfigEvent(Global.SIGNAL_REFRESH_CONTAINER));
            }, () => 
            {
            })
    }

    isGroupOverDue(group : TodoGroup) : boolean
    {
        let today = Date.now();        
        // Fälligkeit der Gruppe liegt in der Vergangenheit?
        if (group.done)
            return false;
        if (group.deadline <= today)
            return true;
        for (let i = 0; i < group.todos.length; i++)
        {
            if ((group.todos[i].deadline <= today) && (!group.todos[i].done))
               return true;
        }
        return false;
    }

    isGroupDue(group : TodoGroup) : boolean
    {
        let week = Date.now() + 2 * 24 * 60 * 60 * 1000;        
        // Fälligkeit der Gruppe liegt in der Vergangenheit?
        if (group.done)
            return false;
        if (group.deadline <= week)
            return true;
        for (let i = 0; i < group.todos.length; i++)
        {
            if ((group.todos[i].deadline <= week) && (!group.todos[i].done))
               return true;
        }
        return false;
    }

    sortPeaksByMonth(c1: CostPair, c2 : CostPair) : number
    {
        if (c1.month< c2.month)
            return -1;
        else 
        if (c1.month > c2.month)
            return 1;
        else return 0;      
    }

    loadCostUnits() : void
    {
        let company = this.getSelectedCompany();
        if (company == null)
           return;
        this.http.get(Global.apiUrl + 'rev2/list/costunit/' + company.id)
            .subscribe((data ) =>
            {
                this.costUnits = data['costUnits'];
                this.configEvent$.emit(new ConfigEvent(Global.SIGNAL_REFRESH_COSTUNIT));
            },
            (error) => 
            {
                this.costUnits = [];
            });
    }

    getCostUnits() : CostUnit[]
    {
        return this.costUnits;
    }

    saveCostUnit(cu : CostUnit) 
    {
        return this.http.post(Global.apiUrl + 'rev2/update/costunit', cu)
            .subscribe(() => {
                    this.popupService.addPopupOk('Erfolg', 'Kostenstelle wurde erfolgreich gespeichert.');
                    // Und Tarife neu laden
                    this.loadCostUnits();
                },
                () => {
                    this.popupService.addPopupError('Fehler beim Speichern',
                        'Die Kostenstelle \'' + cu.name + '\' konnte nicht gespeichert werden.');
                });        
    }

    deleteCostUnit(cu : CostUnit) 
    {
        return this.http.post(Global.apiUrl + 'rev2/update/costunit/delete', cu)
            .subscribe(() => {
                    this.popupService.addPopupOk('Erfolg', 'Kostenstelle wurde gelöscht.');
                    // Und Tarife neu laden
                    this.loadCostUnits();
                },
                () => {
                    this.popupService.addPopupError('Fehler beim Löschen',
                        'Die Kostenstelle \'' + cu.name + '\' konnte nicht gelöscht werden.');
                });        
    }

    setSelectedCompany() : void
    {
        if ((this.companyArray == null) || (this.companyArray.length == 0))
        {
            console.log("SetSelected aber companyArray ist leer???")
            this.loadCompaniesArray();
            return;
        }
        let usrstr = sessionStorage.getItem(Global.ATTRIBUTE_USER_OBJECT);
        let user : User = JSON.parse(usrstr);   
        if (user.admin)
        {
            let selected = sessionStorage.getItem(Global.SESSION_COMPANY);
            if (selected == null)
            {
                sessionStorage.setItem(Global.SESSION_COMPANY, 
                    JSON.stringify(this.companyArray[0]));
            }
        }
        else
        {
            for (let i = 0; i < this.companyArray.length; i++)     
            {
                if (this.companyArray[i].id == user.companyId)
                {
                    sessionStorage.setItem(Global.SESSION_COMPANY, 
                        JSON.stringify(this.companyArray[i]));
                    break;
                }
            }    
        }
        this.configEvent$.emit(new ConfigEvent(Global.SIGNAL_COMPANY_SELECTED));
    }

    getUser()
    {
        let usrstr = sessionStorage.getItem(Global.ATTRIBUTE_USER_OBJECT);
        let user : User = JSON.parse(usrstr);
        return user;
    }

    saveManualValue(mv: ManualValue) : void
    {
        this.http.post(Global.apiUrl + 'rev2/update/manualimport', mv)
            .subscribe(() => {
                    this.popupService.addPopupOk('Erfolg', 'Wert wurde gespeichert.');
                },
                () => {
                    this.popupService.addPopupError('Fehler beim Speichern',
                        'Der Wert konnte nicht gespeichert werden. Evtl. liegen die Daten bereits vor?');
                    this.configEvent$.emit(new ConfigEvent(Global.SIGNAL_DELETE_LAST_INPUT));
                });        

    }

    deleteManualValue(mv: ManualValue) : void
    {
        this.http.post(Global.apiUrl + 'rev2/update/manualimport/delete', mv)
            .subscribe(() => {
                    this.popupService.addPopupOk('Erfolg', 'Wert wurde gelöscht.');
                    this.configEvent$.emit(new ConfigEvent(Global.SIGNAL_DELETE_LAST_INPUT));
                },
                () => {
                    this.popupService.addPopupError('Fehler beim Löschen',
                        'Der Wert konnte nicht gelöscht werden.');
                    this.configEvent$.emit(new ConfigEvent(Global.SIGNAL_DELETE_LAST_INPUT));
                });      
    }

    interpolateMeter(id : string, from : number, to : number) : void
    {
        let obj =
        {
            id : id,
            from: from,
            to: to
        };
        this.http.post(Global.apiUrl + 'rev2/update/interpolate', obj)
            .subscribe(() => {
                this.popupService.addPopupInfo("Interpolation gestartet", 
                    "Die Interpolation der eingegebenen Werte wurde gestartet. Dies kann ein wenig dauern.")

            },
            () => 
            {
                this.popupService.addPopupError('Fehler bei der Inhterpolation',
                        'Die Interpolation konnte nicht gestartet werden.');
            }

            );
    }

    loadWeatherForCompany() : any
    {
        if (this.getSelectedCompany() == null) 
           return;
        let id = this.getSelectedCompany().id;
        this.http.get(Global.apiUrl +"rev2/get/weather/" + id)
            .subscribe((w) => 
            {
                this.weather = w;
                this.configEvent$.emit(new ConfigEvent(Global.SIGNAL_REFRESH_WEATHER));
            })
    }

    loadColumnsForTable(table : string) : any
    {
        let observe = this.http.get(Global.apiUrl +"rev2/list/columns/" + table);
        observe.subscribe((cols) => 
            {
                this.allCols = cols['columns'];
                this.configEvent$.emit(new ConfigEvent(Global.SIGNAL_REFRESH_COLUMNS));
            })
        return observe;
    }
  
}
