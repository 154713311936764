<div class="row" *ngIf="!inputValidation">
    <div class="col-xl-6 col-md-10 col-12">
        <div class="card">
            <div class="card-body">
                <form class="form-detail" #alarmForm="ngForm">
                    <div class="row form-left">
                        <h2 class="titel ">Vorgabekontrolle</h2>
                        <p>
                            An dieser Stelle können Sie die Vorgabekontrolle aktivieren
                            oder auch deaktivieren.
                        </p>
                        <br>
                        <div class="form">
                            <div class="row form-group">
                                <div class="col-lg-6 col-md-12 col-sm-12">
                                    <label>Alarmstyp :</label>
                                    <ng-select [items]="alarmTypeArray"
                                               bindLabel="title"
                                               [multiple]="false"
                                               [clearable]="false"
                                               (change)="isInputValidation()"
                                               [(ngModel)]="selectedAlarmType"
                                               [ngModelOptions]="{standalone: true}">
                                    </ng-select>
                                </div>

                            </div>

                            <div class="row form-group">

                                <div class="col-md-12">
                                    <label style="width: 150px" for="mails">E-Mails :</label>
                                    <ng-select [items]="allMails" id="mails"
                                               bindLabel="name"
                                               name="mails"
                                               [multiple]="true"
                                               [clearable]="true"
                                               [(ngModel)]="selectedMails"
                                               [ngModelOptions]="{standalone: true}"
                                    >
                                    </ng-select>

                                </div>
                            </div>
                            <div class="row form-group">
                                <div class="col-md-12 changeRepeat">
                                    <span>Active :</span>
                                    <input type="checkbox"
                                           name="check"
                                           style="position: absolute; bottom: 0px"
                                           [(ngModel)]="isActive"
                                           #active
                                    >
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="center">
                        <button (click)="createAlarm()"
                                class="submit-button"
                        >Alarm aktivieren
                        </button>
                    </div>

                </form>
            </div>
        </div>
    </div>
</div>
<app-alarms *ngIf="inputValidation"></app-alarms>
