import { Component, OnInit } from '@angular/core';
import { NodeService} from '../../../../services/node.service';
import { ConfigService } from '../../../../services/config.service';
import {NgSelectModule, NgOption} from '@ng-select/ng-select';
import {element} from 'protractor';

@Component({
  selector: 'app-meter',
  templateUrl: './meter.component.html',
  styleUrls: ['./meter.component.scss']
})
export class MeterComponent implements OnInit {
  selectedNodes: string[];
  nodes;
  node;
  topNodes;
  meters;
  meter;
  meter_id;
  connection;
  company;
  unassigned_nodes;


  //company_id = 41 ;
  //nodes_connect = ["vm3c5mkl1s1qm1s5upalj8lhlg","iiogmeikdfva5tk50qo33qehjf"]

  constructor(
    private nodeService : NodeService,
    private configService : ConfigService,
  ) { }

  ngOnInit(): void {
    this.getAllNodes(this);
    this.getAllMeters(this);
    if(this.configService.getSelectedCompany() != null ){
      this.company = this.configService.getSelectedCompany();
      this.selectedNodes = null;
    }

    //this.getNodebyId('vm3c5mkl1s1qm1s5upalj8lhlg');

  }

  getAllNodes(obj){
    this.nodeService.findAllNodes().subscribe(data => {
     obj.nodes = data;
     obj.getTopNodes();
     obj.getConnect(this.company.id);

     obj.getUnassignedNodes(this.company.id);
    });
    return this.nodes;

  }
  getAllMeters(obj){

    this.nodeService.findAllMeters().subscribe(data => {
      //console.log(data);
      obj.meters = data;
      obj.getMeterById('75i7f43q62d4qo6g9ct1tp5vg8');

    });
    return this.meters;

  }
  getNodebyId(id){
    for(let i=0; i < this.nodes.length ; i++){
      if(this.nodes[i].id == id){
        //console.log(this.nodes[i]);
        return this.nodes[i];
      }
    }
  }
  getTopNodes(){
    let arr = [];
    for(let i=0; i < this.nodes.length ; i++){
      if(this.nodes[i].path.indexOf('/') < 0){
        arr.push(this.nodes[i]);
      }
    }
    this.topNodes = arr;
    return arr;
  }

  getMeterById(id){
    for(let i=0; i < this.meters.length ; i++){
      if(this.meters[i].id == id){
        //console.log(this.meters[i]);
        return this.meters[i];
      }
    }
  }

  setConnect(){
    return this.nodeService.connectNodesWithCompany(this.company.id,this.selectedNodes)
        .subscribe(result => {
//            console.log("setConnect erfolgreich")

            }, 
            () => 
            {
//                console.log("setConnect fehlgeschlagen")
            });
  }
   getConnect(company_id){
      return this.nodeService.getConnections(company_id).subscribe(data => {

        this.connection = data;

      });
   }
   getUnassignedNodes(company_id){
    return this.nodeService.getConnections(company_id).subscribe(data => {
      this.unassigned_nodes = data['unassigned'];
      //console.log(this.unassigned_nodes);
      this.connection = data;

    });
   }



}
