import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmService } from '../../../../services/confirm.service';

import { formatNumber, formatCurrency } from '@angular/common';
import { NgbDateCustomParserFormatter} from '../../../../../config/datepicker';
import { NgbDateParserFormatter, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';

import { ConfigService } from '../../../../services/config.service';
import { Tariff } from '../../../../classes/tariff';

import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { Meter } from 'src/app/classes/meter';
import { Global } from 'src/constants/global';

/*
  Mehr Informationen und Beispiele zu den Popovers:
  https://www.freakyjolly.com/angular-ng-bootstrap-popover-tutorial-by-example/
*/

@Component({
  selector: 'app-tariff',
  templateUrl: './tariff.component.html',
  styleUrls: ['./tariff.component.scss'],
  providers: [
    {provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter}
   ]
})

export class TariffComponent implements OnInit 
{
    @ViewChild('tForm') tForm: NgForm;
    
    declare $ : any;
    tariffs : Tariff[];
    currTariffId = -1;
    currIndex  = -1;
    tarName : string = "";
    priceBase: string = "";
    priceUnit: string = "";
    priceCap:  string = "";
    tarDateS: NgbDate;
    errorMessage: string = "";
    energy: string ="0";
    chargeTimeframe: any ="0";
    allMeters : Meter[];
    selectedMeters: Meter[];
    dirty : boolean = false;
  constructor(
    private confirmDialogService: ConfirmService, 
    private configService : ConfigService
  ) 
  {
      registerLocaleData(localeDe);
      this.errorMessage = "";
      let d = new Date();
      this.tarDateS = new NgbDate(d.getFullYear(),d.getMonth() +1,d.getDate());
      this.configService.configEvent$.subscribe(data =>
      {
          if (data.name == Global.SIGNAL_REFRESH_TARIFF)
              this.refreshMetersAndTariffs();
      });
  }

  ngOnInit(): void 
  {
      this.configService.loadTariffs();
  }

  public testMask = {
    mask: Number,
    scale: 2,
    signed: false,
    padFractionalZeros: true,
    normalizeZeros: false,
    min: 0
};


  refreshMetersAndTariffs()
  {
      this.tariffs    = this.configService.getTariffs();
      this.allMeters  = this.configService.getMetersForCompany();
      this.selectedMeters = [];
      this.currIndex      = -1;
      this.currTariffId   = -1;
  }

  selectIndex(index: number) : void
  {
      if (this.isDirty(index))
      {
        this.confirmDialogService.confirmYesNo("Hinweis","Tarif wurde geändert. Daten verwerfen?",
           () => 
           {  
               this.setTariffValues(index);
           }, () => 
           {
           })        
      }
      else
      {
          this.setTariffValues(index);
      }
  }

  setTariffValues(index : number)
  {
    this.currIndex    = index;
    let tariff        = this.tariffs[index];
    this.currTariffId = tariff.id;
    this.tarName    = tariff.name;
    this.energy     = tariff.energy;
    this.priceBase  = "" + tariff.priceBase; 
    this.priceCap   = "" + tariff.priceCap;
    this.priceUnit  = "" + tariff.priceUnit;
    this.chargeTimeframe = tariff.period;
    this.updateSelectedMeters(tariff);
    this.errorMessage    = "";
    let d = new Date(tariff.start);
    this.tarDateS        = new NgbDate(d.getFullYear(),d.getMonth() +1,d.getDate());
    // In selectedMeters die dem Tarif zugeordneten Messpunkte eintragen
    this.selectedMeters = [];
    for (let i = 0; i < tariff.measuringPoints.length; i++)
    {
        let id = tariff.measuringPoints[i];
        for (let j  = 0; j < this.allMeters.length; j ++)
        {
            if (this.allMeters[j].id == id)
            {
                this.selectedMeters.push(this.allMeters[j].values.user0);
                break;
            }
        }
    }
    this.tForm.form.markAsPristine();
    this.dirty = false;
  }

  updateSelectedMeters(t : Tariff) : void
  {
      this.selectedMeters = [];
      for (let i = 0; i < t.measuringPoints.length; i++)
      {
          let meterId = t.measuringPoints[i];
          for (let j = 0; j < this.allMeters.length; j++)
          {
              let meter = this.allMeters[j];
              if (meter.id == meterId)
              {
                  this.selectedMeters.push(meter);
                  break;
              }
          }
      }

  }

  saveTariff()
  {

      this.errorMessage = "";
      // 1. lösche evtl. Fehlermeldungen
      if (this.checkFormData())
      {
          let data      = this.createTariffFromForm();
          this.configService.saveTariff(data);          
      }
  }

  private createTariffFromForm() : Tariff
  {
    let stamp     = new Date(this.tarDateS.year, this.tarDateS.month - 1,this.tarDateS.day);
    let data      = new Tariff();
    data.id       = this.currTariffId;
    data.name     = this.tarName;
    data.start    = stamp.getTime();
//    data.priceBase = parseFloat(this.priceBase);
    data.priceBase = 0;
    let value = this.priceUnit.replace(',', '.');
    data.priceUnit = parseFloat(value);
    //data.priceCap  = parseFloat(this.priceCap);
    data.priceCap  = 0;
    //data.period    = this.chargeTimeframe;
    data.period    = 0;
    data.energy    = this.energy;
    data.companyId = this.configService.getSelectedCompany().id;
    data.measuringPoints = this.getIdList();
    return data;
  }

  private getIdList() : string[]
  {

      let result = [];
      for (let i = 0; i < this.selectedMeters.length; i++)
      {
          // Das sind leider die Namen, nicht die IDs
           let name = this.selectedMeters[i];
           for (let ni = 0; ni < this.allMeters.length; ni++)
           {
               let m = this.allMeters[ni];
               if (m.values.user0 == name)
               {
                  result.push(m.id);
                  break;
               }
           }
      }

      return result;
  }

  checkFormData() : boolean
  {
    if (!this.checkFormTariffName())
      {
          this.errorMessage = "Bitte einen Tarifnamen eingeben!"
          return false;
      }
      if (!this.checkFormStartDate())
      {
        this.errorMessage = "Bitte ein korrektes Startdatum eingeben!"
        return false;
      }
      // if (!this.checkFormCurrency(this.priceBase))
      // {
      //   this.errorMessage = "Der Betrag für den Basispreis ist nicht korrekt!"
      //   return false;
      // }
      // if (!this.checkFormCurrency(this.priceCap))
      // {
      //   this.errorMessage = "Der Betrag für den Leistungspreis ist nicht korrekt!"
      //   return false;
      // }
      if (!this.checkFormCurrency(this.priceUnit))
      {
        this.errorMessage = "Der Betrag für den Verbrauchspreis ist nicht korrekt!"
        return false;
      }

      return true;
  }

  checkFormCurrency(value : string) : boolean
  {
    if (value == null || value.length == 0)
         return false;
      if (value.indexOf(',') >= 0) 
         value = value.replace(',', '.');
      if (isNaN(parseFloat(value)))
         return false;
      return true;
  }

  checkFormTariffName() : boolean
  {
      if (this.tarName == null || this.tarName.length == 0)
         return false;
      return true;
  }

  /**
   * Prüft, ob ein gültiges Datum eingetragen wurde
   * VORSICHT: Setzt this.tarDateS, wenn Datum ok ist
   */
  checkFormStartDate() : boolean
  {
      var value : string;
      var day   : number;
      var month : number;
      var year  : number;
      if (this.tarDateS == null)
         return false;
      // Kann String sein oder NgbDate
      if (this.tarDateS.constructor.name == "String")
      {
          var cpf = new NgbDateCustomParserFormatter();
          let value = this.tarDateS.toString();
          let res = cpf.parse(value);
          year  = res.year;
          month = res.month;
          day   = res.day;
      }
      else
      {
          year  = this.tarDateS.year;
          month = this.tarDateS.month;
          day   = this.tarDateS.day;
      }
      if (year < 2000)
         return false;
      if ((month < 1) || (month > 12))        
         return false;
      if ((day < 1) || (day > 31))
         return false;
      this.tarDateS = new NgbDate(year, month, day);
      return true;
  }

  createNewTariff() : void
  {
      this.currTariffId = -1;
      this.currIndex    = -1;
      this.tForm.reset();
      this.dirty        = false;
      this.energy       = "0";
      this.selectedMeters = [];
  }
  
  deleteCurrentTariff() : void
  {
      let tariff = this.tariffs[this.currIndex];
      this.configService.deleteTariff(tariff);
  }

  isDirty(nr : number) : boolean
  {
      if (this.currIndex < 0)
         return false;
      let org = this.tariffs[this.currIndex];
      let cmp = this.createTariffFromForm();
      if (org.name != cmp.name)
         return true;
      if (org.priceBase != cmp.priceBase)
         return true;
      if (org.priceCap != cmp.priceCap)
         return true;
      if (org.priceUnit != cmp.priceUnit)
         return true;
      let d1 = new Date(org.start);
      let d2 = new Date(cmp.start);
      if ((d1.getMonth() != d2.getMonth()) || (d1.getFullYear() != d2.getFullYear()) ||
          (d1.getDate() != d2.getDate()))
         return true;
      if (org.measuringPoints.length != cmp.measuringPoints.length)
         return true;
      if (org.period != cmp.period)
         return true;
      if (org.energy != cmp.energy)
         return true;
      return false;
  }

  
}
