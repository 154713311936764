import { Component, OnInit } from '@angular/core';
import { ReportService } from '../../../../services/report.service';
import {ConfigEvent, ConfigService} from '../../../../services/config.service';
import { NgbDateCustomParserFormatter} from '../../../../../config/datepicker';
import {NgbDate, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import {registerLocaleData} from '@angular/common';
import localeDe from '@angular/common/locales/de';
import {Global} from '../../../../../constants/global';
import { Meter } from 'src/app/classes/meter';

@Component({
  selector: 'app-alarms',
  templateUrl: './alarms.component.html',
  styleUrls: ['./alarms.component.scss'],
  providers: [
    {provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter}
  ]
})
export class AlarmsComponent implements OnInit {

  alarmTypeArray : any[];
  allMeters      : Meter[];
  allVmps        : Meter[] = [];
  allMails       : any[];
  allReports     : any[];
  selectedReport : any;

  errorMessage   : string;
  medianActive   : boolean;
  diff           : string;
  absolute       : string;
  perc           : string;


  selectedAlarmType  : any;
  selectedMeter      : any;
  selectedPeriod     : any;
  selectedMeters     : any[];
  warnLevel          : number;
  mailLevel          : string;
  minValue           : any;
  maxValue           : any;
  selectedMails      : any[];
  description        : string;
  dateHasChanged     : boolean = false;
  selectedMeter1     : Meter;
  selectedMeter2     : Meter;
  budgetBase         : any = "0";
  recurrenceArray    : any[];

  company   : any;
  mail      : any;
  maxHours  : any;

  mailLevelsArray     = [ "immer" , "wenn Fehler auftreten" , "wenn alles ok ist" ] ;

  constructor(
      private reportService : ReportService,
      private configService : ConfigService
  ) {

    registerLocaleData(localeDe);
    this.configService.configEvent$.subscribe( (data : ConfigEvent) =>
    {
        if (data.name == Global.SIGNAL_COMPANY_SELECTED)
        {
            this.loadAlarmData();
        }
    });
    this.reportService.reportEvent$.subscribe(data =>
    {
      this.refreshAlarmData();
      if (data.name == Global.SIGNAL_DEFAULT_ALARMS)      // als default wird immer InputValidation ausgewählt
      {
        this.loadAlarmData();
      }
    });

  }

  ngOnInit(): void {
    this.mailLevel = this.mailLevelsArray[1];
    this.selectedMeter = null;
    this.selectedMails = null;
    this.selectedReport = null;
  }

  public testMask = {
    mask: Number,
    scale: 0,
    signed: false,
    normalizeZeros: false,
    min: 0,
    max: 36
};


  public diffMask = {
    mask: Number,
    scale: 2,
    signed: false,
    padFractionalZeros: true,
    normalizeZeros: false,
    min: 0
};

  public percMask = {
    mask: Number,
    scale: 2,
    signed: false,
    padFractionalZeros: true,
    normalizeZeros: false,
    min: 1,
    max: 100
    };

  loadAlarmData() :void
  {
    let arr = []
    this.reportService.loadAlarmType().subscribe(data =>
    {
      data['alarms'].forEach(element => {
        arr.push(element);
      });
      this.selectedReport    = null;
      this.alarmTypeArray    = arr;
      this.selectedAlarmType = arr[0];
      this.mail              = JSON.parse(sessionStorage.getItem(Global.ATTRIBUTE_USER_OBJECT)).email;
      this.selectedMails     = [this.mail];
      this.allMeters         = this.configService.getMetersForCompany();
      this.refreshAlarmData();
    })

  }
  
  refreshAlarmData()
  {
    this.company   = this.configService.getSelectedCompany();
    this.allMeters = this.configService.getMetersForCompany();
    this.allMails  = this.configService.getUsersMailArray();
    this.allReports= this.configService.getReportsForCurrentCompany();
    this.recurrenceArray    = this.reportService.getRecurrenceClass();
    this.allVmps = this.allMeters;
    let allMps = this.configService.vmpAndMetersForCurrentCompany;
    for (let i = 0; i < allMps?.length; i++)
    {
        if (allMps[i].className == ".VirtualMeasuringPoint")
           this.allVmps.push(allMps[i]);
    }
  }

  getSelectedMetersId()
  {
    let arr    = [];
    let meters;
    if (this.selectedMeters)
      meters = this.selectedMeters;
    else
      return null;


    for (let i = 0; i < meters.length; i++) {
      let m: Object = meters[i];
      arr.push(m['id']);
    }
    return arr;
  }

  getSelectedMailLevel()
  {
    if (this.mailLevel == this.mailLevelsArray[0])
      return 0;
    else if (this.mailLevel == this.mailLevelsArray[1])
      return 1;
    else if (this.mailLevel == this.mailLevelsArray[2])
      return 2;
    else
      return 0;
  }
  
  getMailLevel(name : string) : number
  {
      if (name.toLowerCase() == "immer")
         return 0;
      if (name.toLowerCase() == 'wenn fehler auftreten')
         return 1;
      return 2;
  }

  isToDoValidation() : boolean
  {
    if (this.selectedAlarmType?.name == "todo_validation")
        return true;
    else return false;
  }

  isPlauseValidation() : boolean
  {
      if (this.selectedAlarmType?.name == "plause_validation")
         return true;
      else return false;
  }

  isInputValidation() : boolean
  {
      if (this.selectedAlarmType?.name == "input_validation")
        return true;
      else return false;
  }

  // Ausreisser
  isThresholdValidation() : boolean
  {
    if (this.selectedAlarmType?.name == "threshold_validation")
       return true;
    else return false;
  }

  isMinMaxValidation() : boolean
  {
    if (this.selectedAlarmType?.name == "minmax_validation")
       return true;
    if (this.selectedAlarmType?.name == "minmax_report")
        return true;
    else return false;
  }

  isMinMaxReportValidation() : boolean
  {
    if (this.selectedAlarmType?.name == "minmax_report")
        return true;
    else return false;
  }

  isBudgetValidation() : boolean
  {
    if (this.selectedAlarmType?.name == "budget_validation")
        return true;
    else return false;
  }


  getFloat(value : string) : number
  {
      if (value == null)
        return 0;
      value = value.replace(',', '.');
      return  parseFloat(value);

  }

  isAlreadyActive() : boolean
  {
      let alarms = this.configService.alarmsForCurrentCompany;
      if (alarms == null)
         return false;
      for (let i = 0; i < alarms.length; i++)
      {
          if (alarms[i].alarmType == 'Vorgabekontrolle')
             return true;
      }
      return false;
  }

  validateInputAlarm() : boolean
  {
      if (this.selectedMails == null)
      {
          this.errorMessage = "Bitte mindestens eine E-Mailadresse angeben.";
          return false;
      }
      if ((this.description == null) || (this.description.length == 0))
      {
          this.errorMessage = "Bitte geben Sie einen Namen an.";
          return false;
      }
      if ((this.selectedMeters == null) || (this.selectedMeters.length == 0))
      {
          this.errorMessage = "Legen Sie bitte mindestens einen Messpunkt fest.";
          return false;
      }
      this.errorMessage = "";
      return true;
  }

  // Messwertkontrolle
  handleCreateInputAlarm() : void
  {
      if (!this.validateInputAlarm())
         return;
      this.createInputAlarm();
  }
  
  handleCreateBudgetAlarm() : void
  {
      if (!this.validateBudgetAlarm())
         return;
      this.createBudgetAlarm();        
  }

  createBudgetAlarm() : void
  {
      let obj = {
        budget : this.getFloat(this.diff),
        measuringPoint: this.selectedMeter.id,
        recurrence: this.selectedPeriod,
        description: this.description,
        alarmType: this.selectedAlarmType.title,
        mail: this.selectedMails,
        mailLevel: this.mailLevel,
        companyId: this.company.id,
        type: this.budgetBase,
        company: this.company.name,
        startDate: Date.now()
      }
      this.reportService.createAlarmByObject(obj);
  }

  createInputAlarm() : void
  {
    this.reportService.createAlarm(
        this.company.id,
        this.company.name,
        this.selectedAlarmType.title,
        this.description,
        "",
        Date.now(),
        0,
        this.warnLevel,                      //?
        this.getSelectedMailLevel(),
        this.maxHours,                       // maxHours
        this.getSelectedMetersId(),
        this.selectedMails);
  }

  handleCreateTodoAlarm() : void
  {
      if (this.selectedMails?.length > 0)
      {
          this.errorMessage= "";

          this.reportService.createAlarm(
            this.company.id,
            this.company.name,
            this.selectedAlarmType.title, 
            this.selectedAlarmType.title,
            "",
            0,
            0,
            0,                      //?
            1,
            0,
            [],
            this.selectedMails
        )
      
      }
      else this.errorMessage ="Bitte E-Mail angeben."
  }

  handleCreatePlauseAlarm() : void
  {
      if (this.validatePlauseAlarm())
      {
          let obj = {
                        meterId1:  this.selectedMeter1.id,
                        meterId2:  this.selectedMeter2.id,
                        companyId: this.company.id,
                        company:   this.company.name,
                        alarmType: this.selectedAlarmType.title,
                        diff:      this.getFloat(this.diff),
                        description: this.description,
                        mail:      this.selectedMails,
                        mailLevel: 1,   //  Bei Fehlern

                    };
          this.reportService.createAlarmByObject(obj);  
      }
  }

  validatePlauseAlarm() : boolean
  {
      // 1. Name
      if ((this.description == null) || (this.description.length == 0))
      {
          this.errorMessage = "Bitte einen Namen eingeben."
          return false;
      }
      // 2. Messpunkte
      if (this.selectedMeter1 == null)
      {
          this.errorMessage = "Bitte geben Sie Messpunkt 1 an."
          return false;
      }
      if (this.selectedMeter2 == null)
      {
          this.errorMessage = "Bitte geben Sie Messpunkt 2 an."
          return false;
      }
      // Sicherstellen, dass gleichartige Messpunkte verglichen werden
      if ((this.selectedMeter1.values['obisid0'] !=
          this.selectedMeter2.values['obisid0']))
      {
          this.errorMessage = "Bitte zwei gleichartige Messpunkte vergleichen."
          return false;
      }
      // 3. Differenz %
      if ((this.diff == null) || (this.diff.length == 0) || 
          (this.getFloat(this.diff) < 0) || (this.getFloat(this.diff) > 100))
      {
          this.errorMessage = "Geben Sie bitte die max. Differenz in Prozent (0 - 100) an!"
          return false;
      }
      // 4. EMails
      if ((this.selectedMails == null) || (this.selectedMails.length == 0))
      {
          this.errorMessage = "Bitte mindestens eine E-Mail angeben!"
          return false;
      }
      this.errorMessage = "";
      return true;
  }

  handleCreateThresholdAlarm() : void
  {
      if (this.validateThresholdAlarm())
      {
        let obj = { medianActive: this.medianActive,
            diff: this.diff,
            absolute: this.absolute,
            perc: this.perc,
            name: this.description,
            median: this.medianActive,
            company: this.company.name,
            companyId: this.company.id,
            alarmType: this.selectedAlarmType.title,
            description: this.description,
            title: this.selectedAlarmType.title,
            startDate:  Date.now(),
            endDate:    0,
            measuringPoints: this.getSelectedMetersId(),
            mailLevel: this.getMailLevel(this.mailLevel),
            mail: this.selectedMails
         };
         
        this.reportService.createAlarmByObject(obj);
      }
  }

  validateThresholdAlarm() : boolean
  {
      if (!this.description)
      {
          this.errorMessage = "Geben Sie den Namen an";
          return false;
      }
      if (((this.perc == null)     || (this.perc.length == 0))     && 
          ((this.absolute == null) || (this.absolute.length == 0)) && 
          ((this.medianActive == null) || (!this.medianActive)))
      {
          this.errorMessage = "Aktivieren Sie entweder die prozentuale, absolute Kontrolle oder die Medianabweichung.";
          return false;
      }
      let p = this.getFloat(this.perc);
      if ((p < 0) || (p >= 100))
      {
          this.errorMessage ="Prozentualer Wert muss größer 0 und kleiner als 100 sein!";
          return false;
      }
      let a = this.getFloat(this.absolute);
      if (a < 0)
      {
          this.errorMessage = "Absolute Wert muss größer als 0 sein!";
          return false;
      }
      if ((this.selectedMeters == null) || (this.selectedMeters.length == 0))
      {
          this.errorMessage = "Bitte mindestens einen Messpunkt zur Kontrolle festlegen!";
          return false;
      }
      if ((this.selectedMails == null) || (this.selectedMails.length == 0))
      {
          this.errorMessage = "Bitte mindestens eine E-Mail angeben!"
          return false;
      }

      this.errorMessage = "";
      return true;
  }

  addAllMeters() : void
  {
      this.selectedMeters = [];
      for (let i = 0; i < this.allMeters?.length; i++)
      {
          if (this.allMeters[i].className != ".VirtualMeasuringPoint")
             this.selectedMeters.push(this.allMeters[i]);
      }
  }

  handleCreateMinMaxAlarm() : void
  {
      if (this.validateMinMaxAlarm())
      {
          let obj = {
              measuringPoints: this.getSelectedMetersId(),
              companyId: this.company.id,
              company:   this.company.name,
              alarmType: this.selectedAlarmType.title,
              description: this.description,
              mail:      this.selectedMails,
              mailLevel: 1,   //  Bei Fehlern
              active:    true,
          };
          if (this.isNumber(this.minValue)) 
             obj['min'] = this.getFloat(this.minValue);
          if (this.isNumber(this.maxValue))
             obj['max'] = this.getFloat(this.maxValue);
          if (this.selectedReport != null)
          {
              obj['reportId'] = this.selectedReport.id;
          }
          this.reportService.createAlarmByObject(obj);
      }
  }

  validateMinMaxAlarm() : boolean
  {
      if ((!this.description) || (this.description?.length == 0))
      {
          this.errorMessage = "Geben Sie den Namen an";
          return false;
      }      
      if ((this.selectedMails == null) || (this.selectedMails.length == 0))
      {
          this.errorMessage = "Bitte mindestens eine E-Mail angeben!"
          return false;
      }
      let minKO = !this.isNumber(this.minValue);
      let maxKO = !this.isNumber(this.maxValue);
      if (minKO && maxKO)
      {
          this.errorMessage = "Es muss mindestens ein Wert (Min. oder Max.) festgelegt werden."
          return false;
      }
      if (!minKO && this.getFloat(this.minValue) <= 0)
      {
          this.errorMessage = "Der minimale Wert muss größer 0 sein.";
          return false;
      }
      if (!maxKO && this.getFloat(this.maxValue) <= 0)
      {
          this.errorMessage = "Der maximale Wert muss größer 0 sein.";
          return false;
      }
      if (this.minValue && this.maxValue)
      {
          if (this.getFloat(this.maxValue) < this.getFloat(this.minValue))
          {
              this.errorMessage = "Der minimale Wert muss kleiner sein als der maximale Wert.";
              return false;
          }
      }
      if (this.selectedAlarmType.name == "minmax_report")
      {
          if ((this.selectedReport == null) || (this.selectedReport.length == 0))
          {
              this.errorMessage = "Bitte einen Bericht bestimmen.";
              return false;
          }
      }
      if ((this.selectedMeters?.length == 0) ||
          (!this.selectedMeters))
      {
          this.errorMessage = "Bitte mindestens einen Messpunkt zur Kontrolle festlegen!";
          return false;
      }
      if ((this.selectedMails == null) || (this.selectedMails.length == 0))
      {
          this.errorMessage = "Bitte mindestens eine E-Mail angeben!"
          return false;
      }

      this.errorMessage = "";
      return true;
  }
  
  isNumber(value : any) : boolean
  {
      return !isNaN(parseFloat(value));
  }

  validateBudgetAlarm() : boolean
  {
      if (!this.description)
      {
          this.errorMessage = "Geben Sie den Namen an";
          return false;
      }
      if (this.selectedMeter == null)
      {
          this.errorMessage = "Bitte wählen Sie einen Messpunkt aus."
          return false;
      }
      if (this.selectedPeriod == null)
      {
          this.errorMessage = "Bitte bestimmen Sie den Kontrollzeitraum."
          return false;
      }
      if (this.diff == null)
      {
          this.errorMessage = "Bitte legen Sie das Budget fest."
          return false;
      }
      let p = this.getFloat(this.diff);
      if (p < 0)
      {
          this.errorMessage ="Budget muss größer als 0 sein!";
          return false;
      }
      if ((this.selectedMails == null) || (this.selectedMails.length == 0))
      {
          this.errorMessage = "Bitte mindestens eine E-Mail angeben!"
          return false;
      }

      this.errorMessage = "";
      return true;
  }

  
}

