<div class="row">
    <div class="col-md-12">
        <div class="card">
            <div class="card-body">
                <h2 class="titel">Kostenstellen
                    <i class="fa fa-info-circle help-icon" 
                    placement="bottom" 
                    container="body"
                    popoverClass="help-popover-wide"
                    triggers="mouseenter:mouseleave"
                    [ngbPopover]="helpCostUnitContent" 
                    [popoverTitle]="helpCostUnitTitle"
                    aria-hidden="true"></i>                                             

                </h2>
                <div>
                    <table class="table table-striped">
                        <thead>
                        <tr>
                            <th scope="col">Kostenstelle</th>
                            <th scope="col">Beschreibung</th>
                            <th scope="col">Messpunkte</th>
                            <th width="120px">Aktion</th>
                        </tr>
                        </thead>
                        <tbody>

                        <tr *ngFor="let cu of costUnits">
                            <td style="width: 200px"> {{ cu.name }}</td>
                            <td> {{ cu.description }}</td>
                            <td> 
                                <div *ngFor="let meter of cu.meters">
                                    {{ getMeterName(meter) }}
                                </div>
                            </td>
                            <td>
                                <button role="link"
                                        (click)="openModal(cu)"
                                        data-toggle="modal" data-target="#costUnitModal"
                                        data-whatever="report"
                                        class="btn btn-primary btn-sm en-btn">
                                    <i class="fa fa-pencil" aria-hidden="true"></i>
                                </button>
                                &#160;
                                <button role="button"
                                        (click)="removeCostUnit(cu)"
                                        class="btn btn-primary btn-sm en-btn">
                                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="row">
                    <div class="col-lg-2" >
                        <button role="link"
                            (click)="createCostUnit()"
                                    data-toggle="modal" 
                                    data-target="#costUnitModal"
                                    data-whatever="report"
                                    class="btn btn-primary">
                            <i class="fa fa-plus" aria-hidden="true"></i>    
                            Neu
                        </button>
                    </div>    
                    <div class="col-lg-8 d-flex justify-content-center">
                        <pagination-controls
                                id="containerPager"
                                responsive="true"
                                autoHide="true"
                                previousLabel=""
                                nextLabel=""
                                (pageChange)="unitName = ''">
                        </pagination-controls>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
<!-- Modal -->
<div class="modal fade" id="costUnitModal" tabindex="-1" role="dialog" aria-hidden="true" #reportModal>
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title titel">Kostenstelle {{ actionText }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form #modalForm="ngForm">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="alert alert-danger" *ngIf='errorMessageModal'>{{errorMessageModal}}</div>
                        </div>
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-5">
                                    <label>Name:</label>
                                </div>
                                <div class="col-md-7">
                                    <input type="text" [(ngModel)]="unitName"
                                            name="name"
                                            style="width: 100%">

                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-5">
                                    <label>Beschreibung:</label>
                                </div>
                                <div class="col-md-7">
                                    <textarea name="desc"
                                                rows="5"
                                                [(ngModel)]="unitDescription"
                                                style="width: 100%;padding: 7px"
                                    ></textarea>

                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="row pad-top">
                                <div class="col-md-5">
                                    <label style="width: 150px">Messpunkte:</label>
                                </div>
                                <div class="col-md-7">
                                    <ng-select [items]="allMeters" id="meter"
                                        bindLabel="values.user0"
                                        bindValue="id"
                                        [multiple]="true"
                                        [clearable]="true"
                                        [(ngModel)]="selectedMeters" 
                                        [ngModelOptions]="{standalone: true}">
                                        <ng-template ng-option-tmp ng-label-tmp let-item="item">
                                            <label>
                                                <i *ngIf="item.values.obisid0 == '1-0:1.8.0*255'"
                                                    class="fa fa-bolt"></i>
                                                <i *ngIf="item.values.obisid0 == '8-0:1.0.0*255'"
                                                    class="fa fa-tint"></i>
                                                <i *ngIf="item.values.obisid0 == '6-0:1.0.0*255'"
                                                    class="fa fa-fire"></i> <!-- red -->
                                                <i *ngIf="item.values.obisid0 == '7-0:3.0.0*255'"
                                                    class="fa fa-fire"></i> <!-- blue -->
                                                <i *ngIf="item.values.obisid0 == '6-0:2.0.0*255'"
                                                    class="fa fa-tint"></i>
                                                <i *ngIf="item.values.obisid0 == '8-0:1.0.0*255'"
                                                    class="fa fa-plug"></i>
                                            </label>
                                            {{ item.values.user0 }}
                                        </ng-template>
                                    </ng-select>
                               </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer center">
                <button type="button"
                        class="submit-button"
                        (click)="validateModalData()"
                >{{buttonText}}
                </button>

            </div>
        </div>
    </div>
</div>
<ng-template #helpCostUnitContent>
    <p>...enthält einen oder mehrere Messpunkte.</p>
    <p>Ist den Messpunkten ein Tarif zugeordnet, so können Sie unter dem Reiter <EM>Kosten</EM> 
       anfallende Kosten pro Kostenstelle ausrechnen lassen.
    </p>
</ng-template>
<ng-template #helpCostUnitTitle>Eine Kostenstelle...</ng-template>
