<div class="login-form panel">
  <h2 class="form-signin-heading">Impressum</h2>
  <p><b><u>enmonitor.de</u></b></p>
  <p>ein Service der</p>
  <p>Transfer Industrie Forschungsgesellschaft (TIF)<br />
    Emil-Figge-Straße 43<br />
    44227 Dortmund</p>
  <p>Mail: support@enmonitor.de<br />
    Tel.: +49 231 / 13 013 899‬
</div>
