<h2 class="form-signin-heading">Datenschutzerklärung von enmonitor.de</h2>
<div class="data-statement scrollbox" *ngIf="!showImpressum">

   <h3>Vorwort</h3>

   <p>Wir nehmen den Schutz Ihrer privaten Daten ernst. Die besondere Beachtung der Privatsphäre
      bei der Verarbeitung persönlicher Daten ist uns ein wichtiges Anliegen. Persönliche Daten
      werden gemäß den Bestimmungen der EU-Datenschutz-Grundverordnung DSGVO und des
      Bundesdatenschutzgesetztes BDSG (neu) verwendet.
   </p>
   <p>Wir, als Betreiber dieser Webseite, verpflichten uns zur Verschwiegenheit.</p>

   <h3>Personenbezogene Daten</h3>

   <p>Personenbezogene Daten sind Informationen, die dazu genutzt werden können, die Identität zu
      erfahren. Darunter fallen Informationen wie
   <p><ul>
       <li>richtiger Name</li>
       <li>Geschlecht</li>
       <li>E-Mail-Adresse</li>
       <li>Unternehmen</li>
   </ul>
   <p>Informationen, die nicht direkt mit der wirklichen Identität in Verbindung gebracht werden
      fallen nicht darunter, wie zum Beispiel</p>
   <ul>
       <li>favorisierte Webseiten</li>
       <li>Anzahl der Nutzer einer Seite</li>
   </ul>

   <p>In Verbindung mit dem Zugriff auf unsere Seiten werden serverseitig Daten (zum Beispiel
      IP-Adresse, Datum, Uhrzeit und betrachtete Seiten) gespeichert. Es findet keine personenbezogene
      Verwertung statt. Die statistische Auswertung anonymisierter Datensätze bleibt vorbehalten.
   </p>
 <div class="data-statement">    <p>Wir nutzen die persönlichen Daten zu Zwecken der technischen Administration der Webseiten, zur
      Erbringung unserer Dienstleistungen und zur Kundenverwaltung nur im jeweils dafür erforderlichen
      Umfang.</p>

   <h3>Weitergabe personenbezogener Daten</h3>

   <p>Wir verwenden personenbezogene Informationen nur für diese Webseite und geben die Informationen nicht ohne
      ausdrückliches Einverständnis an Dritte weiter.</p>

   <p>Sollten im Rahmen der Auftragsdatenverarbeitung Daten an Dienstleister weitergegeben werden, so sind diese an das
      Bundesdatenschutzgesetz BDSG, andere gesetzliche Vorschriften und an diese Privacy Policy gebunden.</p>

   <p>Erhebungen beziehungsweise Übermittlungen persönlicher Daten an staatliche Einrichtungen und Behörden erfolgen nur
      im Rahmen zwingender Rechtsvorschriften.</p>

   <h3>Einsatz von Cookies</h3>

   <p>Wir setzen Cookies – kleine Dateien mit Konfigurationsinformationen – ein. Dabei handelt es sich um sogenannte
      funktionale Cookies, die zwingend erforderlich sind, um wesentliche Funktionen der Webseite zu gewährleisten.
   </p>

   <h3>Auskunftsrecht</h3>

   <p>Sie haben jederzeit das Recht auf Auskunft über die bezüglich Ihrer Person gespeicherten Daten, deren Herkunft und
      Empfänger sowie den Zweck der Datenverarbeitung. Auskunft über die gespeicherten Daten gibt der
      <a href="#" (click)="showImpressum=true" >Webmaster</a>.</p>
   <h3>Recht auf Widerruf</h3>

   <p>F&uuml;r eine vollständige Löschung der angegebenen Daten wenden Sie sich bitte an den
      <a href="#">Webmaster</a>.</p>

   <h3>Fragen und Kommentare</h3>

   <p>Bei Fragen und für Anregungen und Kommentare zum Thema Datenschutz wenden Sie sich bitte per Mail
      an den  <a href="#">Webmaster</a>.
   </p>
   <h3>Sicherheitshinweis</h3>

   <p>Wir sind bemüht Ihre personenbezogenen Daten durch Ergreifung aller technischen und organisatorischen Möglichkeiten so
      zu speichern, dass sie für Dritte nicht zugänglich sind. Bei der Kommunikation per E Mail kann die vollständige
      Datensicherheit von uns nicht gewährleistet werden, so dass wir Ihnen bei vertraulichen Informationen den Postweg
      empfehlen.</p>

   <h3>Änderungen dieser Datenschutzbestimmungen</h3>

   <p>Wir werden diese Richtlinien zum Schutz Ihrer persönlichen Daten von Zeit zu Zeit aktualisieren. Sie sollten sich diese
      Richtlinien gelegentlich ansehen, um auf dem Laufenden darüber zu bleiben, wie wir Ihre Daten schützen und die Inhalte
      unserer Webseite stetig verbessern. Sollten wir wesentliche Änderungen bei der Sammlung, der Nutzung und/oder der
      Weitergabe der uns von Ihnen zur Verfügung gestellten personenbezogenen Daten vornehmen, werden wir Sie durch einen
      eindeutigen und gut sichtbaren Hinweis auf der Webseite darauf aufmerksam machen. Mit der Nutzung der Webseite erklären
      Sie sich mit den Bedingungen dieser Richtlinien zum Schutz persönlicher Daten einverstanden.
   </p>
   <h3>Kontakt</h3>
   <p>Bei Fragen, Anmerkungen oder Kommentaren rund um enmonitor.de, wenden Sie sich bitte an den Webmaster. Sie finden die
      Kontaktdaten im <a href="#">Impressum</a>.</p>
</div>
<app-impressum *ngIf="showImpressum"></app-impressum>
