
export class Tariff
{
    id : number;        // Id aus der DB
    companyId : number; // Firmen-Id aus der DB
    energy    : string; // "Gas", "Strom", "Wasser" etc.
    name      : string; // Tarifbezeichnung
    period    : number; // 0 = Jahr, 1 = Monat
    priceBase : number; // Basispreis
    priceCap  : number;
    priceUnit : number;
    start     : number; // Zeit in ms seit dem 1.1.1970
    measuringPoints: string[];      // Liste mit den Ids der Messpunkte
}