import { formatDate, registerLocaleData } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ConfigEvent, ConfigService } from 'src/app/services/config.service';
import localeDe from '@angular/common/locales/de';
import { Todo, TodoGroup } from 'src/app/classes/todo';
import { Global } from 'src/constants/global';

@Component({
  selector: 'app-todo-tile',
  templateUrl: './todo-tile.component.html',
  styleUrls: ['./todo-tile.component.scss']
})
export class TodoTileComponent implements OnInit 
{
    configService: ConfigService;
    allTodos      : TodoGroup[] = [];
    overDueTodos  : TodoGroup[] = [];
    dueTodos      : TodoGroup[] = [];
    otherTodos    : TodoGroup[] = [];
    
    constructor(confService : ConfigService) 
    { 
        this.configService = confService;
        registerLocaleData(localeDe);
    }
  
    ngOnInit(): void 
    {
        this.configService.configEvent$.subscribe(
            (data : ConfigEvent) => {
                if (data.name == Global.SIGNAL_REFRESH_TODO)
                    this.refreshTodo();
            }
        );
        this.configService.loadTodos();
    }

    refreshTodo() : void
    {
        this.allTodos = this.configService.getTodos();
        // Sortiere Todos nach überfällig, fällig (innerhalb 7 Tage und Sonstige)
        this.overDueTodos = [];
        this.dueTodos     = [];
        this.otherTodos   = [];
        for (let i = 0; i < this.allTodos.length; i++)
        {
            let group : TodoGroup = this.allTodos[i];
            // Überfällig?
            if (this.configService.isGroupOverDue(group))
                this.overDueTodos.push(group);
            else
            if (this.configService.isGroupDue(group))      // Fällig?
                this.dueTodos.push(group);
            else
            {   // Sonstige
                this.otherTodos.push(group);
            }
        }
    }

    getUntilDate(todo : TodoGroup)  : string
    {
        let d = new Date(todo.deadline);
        return formatDate(d, "dd.MM.yyyy", "en-US");
    }

    currentTime() : number
    {
        return Date.now();
    }

    filterOverDue(todos : Todo[]) : Todo[]
    {
        let result : Todo[] = [];
        for (let i = 0; i < todos.length; i++)
        {
            if ((todos[i].deadline <= this.currentTime()) && (!todos[i].done))
                result.push(todos[i]);
        }
        return result;
    }

    filterDue(todos : Todo[]) : Todo[]
    {
        let result : Todo[] = [];
        let week = Date.now() + 2 * 24 * 60 * 60 * 1000;        
        // Fälligkeit der Gruppe liegt in der Vergangenheit?
        for (let i = 0; i < todos.length; i++)
        {
            if ((todos[i].deadline <= week) && (!todos[i].done))
                result.push(todos[i]);
        }
        return result;
    }
}
