import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Global } from '../../constants/global';
import { Logevent } from '../classes/logevent'
import { PopupService, PopupEvent  } from './popup.service';

export class LogSignal
{
    constructor(public name: string)
    {
    }
}

@Injectable({
  providedIn: 'root'
})
export class LogeventsService 
{
    public logEvent$    : EventEmitter<LogSignal>;
    public currReports  : Logevent[] = [];
    public currActions  : Logevent[] = [];
    public currProblems : Logevent[] = [];
    public currAdmEvents: Logevent[] = [];
    public mutex        : boolean;

    constructor(private http: HttpClient,
                private popupService : PopupService) 
    {
        this.logEvent$ = new EventEmitter(); 
        this.loadEvents();
        popupService.popupEvent$.subscribe(
            (event : PopupEvent) => {
                if (event.name == Global.SIGNAL_REFRESH_EVENTS)
                    this.loadEvents();
            }
        );
    }

    loadEvents()
    {
        this.loadEventsInternal(this);
    }

    loadEventsInternal(service : LogeventsService) : void
    {
        let company = JSON.parse(sessionStorage.getItem(Global.SESSION_COMPANY));
        if (company == null)
        {
            setTimeout(function onTimeout() { service.loadEvents() }, 1000);
            return;
        }
        let url     = Global.apiUrl + "rev2/list/events/" +company['name'] +'/reports/'
        var token   = sessionStorage.getItem(Global.ATTRIBUTE_USER_TOKEN);
        if (this.mutex)
           return;
        this.mutex = true;

        this.http.get(url,
              {
                  headers: { 'Authorization': 'Bearer ' + token }
              })
              .subscribe(function onSuccess(response)
              {
                  service.currReports = response['events'];
                  service.mutex = false;
                  service.logEvent$.emit(new LogSignal(Global.SIGNAL_REFRESH_EVENTS));
              },
              function onError(response)
              {
                  service.mutex = false;
              });
        url     = Global.apiUrl + "rev2/list/events/" +company['name'] +'/activities/'
        this.http.get(url,  
            {
                headers: { 'Authorization': 'Bearer ' + token }
            })
            .subscribe(function onSuccess(response)
            {
                service.currActions = response['events'];
                service.mutex       = false;
                service.logEvent$.emit(new LogSignal(Global.SIGNAL_REFRESH_EVENTS));
            },
            function onError(response)
            {
                service.mutex = false;
            })
        url     = Global.apiUrl + "rev2/list/events/" +company['name'] +'/alarms'
        this.http.get(url,  
            {
                headers: { 'Authorization': 'Bearer ' + token }
            })
            .subscribe(function onSuccess(response)
            {
                service.currProblems = response['events'];
                service.mutex       = false;
                service.logEvent$.emit(new LogSignal(Global.SIGNAL_REFRESH_EVENTS));
            },
            function onError(response)
            {
                service.mutex = false;
            });
        url     = Global.apiUrl + "rev2/list/events/" +company['name'] +'/admin/'
        this.http.get(url,  
            {
                headers: { 'Authorization': 'Bearer ' + token }
            })
            .subscribe(function onSuccess(response)
            {
                service.currAdmEvents = response['events'];
                service.mutex         = false;
                service.logEvent$.emit(new LogSignal(Global.SIGNAL_REFRESH_EVENTS));
            },
            function onError(response)
            {
                service.mutex = false;
            });
    }
  
}
