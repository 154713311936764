<form class="form-detail" (change)="firmChange = true;">
    <div class="form-right ">
        <h2 class="titel" *ngIf="companySelectedId == null">Legen Sie eine neue Firma an
            <i class="fa fa-info-circle help-icon" 
                                        placement="bottom" 
                                        container="body"
                                        popoverClass="help-popover-wide"
                                        triggers="mouseenter:mouseleave"
                                        [ngbPopover]="helpCompanyContent" 
                                        [popoverTitle]="helpCompanyTitle"
                                        aria-hidden="true"></i>        
        </h2>
        <h2 class="titel" *ngIf="companySelectedId != null">Führen Sie die Änderungen durch
            <i class="fa fa-info-circle help-icon" 
            placement="bottom" 
            container="body"
            popoverClass="help-popover-wide"
            triggers="mouseenter:mouseleave"
            [ngbPopover]="helpCompanyContent" 
            [popoverTitle]="helpCompanyTitle"
            aria-hidden="true"></i>                    
        </h2>
        <div class="alert alert-warning" *ngIf='error'>{{errorMessage}}</div>
        <div class="alert alert-success" *ngIf='success'>{{successMessage}}</div>
        <div class="row form-group">
            <div class="form-row form-row-1 col-lg-2 col-md-12 col-sm-12">
                Firmenname:
            </div>
            <div class="form-row form-row-1 col-lg-4 col-md-12 col-sm-12">
                <input type="text" id="company" placeholder="Der Name der Firma" [(ngModel)]="company"
                       [ngModelOptions]="{standalone: true}">
            </div>
        </div>
        <div class="row form-group">
            <div class="form-row form-row-2 col-lg-2 col-md-12 col-sm-12">
                E-Mail:
            </div>
            <div class="form-row form-row-2 col-lg-4 col-md-12 col-sm-12">
                <input type="email" pattern="[^@]+@[^@]+.[a-zA-Z]{2,6}" id="mail" placeholder="E-mail Adresse"
                       [(ngModel)]="mail" [ngModelOptions]="{standalone: true}">
            </div>

        </div>
        <div class="row form-group">
            <div class="form-row form-row-1 col-lg-7 col-md-12 col-sm-12">
                <textarea id="description" placeholder="optionale Notizen" [(ngModel)]="description"
                          [ngModelOptions]="{standalone: true}"></textarea>
            </div>
            <div class="form-row form-row-2 col-lg-5 col-md-12 col-sm-12">
                <div class='img_container center'>
                    <img class="preview center" src="assets/upload2.png" alt="Logo" title=''
                         *ngIf="cardImageBase64=='data:image/png;base64,'|| cardImageBase64 == null"/>
                    <img class="preview center" [src]="cardImageBase64" alt="">
                    <label class="btn btn-primary logo center" for="logo" *ngIf="cardImageBase64==null">Logo
                        hinzufügen</label>
                    <label class="btn btn-primary logo center" for="logo" *ngIf="cardImageBase64!=null">Logo
                        ändern</label>
                    <small style="text-align: center; display:block">Breite: max. 250 Pixel</small>
                    <small style="text-align: center; display:block">Höhe: max. 125 Pixel</small>
        
                </div>
                <input type="file" (change)="handleInputChange($event)" id="logo" accept="image/*"
                       style="display:none;">
            </div>

        </div>
        <div class=" row form-group city">
            <div class="form-row form-row-1 col-lg-1 col-md-6 col-sm-12">
                Land:
            </div>

            <div class="form-row form-row-1 col-lg-3 col-md-6 col-sm-12">
                <input type="text" placeholder="Land" [(ngModel)]="country" [ngModelOptions]="{standalone: true}">
            </div>
            <div class="form-row form-row-1 col-lg-1 col-md-6 col-sm-12">
                Strasse:
            </div>
            <div class="form-row form-row-2 col-lg-4 col-md-6 col-sm-12">
                <input type="text" placeholder="Strasse + HausNummer" [(ngModel)]="street"
                       [ngModelOptions]="{standalone: true}">
            </div>
        </div>
        <div class=" row form-group city">
            <div class="form-row form-row-1 col-lg-1 col-md-6 col-sm-12">
                PLZ:
            </div>
            <div class="form-row form-row-1 col-lg-3 col-md-6 col-sm-12">
                <input type="text" id="zip" placeholder="Postleitzahl" [(ngModel)]="zip"
                       [ngModelOptions]="{standalone: true}">
            </div>
            <div class="form-row form-row-2 col-lg-1 col-md-6 col-sm-12">
                Stadt:
            </div>
            <div class="form-row form-row-2 col-lg-4 col-md-6 col-sm-12">
                <input type="text" id="city" placeholder="City" [(ngModel)]="city"
                       [ngModelOptions]="{standalone: true}">
            </div>
        </div>
        <div class="">
            <label>Wählen Sie die Knoten aus
                <i class="fa fa-info-circle help-icon" 
                                        placement="bottom" 
                                        container="body"
                                        popoverClass="help-popover-wide"
                                        triggers="mouseenter:mouseleave"
                                        [ngbPopover]="helpNodeContent" 
                                        [popoverTitle]="helpNodeTitle"
                                        aria-hidden="true"></i>        
            </label>
            <ng-select [items]="unassigned_nodes"
                       bindLabel="name"

                       [multiple]="true"
                       placeholder="Select nodes"
                       [(ngModel)]="selectedNodes" [ngModelOptions]="{standalone: true}">
            </ng-select>

        </div>
        <br>
        <div class="center">
            <button type="submit"
                    (click)="updateCompany()"
                    class="submit-button"
                    [disabled]="isButtonDisabled()">
                <span *ngIf="companySelectedId == null">Firma anlegen</span>
                <span *ngIf="companySelectedId != null">Firma ändern</span>
            </button>

        </div>
    </div>
</form>
<!-- Hilfetexte -->

<ng-template #helpCompanyContent>
    <p>Eine Firma dient als Klammer für die verschiedenen Konfigurationen, (virtuellen)
       Messpunkte, Berichte etc.
    </p>
    <p>Legen Sie bitte Firmenname, E-Mailadresse und postalische Adresse fest.</p>
    <p>Optional können Sie ein Firmenlogo (maximal 250 Pixel in der Breite und maximal 
       125 Pixel in der Höhe) hochladen. Größere Logos werden u.U. <b>verzerrt</b> dargestellt!
    </p>
    <p>
       Das Logo wird dann in den Berichten und im Dashboard genutzt.</p>
    <p>Diese Informationen sind nur von Ihnen und der TIF einsehbar und werden von 
       uns nicht weitergegeben. 
    </p>
</ng-template>
<ng-template #helpCompanyTitle>Firma</ng-template>

<ng-template #helpNodeContent>
    <p>Die Messpunkte werden einem Knoten zugeordnet. Ein Knoten kann beliebig viele
       Messpunkte enthalten.
    </p>
    <p>Ein Knoten kann weitere Knoten enthalten, wodurch sich eine Art Baumstruktur
       ergibt. Bitte geben Sie hier den obersten Knoten an, unter dem alle anderen 
       Knoten und Messpunkte liegen. 
    </p>
</ng-template>
<ng-template #helpNodeTitle>Knoten</ng-template>

