<div class="row">
    <div class="col-md-12">
        <div class="card">
            <div class="card-body">
                <h2 class="titel">Alle geplanten Importe mit Wiederholungen</h2>
                <div>
                    <table class="table table-striped">
                        <thead>
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">aus Tabelle</th>
                            <th scope="col">Nächster Start</th>
                            <th width="35px" scope="col">Aktiv</th>
                            <th width="120px" *ngIf="isModerator()">Aktion</th>
                        </tr>
                        </thead>
                        <tbody>

                        <tr *ngFor="let report of allImportsWithRepeat | paginate : {
                                    itemsPerPage: tableSize,
                                    currentPage: page,
                                    totalItems: count
                                   };
                        ">
                        <td>{{ report.name }}</td>
                        <td>{{ report.tableName }}</td>
                        <td>{{ getNextStart(report) }}</td>
                        <td><i *ngIf="report.active"
                               (click)="toggleActiveAndSave(report)"
                               class="fa fa-check" aria-hidden="true"></i>
                             <i *ngIf="!report.active"
                                (click)="toggleActiveAndSave(report)"
                                class="fa fa-close" aria-hidden="true"></i>
                        </td>
                        <td *ngIf="isModerator()">
                            <button role="link"
                                    (click)="openModal(report.id)"
                                    data-toggle="modal" data-target="#changeReportModal"
                                    data-whatever="report"
                                    class="btn btn-primary btn-sm en-btn">
                                <i class="fa fa-pencil" aria-hidden="true"></i>
                            </button>
                            &#160;
                            <button role="button"
                                    (click)="removeImportWithRepeat(report.id)"
                                    class="btn btn-primary btn-sm en-btn">
                                <i class="fa fa-trash-o " aria-hidden="true"></i>
                            </button>
                        </td>
                        </tr>
                        </tbody>
                    </table>

                    <div class="d-flex justify-content-center" *ngIf="allImportsWithRepeat.length > tableSize">
                        <pagination-controls class="en-pagination"
                                responsive="true"
                                previousLabel=""
                                nextLabel=""
                                (pageChange)="onTableDataChange($event)">
                        </pagination-controls>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<!-- Modal -->
<div class="modal fade" id="changeReportModal" tabindex="-1" role="dialog" aria-hidden="true" *ngIf="importObj!=null">
    <div class="modal-dialog" role="document">
        <div class="modal-content content-wide">
            <div class="modal-header">
                <h5 class="modal-title titel">Import ändern</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="alert alert-danger" *ngIf='errorMessage'>{{errorMessage}}</div>
                        </div>

                        <div class="col-md-12">
                            <table width="95%">
                                <tr class="pad-top">
                                    <td>Tabelle:</td>
                                    <td colspan="2">{{ importObj.tableName}}</td>
                                </tr>
                                <tr><td colspan="3">&nbsp;</td></tr>
                                <tr class="pad-top">
                                    <th style="width: 120px;">Inhalt</th>
                                    <th>Spalte</th>
                                    <th>Format</th>
                                </tr>
                                <tr class="pad-top">
                                    <td style="width: 120px;">Zeitpunkt</td>
                                    <td>
                                        <ng-select style="width: 250px; " [items]="allCols"
                                           bindLabel="name" 
                                           [multiple]="false"
                                           [clearable]="false"
                                            #ngSelect       
                                            [(ngModel)]="colTime"
                                           [ngModelOptions]="{standalone: true}">
                                    </ng-select> 
                                    </td>
                                    <td>
                                        <ng-select style="width: 250px; " [items]="allTimes"
                                           bindLabel="name" 
                                            [multiple]="false"
                                            [clearable]="false"
                                             #ngSelect       
                                            [(ngModel)]="colTimeFormat"
                                           [ngModelOptions]="{standalone: true}">
                                           </ng-select>
                                    </td>
                                </tr>
                                <tr class="pad-top">
                                    <td  style="width: 120px;">
                                        Messpunkt                                        
                                    </td>
                                    <td>
                                        <ng-select style="width: 250px; " [items]="allCols"
                                            bindLabel="name" 
                                            [multiple]="false"
                                            [clearable]="true"
                                            (change)="selectMPColumn($event)"
                                            #ngSelect       
                                            [(ngModel)]="colMP"
                                            [ngModelOptions]="{standalone: true}">      
                                        </ng-select>                 
                                    </td>
                                    <td><div class="row" style="padding-left: 16px;">
                                        <ng-select style="width: 250px; " [items]="allTypes"
                                            [multiple]="false"
                                            [clearable]="false"
                                            (change)="mpTypeChanged($event)"
                                            #ngSelect       
                                            [(ngModel)]="mpType"
                                            [ngModelOptions]="{standalone: true}">      
                                        </ng-select>
                                        </div>
                                    </td>
                                </tr>
                                <tr class="pad-top">
                                    <td></td>
                                    <td style="width: 250px">
                                        <ng-select [items]="allMeters" bindLabel="values.user0" bindValue="id"
                                            [multiple]="false" [(ngModel)]="selectedMeter"
                                            *ngIf="mpType=='Messpunkt auswählen'"
                                            [ngModelOptions]="{standalone: true}">
                                            <ng-template ng-option-tmp ng-label-tmp let-item="item">
                                                <label>
                                                    <i *ngIf="item.values.obisid0 == '1-0:1.8.0*255'"
                                                        class="fa fa-bolt"></i>
                                                    <i *ngIf="item.values.obisid0 == '8-0:1.0.0*255'"
                                                        class="fa fa-tint"></i>
                                                    <i *ngIf="item.values.obisid0 == '6-0:1.0.0*255'"
                                                        class="fa fa-fire"></i> <!-- red -->
                                                    <i *ngIf="item.values.obisid0 == '7-0:3.0.0*255'"
                                                        class="fa fa-fire"></i> <!-- blue -->
                                                    <i *ngIf="item.values.obisid0 == '6-0:2.0.0*255'"
                                                        class="fa fa-tint"></i>
                                                    <i *ngIf="item.values.obisid0 == '8-0:1.0.0*255'"
                                                        class="fa fa-plug"></i>
                                                </label>
                                                {{ item.values.user0 }} ({{ item.id}})
                                            </ng-template>
                                        </ng-select>
                                    </td>
                                    <td></td>

                                </tr>
                                <tr class="pad-top">
                                    <td style="width: 120px;" >Wert</td>
                                    <td>
                                        <ng-select style="width: 250px; " [items]="allCols"
                                            bindLabel="name" 
                                            [multiple]="false"
                                            [clearable]="false"
                                            #ngSelect       
                                            [(ngModel)]="colVal"
                                            [ngModelOptions]="{standalone: true}">      
                                    </ng-select>                 

                                    </td>
                                    <td></td>
                                </tr>
                                <tr class="pad-top">
                                    <td style="width: 120px;" >Wiederholung</td>
                                    <td>
                                            <ng-select style="width: 250px; " [items]="allRecs"
                                                bindLabel="name" 
                                                [multiple]="false"
                                                [clearable]="false"
                                                #ngSelect       
                                                [(ngModel)]="colRec"
                                                [ngModelOptions]="{standalone: true}">      
                                            </ng-select>         
                                    </td>
                                    <td></td>
                                </tr>
                                <tr class="pad-top" *ngIf="colRec != 'Einmalig'">
                                    <td style="width: 120px">
                                        E-Mail:
                                    </td>
                                    <td>
                                        <ng-select [items]="allMails" id="mails"
                                            bindLabel="name"
                                            name="mails"
                                            [multiple]="true"
                                            [clearable]="true"
                                            [(ngModel)]="selectedMails"
                                            [ngModelOptions]="{standalone: true}">
                                        </ng-select>                                    
                                    </td>
                                    <td></td>
                                </tr>
                            </table>

                        </div>


                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-5">
                                    <label>Aktiv :</label>
                                </div>
                                <div class="col-md-7">
                                    <input type="checkbox"
                                           name="check"
                                           style="vertical-align: middle; display: none"
                                           [checked]="active"
                                           #yes>
                                        <i (click)="yes.checked=true; active = true" *ngIf="!yes.checked"
                                            style="color: #2e2d5a" 
                                            class="fa fa-square-o"></i>
                                        <i (click)="yes.checked=false; active=false" *ngIf="yes.checked"
                                            style="color: #2e2d5a"
                                            class="fa fa-check-square"></i>
                                </div>
                            </div>
                        </div>

                    </div>
                </form>
            </div>
            <div class="modal-footer center">
                <button type="button"
                        class="submit-button"
                        (click)="changeImport()"
                >Ändern
                </button>
            </div>
        </div>
    </div>
</div>

