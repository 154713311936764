<div class="row">
  <div class="col-lg-2 col-md-3 col-sm-11 col-xs-11 tabs">

    <div class="card pointer" *ngIf="isAdmin()"
        (click)="setCompanySelectionActive()">
      <div [ngClass]="{'en-active': companySelect}" class="card-body">
        <a>
          <span>Firmen<wbr>auswahl</span>
        </a>
      </div>
    </div>
    <div class="card pointer" *ngIf="isModerator()"
        (click)="setCompanyEditActive()">
      <div class="card-body" [ngClass]="{'en-active': companyEdit}">
        <a>
          <span>Firmen<wbr/>verwaltung</span>
        </a>
      </div>
    </div>
    <div class="card pointer" *ngIf="isModerator()"
        (click)="setCompanyTariffActive()">
        <div class="card-body" [ngClass]="{'en-active': tariffEdit}">
          <a>
            <span>Tarife</span>
          </a>
        </div>
    </div>
    <div class="card pointer" *ngIf="isModerator() && false"
        (click)="setContainerActive()">
        <div class="card-body" [ngClass]="{'en-active': containerActive}">
            <a>
            <span>Mess<wbr/>container</span>
            </a>
        </div>
    </div>
    <div class="card pointer" (click)="setTodoActive()">
      <div class="card-body" [ngClass]="{'en-active': todoActive}">
        <a>
          <span>Vorgaben</span></a>
      </div>
    </div>
    <div class="card pointer" (click)="setReportActive()">
      <div class="card-body" [ngClass]="{'en-active': reportEdit}">
        <a>
          <span>Berichte</span>
        </a>
      </div>
    </div>
    <div class="card" (click)="setAlarmActive()">
      <div class="card-body pointer" [ngClass]="{'en-active': alarmEdit}">
        <a>
          <span>Alarme</span></a>
      </div>
    </div>
    <div class="card" *ngIf="isModerator()"
        (click)="setCostUnitActive()">
        <div class="card-body pointer" [ngClass]="{'en-active': isCostUnitActive()}">
          <a>
            <span>Kosten<wbr/>stelle</span></a>
        </div>
      </div>
      <div class="card pointer" *ngIf="isModerator()"
          (click)="setImportActive()">
        <div class="card-body" [ngClass]="{'en-active': importActive}">
            <a>
              <span>Importe</span>
            </a>
        </div>
      </div>
  </div>
  <div class="col-lg-10 col-md-9 col-sm-12 col-xs-12 workspace" 
    *ngIf='isCompanySelectionActive() && isAdmin()'>
    <app-company-select></app-company-select>
  </div>
  <div class="col-lg-10 col-md-9 col-sm-12 col-xs-12 workspace" *ngIf='isCompanyEditActive()'>
    <app-firm-info></app-firm-info>
  </div>
  <div class="col-lg-10 col-md-9 col-sm-12 col-xs-12 workspace" *ngIf='meterActive'>
    <app-meter></app-meter>
  </div>
  <div class="col-lg-10 col-md-9 col-sm-12 col-xs-12 workspace" *ngIf='importActive'>
    <app-import-config></app-import-config>
  </div>
  <div class="col-lg-10 col-md-9 col-sm-12 col-xs-12 workspace" 
    *ngIf='isTariffEditActive() && isModerator()'>
    <app-tariff></app-tariff>
  </div>
  <div class="col-lg-10 col-md-9 col-sm-12 col-xs-12 workspace" *ngIf='isReportEditActive()'>
    <app-reports-config></app-reports-config>
  </div>
  <div class="col-lg-10 col-md-9 col-sm-12 col-xs-12 workspace" *ngIf='isAlarmActive()'>
    <app-alarms-config></app-alarms-config>
  </div>
  <div class="col-lg-10 col-md-9 col-sm-12 col-xs-12 workspace" *ngIf='containerActive'>
    <app-container></app-container>
  </div>
  <div class="col-lg-10 col-md-9 col-sm-12 col-xs-12 workspace" *ngIf='todoActive'>
    <app-todo></app-todo>
  </div>
  <div class="col-lg-10 col-md-9 col-sm-12 col-xs-12 workspace" *ngIf='isCostUnitActive()'>
    <app-costunit></app-costunit>
  </div>
</div>
