<div class="login-form">
  <h2 class="form-signin-heading">Passwort vergessen</h2>
  <div class="card">
    <div class="card-body">
      <form novalidate name="form">
        <div class="alert alert-warning" *ngIf='invalidEmail'>{{errorMessage}}</div>
        <div class="alert alert-success" *ngIf='emailSuccess'>{{successMessage}}</div>

        <label for="email" class="sr-only">E-Mail-Adresse</label>

        <input type="email" id="email" class="form-control" placeholder="mustermann@example.com" required [(ngModel)]="email" [ngModelOptions]="{standalone: true}">

        <hr>

        <p class="hint">Sie bekommen einen Aktivierungslink an die angegebene E-Mail-Adresse mit weiteren Anweisung zu
          gesendet.</p>

        <button class="btn btn-block newbtn" type="submit" (click)=reset()>OK</button>
      </form>
    </div>
  </div>
</div>
