import { Component, OnInit } from '@angular/core';
import { ConfigEvent, ConfigService } from '../../../services/config.service';
import { User } from '../../../classes/user';
import { Global } from '../../../../constants/global';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit 
{

  companies: any;
  date: Date = new Date(Date.now());
  displayCompanyName : string = "TIF";
  
  constructor(
    private configService: ConfigService,
  ) {
    setInterval(() => {
      this.date = new Date()
    }, 10000);
  }

  ngOnInit(): void {
    this.configService.configEvent$.subscribe((ce : ConfigEvent) => 
    {
        if (ce.name == Global.SIGNAL_REFRESH_COMPANY)
        {
            this.configService.setSelectedCompany();
        }
        if (ce.name == Global.SIGNAL_COMPANY_SELECTED)
            this.displayCompanyName = this.getCompanyName();
    });
  }

  getSalutation()
  {
    let usrstr = sessionStorage.getItem(Global.ATTRIBUTE_USER_OBJECT);
    let user = JSON.parse(usrstr);
    if (user == null)
        return "";
    let name = user.name;

    if (user.gender == 'FEMALE')
    {
      if (name == null)
        return "meine Dame"
      else return "Frau " + name;
    }
    else
    {
      if (name == null)
        return "mein Herr";
      return "Herr " + name;
    }
  }

  getCompanyName() : string
  {
      let usrstr = sessionStorage.getItem(Global.ATTRIBUTE_USER_OBJECT);
      let user : User = JSON.parse(usrstr);
      let comp = sessionStorage.getItem(Global.SESSION_COMPANY)

      let company = null;
      if (comp != null)
         company = JSON.parse(comp);
      if (user != null && user.admin)
      {
          if (comp == null)
             return "TIF";        
          return company.name;
      }
      else
      {
          if (company == null)
            return "Keine Firma";
          return company.name;
      }
  }

  getCompanyLogo() : string
  {
      let cstr = sessionStorage.getItem(Global.SESSION_COMPANY);
      if (cstr == null)
         return "assets/logo.png";
      let company = JSON.parse(cstr);
      if ((company == null) || (company.logo.length < 50))
         return 'assets/logo.png';
      return company.logo;
  }


}
