import { Injectable, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Global } from '../../constants/global';
import { EventEmitter } from '@angular/core'
import { delay } from 'rxjs/operators';
import { Popup } from '../classes/popup';

export class PopupEvent
{
    constructor(public name: string, public done: boolean)
    {
    }
}
@Injectable({
    providedIn: 'root'
})

export class PopupService
{
    public static SUCCESS = 'success';
    public static ERROR   = 'error';
    public static INFO    = 'info';
    public popupEvent$: EventEmitter<PopupEvent>;
    interval: any;
    mutex: boolean = false;
    popupNotifications : Popup[] = [];
    private me : PopupService;

    constructor(private http: HttpClient)
    {
        this.popupEvent$ = new EventEmitter();
        this.me = this;
        // this.updatePopupFunction(this);
        this.enableInterval();
    }

    enableInterval() : void
    {
        this.interval = setInterval(() =>
        {
            this.updatePopupFunction(this);
        }, Global.POPUP_REFRESH_TIMEOUT);
    }

    getBadgeCount()
    {
        return this.popupNotifications.length;
    };

    async updatePopupFunction(service: PopupService)
    {
        // if (service.companyName.length == 0)
        //     return;
        let company = JSON.parse(sessionStorage.getItem(Global.SESSION_COMPANY))
        if ((this.mutex) || (company == null))
           return;
        var token = sessionStorage.getItem(Global.ATTRIBUTE_USER_TOKEN);
        this.mutex = true;
        this.http.post(Global.apiUrl + "popup/get",
            {
                companyName: company['name'],
                id: '',
                headers: { 'Authorization': 'Bearer ' + token },
                action: 'refresh'
            })
            .subscribe(function onSuccess(response)
            {
                // Nur zum Testen!!!! If abfrage sonst weg!
                // if (service.popupNotifications.length == 0)
                service.popupNotifications = response["popups"];
                // service.triggerAutoHideOnServerPopups();
                // var d = new Date();
                // service.lastRefresh = d.getTime();
                for (let i = 0; i < service.popupNotifications.length; i++)
                {
                    let pop : Popup = service.popupNotifications[i];
                    if (pop.refreshEvents)
                       service.popupEvent$.emit(new PopupEvent(Global.SIGNAL_REFRESH_EVENTS, false));
                }
                service.mutex = false;
                service.popupEvent$.emit(new PopupEvent(Global.SIGNAL_REFRESH_POPUPS, false));
            },
                function onError(response)
                {
                    service.mutex = false;
                });
    };

    dismissAllPopups()
    {
        clearInterval(this.interval);
        this.mutex = false;
        var temp = this.popupNotifications;
        for (var i = 0; i < temp.length; i++)
        {
            var el = temp[i];
            this.dismissPopupInternal(el, this, false);
        }
        this.popupNotifications = [];
        this.popupEvent$.emit(new PopupEvent(Global.SIGNAL_REFRESH_POPUPS, false));
        this.enableInterval();
    }

    dismissPopup(popup : Popup)
    {
        this.dismissPopupInternal(popup, this);
    }

    private dismissPopupInternal(popup : Popup, service: PopupService, signal? : boolean)
    {
        var index = this.popupNotifications.findIndex(el => {
            return el['id'] == popup['id'];
        });
        popup['visible'] = false;
        while(this.mutex)
            delay(500);
        this.mutex = true;
        this.popupNotifications.splice(index, 1); // Eintrag löschen
        var dismissEntry = { action: 'dismiss', id: popup.id, companyName: popup.companyName};
        var url = Global.apiUrl + "popup/dismiss";

        this.http.post(url, dismissEntry).subscribe(function onSuccess(response)
        {
            if (signal == null)
                signal = true;
            if (signal)
                service.popupEvent$.emit(new PopupEvent(Global.SIGNAL_REFRESH_POPUPS, false));
        }, function onError() 
        { 
        });
        this.mutex = false;
    }

    updatePopup(popup : Popup)
    {
        while(this.mutex)
            delay(500);
        this.mutex = true;
        popup['visible'] = false;
        popup['newEntry'] = false;
        this.http.post(Global.apiUrl + 'popup/update', popup)
            .subscribe(() => 
            {
            },
            () => {
            })
        this.mutex = false;
    }

    addPopupOk(title: string, text: string) : void
    {
        let pop   = new Popup();
        pop.id    = 'nf-pop-id-ok-' + (new Date().getTime);
        pop.text  = text;
        pop.title = title;
        pop.type  = PopupService.SUCCESS;
        pop.visible  = true;
        pop.timestamp = (new Date()).getTime();
        pop.progress = -1;
        this.popupNotifications.push(pop);
        // Todo: Hier die Abfrage auf dem Server 10s anhalten
        this.popupEvent$.emit(new PopupEvent(Global.SIGNAL_REFRESH_POPUPS, true));
    }

    addPopupInfo(title: string, text: string) : void
    {
        let pop   = new Popup();
        pop.id    = 'nf-pop-id-in-' + (new Date().getTime);
        pop.text  = text;
        pop.title = title;
        pop.type  = PopupService.INFO;
        pop.visible  = true;
        pop.timestamp = (new Date()).getTime();
        pop.progress = -1;
        this.popupNotifications.push(pop);
        this.popupEvent$.emit(new PopupEvent(Global.SIGNAL_REFRESH_POPUPS, true));
    }

    addPopupError(title: string, text: string)
    {
        let pop   = new Popup();
        pop.id    = 'nf-pop-id-ko-' + (new Date().getTime);
        pop.text  = text;
        pop.title = title;
        pop.visible  = true;
        pop.progress = -1;
        pop.timestamp = (new Date()).getTime();
        pop.type = PopupService.ERROR;
        this.popupNotifications.push(pop);
        this.popupEvent$.emit(new PopupEvent(Global.SIGNAL_REFRESH_POPUPS, true));
    }

}
