import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'app-weather',
  templateUrl: './weather.component.html',
  styleUrls: ['./weather.component.scss']
})
export class WeatherComponent implements OnInit {

    configService : ConfigService;
    weather       : any;

    constructor(configServ : ConfigService) 
    { 
        this.configService = configServ;
    }

    ngOnInit(): void 
    {
        this.configService.configEvent$.subscribe((data) =>
        {
            this.weather = this.configService.weather;
        })
        this.configService.loadWeatherForCompany();
    }

    getTime() : string
    {
        let d = new Date(this.weather.time);
        return formatDate(d, "dd.MM.yyyy HH:mm", "en-US");
    }
}
