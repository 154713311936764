<div class="container ng-scope">
  <div class="account-logo">
    <img src="assets/logo.png" alt="" align="middle">
  </div>
  <div class="account">
    <app-login *ngIf="showDiv.login"></app-login>
    <app-impressum *ngIf="showDiv.impressum"></app-impressum>
    <app-datenschutz *ngIf="showDiv.datenschutz" ></app-datenschutz>


  </div>
  <div class="fixed-bottom">
    <a href="#" (click)="showDiv.login = true;showDiv.impressum = false;showDiv.datenschutz = false">Anmeldung</a> &nbsp;
    <a href="#" (click)="showDiv.impressum = true;showDiv.login = false;showDiv.datenschutz = false">Impressum</a> &nbsp;
    <a href="#" (click)="showDiv.datenschutz = true;showDiv.login = false;showDiv.impressum = false">Datenschutzerkl&auml;rung</a>
  </div>
</div>
