
    <div *ngIf="message" class="modal" tabindex="-1" role="dialog" style="display:block!important">  
        <div class="modal-dialog custom-alert" role="document">  
            <div class="modal-content">  
                
                <div *ngIf="message?.type == 'confirm'" class="modal-body">  
                    <div class="row">  
                        <div class="col-md-12">  
                            <p class="confirm-title">{{message.title}}</p>  
                            <hr class="title"/>
                        </div>  
                    </div>  

                    <div class="row">  
                        <div class="col-md-12">  
                            <p class="confirm-message">{{message.text}}</p>  
                        </div>  
                    </div>  
                    <div class="row">  
                        <div class="col-md-12">  
                            <p class="confirm-button">  
                                <button class="btn btn-primary"
                                    (click)="message.yesFn()">Ja</button>
                                    &nbsp;                           
                                <button class="btn btn-primary"
                                    (click)="message.noFn()">Nein</button>  
                            </p>  
                        </div>  
                    </div>  
                </div>  
            </div>  
        </div>  
    </div>  
