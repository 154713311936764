<div class="row">
  <!--
  <div class="col-md-5">
    <h1>Alle Knoten :</h1>
    <ul>
      <li *ngFor="let node of nodes">
        {{ node.name }}
      </li>
    </ul>
    <h1>Alle TopNodes</h1>
    <ul>
      <li *ngFor="let top of topNodes">
        {{ top.name }}
      </li>
    </ul>
    <h1>Alle Messpunkte</h1>
    <ul>
      <li *ngFor="let meter of meters">
        {{ meter.values.user0 }}
      </li>
    </ul>
  </div>
   -->


  <div class="col-md-7" *ngIf="company != null">
    <h5>{{ company.name }}</h5>
    <label>Wählen Sie die Knoten aus</label>
    <ng-select [items]="unassigned_nodes"
               bindLabel="name"
               bindValue="id"
               [multiple]="true"
               placeholder="Select nodes"
               [(ngModel)]="selectedNodes">
    </ng-select>
    <br>
    <p>
      Selected nodes : {{ selectedNodes }}
    </p>
    <button (click)="setConnect()">Knoten zuordnen</button>
    <button (click)="getConnect(company.id)">Get Connect</button>

  </div>
</div>


