<div class="row ">
    <div class="col-lg-12" >
        <div class="card">
            <div class="card-body">
                <h2>Tarife verwalten
                    <i class="fa fa-info-circle help-icon" 
                        placement="bottom"
                        container="body"
                        popoverClass="help-popover-wide"
                        triggers="mouseenter:mouseleave"
                        [ngbPopover]="helpTarifContent" [popoverTitle]="helpTarifTitle"

                        aria-hidden="true"></i>
                </h2>
                <div class="row">
                    <div class="col-lg-5">
                        <label>Verfügbare Tarife. Zum Ändern anklicken.
                            <i class="fa fa-info-circle help-icon" 
                        placement="bottom"
                        container="body"
                        popoverClass="help-popover-wide"
                        triggers="mouseenter:mouseleave"
                        [ngbPopover]="helpTEContent" [popoverTitle]="helpTETitle"

                        aria-hidden="true"></i>
                        </label>
                        <div class="list-group">
                            <div>
                            <button type="button"  class="list-group-item list-group-item-action"
                                [ngClass]="{ active: currIndex == indexOfelement }"
                                *ngFor="let tar of tariffs; let indexOfelement=index" 
                                (click)="selectIndex(indexOfelement)">
                                {{ tar.name }}
                            </button>
                            </div>
                            <div class="pad-top">
                                <button type="button" (click)="createNewTariff()" 
                                    placement="right" ngbTooltip="Neuen Tarif anlegen und dann rechts editieren."
                                    class="btn btn-primary">
                                    <i class="fa fa-plus" aria-hidden="true"></i>          
                                Neu
                                </button> &nbsp;
                                <button type="button" class="btn btn-primary"
                                (click)="deleteCurrentTariff()"
                                ngbTooltip="Ausgewählten Tarif löschen."
                                [ngClass]="{ disabled: currIndex == -1}">        
                                <i class="fa fa-times" aria-hidden="true"></i>          
                                Löschen
                            </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-7">
                        <form #tForm="ngForm">
                            <div class="row">
                                <label>Tarifname
                                    <i class="fa fa-info-circle help-icon" 
                                    placement="bottom"
                                    container="body"
                                    popoverClass="help-popover-medium"
                                    triggers="mouseenter:mouseleave"
                                    [ngbPopover]="helpNameContent" [popoverTitle]="helpNameTitle"
                                    aria-hidden="true"></i>                    
                                </label>
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control" id="tarName"
                                        required [(ngModel)]="tarName" name="tarName"
                                        #name="ngModel">
                                </div>
                            </div>
                            <div class="row">
                                <label>Gültig ab
                                    <i class="fa fa-info-circle help-icon" 
                                    placement="bottom"
                                    container="body"
                                    popoverClass="help-popover-medium"
                                    triggers="mouseenter:mouseleave"
                                    [ngbPopover]="helpValidContent" [popoverTitle]="helpValidTitle"
                                    aria-hidden="true"></i>                    
                                </label>
                                <div class="input-group mb-3">
                                    <input class="form-control date" placeholder="TT.MM.JJJJ" 
                                        name="dp" 
                                        [(ngModel)]="tarDateS"
                                        required ngbDatepicker #d="ngbDatepicker"/>
                                        
                                    <div class="input-group-append">
                                    <span class="input-group-text" id="basic-addon2" (click)="d.toggle()">
                                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-calendar-date" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/>
                                            <path d="M6.445 11.688V6.354h-.633A12.6 12.6 0 0 0 4.5 7.16v.695c.375-.257.969-.62 1.258-.777h.012v4.61h.675zm1.188-1.305c.047.64.594 1.406 1.703 1.406 1.258 0 2-1.066 2-2.871 0-1.934-.781-2.668-1.953-2.668-.926 0-1.797.672-1.797 1.809 0 1.16.824 1.77 1.676 1.77.746 0 1.23-.376 1.383-.79h.027c-.004 1.316-.461 2.164-1.305 2.164-.664 0-1.008-.45-1.05-.82h-.684zm2.953-2.317c0 .696-.559 1.18-1.184 1.18-.601 0-1.144-.383-1.144-1.2 0-.823.582-1.21 1.168-1.21.633 0 1.16.398 1.16 1.23z"/>
                                        </svg>
                                    </span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <label>Energieträger
                                    <i class="fa fa-info-circle help-icon" 
                                        placement="bottom"
                                        container="body"
                                        popoverClass="help-popover-narrow"
                                        triggers="mouseenter:mouseleave"
                                        [ngbPopover]="helpEnergyContent" [popoverTitle]="helpEnergyTitle"
                                        aria-hidden="true"></i>                    
                                </label>
                                <div class="input-group mb-3">
                                    <select style="width: 100%" id="energy"
                                        [(ngModel)]="energy" name="energy"
                                        #name="ngModel"
                                        [ngModelOptions]="{standalone: true}">

                                    >
                                        <option value="0">Strom</option>
                                        <option value="1">Wasser</option>
                                        <option value="2">Gas</option>
                                        <option value="3">Wärme</option>
                                        <option value="4">Druckluft</option>
                                    </select>
                                </div>
                            </div>
<!--
                            <div class="row">
                                <label>Grundpreis
                                    <i class="fa fa-info-circle help-icon" 
                                        placement="bottom"
                                        container="body"
                                        popoverClass="help-popover-narrow"
                                        triggers="mouseenter:mouseleave"
                                        [ngbPopover]="helpBasePriceContent" [popoverTitle]="helpBasePriceTitle"
                                        aria-hidden="true"></i>                    
                                </label>
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control" aria-label="Grundpreis"
                                        style="text-align: right;"
                                        id="priceBase" name="priceBase"
                                        [imask]="this.testMask"
                                        [(ngModel)]="priceBase" #name="ngModel">
                                    <div class="input-group-append">
                                    <span class="input-group-text" id="basic-addon2">
                                        &euro;
                                    </span>
                                    <div style="margin-top: 6px">&nbsp; pro &nbsp; </div>
                                    <select id="chargeTimeframe"
                                    [(ngModel)]="chargeTimeframe" name="chargeTimeframe"
                                            #name="ngModel">
                                        <option value="0">Jahr</option>
                                        <option value="1">Monat</option>
                                    </select>
                        
                                    </div>
                                </div>
                            </div>
-->                                                                
                            <div class="row">
                                <label>Preis pro Verbrauchseinheit
                                    <i class="fa fa-info-circle help-icon" 
                                    placement="bottom"
                                    container="body"
                                    popoverClass="help-popover-wide"
                                    triggers="mouseenter:mouseleave"
                                    [ngbPopover]="helpUnitPriceContent" [popoverTitle]="helpUnitPriceTitle"
                                    aria-hidden="true"></i>     
                                </label>
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control" 
        
                                        id="priceUnit" name="priceUnit"   
                                        style="text-align: right;" 
                                        [imask]="this.testMask" 
                                        [(ngModel)]="priceUnit" #name="ngModel"
                                        aria-label="Preis pro Verbrauchseinheit">
                                    <div class="input-group-append">
                                    <span class="input-group-text" id="basic-addon2">
                                        &euro;
                                    </span>
                                    </div>
                                </div>
                            </div>
<!--
                            <div class="row">
                                <label>Preis pro Leistungseinheit
                                    <i class="fa fa-info-circle help-icon" 
                                        placement="bottom"
        
                                        container="body"
                                        popoverClass="help-popover-wide"
                                        triggers="mouseenter:mouseleave"
                                        [ngbPopover]="helpCapPriceContent" [popoverTitle]="helpCapPriceTitle"
                                        aria-hidden="true"></i>     
                                </label>
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control" 
                                        id="priceCap" name="priceCap"    
                                        style="text-align: right;" 
                                        [imask]="this.testMask"
        
                                        [(ngModel)]="priceCap" #name="ngModel"                    
                                        aria-label="Preis pro Leistungeinheit">
                                    <div class="input-group-append">
                                    <span class="input-group-text" id="basic-addon2">
                                        &euro;
                                    </span>
                                    </div>
                                </div>
                            </div>
-->                            
                            <div class="row">
                                <label for="meter">Wählen Sie die Messpunkte aus
                                    <i class="fa fa-info-circle help-icon" 
                                        placement="top"
                                        container="body"
                                        popoverClass="help-popover-medium"
                                        triggers="mouseenter:mouseleave"
                                        [ngbPopover]="helpCapPriceContent" [popoverTitle]="helpCapPriceTitle"
                                        aria-hidden="true"></i>     
                                </label>
                                <div class="input-group mb-3">
                                    <ng-select [items]="allMeters" id="meter"
                                        bindLabel="values.user0"
                                        bindValue="values.user0"
                                        [multiple]="true"
                                        [clearable]="true"
                                        [(ngModel)]="selectedMeters" 
                                        [ngModelOptions]="{standalone: true}">
                                        <ng-template ng-option-tmp ng-label-tmp let-item="item">
                                            <label>
                                                <i *ngIf="item.values.obisid0 == '1-0:1.8.0*255'"
                                                    class="fa fa-bolt"></i>
                                                <i *ngIf="item.values.obisid0 == '8-0:1.0.0*255'"
                                                    class="fa fa-tint"></i>
                                                <i *ngIf="item.values.obisid0 == '6-0:1.0.0*255'"
                                                    class="fa fa-fire"></i> <!-- red -->
                                                <i *ngIf="item.values.obisid0 == '7-0:3.0.0*255'"
                                                    class="fa fa-fire"></i> <!-- blue -->
                                                <i *ngIf="item.values.obisid0 == '6-0:2.0.0*255'"
                                                    class="fa fa-tint"></i>
                                                <i *ngIf="item.values.obisid0 == '8-0:1.0.0*255'"
                                                    class="fa fa-plug"></i>
        
                                            </label>
                                            {{ item.values.user0 }}
                                        </ng-template>
                                    </ng-select>
                                </div>
                            </div>                
                            <div class="row">
                                <div class="col-lg-12" style="padding-left: 0;">
                                    <div [hidden]="errorMessage.length == 0"
                                        class="alert alert-danger">
                                        {{ errorMessage }}
                                    </div>
                                    <button type="submit" class="submit-button w30"
                                        (click)="saveTariff()">        
                                        <i class="fa fa-floppy-o" aria-hidden="true"></i>          
                                        Speichern
                                    </button>
                                </div>
                            
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-confirm-dialog></app-confirm-dialog>  

<ng-template #helpTarifContent>
    <p>Ein Tarif legt die Kosten für einen Energieträger fest und
       wird einem oder mehreren Messpunkten zugeordnet.
    </p>
    <p>Werden einem Messpunkt mehrere Tarife zugeordnet, so wird
       der Tarif für die Kostenrechnung anhand vom Datum des 
       Messwertes und des Gültigkeitsdatums bestimmt.
    </p>
</ng-template>
<ng-template #helpTarifTitle>Was ist ein Tarif?</ng-template>

<ng-template #helpNameContent>
    Der Name dient zur Identifizierung des Tarifs in Listen 
    und Zuordnungen. Er kann von Ihnen frei gewählt werden.
</ng-template>
<ng-template #helpNameTitle>Wozu dient der Tarifname?</ng-template>

<ng-template #helpValidContent>
    Der Tarif ist gültig ab diesem Datum und wird ab dann für Kostenrechnungen
    berücksichtigt. Er ist unbegrenzt gültig, bis er von einem anderen Tarif
    abgelöst wird.
</ng-template>
<ng-template #helpValidTitle>Was beschreibt <b><i>Gültig ab</i></b>?</ng-template>

<ng-template #helpEnergyContent>
    Legt fest, wofür die Kosten anfallen.
</ng-template>
<ng-template #helpEnergyTitle>Was beschreibt Energieträger?</ng-template>

<ng-template #helpBasePriceContent>
    Der Grundpreis ist der Preis, der im Abrechnungszeitraum (Monat oder Jahr)
    mindestens zu zahlen ist.
</ng-template>
<ng-template #helpBasePriceTitle>Was legt der Grundpreis fest?</ng-template>

<ng-template #helpUnitPriceContent>
    <p>... legt fest, welcher Betrag auf den Grundpreis addiert wird pro 
        genutzter Verbrauchseinheit.
    </p>
</ng-template>
<ng-template #helpUnitPriceTitle>Der <b><i>Preis pro Verbrauchseinheit</i></b>...</ng-template>

<ng-template #helpCapPriceContent>
    <p>... legt fest, welcher Betrag auf den Grundpreis addiert wird. Er wird
    multipliziert mit der bisher höchsten Lastspitze im Jahr.</p>
    <p>Beispiel:<br/>
    Verbrauch Juni: 10.000 kWh, höchste Lastspitze bisher 13 kW, Preis 100,00 &euro;
    ergibt 13 * 100 = 1300 &euro; zusätzliche Kosten.
    </p>
</ng-template>
<ng-template #helpCapPriceTitle>Der <b><i>Preis pro Leistungseinheit</i></b>...</ng-template>

<ng-template #helpMeterContent>
    Bitte wählen Sie die Messpunkte aus, für die der
    Tarif gilt.
</ng-template>
<ng-template #helpMeterTitle>Messpunkte auswählen</ng-template>

<ng-template #helpTEContent>
 <p>Wählen Sie einen Tarif aus der Liste aus und ändern Sie ihn dann auf der rechten Seite
     oder löschen Sie ihn durch den Klick auf "Löschen"
 </p>
 <p>Klicken Sie auf "Neu", wenn Sie einen neuen Tarif anlegen wollen, tragen dann die 
     gewünschten Werte ein und klicken dann auf "Speichern".
 </p>
</ng-template>
<ng-template #helpTETitle>Tarife ändern</ng-template>
