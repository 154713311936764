import {Component, OnInit, ViewChild} from '@angular/core';
import {ConfigEvent, ConfigService} from '../../../services/config.service';
import {ReportService} from '../../../services/report.service';
import {Global} from '../../../../constants/global';
import {NgbDateCustomParserFormatter} from '../../../../config/datepicker';
import {NgbDate, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import {registerLocaleData} from '@angular/common';
import localeDe from '@angular/common/locales/de';
import {NgForm} from '@angular/forms';
import {NgSelectComponent} from '@ng-select/ng-select';
import {User} from "../../../classes/user";
import { regExpEscape } from '@ng-bootstrap/ng-bootstrap/util/util';
import { Meter } from 'src/app/classes/meter';
import { CostUnit } from 'src/app/classes/costunit';

declare var $: any;

@Component({
    selector: 'app-reports',
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.scss'],
    providers: [
        {provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter},
    ]
})

export class ReportsComponent implements OnInit {

    @ViewChild('reportForm') reportForm: NgForm;
    @ViewChild('modalForm') modalForm: NgForm;
    @ViewChild('ngSelect') ngSelect: NgSelectComponent;

    company : any;
    mail    : any;

    // to show
    allMeters         : Meter[];
    allMails          : any[];
    reportsArray      : any[];
    recurrenceArray   : string[];
    errorMessage      : string;
    errorMessageModal : string;
    allCostUnits      : CostUnit[] = [];
    selectedCostUnit  : any = {};


    // to create
    selectedReport     : any;
    selectedMeters     : any[];
    selectedMetersId   : string[];
    selectedRecurrence : string;
    selectedPeriod     : string;
    selectedMails      : string[];
    repeatName         : string;
    description        : string
    active             : boolean = true;


    // to limit selecting charts
    countCharts = 1;
    maxCharts   = 3;

    // selected charts
    barConsumption         = true;
    capStepLine            = false;
    capConsumptionCombined = false;
    dateHasChanged         = false;


    tarDateS    : NgbDate;
    endDateS    : NgbDate;
    startRepeat : NgbDate;

    addMail     : string = "";

    listHour   : string[] = [];
    listMinute : string[] = [];
    minute     : string = "00";
    hour       : string = "08";

    public mailMask = {
//        mask: /^w+[+.w-]*@([w-]+.)*w+[w-]*.([a-z]{2,4}|d+)$/i,
          mask: /^\w+[+.\w-]*@([\w-]+.)*\w+[\w-]*.([a-z]{2,4}|\d+)$/i,
    };
    

    constructor(
        private configService: ConfigService,
        private reportService: ReportService,
    ) {
        registerLocaleData(localeDe);
        this.configService.configEvent$.subscribe(data =>
        {
            if (data.name == Global.SIGNAL_REFRESH_COMPANY)
               this.configService.loadVMPsForCompany();
            else this.refresh();

        });
        this.configService.loadCostUnits();
        this.reportService.reportEvent$.subscribe(data =>
        {
            this.loadReportData();
        });
        this.listHour = [ "00","01","02","03","04","05","06","07",
                          "08","09","10","11","12","13","14","15",
                          "16","17","18","19","20","21","22","23"];
        this.listMinute=[ "00","05","10","15","20","25","30","35",
                          "40","45","50","55"];
        this.loadRecurrenceArray();
    }

    ngOnInit(): void
    {
        this.recurrenceArray = this.getRecurrenceArray();
        this.company         = this.configService.getSelectedCompany();
        this.loadReportData();
        this.configService.loadVMPsForCompany();
        this.configService.loadCostUnits();

    }

    setDefaultStartDate()
    {
        if (this.dateHasChanged && this.tarDateS != null)
        {
            return;
        }
        let now = new Date(Date.now());
        if (this.selectedRecurrence === "Täglich")
        {
            let start         = new Date(Date.now() - 24 * 60 * 60 * 1000);
            this.tarDateS     = new NgbDate(start.getFullYear(), start.getMonth() + 1, start.getDate());
        }else if (this.selectedRecurrence === "Wöchentlich")
        {
            let start         = new Date(Date.now() - 7*24 * 60 * 60 * 1000);
            this.tarDateS     = new NgbDate(start.getFullYear(), start.getMonth() + 1, start.getDate());

        } else if (this.selectedRecurrence === "Monatlich")
        {
            let start = new Date(now.getFullYear(),now.getMonth()-1,1);
            this.tarDateS     = new NgbDate(start.getFullYear(), start.getMonth() + 1, start.getDate());

        } else if (this.selectedRecurrence === "Jährlich")
        {
            let start         = new Date(now.getFullYear()-1,0,1);
            this.tarDateS     = new NgbDate(start.getFullYear(),   start.getMonth() + 1, start.getDate());

        } else if ( this.selectedRecurrence === "Individuell")
        {
            this.tarDateS = null;
        }
    }

    setChangedEndDate(startDate : NgbDate) : void
    {
        this.tarDateS = startDate;
        this.dateHasChanged = true;
        if (this.endDateS != null)
        {
            this.validateEndDate(null);
        }
    }

    refresh()
    {
        this.company   = this.configService.getSelectedCompany();
        this.allMeters = this.configService.vmpAndMetersForCurrentCompany;
        this.allCostUnits = this.configService.getCostUnits();
        this.allMails  = this.getMailArray();
        this.selectedMails = [];
        this.selectedMeters = [];
    }

    loadRecurrenceArray()
    {
        let recurrenceClass = 'Recurrence';
        this.reportService.getClass(recurrenceClass).subscribe(data =>
        {
            this.reportService.storeRecurrenceClass(data);
            this.recurrenceArray    = this.reportService.getRecurrenceClass();
            this.selectedRecurrence = this.recurrenceArray[0];

        });
    }

    getRecurrenceArray() : string[]
    {
        return this.recurrenceArray;
    }

    loadReportData()
    {
        let arr = [];
        if (sessionStorage.getItem(Global.ATTRIBUTE_USER_OBJECT) == null)
           return;
        this.reportService.loadReports().subscribe(data =>
        {
            data['reports'].forEach(element => {
                arr.push(element);
            });
            this.reportsArray   = arr;
            this.selectedReport = arr[0];
            this.allMeters      = this.configService.vmpAndMetersForCurrentCompany;
            this.mail           = JSON.parse(sessionStorage.getItem(Global.ATTRIBUTE_USER_OBJECT)).email;
            this.selectedMails  = [this.mail];
            this.maxCharts      = this.getMaxCharts();

            this.setDefaultStartDate();

        });

    }

    resetForm()
    {
/*        this.reportForm.reset();
        this.modalForm.reset();
        this.ngSelect.clearModel();
        this.dateHasChanged = false;
        this.selectedPeriod = null;
        this.loadReportData();
        this.selectedRecurrence = this.recurrenceArray[0];
        this.selectedPeriod     = null;
        this.setDefaultStartDate();
*/
    }


    getMaxCharts()
    {
        if (this.selectedReport.name == 'energy') {
            return 2;
        } else {
            return 1;
        }
    }

    // nur für ein Report
    getReportsParams()
    {
        this.reportService.loadReportsParams(this.selectedReport.name).subscribe(data =>
        {
            console.log(data);
        });
    }


    useBarConsumption()
    {
        if (this.countCharts <= this.maxCharts) {
            this.barConsumption = !this.barConsumption;
        }

    }

    useCapStepLine()
    {
        if (this.countCharts <= this.maxCharts) {
            this.capStepLine = !this.capStepLine;
        }
    }

    useCapConsumptionCombined()
    {
        if (this.countCharts <= this.maxCharts) {
            this.capConsumptionCombined = !this.capConsumptionCombined;
        }
    }

    getSelectedMetersId(){
        let arr    = [];
        let meters = this.selectedMeters;

        for (let i = 0; i < meters.length; i++) {
            let m: Object = meters[i];
            arr.push(m['id']);
        }
        return arr;
    }

    createReport()
    {
        if (this.selectedReport.title == 'Energiebericht')
        {
            this.selectedMetersId = this.getSelectedMetersId();
            let mailArray = [];
            mailArray.push(this.mail)
            let stamp     = new Date(this.tarDateS.year, this.tarDateS.month -1,this.tarDateS.day);
            let end = 0;
            if (this.selectedRecurrence == "Individuell")
            {
                let d = new Date(this.endDateS.year, this.endDateS.month-1, this.endDateS.day);
                end = d.getTime();
            }

            //console.log(reportObj);
            return this.reportService.createReport
            (
                this.company.name,
                this.company.id,
                this.description,
                this.selectedReport.title,
                this.selectedRecurrence,
                this.selectedMetersId,
                stamp.getTime(),            //startDate
                end, 
                mailArray,
                this.barConsumption,
                this.capStepLine,
                this.capConsumptionCombined
            );
        }
        else
        {
            let mailArray = [];
            mailArray.push(this.mail)
            let stamp     = new Date(this.tarDateS.year, this.tarDateS.month -1,this.tarDateS.day);

            //console.log(reportObj);
            return this.reportService.createCostReport
            (
                this.company.name,
                this.company.id,
                this.description,
                this.selectedReport.title,
                this.selectedRecurrence,
                this.selectedCostUnit,
                stamp.getTime(),            //startDate
                mailArray,
                this.barConsumption,
                this.selectedCostUnit
            );

        }
    }
    
    getMailArray()
    {
        let company = this.configService.getSelectedCompany();
        let arr = [];
        if (company == null)
            return arr;
        let usr = JSON.parse(sessionStorage.getItem(Global.ATTRIBUTE_USER_OBJECT));
        company.users?.forEach(user =>
        {
            if (user != null)
            {
                arr.push(user.email);
            }
        })
        if (!arr.includes(usr.email))
            arr.push(usr.email);
        return arr.sort();
    }

    updateReportWithRepeat()
    {
        this.selectedMetersId = this.getSelectedMetersId();
        this.configService.loadReportsForCurrentCompany();
        let h      = parseInt(this.hour);
        let m      = parseInt(this.minute);
        let stamp  = new Date(this.tarDateS.year, this.tarDateS.month -1,this.tarDateS.day);
        let start  = new Date(this.startRepeat.year, this.startRepeat.month -1,
                              this.startRepeat.day, h, m);

        let repeat = this.reportService.updateReportWithRepeat
        (
            this.company.id,
            this.company.name,
            start.getTime(),             //repeatStartDate
            0,               //repeatEndDate 0 = Unendlich
            1,               // repeat in 1 Woche
            this.selectedPeriod,         //repeatEvery
            this.repeatName,
            this.description,            //Description
            true,                 //active
            this.selectedReport.title,
            this.selectedRecurrence,
            stamp.getTime(),            //startDate
            this.selectedMetersId,
            this.selectedMails,
            this.barConsumption,
            this.capStepLine,
            this.capConsumptionCombined,
            this.selectedCostUnit
        );
      //  console.log(repeat);
        return repeat;
    }

    validateAndShowModal() : void
    {
        this.errorMessageModal = "";
        this.validateCreateReport();
        if (this.errorMessage.length == 0)
           this.showModal();
    }

    validateCreateReport()
    {
        if (this.selectedReport.title != 'Kostenübersicht')
        {
            let start  = new Date(this.tarDateS?.year, this.tarDateS?.month -1,this.tarDateS?.day);
            if (this.selectedRecurrence == null)
                this.errorMessage = "Geben Sie den Berichtszeitraum an.";
            else if (this.tarDateS == null)
                this.errorMessage = "Geben Sie das Startdatum an.";
            else if ((this.endDateS == null) && (this.selectedRecurrence == "Individuell"))
                this.errorMessage = "Geben Sie das Enddatum an.";
            else if ((this.selectedMeters == null) || (this.selectedMeters.length == 0))
                this.errorMessage = "Geben Sie die Messpunkte an.";
            else
                this.errorMessage = "";
        }
        else
        {
            if ((this.selectedRecurrence == 'Täglich')  || (this.selectedRecurrence == 'Individuell'))
                this.errorMessage = "Tägliche oder Individuelle Berichtszeiträume werden nicht unterstützt.";
            else if (this.tarDateS == null)
                this.errorMessage = "Geben Sie das Startdatum an.";
            else if ((this.selectedCostUnit == null) || (this.selectedCostUnit.length == 0))
                this.errorMessage = "Wählen Sie eine Kostenstelle aus.";
            else this.errorMessage = "";
        }

    }

    handleCreateOrUpdate()
    {
        // Erstellt sofort einen Bericht
        this.validateCreateReport();
        if (this.errorMessage)
            return;
        this.createReport();
        this.resetForm();
    }

    showModal() 
    {
        if (this.selectedReport.title == 'Energiebericht')
        {
            if (this.selectedRecurrence == 'Täglich')
                this.repeatName = "Täglicher Energiebericht ${DATUM}";
            if (this.selectedRecurrence == 'Wöchentlich')
                this.repeatName = "Wöchentlicher Energiebericht KW ${KW}";
            if (this.selectedRecurrence == 'Monatlich')
                this.repeatName = "Monatlicher Energiebericht ${MONAT}";
            if (this.selectedRecurrence == 'Quartalsweise')
                this.repeatName = "Quartalsweiser Energiebericht ${QUARTAL}";
            if (this.selectedRecurrence == 'Jährlich')
                this.repeatName = "Jahresenergiebericht ${JAHR}";
            if (this.selectedRecurrence == 'Quartal')
                this.repeatName = "Energiebericht Quartal ${QUARTAL}";            
        }
        else
        {
            if (this.selectedRecurrence == 'Wöchentlich')
                this.repeatName = "Wöchentliche Kostenübersicht KW ${KW}";
            if (this.selectedRecurrence == 'Monatlich')
                this.repeatName = "Monatliche Kostenübersicht ${MONAT}";
            if (this.selectedRecurrence == 'Quartalsweise')
                this.repeatName = "Quartalsweise Kostenübersicht ${QUARTAL}";
            if (this.selectedRecurrence == 'Jährlich')
                this.repeatName = "Jahresekostenübersicht ${JAHR}";
        }


        $("#reportModal").modal('show');
    }

    closeModal()
    {
        $("#reportModal").modal('hide');
    }

    validateModalData()
    {
        
        let start  = new Date(this.startRepeat?.year, this.startRepeat?.month -1,this.startRepeat?.day);
        if ((this.repeatName == null) || (this.repeatName.trim().length == 0))
            this.errorMessageModal = "Geben Sie den Namen an";
        else if (this.selectedPeriod == null)
            this.errorMessageModal = "Geben Sie den Wiederholungszeitraum an";
        else if (this.startRepeat == null)
            this.errorMessageModal = "Geben Sie das Startdatum an";
        else if (( this.selectedMails == null) || (this.selectedMails.length == 0))
            this.errorMessageModal = "Geben Sie mindestens eine E-Mail an";
        else
        {
            this.errorMessageModal = "";
            this.closeModal();
            this.updateReportWithRepeat();
        }

    }

    isAdmin() : boolean
    {
        let usrstr = sessionStorage.getItem(Global.ATTRIBUTE_USER_OBJECT);
        let user : User = JSON.parse(usrstr);
        if (user == null)
            return false;
        return user.admin;
    }

    // Ein Admin ist auch ein Moderator!
    isModerator() : boolean
    {
        let usrstr = sessionStorage.getItem("authenticatedUser");
        let user : User = JSON.parse(usrstr);
        if (user == null)
            return false;
        return user.admin || user.moderator;
    }

    addNewMail() : void
    {
        let reg = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

        if (reg.test(this.addMail))
        {
            let bakSel = this.selectedMails;
            let bakAll = this.allMails;
            if (!this.selectedMails.includes(this.addMail))
            {
                if (!bakAll.includes(this.addMail))
                    bakAll.push(this.addMail);
                bakAll.sort();
                this.allMails = [];
                this.selectedMails = [];
                for (let i = 0; i < bakAll.length; i++)
                    this.allMails.push(bakAll[i]);
                bakSel.push(this.addMail);
                bakSel.sort();
                for (let i = 0; i < bakSel?.length; i++)
                    this.selectedMails.push(bakSel[i]);
                this.errorMessage = "";
            }
        }
        else
        {
            this.errorMessage = "Keine gültige E-Mail";
        }
    }

    validateEndDate(event : NgbDate) : void
    {
        if (this.selectedRecurrence == 'Individuell')
        {
            let current = event;
            if (current == null)
               current = this.endDateS;
            let end    = new Date(current.year, current.month-1, current.day);
            let start  = new Date(this.tarDateS.year, this.tarDateS.month-1, this.tarDateS.day);
            let diffSec= Math.abs(end.getTime() - start.getTime()) / 1000;
            diffSec = diffSec / 3600;   // Stunden
            diffSec = diffSec / 24;     // Tage
            if (diffSec > 31)
            {
                alert("Ein indvidueller Zeitraum darf maximal 31 Tage umfassen.\nLängere Zeiträume sind Quartal oder Jahr.")
                end = new Date(start.getTime() +  31 * 24 * 3600 * 1000);
                this.endDateS = new NgbDate(end.getFullYear(), end.getMonth()+1, end.getDate());
            }
        }
    }
}
