import { registerLocaleData } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Meter } from 'src/app/classes/meter';
import localeDe from '@angular/common/locales/de';
import { ConfigService } from 'src/app/services/config.service';
import { ReportService } from 'src/app/services/report.service';
import { Global } from 'src/constants/global';
import { User } from 'src/app/classes/user';
import { formatDate } from '@angular/common';
declare var $: any;

@Component({
  selector: 'app-import-config',
  templateUrl: './import-config.component.html',
  styleUrls: ['./import-config.component.scss']
})
export class ImportConfigComponent implements OnInit 
{

  //pagination
  page      = 1;
  count     = 0;
  tableSize = 7;


  allImportsWithRepeat : any[] = [];
  importObj            : any = {};    //for Modal window

  repeatName      : string;
  description     : string;
  selectedPeriod  : string;
  startRepeat     : NgbDate;
  endRepeat       : NgbDate;
  allMails        : string[];
  selectedMails   : string[];
  active          : boolean = true;
  formHasChanged  : boolean = false;

  colTimeFormat : string;
  errorMessage  : string = "";
  selectedMeter : Meter;
  allMeters     : Meter[];
  allRecs       : string[];
  allCols       : any[] = [];
  allTimes  : string[];
  allTypes  : string[];
  colTime   : any;
  colMP     : any;
  colVal    : any;
  colRec    : any;
  
  mpType    : string = 'Messpunkt auswählen';


  constructor(
      public configService : ConfigService,
      public reportService : ReportService
  ) {
    registerLocaleData(localeDe);
    this.allRecs   = [ "jede Stunde", "alle 2 Stunden", "alle 4 Stunden", "einmal täglich"];   // Einmalig macht für Wiederholungen kein Sinn
    this.allTimes  = [ "Timestamp", "Text (TT.MM.JJJJ hh:mm)", "Text (TT.MM.JJJJ hh:mm:ss)", 
                       "Text (JJJJ-MM-TT hh:mm)"]
    this.allTypes  = [ "Messpunkt-ID", "Messpunkt-Referenz", "Messpunkt auswählen"];


    this.reportService.reportEvent$.subscribe(data =>
    {
      if (data.name == Global.SIGNAL_REFRESH_IMPORTS)
      {

        this.refreshImports();

      }

    });
    configService.configEvent$.subscribe(data => 
        {
            if (data.name == Global.SIGNAL_REFRESH_COLUMNS)
               this.allCols   = this.configService.allCols;
        });

  }

  ngOnInit(): void 
  {
    this.reportService.loadImportsForCurrentCompany();
    this.allMails  = this.configService.getUsersMailArray();
  }

  refreshImports(){
    this.allImportsWithRepeat = this.reportService.getImportsForCurrentCompany();
    this.allMails             = this.configService.getUsersMailArray();
    this.allMeters            = this.configService.getMetersForCompany();
  }

  refreshContentOfModal()
  {
    this.active         = this.importObj.active;
    this.colMP          = this.importObj.colMP;
    this.selectedMeter  = this.importObj.meterId;
    this.selectedMails  = this.importObj.mails;             // TODO fehlt in JSON Object
    this.colTime        = this.importObj.colTime;
    this.mpType         = this.importObj.type;
    if (this.importObj.timeFormat == "ts")
        this.colTimeFormat  = "Timestamp";
    else
    if (this.importObj.timeFormat == "dd.MM.yyyy HH:mm")
        this.colTimeFormat = "Text (TT.MM.JJJJ hh:mm)";
    else this.colTimeFormat = "Text (TT.MM.JJJJ hh:mm:ss)"
    this.colVal         = this.importObj.colVal;
    let rec = this.importObj.period * this.importObj.recurrence;
    if (rec == 3600000)
        this.colRec = "jede Stunde";
    if (rec == 3600000 * 2)
        this.colRec = "alle 2 Stunden";
    if (rec == 3600000 * 4)
        this.colRec = "alle 4 Stunden";
    if (rec == 3600000 * 24)
        this.colRec = "einmal täglich";
  }    

  findImportObj(id)
  {
    let report     = this.allImportsWithRepeat.find(el => el.id === id);
    this.importObj = report;
    this.importObj.tableName = "raw_measurement"
    this.configService.loadColumnsForTable(this.importObj.tableName)
        .subscribe(() =>
        {
            this.refreshContentOfModal();
        })
  }

  openModal(id)
  {
      this.errorMessage = "";
      this.findImportObj(id);
  }

  removeImportWithRepeat(id)
  {
    let report  = this.allImportsWithRepeat.find(el => el.id === id);
    let company = this.configService.getSelectedCompany();
    this.allImportsWithRepeat = this.allImportsWithRepeat.filter(item => item.id !== id);
    return this.reportService.removeReportWithRepeat(report,company.name)
  }

  onTableDataChange(event){
    this.page = event;
    this.allImportsWithRepeat = this.reportService.getImportsForCurrentCompany();
  }

  updateImportObj() : any
  {
      let obj =
      {
          id:          this.importObj.id,
          tableName:   this.importObj.tableName,
          name:        this.importObj.name,
          description: this.importObj.description,
          active:  this.active,
          company: this.importObj.company,
          companyId:   this.importObj.companyId,
          colMp:   this.colMP,
          colTime: this.colTime,
          mails:   this.selectedMails,
          colVal:  this.colVal,
          type:    this.mpType,
          timeFormat:  this.colTimeFormat
      }
      obj['active'] = this.active;
      if (this.selectedMeter != null)
         obj['meterId']  = this.selectedMeter.id;
      if (this.colRec == "jede Stunde")
       obj['recurrence'] = 1;
      if (this.colRec == "alle 2 Stunden")
        obj['recurrence'] = 2;
      if (this.colRec == "alle 4 Stunden")
        obj['recurrence'] = 3;
      if (this.colRec == "einmal täglich")
         obj['recurrence'] = 4;
      return obj;
  }

  changeImport()
  {
      // Daten überprüfen
    if (this.validateFormData())
    {
        let obj = this.updateImportObj();
        this.closeModal();
        return this.reportService.changeImport(obj);
    }
  }

  validateFormData() : boolean
  {
      if ((this.mpType == "Messpunkt auswählen") && (this.selectedMeter == null))
          this.errorMessage = "Bitte geben Sie einen Messpunkt an."
      if ((this.mpType != "Messpunkt auswählen") && (this.colMP == null))  
          this.errorMessage = "Bitte geben Sie die Spalte an, die den Messpunkt enthält."
      if (( this.selectedMails == null) || (this.selectedMails.length == 0))
          this.errorMessage = "Geben Sie mindestens eine E-Mail an.";
      else
      {
        this.errorMessage = "";
        this.closeModal();
      }
      return this.errorMessage.length == 0;
  }


  closeModal()
  {
    $("#changeReportModal").modal('hide');
  }

  isAdmin() : boolean
  {
    let usrstr = sessionStorage.getItem(Global.ATTRIBUTE_USER_OBJECT);
    let user : User = JSON.parse(usrstr);
    if (user == null)
      return false;
    return user.admin;
  }

  // Ein Admin ist auch ein Moderator!
  isModerator() : boolean
  {
    let usrstr = sessionStorage.getItem("authenticatedUser");
    let user : User = JSON.parse(usrstr);
    if (user == null)
      return false;
    return user.admin || user.moderator;
  }

  getSelectedMeters(list : string[]) : Meter[]
  {
      let result : Meter[] = [];
      for (let i = 0; i < list.length; i++)
      {
          for (let j = 0; j < this.allMeters.length; j++)
          {
              if (this.allMeters[j].deviceId == list[i])
                 result.push(this.allMeters[j]);
          }
      }
      return result;
  }

  getNextStart(data) : string
  {
      if (!this.importObj.active)
      {
          return "Keine Planung";
      }
      let now = Date.now();
      let offset  = data.period * data.recurrence;
      let lastRun = data.lastRun;
      while (lastRun + offset < now - 5*60 *1000)
      {
          lastRun += offset;
      }
      let d = new Date(lastRun);
      return formatDate(d, "dd.MM.yyyy HH:mm", "en-US");
  }

  toggleActiveAndSave(imp) : void
  {
      imp.active = !imp.active;
      this.reportService.changeImport(imp);
  }

  selectMPColumn(colName : any) : void
  {
      if (colName == null)
         this.mpType ='Messpunkt auswählen';
      else this.mpType = "Messpunkt-ID";
  }

  mpTypeChanged(data) : void
  {
      if (this.mpType == "Messpunkt auswählen")
         this.colMP = null;
      else this.selectedMeter = null;
  }
}
