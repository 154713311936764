export class Popup
{
    id         : string;
    title      : string; 
    text       : string;
    type       : string;
    progress   : number;
    companyName: string;
    timestamp  : number;
    visible    : boolean;
    refreshEvents: boolean;     // True, dann die Events im Dashboard / Tab "Events" aktualisieren
    added      : boolean;       // True, dann wurde das Popup bereits zur Anzeige hinzugefügt
}
