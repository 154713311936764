import { Component, OnInit } from '@angular/core';
import { LogeventsService, LogSignal } from '../../../services/logevents.service';
import { Logevent } from 'src/app/classes/logevent';
// import { ThrowStmt } from '@angular/compiler';
import { User } from 'src/app/classes/user';
import { Global } from 'src/constants/global';
import { HttpClient } from '@angular/common/http';
import * as fileSaver from 'file-saver';
import { ConfigEvent, ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'app-eventlog',
  templateUrl: './eventlog.component.html',
  styleUrls: ['./eventlog.component.scss']
})
export class EventlogComponent implements OnInit 
{
    public filterReport    : string = "";
    public filterAction    : string = "";
    public filterProblem   : string = "";
    public filteredReports : Logevent[];
    public filteredActions : Logevent[];
    public filteredProblems: Logevent[];

    constructor(public logeventsService : LogeventsService,
                private configService : ConfigService,
                private http: HttpClient
               ) 
    {
    }

    ngOnInit(): void 
    {
        this.filteredReports = [];
        this.filteredActions = [];
        this.filteredProblems= [];
        this.filterReport = "";
        this.filterAction = "";
        this.filterProblem= "";
        this.logeventsService.logEvent$.subscribe(
            () => {
                this.filteredReports = this.logeventsService.currReports;
                this.filteredActions = this.logeventsService.currActions;
                this.filteredProblems= this.logeventsService.currProblems;
            }
        );
        this.configService.configEvent$.subscribe((ce : ConfigEvent) => 
        {
            if (ce.name == Global.SIGNAL_REFRESH_COMPANY)
               this.logeventsService.loadEvents();
        });
        this.logeventsService.loadEvents();
        
    }

    refreshFilteredProblems() : void
    {
        if (this.filterProblem.length < 2)
        {
            this.filteredProblems = this.logeventsService.currProblems;
            return;
        }
        this.filteredProblems = this.logeventsService.
           currProblems.filter((el) => {
               if (el.text.toLowerCase().indexOf(this.filterProblem.toLowerCase()) >= 0)
                  return true;
               else return false;
           });
    }

    refreshFilteredReports() : void
    {
        if (this.filterReport.length < 2)
        {
            this.filteredReports = this.logeventsService.currReports;
            return;
        }
        this.filteredReports = this.logeventsService.
           currReports.filter((el) => {
               if (el.text.toLowerCase().indexOf(this.filterReport.toLowerCase()) >= 0)
                  return true;
               else return false;
           });
    }

    refreshFilteredActions() : void
    {
        if (this.filterAction.length < 2)
        {
            this.filteredActions = this.logeventsService.currActions;
            return;
        }
        this.filteredActions = this.logeventsService.
           currActions.filter((el) => {
               if (el.text.toLowerCase().indexOf(this.filterAction.toLowerCase()) >= 0)
                  return true;
               else return false;
           });
    }

    clearFilterReport() : void
    {
        this.filterReport    = "";        
        this.filteredReports = this.logeventsService.currReports;
    }

    clearFilterAction() : void
    {
        this.filterAction    = "";        
        this.filteredActions = this.logeventsService.currActions;
    }

    clearFilterProblem() : void
    {
        this.filterProblem    = "";        
        this.filteredProblems = this.logeventsService.currProblems;
    }

    getRowClass() : string
    {
        let usrstr = sessionStorage.getItem("authenticatedUser");
        let user : User = JSON.parse(usrstr);
        if (user.admin)
        {
            return "col-lg-3 col-md-6 margin-bottom";
        }
        else return "col-lg-4 col-md-6 margin-bottom";
    }

    isAdmin() : boolean
    {
        let usrstr = sessionStorage.getItem("authenticatedUser");
        let user : User = JSON.parse(usrstr);
        if (user == null)
           return false;
        return user.admin;
    }

    downloadReport(event : Logevent) : any
    {
      let company = JSON.parse(sessionStorage.getItem(Global.SESSION_COMPANY));
      let url = Global.apiUrl + 'rev2/download/report/' + company.name;
      event['service'] = this;

      this.http.post(url, event.action, {responseType: 'blob'}).subscribe(
        function onSuccess(data)
        {
            let blob:any = new Blob([data], { type: 'application/pdf' });
            // const url = window.URL.createObjectURL(blob);
            fileSaver.saveAs(blob, "report.pdf");
        },
        function onError(data)
        {
            this.popupService.addPopupError("Fehler", "Herunterladen gescheitert!");        
        }
      );
    }

}
