<div class="row">
    <div class="col-md-12"
      *ngIf="(weather != null) && (weather.icon != 'empty') "
     >
        <h2 class="titel">{{ weather.city}}<br/>
            <small> {{ getTime() }}</small></h2>
        
        <table width="90%">
            <tr>
                <td colspan="2" align="center">
                    <img width="100px" src="assets/weather/{{weather.icon + '.png'}}"><br/>
                    {{ weather.text }}
                </td>
                
            </tr>
            <tr>
                <td>Temperatur</td>
                <td>{{ weather.temp }} °C</td>
            </tr>
            <tr>
                <td>Gefühlt</td>
                <td>{{ weather.felt_temp}} °C</td>
            </tr>
            <tr>
                <td>Luftfeuchtigkeit</td>
                <td>{{ weather.humidity }}%</td>
            </tr>
            <tr>
                <td>Luftdruck</td>
                <td>{{ weather.pressure }} hPa</td>
            </tr>
        </table>
        <small class="tiny">Daten von OpenWeather.com,
               Icons von Enthropia Labs</small>

    </div>
</div>