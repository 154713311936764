import { ExecFileOptionsWithStringEncoding } from 'child_process';

export class MContainerEntry
{
    from: Date;
    meterId: string;
}

export class MContainer
{
    id        : number;
    companyId : number;
    name      : string;
    active    : boolean;
    deviceId  : string;
    meters    : MContainerEntry[];
}