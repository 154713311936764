import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {Router, ActivatedRoute} from '@angular/router';
import {Global} from '../../constants/global';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  apiUrl = Global.apiUrl;

  private user: Object;

  constructor(
    private http: HttpClient,
    private configService : ConfigService,
    private route: ActivatedRoute,
    private router: Router) {

  }

  getToken() {
    return sessionStorage.getItem(Global.ATTRIBUTE_USER_TOKEN);
  }

  authenticationService(username: String, password: String) {
    let ob = this.http.get<any>(this.apiUrl + 'users/me',
      {
        headers:
          {authorization: this.createBasicAuthToken(username, password)},
        observe: 'response'
      });
    ob.subscribe(resp => {
      let token = resp.headers.get('Authorization');
      token = token.substr(7);
      let usr = resp.body;
      this.registerSuccessfulLogin(usr, token);
    }, () => {});
    return ob;
  }


  /*
    Konvertiere Base64 mit UTF-8 Zeichen. btoa kodiert als
    Unicode, unser backend aber als utf8
   */
  b64EncodeUnicode(str: string): string {
    // Haben wir die Funktionen, die wir brauchen?
    if (window
      && 'btoa' in window
      && 'encodeURIComponent' in window) {
      // URI-Encode und dann jedes nicht Ascii-Zeichen (%xx) wandeln
      // in sein Character-Äquivalent
      return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) => {
        return String.fromCharCode(('0x' + p1) as any);
      }));
    } else {
      console.warn('b64EncodeUnicode requirements: window.btoa and window.encodeURIComponent functions');
      return null;
    }

  }

  createBasicAuthToken(username: String, password: String) {
    var b64 = this.b64EncodeUnicode(username + ':' + password);
    return 'Basic ' + b64;
  }

  registerSuccessfulLogin(user, token) 
  {
    console.log("Register Login")
    sessionStorage.setItem(Global.ATTRIBUTE_USER_TOKEN, token);
    sessionStorage.setItem(Global.ATTRIBUTE_USER_OBJECT, JSON.stringify(user));
    //console.log(this.configService.getUser())
    this.configService.loadCompaniesArray();
  }

  logout() {
    sessionStorage.removeItem(Global.ATTRIBUTE_USER_OBJECT);

  }

  isUserLoggedIn() {
    let user = sessionStorage.getItem(Global.ATTRIBUTE_USER_OBJECT);
    if (user === null) {
      return false;
    }
    return true;
  }

  getLoggedInUserName() {
    let userAsString = sessionStorage.getItem(Global.ATTRIBUTE_USER_OBJECT);
    let user = JSON.parse(userAsString);
    if (user === null) {
      return '';
    }
    return user.name;
  }

  lostPassword(email: String) {
    return this.http.post(this.apiUrl + `users/password/lost`, {email: email});
  }

  resetPassword(id: String, transactionCode: String, password: String) {
    return this.http.post(this.apiUrl + `users/password/reset`, {
      id: id,
      transactionCode: transactionCode,
      password: password,

    });
  }


}
