import { formatDate } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { Todo, TodoGroup } from 'src/app/classes/todo';
import { ConfigEvent, ConfigService } from 'src/app/services/config.service';
import { Global } from 'src/constants/global';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { NgbDateCustomParserFormatter } from 'src/config/datepicker';
import { User } from 'src/app/classes/user';

declare var $: any;


@Component({
  selector: 'app-todo',
  templateUrl: './todo.component.html',
  styleUrls: ['./todo.component.scss'],
  providers: [
    {provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter}
   ]
})
export class TodoComponent implements OnInit 
{
    @ViewChild('modal') modal : Component;
    
    allTodos      : TodoGroup[] = [];
    closeTodos    : boolean[];
    allEMails     : string[]    = [];
    selectedEMails: string[]    = [];
    configService : ConfigService;
    
    editMode  : boolean = false;
    currId    : number   = -1;          // Die DB-ID der aktuellen Vorgabe
    subId     : number   = -1;
    subTodo   : boolean  = false;       // True, wenn Todo hinzugefügt wird.
    errorMessage: string = "";
    inputName : string   = "";
    inputDesc : string   = "";
    inputDeadline : NgbDate;
    displayTitle  : string = "";
    displayButton : string;

    //pagination
    page      = 1;
    count     = 0;
    tableSize = 5;


    constructor(confService : ConfigService) 
    { 
        this.configService = confService;
        registerLocaleData(localeDe);
    }

    ngOnInit(): void 
    {
        this.configService.configEvent$.subscribe(
                (data : ConfigEvent) => {
                    if (data.name == Global.SIGNAL_REFRESH_TODO)
                        this.refreshTodo();
                }
            );
        this.configService.loadTodos();
    }

    getUntilDate(todo : TodoGroup)  : string
    {
        let d = new Date(todo.deadline);
        return formatDate(d, "dd.MM.yyyy", "en-US");
    }

    getEMails(emails : any[]) : string
    {
        if (emails == null)
            return "";
        if (emails.length ==1)
           return emails[0];
        return emails[0] + " ...";
    }

    refreshTodo() : void
    {
        let usr : User = JSON.parse(sessionStorage.getItem(Global.ATTRIBUTE_USER_OBJECT));
        this.allTodos  = this.configService.getTodos();
        this.allEMails = this.configService.getUsersMailArray();
        if (!this.allEMails.includes(usr.email))
            this.allEMails.push(usr.email);
        this.allEMails.sort();
        this.closeTodos = [];
        for (let i = 0; i < this.allTodos.length; i++)
            this.closeTodos.push(false);
        this.count =  this.allTodos.length;
    }

    getDeadlineAsDate() : Date
    {
        let year  = 0;
        let month = 0;
        let day   = 0;
        if (this.inputDeadline.constructor.name == "String")
        {
            var cpf   = new NgbDateCustomParserFormatter();
            let value = this.inputDeadline.toString();
            let res = cpf.parse(value);
            year  = res.year;
            month = res.month;
            day   = res.day;
        }
        else
        {
            year  = this.inputDeadline.year
            month = this.inputDeadline.month - 1;
            day   = this.inputDeadline.day;
        }
        return new Date(year, month, day);
    }

    getDeadlineAsNgbDate(ts : number) : NgbDate
    {
        let d = new Date(ts);
        return new NgbDate(d.getFullYear(), d.getMonth() +1, d.getDate());
    }

    submitData() : void
    {
        if (this.subTodo)
            this.submitTodo()
        else this.submitTodoGroup();
    }

    submitTodo() : void
    {
        if (this.checkValidForm())
        {
            let stamp   = this.getDeadlineAsDate(); // new Date(this.inputDeadline.year, this.inputDeadline.month - 1,this.inputDeadline.day);
            let company = JSON.parse(sessionStorage.getItem(Global.SESSION_COMPANY));
            let todo : Todo = new Todo();
            todo.name = this.inputName;
            todo.done = false;
            todo.id   = this.subId;
            todo.deadline    = stamp.getTime();
            todo.emails      = this.selectedEMails;
            todo.companyId   = company.id;
            todo.description = this.inputDesc;
            todo.parent      = this.currId;
            let group = this.getTodoGroup(this.currId);
            this.addTodoToGroup(group, todo);
            this.configService.saveTodo(todo);
            $("#todoModal").modal('hide');
        }
    }

    submitTodoGroup() : void
    {
        if (this.checkValidForm())
        {
            let stamp   = this.getDeadlineAsDate(); // new Date(this.inputDeadline.year, this.inputDeadline.month - 1,this.inputDeadline.day);
            let company = JSON.parse(sessionStorage.getItem(Global.SESSION_COMPANY));
            let tg  = this.getTodoGroup(this.currId);
            if (tg != null)
            {
                // Todogruppe gefunden
                tg.name = this.inputName;
                tg.done = false;
                tg.id   = this.currId;
                tg.deadline    = stamp.getTime();
                tg.emails      = this.selectedEMails;
                tg.companyId   = company.id;
                tg.description = this.inputDesc;
                tg.parent      = 0;
                this.configService.saveTodoGroup(tg);
            }
            else
            {
                if (this.currId > 0)
                {
                    tg = this.getTodoGroupByTodoId(this.currId);
                    if (tg != null)
                    {
                        let todo = null;
                        let ind = -1;
                        for (let i = 0; i < tg.todos.length; i++)
                        {
                            if (tg.todos[i].id == this.currId)
                            {
                                todo = tg.todos[i];
                                ind = i;
                                break;
                            }
                        }
                        if (ind > -1)
                        {
                            todo.name = this.inputName;
                            todo.id   = this.currId;
                            todo.deadline    = stamp.getTime();
                            todo.emails      = this.selectedEMails;
                            todo.companyId   = company.id;
                            todo.description = this.inputDesc;
                            tg.todos[ind] = todo;
                            this.configService.saveTodoGroup(tg);
                        }
                    }
                }
            }
            $("#todoModal").modal('hide');
        }
    }

    getTodoGroupByTodoId(id : number) : TodoGroup
    {
        if (id < 0)
           return new TodoGroup();
        for (let i = 0; i < this.allTodos.length; i++)
        {
            for (let j = 0; j < this.allTodos[i].todos.length; j++)
            {
                if (this.allTodos[i].todos[j].id == id)
                    return this.allTodos[i];
            }
        }
        return null;
    }

    deleteTodoGroup(todo : TodoGroup) : void
    {
        if (todo.id > 0)
        {
            this.configService.deleteTodoGroup(todo);
        }
    }

    checkValidForm() : boolean
    {
        this.errorMessage = "";
        if (this.inputName.length == 0)
        {
            this.errorMessage = "Bitte einen Namen eingeben!"
            return false;
        }
        if (this.inputDeadline == null) 
        {
            this.errorMessage = "Bitte eine Fälligkeit eintragen."
            return false;
        }
        if (this.selectedEMails.length == 0)
        {
            this.errorMessage = "Bitte mindestens einen Benutzer auswählen";
            return false;
        }
        return true;
    }

    editTodoGroup(todo : TodoGroup) : void
    {
        this.errorMessage  = "";
        this.displayTitle  = "Vorgabe ändern";
        this.displayButton = "Ändern";
        this.inputDesc     = todo.description;
        this.inputName     = todo.name;
        this.inputDeadline = this.getDeadlineAsNgbDate(todo.deadline);
        this.selectedEMails= [];
        for (let i = 0; i < todo.emails.length; i++)
        {
            let obj = todo.emails[i];
            this.selectedEMails.push(obj);
        }
        this.currId = todo.id;
        this.subId = -1;
        this.subTodo = false;
    }

    createTodoGroup() : void
    {
        this.displayTitle = "Vorgabe erstellen";
        this.displayButton= "Erstellen"
        this.currId       = -1;
        this.inputName    = "";
        this.inputDeadline= null;
        this.inputDesc    = "";
        this.selectedEMails = [];
        this.subId   = -1;
        this.subTodo = false;
    }

    createTodoInGroup(group : TodoGroup) : void
    {
        this.displayTitle  = "Teilvorgabe erstellen";
        this.displayButton = "Erstellen";
        this.subId         = -1;
        this.subTodo       = true;
        this.currId        = group.id;
        this.inputName    = "";
        this.inputDeadline= null;
        this.inputDesc    = "";
        this.selectedEMails = [];
    }

    toggleVisibility(index : number) : void
    {
        this.closeTodos[index] = !this.closeTodos[index];
    }

    editSubTodo(group : TodoGroup, todo : Todo) : void
    {
        this.subId = todo.id;
    }

    /* Gibt die aktuell editierte TodoGroup zurück oder 
       legt eine neue an, wenn currId = -1
    */
    getTodoGroup(id : number) : TodoGroup
    {
        if (id < 0)
           return new TodoGroup();
        for (let i = 0; i < this.allTodos.length; i++)
        {
            if (this.allTodos[i].id == id)
               return this.allTodos[i];
        }
    }

    /*
       Fügt ein Todo zu der TodoGroup hinzu
    */
    addTodoToGroup(group : TodoGroup, todo : Todo) : void
    {
        if (todo.id < 0)
            group.todos.push(todo);
        else
        {
            for (let i = 0; i < group.todos.length; i++)
            {
                if (group.todos[i].id == todo.id)
                {
                    group.todos[i] = todo;
                    break;
                }
            }
        }
    }

    getClassForSub(ind : number) : string
    {
        if (!this.closeTodos[ind])
           return "display: none ";
        return "display: contents ";

    }

    onTableDataChange(event : any) : void
    {
        this.page = event;
    }
}
