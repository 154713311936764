import { Component, OnInit } from '@angular/core';
import {ConfigService} from '../../../../services/config.service';
import {Global} from '../../../../../constants/global';
import {ReportEvent, ReportService} from '../../../../services/report.service';
import {NgbDate, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import {NgbDateCustomParserFormatter} from '../../../../../config/datepicker';
import {registerLocaleData} from '@angular/common';
import localeDe from '@angular/common/locales/de';
import {User} from "../../../../classes/user";
import { Meter } from 'src/app/classes/meter';
import { CostUnit } from 'src/app/classes/costunit';

declare var $: any;

@Component({
  selector: 'app-reports-config',
  templateUrl: './reports-config.component.html',
  styleUrls: ['./reports-config.component.scss'],
  providers: [
    {provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter}
  ]
})
export class ReportsConfigComponent implements OnInit {

  //pagination
  page      = 1;
  count     = 0;
  tableSize = 7;


  allReportsWithRepeat : any[] = [];
  reportObj            : any;    //for Modal window

  repeatName      : string;
  description     : string;
  recurrenceArray : string[];
  selectedPeriod  : string;
  startRepeat     : NgbDate;
  endRepeat       : NgbDate;
  allMails        : string[];
  selectedMails   : string[];
  active          : boolean = true;
  formHasChanged  : boolean = false;
  minute     : string = "00";
  hour       : string = "08";
  listHour   : string[] = [];
  listMinute : string[] = [];

  errorMessage  : string = "";
  selectedMeters: Meter[]  = [];
  allMeters     : Meter[];
  selectedCostUnit : any;
  allCostUnits     : CostUnit[] = [];

  constructor(
      public configService : ConfigService,
      public reportService : ReportService
  ) {
    registerLocaleData(localeDe);
    this.listHour = [ "00","01","02","03","04","05","06","07",
                      "08","09","10","11","12","13","14","15",
                      "16","17","18","19","20","21","22","23"];
    this.listMinute=[ "00","05","10","15","20","25","30","35",
                      "40","45","50","55"];

    this.configService.configEvent$.subscribe(data =>
    {
      if (data.name == Global.SIGNAL_REFRESH_REPORTS)
      {

          this.refreshReports();
      }
      if (data.name == Global.SIGNAL_REFRESH_COMPANY)
      {
          this.getRecurrenceArray();
      }

    });
    this.reportService.reportEvent$.subscribe( data =>
    {
      if (data.name == Global.SIGNAL_REFRESH_REPORTS)
      {
        this.configService.loadReportsForCurrentCompany();
        this.refreshReports();
      }

    })

  }
  ngOnInit(): void 
  {
      this.configService.loadReportsForCurrentCompany();
    // this.recurrenceArray = this.getRecurrenceArray();
  }
  
  refreshReports(){
    this.allReportsWithRepeat = this.configService.getReportsForCurrentCompany();
    this.allMails             = this.configService.getUsersMailArray();
    this.allMeters            = this.configService.getMetersForCompany();
    this.allCostUnits         = this.configService.getCostUnits();
  }

  refreshContentOfModal(){
    this.repeatName     = this.reportObj.name;
    this.description    = this.reportObj.description;
    this.selectedPeriod = this.reportObj.repeatEvery;
    let start           = new Date(this.reportObj.repeatStartDate);
    this.startRepeat    = new NgbDate(start.getFullYear(), start.getMonth() + 1, start.getDate());
    let end             = new Date(this.reportObj.repeatEndDate);
    this.endRepeat      = new NgbDate(end.getFullYear(),end.getMonth() + 1, end.getDate());
    this.active         = this.reportObj.active;
    this.selectedMails  = this.reportObj.mails;             // TODO fehlt in JSON Object
    this.minute         = (start.getMinutes() < 10 ? "0" : "") + start.getMinutes();
    this.hour           = (start.getHours() < 10 ? "0" : "") + start.getHours();
    if (this.reportObj.reportType == 'cost')
    {
        this.selectedCostUnit = "";
        for (let i = 0; i < this.allCostUnits.length; i++)
        {
          if (this.reportObj.costUnit == this.allCostUnits[i].id)
              this.selectedCostUnit = this.allCostUnits[i].id;
        }
    }
    else
    {
      this.selectedMeters = this.getSelectedMeters(this.reportObj.measuringPoints);
    }
  }

  findReportObj(id)
  {
    let report     = this.allReportsWithRepeat.find(el => el.id === id);
    this.reportObj = report;

  }

  openModal(id)
  {
      this.errorMessage = "";
      this.findReportObj(id);
      this.refreshContentOfModal();
  }

  removeReportWithRepeat(id)
  {
    let report  = this.allReportsWithRepeat.find(el => el.id === id);
    let company = this.configService.getSelectedCompany();
    this.allReportsWithRepeat = this.allReportsWithRepeat.filter(item => item.id !== id);
    return this.reportService.removeReportWithRepeat(report,company.name)
  }

  getRecurrenceArray() : void
  {
    let recurrenceClass = 'Recurrence';
    let observe = this.reportService.getClass(recurrenceClass).subscribe(data =>
    {
      this.reportService.storeRecurrenceClass(data);
      this.recurrenceArray    = this.reportService.getRecurrenceClass();
    });
  }

  onTableDataChange(event){
    this.page = event;
    this.allReportsWithRepeat = this.configService.getReportsForCurrentCompany();
  }

  updateReportObj()
  {
    let start  = new Date(this.startRepeat.year, this.startRepeat.month -1,this.startRepeat.day,
                          parseInt(this.hour), parseInt(this.minute));
    let end    = new Date(this.endRepeat.year, this.endRepeat.month -1,this.endRepeat.day);
    this.reportObj = {
      id              : this.reportObj.id,
      reportType      : this.reportObj.reportType,
      name            : this.repeatName,
      description     : this.description ,
      repeatStartDate : start.getTime(),
      repeatEndDate   : end.getTime(),
      repeatPeriod    : 1,                        //TODO
      repeatEvery     : this.selectedPeriod,
      mails           : this.selectedMails,
      active          : this.active,
    }
    if (this.reportObj.reportType == 'cost')
    {
        this.reportObj['costUnit'] = this.selectedCostUnit;
    }
    else
    {
        this.reportObj['measuringPoints'] = this.getMeasuringPoints();
    }
  }

  changeReport()
  {
      // Daten überprüfen
    if (this.validateFormData())
    {
        let companyId = this.configService.getSelectedCompany().id;
        this.updateReportObj();
        this.closeModal();
        return this.reportService.changeReport(this.reportObj,companyId);
    }
  }

  validateFormData() : boolean
  {
    let start  = new Date(this.startRepeat?.year, this.startRepeat?.month -1,this.startRepeat?.day,
                parseInt(this.hour), parseInt(this.minute));
    let end    = new Date(this.endRepeat?.year, this.endRepeat?.month -1,this.endRepeat?.day);
    if ((this.repeatName == null) || (this.repeatName.trim().length == 0))
        this.errorMessage = "Geben Sie den Namen an";
    else if (this.selectedPeriod == null)
        this.errorMessage = "Geben Sie den Wiederholungszeitraum an";
    else if (this.startRepeat == null)
        this.errorMessage = "Geben Sie das Startdatum an";
    else if (( this.selectedMails == null) || (this.selectedMails.length == 0))
        this.errorMessage = "Geben Sie mindestens eine E-Mail an";
    else
    {
        this.errorMessage = "";
        this.closeModal();
    }
    return this.errorMessage.length == 0;
}


  closeModal()
  {
    $("#changeReportModal").modal('hide');
  }

  isAdmin() : boolean
  {
    let usrstr = sessionStorage.getItem(Global.ATTRIBUTE_USER_OBJECT);
    let user : User = JSON.parse(usrstr);
    if (user == null)
      return false;
    return user.admin;
  }

  // Ein Admin ist auch ein Moderator!
  isModerator() : boolean
  {
    let usrstr = sessionStorage.getItem("authenticatedUser");
    let user : User = JSON.parse(usrstr);
    if (user == null)
      return false;
    return user.admin || user.moderator;
  }

  getSelectedMeters(list : string[]) : Meter[]
  {
      let result : Meter[] = [];
      for (let i = 0; i < list.length; i++)
      {
          for (let j = 0; j < this.allMeters.length; j++)
          {
              if (this.allMeters[j].deviceId == list[i])
                 result.push(this.allMeters[j]);
          }
      }
      return result;
  }

  getMeasuringPoints() : string[]
  {
      let result = [];
      for (let i = 0; i < this.selectedMeters.length; i++)
      {
          let m : Meter = this.selectedMeters[i];
          result.push(m.deviceId);
      }
      return result;
  }

}
