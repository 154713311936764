/*
    Die Klasse Todo beschreibt einen Vorgang der zu erledigen ist.
    TodoGroup enthält eine Liste von Todos die thematisch 
    zusammengehören.
*/

export class Todo
{
    id     : number;             // Id in der DB
    name   : string;             // Name des Todos
    description : string;        // Zusätzliche Beschreibung des Todos
    deadline    : number;        // muss erledigt sein bis (ms seit 1.1.1970)
    emails   : string[];         // Mailadressen für die Benachrichtung
    done     : boolean;          // Erledigt ja / nein
    companyId: number;           // Für welche Firma?
    parent   : number;           // in welche TodoGroup eintragen?
    // Nur für Status lokal, wird auf dem Server nicht genutzt
    changed  : boolean;
}

export class TodoGroup extends Todo
{
    todos: Todo[];    
}

