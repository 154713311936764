import { Component, OnInit } from '@angular/core';
import { ConfigService, ConfigEvent } from 'src/app/services/config.service';
import { Global } from 'src/constants/global';
import { NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateCustomParserFormatter } from 'src/config/datepicker';
import { formatDate, formatCurrency, formatNumber, registerLocaleData } from '@angular/common';
import { Meter } from 'src/app/classes/meter';
import { CostUnit } from 'src/app/classes/costunit';
import { Tariff } from 'src/app/classes/tariff';
import { CostPerCostUnit, CostPerMeter } from 'src/app/classes/cost';
import { NgxSpinnerService } from "ngx-spinner";  
import localeDe from '@angular/common/locales/de';


@Component({
  selector: 'app-cost',
  templateUrl: './cost.component.html',
  styleUrls: ['./cost.component.scss'],
  providers: [
    {provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter}
   ]
})
export class CostComponent implements OnInit {

    company   : any;
    dateUntil : string = "";        // Anzeige: Datum bis
    dateFrom  : string = "";        // Anzeige: Datum von
    dFrom     : number;             // Datum von in ms
    dTo       : number;             // Datum bis in ms
    costStartDate : NgbDate;        // Modellvariable für Datumsauswahl
    currMeterIndex: number = -1;
    unit          : number = 0;
    allMeters     : Meter[] = [];
    allCostUnits  : CostUnit[] = [];
    costs         : CostPerCostUnit = new CostPerCostUnit();
    // Anzeigevariablen
    displayTariff        : string = "";
    displayBaseprice     : string = "";
    displayBasepriceRatio: string = "";     // Gibt den Basispreis für den Zeitraum (Woche, Jahr Monat, Tag)
    displaySumConsumption: string = "";
    displayCapacity      : string = "";
    displaySumCapacity   : string = "";
    displayTotal         : string = "";
    displayPriceAndConsumption : string ="";
    selectedCostUnit     : string = "";

    constructor(private configService : ConfigService,
                private spinnerService: NgxSpinnerService
        ) 
    { 
        registerLocaleData(localeDe);
    }

  ngOnInit(): void 
  {
      if (this.costs.meters == null)
         this.costs.meters = [];
      this.configService.configEvent$.subscribe((data : ConfigEvent) =>
        {
            if (data.name == Global.SIGNAL_REFRESH_METER)
            {
                this.refreshCompany();
            }
            if (data.name == Global.SIGNAL_REFRESH_COST )
            {
               this.refreshCosts();
               this.computeCost();
            }
            if (data.name == Global.SIGNAL_REFRESH_COSTUNIT)
            {
                this.allCostUnits = this.configService.getCostUnits();
                if (this.allCostUnits?.length > 0)
                {
                    this.selectedCostUnit = this.allCostUnits[0].name;
                    this.changeCostUnit(this.allCostUnits[0].name);
                    this.computeCost();
                }
            }
            if (data.name == Global.SIGNAL_REFRESH_COST_CALC)
            {
                if (this.selectedCostUnit != null)
                {
                    this.changeCostUnit(this.selectedCostUnit)
                }
            }
        }); 
      let d = new Date();
      this.company = { name : ""};
      this.costStartDate = new NgbDate(d.getFullYear(), d.getMonth() +1, d.getDate());
      this.dateFrom  = formatDate(new Date(d.getTime()), "dd.MM.yyyy", "en-US");
      this.dFrom     = d.getTime();
      this.dateUntil = formatDate(new Date(d.getTime() + 24* 3600 * 1000), "dd.MM.yyyy", "en-US");
      this.dTo       = d.getTime() + 24* 3600 * 1000;
  }

  refreshCompany() : void
  {
      this.company = JSON.parse(sessionStorage.getItem(Global.SESSION_COMPANY));
      this.allMeters = this.configService.getMetersForCompany();
  
  }
  
  setDate(event) : void
  {
      this.costStartDate = event;
      this.setUnit(this.unit);
  }

  changeDate(event) : void
  {
      let d = new Date(event.year, event.month-1, event.day);
      this.dFrom = d.getTime();
      this.dateFrom =  formatDate(new Date(d.getTime()), "dd.MM.yyyy", "en-US");

      this.dTo = d.getTime() + this.getUnitOffset(event.month, event.year, false);
      this.dateUntil = formatDate(new Date(this.dTo), "dd.MM.yyyy", "en-US");
  }

  getClass(chk : number) : string
  {
      if (this.unit == chk)
         return "btn btn-outline-secondary en-selected";
      else return "btn btn-secondary en-active";
  }

  setUnit(un : number)
  {
      this.unit = un;
      let year  = this.costStartDate.year;
      if (un < 2)      // Täglich oder Wöchentlich
      {
          // Nichts zu ändern
      }
      if (un == 2)      // Monatlich
      {
          let month = this.costStartDate.month;
          this.costStartDate = new NgbDate(year, month, 1);
      }
      if (un == 3)      // Jährlich
      {
          this.costStartDate = new NgbDate(year, 1, 1);
      }
      this.changeDate(this.costStartDate);
      if ((this.selectedCostUnit != null) && (this.selectedCostUnit.length > 0)) 
          this.changeCostUnit(this.selectedCostUnit);
  }

  getUnitOffset(month : number, year : number, inc : boolean) : number
  {
      if (this.unit == 0)
         return 24 * 60 * 60 * 1000;
      if (this.unit == 1)
         return 7 * 24 * 60 * 60 * 1000;
      let days = [ 30, 27, 30, 29, 30, 29, 30, 30, 29, 30, 29, 30];
      let daysYear = 364;
      if ((year % 4 == 0) && (year % 100 != 0) || (year % 400 == 0))
      {
        days[1] = 28;
        daysYear = 365;
      }
      let offs = 0;
      if (inc)
         offs = 24 * 60 * 60 * 1000;
      if (this.unit == 2)
         return days[month -1] * 24 * 60 * 60 *1000 + offs;
      return daysYear * 24 * 60 * 60 * 1000 + offs;
  }

  nextUnit() : void 
  {
      let ts = this.dFrom;
      if (this.unit == 0) 
         ts += 24 * 60 * 60 * 1000;
      if (this.unit == 1)
         ts += 7 * 24 * 60 * 60 * 1000;
      if (this.unit == 2)
         ts += this.getDaysInMonth(this.costStartDate.month+1, this.costStartDate.year) * 24*60*60*1000;
      if (this.unit == 3)
         ts += this.getDaysInYear(this.costStartDate.year) * 24*60*60*1000 + 60*60*1000;
      if (this.isDST(this.dFrom))
         ts += 60 * 60 * 1000;
      let d = new Date(ts);
      this.costStartDate.year  = d.getFullYear();
      this.costStartDate.month = d.getMonth() +1;
      this.costStartDate.day   = d.getDate();
      this.setDate(this.costStartDate);
    //   this.configService.loadCostDataForMeter(this.costs.meterId, 
    //         this.costs.measurand,
    //         this.dFrom, this.dTo);
  }

  prevUnit() : void
  {
      let ts = this.dFrom;
      if (this.unit == 0) 
         ts -= 24 * 60 * 60 * 1000;
      if (this.unit == 1)
         ts -= 7 * 24 * 60 * 60 * 1000;
      if (this.unit == 2)
         ts -= this.getDaysInMonth(this.costStartDate.month, this.costStartDate.year) * 24*60*60*1000;
      if (this.unit == 3)
         ts -= this.getDaysInYear(this.costStartDate.year-1) * 24*60*60*1000;
      if (this.isDST(this.dFrom))
         ts += 60 * 60 * 1000;
      let d = new Date(ts);
      this.costStartDate.year  = d.getFullYear();
      this.costStartDate.month = d.getMonth() +1;
      this.costStartDate.day   = d.getDate();
      this.setDate(this.costStartDate);
    //   this.configService.loadCostDataForMeter(this.costs.meterId, 
    //         this.costs.measurand,
    //         this.dFrom, this.dTo);

  }

  refreshCosts() : void
  {
      this.costs = this.configService.getCosts();
  }

  getDaysInMonth(month : number, year : number) : number
  {
      // 0 ist der letzte Tag des Monates
      // Monat von 1 - 12, Date will aber 0-11
      // Hinweis: 0 als Tag, gibt den letzten Tag 
      // des Vormonates zurück!
      return new Date(year, month-1, 0).getDate();
  }

  getDaysInYear(year : number) : number
  {
      let result = 365;
      if (this.getDaysInMonth(3, year) == 29)
         result++;
      return result;
  }

  resetDisplay() : void
  {
      this.displayPriceAndConsumption = "";
      this.displaySumConsumption      = "";
      this.displayTariff    = "";
      this.displayBaseprice = "";
      this.displayCapacity  = "";
  }

  getMeterName(id : string) : string
  {
      for (let i = 0; i < this.allMeters.length; i++)
      {
          if (this.allMeters[i].id == id)
             return this.allMeters[i].values.user0;
      }
      return id;
  }

  getMeterUnit(id : string) : string
  {
      for (let i = 0; i < this.allMeters.length; i++)
      {
          if (this.allMeters[i].id == id)
             return this.allMeters[i].values.unit0;
      }
      return "";
  }

  getConsumptionFormatted(cpm : CostPerMeter) : string
  {
      let result : number = 0;
      for (let i = 0; i < cpm.measurements.length; i++)
      {
          if (!isNaN(parseFloat(cpm.measurements[i].value)))
             result += parseFloat(cpm.measurements[i].value);
      }
      return formatNumber(result, "de_DE", "1.2-2") + " " + this.getMeterUnit(cpm.meterId);
  }

   computeCost() : void
   {
      this.resetDisplay();
      if (this.costs?.meters == null)
      {
          this.spinnerService.hide();
          return;
      }
      // 1. Für alle Kostenstellen
      let sumCostUnit : number = 0;
      for (let i = 0; i < this.costs.meters.length; i++)
      {
          let cpm : CostPerMeter = this.costs.meters[i];
          sumCostUnit += this.computeCostPerMeter(cpm);
      }
      this.displayTotal = formatNumber(sumCostUnit, "de-DE", "1.2-2")
      this.spinnerService.hide();
    } 

    getDisplayTotal() : string
    {
        return this.displayTotal;
    }

    sortTariffByTimestamp(t1 : Tariff, t2 : Tariff) : number
    {
        if (t1.start < t2.start)
            return 1;
        else
        if (t1.start > t2.start)
            return -1;
        else return 0;
    }

    computeCostPerMeter(cpm : CostPerMeter) : number
    {
        let cost : number = 0;
        // Tarife nach Zeit sortieren und dann die Messpunkte durchgehen
        cpm.tariff.sort(this.sortTariffByTimestamp)
        for (let i = 0; i < cpm.measurements.length; i++)
        {
            let consumption : number = parseFloat(cpm.measurements[i].value);
            if (isNaN(consumption))
                continue;
            let ts          : number = cpm.measurements[i].timestamp;
            let tariff      : Tariff = this.getTariffForTimestamp(cpm.tariff, ts);
            if (tariff == null)
               continue;
            cost += consumption * tariff.priceUnit;
        }
        return cost;
    }

    getCostPerMeterFormatted(cpm : CostPerMeter) : string
    {
        return formatNumber(this.computeCostPerMeter(cpm), "de-DE", "1.2-2");
    }

    getTariffForTimestamp(tariffs : Tariff[], ts : number) : Tariff
    {
        let offset = 60 * 60 * 1000;
        if (this.isDST(ts))
           offset = 2 * offset;
        for (let i = 0; i < tariffs.length; i++)
        {
            if (tariffs[i].start - offset <= ts)
                return tariffs[i];
        }
        return null;
    }


    /*
      Berechnet den Basispreis bezogen auf den aktuell
      gewählten Zeitraum (Jahr, Monat, Woche, Tag)
      @param base : Tarifpreis pro Jahr
    */
    getBasePriceRatio(base: number) : number
    {
        if (this.unit == 0) // Tag
        {
            return base / this.getDaysInYear(this.costStartDate.year);
        }
        if (this.unit == 1) // Woche
        {
            let daily = base / this.getDaysInYear(this.costStartDate.year);
            return daily * 7;
        }
        if (this.unit == 2) // Monat
        {
            return base / 12;
        }
        // Bleibt nur das Jahr
        return base;
    }

    getDayOfYear(date : Date) : number
    {
        return (Date.UTC(date.getFullYear(), 
                         date.getMonth(), 
                         date.getDate()) - 
                         Date.UTC(date.getFullYear(), 0, 0)) / 24 / 60 / 60 / 1000;
    }

    /**
     * Der Basispreis des Tarifs bezogen aufs Jahr
     * @param tariff
     */
  getBasePrice(tariff : Tariff) : number
  {
      if (tariff == null)
         return 0;
      let price = tariff.priceBase;
      if (tariff.period == 1)
          price = price * 12;
      return price;
  }

  isDST(ts : number) 
  {
      let d = new Date(ts);
      let jan = new Date(d.getFullYear(), 0, 1).getTimezoneOffset();
      let jul = new Date(d.getFullYear(), 6, 1).getTimezoneOffset();
      return Math.max(jan, jul) != d.getTimezoneOffset(); 
  }

  changeCostUnit(event : any) : void
  {
      let cu : CostUnit = null;
      // Finde Kostenstelle
      this.spinnerService.show();
      for (let i = 0; i < this.allCostUnits.length; i++) 
      {
          if (this.allCostUnits[i].name == event)
          {
              // Kostenstelle gefunden
              cu = this.allCostUnits[i];
              this.configService.loadCostDataForCostUnit(cu, "ELECTRICAL_CONSUMPTION", this.dFrom, this.dTo);
              break;
          }
      }
      this.spinnerService.hide();
  }
}
