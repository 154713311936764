<div class="row">
    <div class="col-md-12">
        <div class="card">
            <div class="card-body">
                <h2 class="titel">Alle Alarme</h2>
                <div>
                    <table class="table table-striped">
                        <thead>
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Typ</th>
                            <th scope="col">Geändert am</th>
                            <th scope="col">Aktiv</th>
                            <th width="120px" *ngIf="isModerator()">Aktion</th>
                        </tr>
                        </thead>
                        <tbody>

                        <tr *ngFor="let alarm of allAlarms">
                            <td style="width: 200px">{{ alarm.title }}</td>
                            <td style="width: 350px">{{ alarm.alarmType }}</td>
                            <td style="width: 150px">{{ alarm.startDate | date: 'dd.MM.yyyy'}}</td>
                            <td>
                                <div style="padding-bottom: 10px;">
                                    <i *ngIf="alarm.active" class="fa fa-check-square" aria-hidden="true"></i> 
                                    <i *ngIf="!alarm.active" class="fa fa-square-o" aria-hidden="true"></i>
                                </div>
                            </td>
                            <td *ngIf="isModerator()">
                                <button role="link"
                                        (click)="openModal(alarm.id)"
                                        data-toggle="modal" data-target="#changeAlarmModal"
                                        data-whatever="alarm"
                                        class="btn btn-primary btn-sm en-btn">
                                    <i class="fa fa-pencil" aria-hidden="true"></i>
                                </button>
                                &#160;
                                <button role="button"
                                        (click)="removeAlarm(alarm.id)"
                                        class="btn btn-primary btn-sm en-btn">
                                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <div class="d-flex justify-content-center" *ngIf="allAlarms?.length > tableSize">
                        <pagination-controls class="en-pagination"
                                             responsive="true"
                                             previousLabel=""
                                             nextLabel=""
                                             (pageChange)="onTableDataChange($event)">
                        </pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Modales Fenster -->
<div class="modal fade" id="changeAlarmModal" tabindex="-1" role="dialog" aria-hidden="true" *ngIf="alarmObj!=null">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title titel">Führen Sie die Änderungen durch</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form>
                    <div class="row" *ngIf="errorMessage?.length > 0">
                        <div [hidden]="errorMessage.length == 0"
                            class="col-md-12 alert alert-danger">
                            {{ errorMessage }}
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-3">
                                    <label>Name:</label>
                                </div>
                                <div class="col-md-9">
                                    <input type="text" [(ngModel)]="description"
                                           name = "name"
                                           *ngIf="alarmObj.alarmType != 'Vorgabekontrolle'"
                                           style="width: 100%">
                                    <input type="text" [(ngModel)]="alarmTitle"
                                           name = "name"
                                           readonly
                                           *ngIf="alarmObj.alarmType == 'Vorgabekontrolle'"
                                           style="width: 100%">
                                </div>
                            </div>
                        </div>
                    </div>
<!-- Plausibilität -->                    
                    <div *ngIf="alarmObj.alarmType =='Plausibilitätskontrolle'"
                        class="row">
                        <div class="col-md-12">
                            <div class="row" >
                                <div class="col-md-3">
                                    <label>1. Messpunkt:</label>
                                </div>
                                <div class="col-md-9">
                                    <ng-select [items]="allMeters" id="meter"
                                        bindLabel="values.user0"
                                        name="meters"
                                        [clearable]="true"
                                        [(ngModel)]="selectedMeter1"
                                        [ngModelOptions]="{standalone: true}">
                                        <ng-template ng-option-tmp ng-label-tmp let-item="item">
                                            <label class="icon-width">
                                                <i *ngIf="item.values.obisid0 == '1-0:1.8.0*255'"
                                                    class="fa fa-bolt icon-width"></i>
                                                <i *ngIf="item.values.obisid0 == '8-0:1.0.0*255'"
                                                    class="fa fa-tint icon-width"></i>
                                                <i *ngIf="item.values.obisid0 == '6-0:1.0.0*255'"
                                                    class="fa fa-fire icon-width"></i> <!-- red -->
                                                <i *ngIf="item.values.obisid0 == '7-0:3.0.0*255'"
                                                    class="fa fa-fire icon-width"></i> <!-- blue -->
                                                <i *ngIf="item.values.obisid0 == '6-0:2.0.0*255'"
                                                    class="fa fa-tint icon-width"></i>
                                                <i *ngIf="item.values.obisid0 == '8-0:1.0.0*255'"
                                                    class="fa fa-plug icon-width"></i>
                                                <i *ngIf="item.values.obisid0 == null"
                                                    class="fa fa-question"></i>
                                            </label>
                                            {{ item.values.user0 }}       
                                        </ng-template>                                 
                                    </ng-select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="alarmObj.alarmType =='Plausibilitätskontrolle'"
                        class="row">
                        <div class="col-md-12">
                            <div class="row" >
                                <div class="col-md-3">
                                    <label>2. Messpunkt:</label>
                                </div>
                                <div class="col-md-9">
                                    <ng-select [items]="allMeters" id="meter"
                                        bindLabel="values.user0"
                                        name="meters"
                                        [clearable]="true"
                                        [(ngModel)]="selectedMeter2"
                                        [ngModelOptions]="{standalone: true}">
                                        <ng-template ng-option-tmp ng-label-tmp let-item="item">
                                            <label class="icon-width">
                                                <i *ngIf="item.values.obisid0 == '1-0:1.8.0*255'"
                                                    class="fa fa-bolt icon-width"></i>
                                                <i *ngIf="item.values.obisid0 == '8-0:1.0.0*255'"
                                                    class="fa fa-tint icon-width"></i>
                                                <i *ngIf="item.values.obisid0 == '6-0:1.0.0*255'"
                                                    class="fa fa-fire icon-width"></i> <!-- red -->
                                                <i *ngIf="item.values.obisid0 == '7-0:3.0.0*255'"
                                                    class="fa fa-fire icon-width"></i> <!-- blue -->
                                                <i *ngIf="item.values.obisid0 == '6-0:2.0.0*255'"
                                                    class="fa fa-tint icon-width"></i>
                                                <i *ngIf="item.values.obisid0 == '8-0:1.0.0*255'"
                                                    class="fa fa-plug icon-width"></i>
                                                <i *ngIf="item.values.obisid0 == null"
                                                    class="fa fa-question"></i>
                                            </label>
                                            {{ item.values.user0 }}       
                                        </ng-template>                                 
                                    </ng-select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="alarmObj.alarmType =='Plausibilitätskontrolle'"
                        class="row">
                        <div class="col-md-12">
                            <div class="row" >
                                <div class="col-md-3">
                                    <label>Differenz %:</label>
                                </div>
                                <div class="col-md-9">
                                    <input type="text"
                                        [(ngModel)]="diff"
                                        [imask]="this.percMask" 
                                        [ngModelOptions]="{standalone: true}"
                                        style="width: 100%">                                    
                                </div>
                            </div>
                        </div>
                    </div>
<!-- Ausreisser -->                    
                    <div class="row" *ngIf="alarmObj.alarmType == 'Ausreisserkontrolle'">
                        <div class="col-md-12">
                            <div class="row" >
                                <div class="col-md-3">
                                    <label>Absolut:
                                    </label>
                                </div>
                                <div class="col-md-9">
                                        <input type="text"
                                            [(ngModel)]="absolute"
                                            [imask]="this.diffMask" 
                                            [ngModelOptions]="{standalone: true}"
                                            style="width: 100%">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="alarmObj.alarmType == 'Ausreisserkontrolle'">
                        <div class="col-md-12">
                            <div class="row" >
                                <div class="col-md-3">
                                    <label>Median:
                                    </label>
                                </div>
                                <div class="col-md-9">
                                    <div class="input-group">
                                        <input type="checkbox"
                                           [(ngModel)]="medianActive"
                                           [ngModelOptions]="{standalone: true}"
                                           class="input-group-append margin-top" >
                                           <span class="input-group-append ">&nbsp; aktivieren</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="alarmObj.alarmType == 'Ausreisserkontrolle'">
                        <div class="col-md-12">
                            <div class="row" >
                                <div class="col-md-3">
                                    <label>Prozentual:
                                    </label>
                                </div>
                                <div class="col-md-9">
                                    <input type="text"
                                           [(ngModel)]="perc"
                                           [imask]="this.diffMask" 
                                           [ngModelOptions]="{standalone: true}"
                                            style="width: 100%">
                                </div>

                            </div>
                        </div>
                    </div>
<!-- Budget -->
<div class="row" *ngIf="alarmObj.alarmType == 'Budgetkontrolle'">
    <div class="col-md-12">
        <div class="row" >
            <div class="col-md-3">
                <label>Budget:
                </label>
            </div>
            <div class="col-md-9">
                <div class="input-group-append">
                    <input type="text" class="input-group"
                        [(ngModel)]="budget"
                        [imask]="this.diffMask" 
                        [ngModelOptions]="{standalone: true}"
                        style="width: 100%">
                        &nbsp;
                        <div class="input-group-append">
                            <select style="width: 150px" id="budgetbase"
                                [(ngModel)]="budgetBase" name="budgetbase"
                                    #name="ngModel"
                                    [ngModelOptions]="{standalone: true}">
                                    >
                                <option value="0">Verbrauch</option>
                                <option value="1">Kosten</option>
                            </select>
                        </div>
                </div>            </div>
        </div>
        <div class="row">

        </div>
    </div>
</div>
<div class="row" *ngIf="alarmObj.alarmType == 'Budgetkontrolle'">
    <div class="col-md-12">
        <div class="row" >
            <div class="col-md-3">
                <label>Kontrollzeitraum:
                </label>
            </div>
            <div class="col-md-9">
                <ng-select [items]="recurrenceArray"
                    id="repeat"
                    [multiple]="false"
                    [clearable]="false"
                    [(ngModel)]="selectedPeriod"
                    [ngModelOptions]="{standalone: true}">
                </ng-select>                    
            </div>

        </div>
        <div class="row">

        </div>
    </div>
</div>
<div class="row" *ngIf="alarmObj.alarmType == 'Budgetkontrolle'">
    <div class="col-md-12">
        <div class="row" >
            <div class="col-md-3">
                <label>Messpunkt:
                </label>
            </div>
            <div class="col-md-9">
                <ng-select [items]="allVmps" id="meter"
                            bindLabel="values.user0"
                            name="meters"
                            [clearable]="true"
                            [(ngModel)]="selectedMeter"
                            [ngModelOptions]="{standalone: true}">
                            <ng-template ng-option-tmp ng-label-tmp let-item="item">
                            <label class="icon-width">
                                <i *ngIf="item.values.obisid0 == '1-0:1.8.0*255'"
                                    class="fa fa-bolt icon-width"></i>
                                <i *ngIf="item.values.obisid0 == '8-0:1.0.0*255'"
                                    class="fa fa-tin icon-width"></i>
                                <i *ngIf="item.values.obisid0 == '6-0:1.0.0*255'"
                                    class="fa fa-fire icon-width"></i> <!-- red -->
                                <i *ngIf="item.values.obisid0 == '7-0:3.0.0*255'"
                                    class="fa fa-fire icon-width"></i> <!-- blue -->
                                <i *ngIf="item.values.obisid0 == '6-0:2.0.0*255'"
                                    class="fa fa-tint icon-width"></i>
                                <i *ngIf="item.values.obisid0 == '8-0:1.0.0*255'"
                                    class="fa fa-plug icon-width"></i>
                                <i *ngIf="item.values.obisid0 == null"
                                    class="fa fa-question"></i>
                                </label>
                            {{ item.values.user0 }}
                        </ng-template>                                                             
                </ng-select>
 

            </div>

        </div>
        <div class="row">

        </div>
    </div>
</div>

<!-- Min/Max -->
                    <div class="row" *ngIf="alarmObj.alarmType == 'Schwellwertkontrolle' ||
                                            alarmObj.alarmType == 'Schwellwert mit Bericht'">
                        <div class="col-md-12">
                            <div class="row" >
                                <div class="col-md-3">
                                    <label>Minimum:
                                    </label>
                                </div>
                                <div class="col-md-9">
                                    <input type="text"
                                           [(ngModel)]="minValue"
                                           [imask]="this.diffMask" 
                                           [ngModelOptions]="{standalone: true}"
                                            style="width: 100%">
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="alarmObj.alarmType == 'Schwellwertkontrolle' ||
                                alarmObj.alarmType == 'Schwellwert mit Bericht'">
                        <div class="col-md-12">
                            <div class="row" >
                                <div class="col-md-3">
                                    <label>Maximum:
                                    </label>
                                </div>
                                <div class="col-md-9">
                                    <input type="text"
                                           [(ngModel)]="maxValue"
                                           [imask]="this.diffMask" 
                                           [ngModelOptions]="{standalone: true}"
                                            style="width: 100%">
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="alarmObj.alarmType == 'Schwellwert mit Bericht' ||
                                alarmObj.alarmType == 'Schwellwert mit Bericht'">
                        <div class="col-md-12">
                            <div class="row" >
                                <div class="col-md-3">
                                    <label>Erstelle Bericht:
                                    </label>
                                </div>
                                <div class="col-md-9">
                                    <ng-select [items]="allReports"
                                        bindLabel="name"
                                        [multiple]="false"
                                        [clearable]="false"
                                        [(ngModel)]="selectedReport"
                                        [ngModelOptions]="{standalone: true}">
                                    </ng-select>
                                </div>

                            </div>
                        </div>
                    </div>
<!-- Max Toleranz-->
                    <div class="row" *ngIf="alarmObj.alarmType == 'Messwertkontrolle'">
                        <div class="col-md-12">
                            <div class="row form-group">
                                <div class="col-md-3">
                                    <label>Max. Toleranz:
                                    </label>
                                </div>
                                <div class="col-md-9">
                                    <input type="text"
                                           name="desc"                                        
                                           size="40"
                                           [imask]="testMask" 
                                           style="width: 100%"
                                           [(ngModel)]="maxHours">
                                </div>

                            </div>
                            
                        </div>
                    </div>

                    <!-- Messpunkte und EMails -->
                    <div class="row">
                        <div class="col-md-12">
                            <div class="row" *ngIf="(alarmObj.alarmType != 'Vorgabekontrolle') && (alarmObj.alarmType !='Plausibilitätskontrolle') && (alarmObj.alarmType != 'Budgetkontrolle')">
                                <div class="col-md-3">
                                    <label style="width: 150px"for="meter">Messpunkte:</label>
                                </div>
                                <div class="col-md-7">
                                    <ng-select [items]="allMeters" id="meter"
                                        bindLabel="values.user0"
                                        name="meters"
                                        #ngSelect
                                        [multiple]="true"
                                        [clearable]="true"
                                        [(ngModel)]="selectedMeters"
                                        [ngModelOptions]="{standalone: true}">
                                        <ng-template ng-option-tmp ng-label-tmp let-item="item">
                                            <label class="icon-width">
                                                <i *ngIf="item.values.obisid0 == '1-0:1.8.0*255'"
                                                    class="fa fa-bolt icon-width"></i>
                                                <i *ngIf="item.values.obisid0 == '8-0:1.0.0*255'"
                                                    class="fa fa-tint icon-width"></i>
                                                <i *ngIf="item.values.obisid0 == '6-0:1.0.0*255'"
                                                    class="fa fa-fire icon-width"></i> <!-- red -->
                                                <i *ngIf="item.values.obisid0 == '7-0:3.0.0*255'"
                                                    class="fa fa-fire icon-width"></i> <!-- blue -->
                                                <i *ngIf="item.values.obisid0 == '6-0:2.0.0*255'"
                                                    class="fa fa-tint icon-width"></i>
                                                <i *ngIf="item.values.obisid0 == '8-0:1.0.0*255'"
                                                    class="fa fa-plug icon-width"></i>
                                                <i *ngIf="item.values.obisid0 == '8-0:1.0.0*255'"
                                                    class="fa fa-question icon-width"></i>        
                                            </label>
                                            {{ item.values.user0 }}       
                                        </ng-template>                                 
                                    </ng-select>
                                </div>
                                <div class="col-md-2">
                                    <button type="button" class="btn btn-primary"
                                        (click)="addAllMeters()">
                                        Alle 
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>   
                    <div class="row" *ngIf="(alarmObj.alarmType != 'Vorgabekontrolle')">
                        <div class="col-md-12">
                            <div class="row" >
                                <div class="col-md-3">
                                    <label>E-Mail senden:
                                    </label>
                                </div>
                                <div class="col-md-9">
                                    <ng-select [items]="mailLevelsArray"
                                               name="mailLevel"
                                               [clearable]="false"
                                               [multiple]="false"
                                               [(ngModel)]="mailLevel"
                                               [ngModelOptions]="{standalone: true}"
                                    ></ng-select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="row pad-top">
                                <div class="col-md-3">
                                    <label style="width: 150px"for="mails">E-Mails :</label>
                                </div>
                                <div class="col-md-9">
                                    <ng-select [items]="allMails" id="mails"
                                           bindLabel="name"
                                           name="mails"
                                           [multiple]="true"
                                           [clearable]="true"
                                           [(ngModel)]="selectedMails"
                                           [ngModelOptions]="{standalone: true}">
                                    </ng-select>
                                </div>
                            </div>                                
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="row pad-top">
                                <div class="col-md-3">
                                    <label>Aktiv:</label>
                                </div>
                                <div class="col-md-9">
                                    <div style="padding-bottom: 10px;">
                                        <i *ngIf="active" 
                                            (click)="active = !active"
                                            class="fa fa-check-square" 
                                            aria-hidden="true"></i> 
                                        <i *ngIf="!active" 
                                            (click)="active = !active"
                                            class="fa fa-square-o" 
                                            aria-hidden="true"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer center">
                <button type="button" (click)="changeAlarm()"
                        class="submit-button"
                >Ändern</button>
            </div>
        </div>
    </div>
</div>

