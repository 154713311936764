<div class="container ng-scope">
  <div class="account-logo">
    <img src="assets/logo.png" alt="" align="middle">
  </div>
  <div class="account">
    <div class="login-form">
      <h2 class="form-signin-heading">Password setzen</h2>
      <div class="card">
        <div class="card-body">
          <form class="form-group">
            <div class="alert alert-warning" *ngIf='invalidPassword'>{{errorMessage}}</div>
            <div class="alert alert-success" *ngIf='passwordSuccess'>{{successMessage}}</div>
            <p class="hint">Setzen Sie ein neues Passwort.</p>
            <div class="form-group">
              <label for="password" class="sr-only">Passwort</label>

              <input type="password" id="password" class="form-control" placeholder="Neues Passwort eingeben" required [(ngModel)]="password" [ngModelOptions]="{standalone: true}">
            </div>
            <div class="form-group">
              <label for="password-check" class="sr-only">Passwort wiederholen</label>

              <input type="password" id="password-check" class="form-control" placeholder="Neues Passwort wiederholen" required [(ngModel)]="passwordCheck" [ngModelOptions]="{standalone: true}">
            </div>
            <p *ngIf="password != passwordCheck && passwordCheck.length > 0">
        Ihre Eingaben stimmen nicht überein!
    </p>
            <hr>

            <button class="btn btn-block newbtn" type="submit" (click)=resetPassword()>
              OK
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
