import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginPageComponent } from './components/login-page/login-page.component';
import { LoginComponent } from './components/login-page/login/login.component';
import { DashboardComponent } from './components/main-page/dashboard/dashboard.component';
import { MainPageComponent } from './components/main-page/main-page.component';
import { DatenschutzComponent } from './components/login-page/datenschutz/datenschutz.component'
import { PasswordLostComponent } from './components/login-page/password-lost/password-lost.component'
import { PasswordResetComponent } from './components/login-page/password-reset/password-reset.component'
import { ActivationComponent } from './components/login-page/activation/activation.component'

const routes: Routes = [
  {path: 'login', component: LoginPageComponent},
  {path: '', component: LoginPageComponent},
  {path: 'home', component: MainPageComponent},
  {path: 'logout', component: LoginPageComponent},
  {path: 'statement', component: DatenschutzComponent},

  {path: 'account/password/lost/:id/:transactionCode', component: PasswordResetComponent },
  //{path: '**', component: DashboardComponent},


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
