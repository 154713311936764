import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import * as fileSaver from 'file-saver';
import { Meter } from 'src/app/classes/meter';
import { ConfigService } from 'src/app/services/config.service';
import { PopupService } from 'src/app/services/popup.service';
import { Global } from 'src/constants/global';
import { NgxSpinnerService } from "ngx-spinner";  
import { ManualValue } from 'src/app/classes/manualvalue';
import {NgbDate, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import localeDe from '@angular/common/locales/de';
import { formatNumber, registerLocaleData } from '@angular/common';
import { NgbDateCustomParserFormatter } from 'src/config/datepicker';
import { formatDate } from '@angular/common';
import { User } from 'src/app/classes/user';
import { ReportEvent, ReportService } from 'src/app/services/report.service';

declare var $ : any;



@Component({
  selector: 'app-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.scss'],
  providers: [
    {provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter},
  ]

})

export class ExportComponent implements OnInit 
{

    popupService: PopupService;
    page     : number = 0;      // Seite beim Import
    fileName : string = "";
    content  : string = "";
    colCount : number;
    colCollection = [];
    delimeter : string = ",";
    commentLines : number = 0;
    selectedColFormats : string[] = ['',''];
    inputComplete : boolean = false;   // Seite 2 Buttonstatus
    availableMeters : Meter[];
    allMeters       : Meter[];   // Inkl. VMPS
    selectedMeters  : any = [];
    selectedMetersDB: Meter[ ] = [];
    selectedMeter   : any = [];  // Manueller Import: Ausgewählter Messpunkt
    selectedMails   : any[] = [];
    hasMP           : boolean = false;
    errorMessage    : string = "";
    formatDate      : string = "";
    formatTime      : string = "";
    posId           : number = -1;
    posVal          : number = -1;
    posTime         : number = -1;
    posDate         : number = -1;
    quoted          : boolean = false;
    restart         : boolean = false;
    numbers     : number[] = [1,2,3,4,5,6];
    editDate    : NgbDate = null;
    allValues   : ManualValue[] = [];
    allMails    : any[];
    listHour    : string[] = ["AA", "Bb"];
    listMinute  : string[];
    hour        : string = "08";
    minute      : string = "00";
    value       : any = null;
    editIndex   : number = null;
    // Fürs Blättern
    tabPage  : number = 1;
    tableSize: number = 3;
    // Für die DB-Import
    allTables : string[] = [];
    allCols   : any[] = [];
    selectedTable: string;
    colTime   : any;
    colMP     : any;
    colVal    : any;
    colRec    : any;
    allRecs   : any[] =[];
    mpType    : string = 'Messpunkt auswählen';
    allTypes  : string[];
    allTimes  : string[];
    colTimeFormat : string = "Timestamp";
    // Export in Datenbank
    dbOrigin : string = "0";
    export   : boolean = false;

    constructor(private http: HttpClient,
        private configService: ConfigService,
        private reportService: ReportService,
        private spinnerService: NgxSpinnerService,
        popup : PopupService) 
    { 
        registerLocaleData(localeDe);
        this.popupService = popup;
        this.allTypes = [ "Messpunkt-ID", "Messpunkt-Referenz", "Messpunkt auswählen"];
        this.allTimes = [ "Timestamp", "Text (TT.MM.JJJJ hh:mm)", "Text (TT.MM.JJJJ hh:mm:ss)", 
                          "Text (JJJJ-MM-TT hh:mm)"]
        this.listHour = [ "00","01","02","03","04","05","06","07",
                          "08","09","10","11","12","13","14","15",
                          "16","17","18","19","20","21","22","23"];
        this.listMinute=[ "00","05","10","15","20","25","30","35",
                          "40","45","50","55"];
        let d = new Date();
        this.editDate = new NgbDate(d.getFullYear(), d.getMonth()+1, d.getDate());
        configService.configEvent$.subscribe(data => 
            {
                if (data.name == Global.SIGNAL_DELETE_LAST_INPUT)
                    this.allValues.shift();
                if (data.name == Global.SIGNAL_REFRESH_METER)
                {
                    this.availableMeters = configService.getMetersForCompany();
                    this.allMeters       = configService.vmpAndMetersForCurrentCompany;
                }
                if (data.name == Global.SIGNAL_REFRESH_TABLES)
                   this.allTables = this.configService.allTables;
                if (data.name == Global.SIGNAL_REFRESH_COLUMNS)
                   this.allCols   = this.configService.allCols;
                if (data.name == Global.SIGNAL_REFRESH_COMPANY)
                    this.allMails  = this.configService.getUsersMailArray();
            });
        this.allRecs = [ "Einmalig", "jede Stunde", "alle 2 Stunden", "alle 4 Stunden", "einmal täglich"];
        this.colRec  = this.allRecs[0];
    }
 
    public testMask = {
        mask: Number,
        scale: 2,
        signed: false,
        padFractionalZeros: true,
        normalizeZeros: false,
        min: 0
    };

    ngOnInit(): void 
    {
        let d = new Date();
        this.editDate = new NgbDate(d.getFullYear(), d.getMonth()+1, d.getDate());

        this.allMails = [];

    }

    downloadList(asXML : boolean) : any
    {
      let company = JSON.parse(sessionStorage.getItem(Global.SESSION_COMPANY));
      let url = Global.apiUrl + 'rev2/download/datalist/';
      if (asXML)
         url += "xml/"
      else url += "json/";
      url += company.id;

      this.http.get(url, {responseType: 'blob'} ).subscribe(
          (data) => 
          {
              let type : string = (asXML ? "xml" : "json");
              let blob:any = new Blob([data], { type: 'application/'+type });
              fileSaver.saveAs(blob, "datalist." + type);
          },
          () => 
          {
              this.popupService.addPopupError("Fehler", "Die Datei konnte nicht erstellt werden!");          
          }
      );
    }

    changeListener(event) : void
    {
        let file      = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
        this.fileName = file?.name;
        let reader    = new FileReader();
        reader.onload = this.handleFileRead.bind(this);
        reader.readAsDataURL(file);        
    }

    openBrowser() : void
    {
        this.fileName = "";
        let el = document.getElementById("fileButton");
        el.click();
    }

    isInputComplete() : boolean
    {
        // 1. Wir brauchen mindestens
        //    Uhrzeit, Datum und Wert
        let datum = false;
        let zeit  = false;
        let val   = false;
        this.hasMP = false;
        this.posId = -1;
        this.posVal  = -1;
        this.posTime = -1;
        this.posDate = -1;
        for (let i = 0; i < this.getColumnCount(); i++)
        {
            let select = document.getElementById('sel-col' + i);        
            if (select == null) 
                continue;
            for (let si = 0; si < select.childNodes.length; si++)
            {
                let opt = select.childNodes[si];
                if (opt['selected'] == true)
                {
                    let value = opt['value'];
                    if (value.indexOf("HH:mm") >= 0)
                    {
                       zeit  = true;
                       if (value.indexOf("yyyy") >= 0)
                          this.posDate = i;
                       else this.posTime = i;
                    }
                    if (value.indexOf("yyyy") >= 0) 
                    {
                        datum = true;
                        if (value.indexOf("HH:mm") >= 0)
                            this.posTime = i;
                        else this.posDate = i;

                    }
                    if (value.indexOf("value") >= 0)
                    {
                       val   = true;
                       this.posVal = i;
                    }
                    if (value.indexOf("id") >= 0)
                    {
                       this.hasMP = true;
                       this.posId = i;
                    }
                    break;
                }
            }
                 
        }
        return val && zeit && datum;
    }

    isMPColDefined() : boolean
    {
        return false;
    }

    showPage(nr : number) : void
    {
        this.page = nr;
        if (this.page == 1)
        {
            this.colCount = this.getColumnCount();
            this.colCollection = Array(this.colCount);
            this.selectedColFormats = Array(this.colCount);
            this.inputComplete = false;
        }
        if (this.page == 2)
        {
            this.availableMeters = this.configService.getMetersForCompany();
        }
        if (this.page == 3)
        {
            // Importieren
            this.importData();
        }
    }

    handleFileRead(obj)
    {
        let reader   = obj.target;
        let data     = reader.result;
        // Typangabe eliminieren
        if (data.indexOf(',')>0)
            data = data.split(',')[1];
        this.content = atob(data);
    }
    
    getColumnCount() : number
    {
        // 1. die Kommentarzeilen überspringen
        if (this.content.length == 0)
            return 0;
        let lines = this.content.split("\n");
        if (this.commentLines > lines.length)
        {
            this.commentLines = lines.length -1;
            return 1;
        }
        let firstLine = lines[this.commentLines];
        let result = 0;
        for (let i = 0; i < firstLine.length; i++)
        {
            if (firstLine.charAt(i) == this.delimeter)
               result++;
        }
        return result+1;
    }

    changeDelimeter() : void
    {
        this.colCount = this.getColumnCount();
        this.colCollection = Array(this.colCount);
        this.selectedColFormats = Array(this.colCount);

    }

    updateInputComplete(event, ind : number) : void
    {
        let value = event.target.value;
        if (value == null)
           return;
        if ((value.indexOf('yyyy') >= 0) && (value.indexOf("mm") > 0))
        {
            this.formatDate = value;
            this.formatTime = "";
        }
        else
        if (value.indexOf('yyyy') >= 0)
        {
            this.formatDate = value;
        }
        else
        if (value.indexOf("mm") > 0)
        {
            this.formatTime = value;
        }
        this.inputComplete = this.isInputComplete();
    }

    newImport() : void
    {
        this.page     = 0;
        this.fileName = "";
        this.selectedMeters = [];
        this.posId    = -1;
        this.restart  = false;
    }

    importData() : void
    {
        let company = JSON.parse(sessionStorage.getItem(Global.SESSION_COMPANY));
        let token = sessionStorage.getItem(Global.ATTRIBUTE_USER_TOKEN);
        let data =
        {
            companyId: company.id,
            commentLines: this.commentLines,
            posVal:  this.posVal,
            posTime: this.posTime,
            posDate: this.posDate ,
            posId:   this.posId,
            id:      this.selectedMeters,
            formatDate: this.formatDate,
            formatTime: this.formatTime,
            quoted: this.quoted,
            separator: this.delimeter,
            data: btoa(this.content),
            headers: { 'Authorization': 'Bearer ' + token }
        }
        this.spinnerService.show();
        this.http.post(Global.apiUrl + "rev2/import", data)
            .subscribe(response => 
            {
                this.restart = true;
                this.spinnerService.hide();
            },
            (response) => 
            {
                this.restart = true;
                this.spinnerService.hide();
            }
            );
    }


    downloadListasCSV() : any
    {
        let company = JSON.parse(sessionStorage.getItem(Global.SESSION_COMPANY));
        let url = Global.apiUrl + 'rev2/download/datalist/csv/';
        url += company.id;

        this.http.get(url, {responseType: 'blob'}).subscribe(
            (data) =>
            {
                let blob:any = new Blob([data], { type: 'application/csv' });
                fileSaver.saveAs(blob,"datalist.csv");
            },
            () =>
            {
                this.popupService.addPopupError("Fehler", "Die Datei konnte nicht erstellt werden!");
            }
        );
    }

    displayFirstLines() : string
    {
        if (this.content == null)
           return "";
        let len   = this.content.length;
        if (len > 10000)
            len = 10000;
        let part  = this.content.substring(0, len);
        let lines = part.split('\n');
        part = "";
        for (let i = 0; i < lines.length; i++)
        {
            part += lines[i]?.trim() +"\n";
            if (i == 15)
                break;
        }
        part += "...\n";
        return part;
    }

    initManualImport() : void
    {
        let meters = this.configService.getMetersForCompany();
        this.availableMeters = [];
        for (let i = 0; i < meters.length; i++)
        {
            let meter : Meter = meters[i];
            if (meter.className == ".MeasuringPoint")
               this.availableMeters.push(meter);
        }
    }

    getColorClass(index : number) : string
    {
        if (index % 2 == 0)
            return "table-grey"
        return "table-white"
    }

    saveValue() : void
    {
        if (this.editDate == null)
        {
            this.errorMessage = "Bitte ein Datum angeben!"
            return;
        }
        if ((this.value == null) || (this.value.length == 0))
        {
            this.errorMessage = "Bitte einen Wert eingeben!";
            return;
        }
        if ((this.selectedMeter == null) || (this.selectedMeter.length == 0))
        {
            this.errorMessage = "Bitte einen Messpunkt bestimmen!";
            return;
        }
        this.errorMessage = "";
        let mv : ManualValue = {timestamp: 0, value:0, meterId: ""};
        let date = new Date(this.editDate?.year, this.editDate?.month -1,this.editDate?.day);
        let mi = parseInt(this.minute);
        let ho = parseInt(this.hour);
        date.setHours(ho);
        date.setMinutes(mi);
        mv.timestamp = date.getTime();
        mv.value     = this.getFloat(this.value);
        mv.meterId   = this.selectedMeter;
        if (this.editIndex != null)
           this.allValues[this.editIndex] = mv;
        else this.allValues.unshift(mv);
        this.editIndex = null;
        this.increaseTime();
        this.configService.saveManualValue(mv);
    }

    onTablePageChange (page : any) : void
    {
        this.tabPage = page;
    }

    getFormattedDate(ts : number) : string
    {
        let d = new Date(ts);
        return formatDate(d, "dd.MM.yyyy HH:mm", "en-US");
    }

    isValidValue() : boolean
    {
        // 1. Datum eingetragen
        if (this.editDate == null)
            return false;
        // 2. Messpunkt eingetragen
        if ((this.selectedMeter == null)  || (this.selectedMeter.length == 0))
            return false;
        // 3. Wert eingetragen
        if ((this.value == null) || (this.value.length == 0))
            return false;
        // 4. Uhrzeit noch nicht eingetragen

        return this.checkTime();
    }

    checkTime() : boolean
    {        
        if (this.editIndex != null)
           return true;
        for (let i = 0; i < this.allValues?.length; i++)
        {
            let d = new Date(this.allValues[i].timestamp);
            let chour   = d.getHours();
            let cminute = d.getMinutes();
            let cday    = d.getDate();
            let cmonth  = d.getMonth() + 1;
            let cyear   = d.getFullYear();
            if (   (chour == parseInt(this.hour)) && (cminute == parseInt(this.minute))
                && (cday == this.editDate.day) && (cmonth == this.editDate.month) 
                && (cyear == this.editDate.year)
                )
                return false;
        }
        return true;
    }

    increaseTime() : void
    {
        let hour   = parseInt(this.hour);
        let minute = parseInt(this.minute);
        let add = 15;
        if (this.selectedMeter.overriddenValues?.interpolationInterval > 0)
           add = this.selectedMeter.overriddenValues.interpolationInterval;
        minute += add; 
        if (minute >= 60)
        {
            minute = 0;
            hour++;
        }
        if (hour == 24)
            hour = 0;
        if (hour < 10)
            this.hour   = "0" + hour;
        else this.hour = "" + hour;
        if (minute < 10)
            this.minute = "0" + minute;
        else this.minute = "" + minute;
    }

    deleteManualValue(ind : number) : void
    {
        if (window.confirm("Datensatz wirklich löschen?"))
        {
            let mv = this.allValues[ind];
            this.configService.deleteManualValue(mv);
        }
    }

    editManualValue(ind : number) : void
    {
        let index = (this.tabPage -1) * this.tableSize + ind;
        let mv = this.allValues[index];
        if (mv == null)
        {
            return;
        }
        else
        {
            this.editIndex = index;
            this.value = "" + mv.value;
            let d = new Date(mv.timestamp);
            let h = d.getHours();
            let m = d.getMinutes();
            if (h < 10)
                this.hour   = "0" + h;
            else this.hour = "" + h;
            if (m < 10)
                this.minute = "0" + m;
            else this.minute = "" + m;
            // this.allValues.splice(ind, 1);
        }
    }

    getFloat(val : string) : number
    {
        let pv = val.replace(',', '.');
        return parseFloat(pv);
    }

    getFormattedValue(val : number) : string
    {
        return formatNumber(val, "de-DE", "1.2-2")
    }

    resetData() : void
    {
        this.hour = "08";
        this.minute = "00";
        this.value = "";
    }

    interpolate() : void
    {
        let from = this.getFrom();
        let to   = this.getTo();
        this.configService.interpolateMeter(this.selectedMeter, from, to);
    }

    getFrom() : number
    {
        let result : number = Date.now();
        for (let i = 0; i < this.allValues.length; i++)
        {
            let mv : ManualValue = this.allValues[i];
            if (mv.timestamp < result)
               result = mv.timestamp;
        }
        return result;
    }
    
    getTo() : number
    {
        let result : number = 0;
        for (let i = 0; i < this.allValues.length; i++)
        {
            let mv : ManualValue = this.allValues[i];
            if (mv.timestamp > result)
               result = mv.timestamp;
        }
        return result;
    }

    selectTable(tableName : any) : void
    {
        this.configService.loadColumnsForTable(tableName);
        this.colMP = null;
        this.colTime = null;
        this.colVal  = null;
        this.colTimeFormat = "Timestamp";
        this.errorMessage  = "";
    }

    selectMPColumn(colName : any) : void
    {
        if (colName == null)
           this.mpType ='Messpunkt auswählen';
        else this.mpType = "Messpunkt-ID";
    }

    startCopy() : void
    {
        // Validieren 
        if (!this.validateDBImportData())
           return;
        let company = JSON.parse(sessionStorage.getItem(Global.SESSION_COMPANY));

        let obj = 
        {
            companyId: company.id,
            company: company.name,
            table: this.selectedTable,
            colContainingValue: this.colVal,
            colContainingMP:    this.colMP,
            mpType:             this.mpType,
            colContainingTime:  this.colTime,
            timeFormat:         this.colTimeFormat,
            meterId:            this.selectedMeter,
            mails:              this.selectedMails,
            recurrence:         this.getRecurrence()
        }
        this.http.post(Global.apiUrl + "rev2/import/dbimport", obj)
            .subscribe((time) => 
            {
                this.popupService.addPopupInfo("Import erfolgreich", "Es wurden Daten für den Zeitraum " + time + " importiert.");
                this.reportService.reportEvent$.emit(new ReportEvent(Global.SIGNAL_REFRESH_IMPORTS))
            },
            () => {
                this.popupService.addPopupError("Import gescheitert", 
                    "Der Import wurde abgebrochen. Bitte kontrollieren Sie Ihre Eingaben und versuchen Sie es erneut!")
            })
    }

    isValidDBImport() : boolean
    {
        if ((this.colVal == null) || (this.colVal.length == 0))
            return false;
        if ((this.colTime == null) || (this.colTime.length == 0))
            return false;    
        if (this.mpType == 'Messpunkt auswählen') 
        {
            if ((this.selectedMeter == null) ||(this.selectedMeter.length == 0))
               return false;
            if (this.colMP != null)
                return false;
        } 
        else
        {
            if ((this.colMP == null) || (this.colMP.length == 0))
                return false;            
        }
        if (!this.verifyTimeData())
        {
            // this.errorMessage = "Für die Spalte " + this.colTime.name +" ist ein falscher Datentyp oder ein falsches Format angegeben!"
            return false;
        }
        if (this.colRec != "Einmalig") 
        {
            if ((this.selectedMails == null) || (this.selectedMails.length == 0))
            {
//                this.errorMessage = "Wenn Sie einen Import automatisieren wollen, müssen Sie mindestens eine E-Mailadresse angeben";
                return false;
            }
        }
        return true;
    }

    validateDBImportData() : boolean
    {
        this.errorMessage = "";
        if ((this.colVal == null) || (this.colVal.length == 0))
        {
            this.errorMessage = "Bitte die Spalte mit den Werten bestimmen."
            return false;
        }
        if ((this.colTime == null) || (this.colTime.length == 0))
        {
            this.errorMessage = "Bitte die Spalte mit der Zeitangabe bestimmen.";
            return false;    
        }
        if (this.mpType == 'Messpunkt auswählen')
        {
            if ((this.selectedMeter == null) ||(this.selectedMeter.length == 0))
            {
                this.errorMessage ="Bitte bestimmen Sie den Messpunkt, für den die Daten importiert werden sollen."
                return false;
            }
        }
        else
        {
            if ((this.colMP == null) || (this.colMP.length == 0))
            {
                this.errorMessage = "Bitte die Spalte mit dem Messpunkt bestimmen."
                return false;
            }                
        }
        // Und Typen prüfen
        if (!this.verifyTimeData())
        {
            return false;
        }
        // if (!this.verifyValueType())
        // {
        //     this.errorMessage = "Die Spalte '" + this.colVal.name + "' muss ein numerischer Typ (Float, Decimal, Numeric, Integer...) sein."
        //     return false;
        // }
        if (this.colRec != "Einmalig") 
        {
            if ((this.selectedMails == null) || (this.selectedMails.length == 0))
            {
                this.errorMessage = "Wenn Sie einen Import automatisieren wollen, müssen Sie mindestens eine E-Mailadresse angeben";
                return false;
            }
        }
        return true;
    }

    verifyTimeData() : boolean
    {
        if (this.colTimeFormat == "Timestamp")
        {
            if (this.colTime.typeName == 'TIMESTAMP')
            {
                this.errorMessage = "";
                return true;
            }
            else
            {
                this.errorMessage = "Der Typ für '" + this.colTime.name +"' ist kein Timestamp."
                return false;
            }
        }
        else
        if (this.colTimeFormat.indexOf("CHAR") >= 0)
        {
            if (this.colTimeFormat == "Timestamp")
            {
                this.errorMessage = "Bitte ein Datumsformat für '" + this.colTime.name +
                    "' angeben. Diese Spalte ist nicht vom Typ 'Timestamp'";
                return false;
            }
        }
        return true;
    }

    verifyValueType() : boolean
    {
        let validTypeNames : string[] = 
            ["NUMERIC", "DECIMAL",
            "INTEGER",  "SMALLINT",
            "FLOAT",    "REAL",
            "DOUBLE" ];
        for (let i = 0; i < validTypeNames.length; i++)
        {
            if (this.colVal.typeName == validTypeNames[i])
               return true;
        }
        return false;
    }

    getRecurrence() : number
    {
        for (let i = 0; i < this.allRecs.length; i++)
        {
            if (this.allRecs[i] == this.colRec)
                return i;
        }
        return 0;       // Default: Einmalig
    }

     // Ein Admin ist auch ein Moderator!
    isModerator() : boolean
    {
        let usrstr = sessionStorage.getItem("authenticatedUser");
        let user : User = JSON.parse(usrstr);
        if (user == null)
            return false;
        return user.admin || user.moderator;
    }

    isExportDBValid() : boolean
    {
        return true;
    }

    meterChanged(current : Meter[])
    {
        let hasVmps = false;
        for (let i = 0; i < current.length; i++)
        {
            if (current[i].className == ".VirtualMeasuringPoint")
               hasVmps = true;
        }
        if (hasVmps)
        {
            this.dbOrigin ="1";
        }
    }

    exportToDB() : void
    {
        let hasVmps = false;
        let ids =  [];
        for (let i = 0; i < this.selectedMetersDB.length; i++)
        {
            ids.push(this.selectedMetersDB[i].id);
            if (this.selectedMetersDB[i].className == ".VirtualMeasuringPoint")
               hasVmps = true;
        }
        if (hasVmps && this.dbOrigin == '0')
        {
            alert("Für virtuelle Messpunkte existieren keine Orginaldaten.")
            return;
        }
        let date = new Date(this.editDate?.year, this.editDate?.month -1,this.editDate?.day);
        let mi = parseInt(this.minute);
        let ho = parseInt(this.hour);
        date.setHours(ho);
        date.setMinutes(mi);
        let obj = {
            ids : ids,
            start: date.getTime(),
            data: this.dbOrigin
        };
        this.export = true;
        this.spinnerService.show();
        this.http.post(Global.apiUrl + "rev2/exportdb", obj)
            .subscribe(response => 
            {
                this.spinnerService.hide();
                this.export = false;
            },
            (response) => 
            {
                this.spinnerService.hide();
                this.export = false;
            }
            );
    }

}
