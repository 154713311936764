import { Tariff } from './tariff';

export class CostPerCostUnit
{
    cuId:       number;
    companyId:  number;
    start:      number;         // Startdatum als ms nach dem 1.1.1970
    end:        number;         // Endedatum als ms nach dem 1.1.1970
    meters:     CostPerMeter[];
}

export class CostPerMeter
{
    meterId:    string;         // Für welchen Messpunkt sollen die Kosten ermittelt werden?
    measurand:  string;         // Messeinheit
    peaksMonth: CostPair[];
    measurements: Measurement[];
    tariff:     Tariff[];
}

export class Measurement
{
    timestamp: number;          // Messzeitpunkt als ms seit dem 1.1.1970 
    value:     string;          // Messwert
}

export class CostPair
{
    value: number;        // Max. monatliche Werte für das laufende Jahr (kann 0 Einträge lang sein)
    month: number;        // Zeit in ms seit dem 1.1.1970 für den ersten Tag des Monats
}

