import { Component, OnInit } from '@angular/core';
import { ConfirmService } from 'src/app/services/confirm.service';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
    message: any;  
    constructor(  
        private confirmDialogService: ConfirmService  
    ) { }  
  
    ngOnInit() {  
        //this function waits for a message from alert service, it gets   
        //triggered when we call this from any other component  
        this.confirmDialogService.getMessage().subscribe(message => {  
            this.message = message;  
        });  
    }  

}
