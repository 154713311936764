import { Injectable } from '@angular/core';
import { EventEmitter } from '@angular/core'


export class SignalEvent
{
  constructor(public name: string, public done: boolean)
  {
  }
}
@Injectable({
  providedIn: 'root'
})
export class SignalService {

  public signalEvent$: EventEmitter<SignalEvent>;
  private me : SignalService;

  constructor() {
    this.signalEvent$ = new EventEmitter();
  }
}
