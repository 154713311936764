<app-header></app-header>

<div class="page-content d-flex align-items-stretch">
  <app-vertical-tabs ></app-vertical-tabs>
  <div class="tab-content content-inner" id="main">
    <article class="tab-pane container-fluid active" id="tab-panel-1">
      <app-dashboard></app-dashboard>
    </article>
    <article class="tab-pane container-fluid" id="tab-panel-2">
      Chart Table
    </article>
    <article class="tab-pane container-fluid" id="tab-panel-3">
      <app-reports></app-reports>
    </article>
    <article class="tab-pane container-fluid" id="tab-panel-4">
      <app-eventlog></app-eventlog>
    </article>
    <article class="tab-pane container-fluid" id="tab-panel-5">
        <app-config></app-config>
    </article>
    <article class="tab-pane container-fluid" id="tab-panel-6">
        <app-cost></app-cost>
    </article>
    <article class="tab-pane container-fluid" id="tab-panel-7">
        <app-alarms></app-alarms>
      </article>
    <article class="tab-pane container-fluid" id="tab-panel-8">
        <app-todostate></app-todostate>
    </article>
    <article class="tab-pane container-fluid" id="tab-panel-9">
        <app-export></app-export>
    </article>  </div>

</div>
