import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {CompanySelectComponent} from './company-select/company-select.component';
import {ConfigService} from '../../../services/config.service';
import {Global} from '../../../../constants/global';
import { User } from 'src/app/classes/user';

@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.scss'],
})
export class ConfigComponent implements OnInit {
  companySelect = true;
  companyEdit   = false;
  meterActive   = false;
  tariffEdit    = false;
  reportEdit    = false;
  alarmEdit     = false;
  costUnitActive= false;
  containerActive = false;
  importActive  = false;
  todoActive    = false;

  user : User;

  constructor(
      private configService: ConfigService,
  ) {
    this.configService.configEvent$.subscribe(data =>
    {
      if (data.name == Global.SIGNAL_SET_COMPANY_CHANGE_ACTIVE) {
        this.setCompanyEditActive();
      }else if (data.name == Global.SIGNAL_REFRESH_COMPANY_INFO)
      {
        this.setCompanySelectionActive();
      }
    });
    this.setTabActiveForUser();
  }

  setTabActiveForUser()
  {
    let usrstr = sessionStorage.getItem(Global.ATTRIBUTE_USER_OBJECT);
    let user : User = JSON.parse(usrstr);
    if (user == null)
      return;
    if (user.moderator)                                         //MODERATOR
      this.setCompanyTariffActive();
    else if (!user.moderator && !user.admin)                    //USER
      this.setTodoActive();
    else this.setCompanySelectionActive();                      //ADMIN
  }

  ngOnInit(): void {

  }

  setTodoActive()
  {
    this.reportEdit    = false;
    this.meterActive   = false;
    this.companyEdit   = false;
    this.tariffEdit    = false;
    this.companySelect = false;
    this.alarmEdit     = false;
    this.todoActive    = true;
    this.containerActive = false;
    this.costUnitActive= false;
    this.importActive  = false;
}

  setCompanyTariffActive()
  {
    this.reportEdit    = false;
    this.meterActive   = false;
    this.companyEdit   = false;
    this.tariffEdit    = true;
    this.companySelect = false;
    this.alarmEdit     = false;
    this.containerActive = false;
    this.todoActive    = false;
    this.costUnitActive= false;
    this.importActive  = false;
  }

  setReportActive()
  {
    this.reportEdit    = true;
    this.meterActive   = false;
    this.companyEdit   = false;
    this.tariffEdit    = false;
    this.companySelect = false;
    this.todoActive    = false;
    this.alarmEdit     = false;
    this.containerActive = false;
    this.costUnitActive= false;
    this.importActive  = false;
  }

  setCompanySelectionActive()
  {
    this.reportEdit    = false;
    this.meterActive   = false;
    this.companyEdit   = false;
    this.tariffEdit    = false;
    this.companySelect = true;
    this.todoActive    = false;
    this.alarmEdit     = false;
    this.containerActive = false;
    this.costUnitActive= false;
    this.importActive  = false;
  }

  setCompanyEditActive()
  {
    this.reportEdit    = false;
    this.companyEdit   = true;
    this.companySelect = false;
    this.tariffEdit    = false;
    this.meterActive   = false;
    this.alarmEdit     = false;
    this.containerActive = false;
    this.todoActive    = false;
    this.costUnitActive= false;
    this.importActive  = false;
  }
  setMeterActive()
  {
    this.reportEdit    = false;
    this.meterActive   = true;
    this.companyEdit   = false;
    this.companySelect = false;
    this.tariffEdit    = false;
    this.alarmEdit     = false;
    this.containerActive = false;
    this.todoActive    = false;
    this.costUnitActive= false;
    this.importActive  = false;
  }

  setContainerActive() : void
  {
      this.reportEdit    = false;
      this.containerActive = true;
      this.companyEdit   = false;
      this.companySelect = false;
      this.tariffEdit    = false;
      this.alarmEdit     = false;
      this.meterActive   = false;
      this.todoActive    = false;
      this.costUnitActive= false;
      this.importActive  = false;
    }

  setAlarmActive() : void
  {
    this.alarmEdit     = true;
    this.reportEdit    = false;
    this.companyEdit   = false;
    this.companySelect = false;
    this.tariffEdit    = false;
    this.meterActive   = false;
    this.todoActive    = false;
    this.containerActive = false;
    this.costUnitActive= false;
    this.importActive  = false;
  }

  setCostUnitActive() : void
  {
    this.alarmEdit     = false;
    this.reportEdit    = false;
    this.companyEdit   = false;
    this.companySelect = false;
    this.tariffEdit    = false;
    this.meterActive   = false;
    this.todoActive    = false;
    this.containerActive = false;
    this.costUnitActive= true;
    this.importActive  = false;
  }

  setImportActive() : void
  {
    this.alarmEdit     = false;
    this.reportEdit    = false;
    this.companyEdit   = false;
    this.companySelect = false;
    this.tariffEdit    = false;
    this.meterActive   = false;
    this.todoActive    = false;
    this.containerActive = false;
    this.costUnitActive= false;
    this.importActive  = true;
  }

  isCostUnitActive() : boolean
  {
      return this.costUnitActive;    
  }

  isContainerActive() : boolean
  {
      return this.containerActive;
  }

  isTariffEditActive() : boolean
  {
      return this.tariffEdit;
  }

  isCompanySelectionActive()
  {
    return this.companySelect;
  }

  isCompanyEditActive()
  {
    return this.companyEdit;
  }

  isReportEditActive()
  {
    return this.reportEdit;
  }

  isAlarmActive()
  {
    return this.alarmEdit;
  }

  isAdmin() : boolean
  {
      let usrstr = sessionStorage.getItem(Global.ATTRIBUTE_USER_OBJECT);
      let user : User = JSON.parse(usrstr);
      if (user == null)
         return false;
      return user.admin;
  }

  // Ein Admin ist auch ein Moderator!
  isModerator() : boolean
  {
    let usrstr = sessionStorage.getItem("authenticatedUser");
    let user : User = JSON.parse(usrstr);
    if (user == null)
        return false;
    return user.admin || user.moderator;
  }

}
