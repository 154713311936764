import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../services/auth.service';

@Component({
  selector: 'app-password-lost',
  templateUrl: './password-lost.component.html',
  styleUrls: ['./password-lost.component.scss']
})
export class PasswordLostComponent implements OnInit {
  invalidEmail = false;
  emailSuccess = false;
  errorMessage = 'Invalid Credentials';
  successMessage: string;
  email         : string;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService) { }

  ngOnInit(): void {
  }
  reset(){
      this.authenticationService.lostPassword(this.email).subscribe((result) => {
        this.invalidEmail   = false;
        this.emailSuccess   = true;
        this.successMessage = 'Successful.';
      
        this.router.navigate(['/login']);
      } , () => {
        this.invalidEmail = true;
        this.emailSuccess = false;
      });
  }


}
