<div class="row ">
    <div class="col-xl-10 col-md-12 col-12">
        <div class="card">
            <div class="card-body">
                <div>
                    <h2 class="titel">Vorgaben</h2>
                    <div>
                        <p>Hier finden Sie eine Liste der eingetragenen Vorgaben für
                            Ihre Firma. Sie sind unterteilt in <B>überfällig</B>, <b>fällig</b>,
                            <b>Später</b> und <b>Bereits erledigt</b>.
                        </p>
                        <p>Vorgaben können Sie unter "Konfiguration - Vorgaben erstellen, löschen oder ändern.</p>
                        <div>
                            <div style="padding-bottom: 1.25rem">
                                <ul class="nav nav-tabs" role="tablist" style="width: 100%">
                                    <li class="nav-item">
                                        <a href="#page1" class="nav-link active" data-toggle="tab">Überfällig</a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="#profile1" class="nav-link" data-toggle="tab">Fällig</a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="#messages" class="nav-link" data-toggle="tab">Später</a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="#done" class="nav-link" data-toggle="tab">Bereits erledigt</a>
                                    </li>
                                </ul>
                            </div>
                            <div class="tab-content">
                                <div class="tab-pane show active" id="page1">
                                    <div>
                                        <p>Diese Punkte sind schon überfällig!</p>
                                    </div>
                                    <table class="table table-striped">
                                        <thead>
                                        <th width="30px"></th>
                                        <th width="25%">Name</th>
                                        <th>Bis</th>
                                        <th>Benachrichtige</th>
                                        <th>Erledigt</th>
                                        </thead>
                                        <tbody>
                                        <tr *ngFor="let todo of urgentTodos| paginate : {
                                                id: 'todo-urgent',
                                                itemsPerPage: tableSize,
                                                currentPage: pageUrgent
                                            }; let ind=index">
                                            <td>
                                                <i class="fa fa-caret-right pointer"
                                                   (click)="toggleVisibility(ind)"
                                                   *ngIf="todo.todos.length > 0 && (!closeTodos[ind])"
                                                   aria-hidden="true"></i>
                                                <i class="fa fa-caret-down pointer"
                                                   (click)="toggleVisibility(ind)"
                                                   *ngIf="todo.todos.length > 0 && closeTodos[ind]"
                                                   aria-hidden="true"></i>

                                            </td>
                                            <td width="25%" class="table-content-left">
                                                <div style="padding-bottom: 10px;">
                                                    {{ todo.name }}
                                                </div>
                                                <div style="padding-top: 10px"
                                                     [style.display]="(closeTodos[ind] ? 'contents' :'none')">
                                                    <div *ngFor="let val of todo.todos; let subind=index"
                                                         style="height: 35px;">
                                                        {{ val.name}}
                                                    </div>
                                                    <br *ngIf="subind < todo.todos.length"/>
                                                </div>
                                            </td>
                                            <td class="table-content-right">
                                                <div style="padding-bottom: 10px;">
                                                    {{ getUntilDate(todo) }}
                                                </div>
                                                <div style="padding-top: 10px"
                                                     [style.display]="(closeTodos[ind] ? 'contents' :'none')">
                                                    <div *ngFor="let val of todo.todos; let subind=index"
                                                         style="height: 35px;">
                                                        {{ getUntilDate(val) }}
                                                    </div>
                                                    <br *ngIf="subind < todo.todos.length"/>
                                                </div>
                                            </td>
                                            <td class="table-content-left">
                                                <div style="padding-bottom: 10px;">
                                                    {{ getEMails(todo.emails) }}
                                                </div>
                                                <div style="padding-top: 10px"
                                                     [style.display]="(closeTodos[ind] ? 'contents' :'none')">
                                                    <div *ngFor="let val of todo.todos; let subind=index"
                                                         style="height: 35px;">
                                                        {{ getEMails(val.emails) }}
                                                    </div>
                                                    <br *ngIf="subind < todo.todos.length"/>
                                                </div>
                                            </td>
                                            <td>
                                                <div style="padding-bottom: 10px;">
                                                    <div *ngIf="todo.todos?.length > 0">
                                                        {{ getDoneFromTotal(todo) }}
                                                        <i class="fa fa-floppy-o pointer"
                                                           *ngIf="todo.changed"
                                                           (click)="saveTodoState(todo)"
                                                           aria-hidden="true"></i>
                                                    </div>
                                                    <div *ngIf="todo.todos?.length == 0">
                                                        <i *ngIf="todo.done && todo.todos?.length == 0"
                                                           (click)="toggleDone(todo)"
                                                           class="fa fa-check-square" aria-hidden="true"></i>
                                                        <i *ngIf="!todo.done && todo.todos?.length == 0"
                                                           (click)="toggleDone(todo)"
                                                           class="fa fa-square-o" aria-hidden="true"></i>
                                                        &nbsp;
                                                        <i class="fa fa-floppy-o pointer"
                                                           *ngIf="todo.changed"
                                                           (click)="saveTodoState(todo)"
                                                           aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                                <div
                                                        [style.display]="(closeTodos[ind] ? 'contents' :'none')">
                                                    <div *ngFor="let val of todo.todos; let subind=index"
                                                         style="height: 35px;">
                                                        <i *ngIf="val.done"
                                                           (click)="toggleDone(val, todo)"
                                                           class="fa fa-check-square" aria-hidden="true"></i>
                                                        <i *ngIf="!val.done"
                                                           (click)="toggleDone(val, todo)"
                                                           class="fa fa-square-o" aria-hidden="true"></i>
                                                    </div>
                                                    <br *ngIf="subind < todo.todos.length"/>
                                                </div>

                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <div class="d-flex justify-content-center">
                                        <pagination-controls
                                                id="todo-urgent"
                                                autoHide="true"
                                                responsive="true"
                                                previousLabel=""
                                                nextLabel=""
                                                (pageChange)="pageUrgent = $event">
                                        </pagination-controls>
                                    </div>

                                </div>
                                <div class="tab-pane fade" id="profile1">
                                    <p>Diese Punkte müssen in den nächsten 2 Tagen abgearbeitet werden!</p>
                                    <table class="table table-striped">
                                        <thead>
                                        <th width="30px"></th>
                                        <th width="25%">Name</th>
                                        <th>Bis</th>
                                        <th>Benachrichtige</th>
                                        <th>Erledigt</th>
                                        </thead>
                                        <tbody>
                                        <tr *ngFor="let todo of nextTodos| paginate : {
                                                id: 'todo-next',
                                                itemsPerPage: tableSize,
                                                currentPage: pageNext
                                            }; let ind=index">
                                            <td>
                                                <i class="fa fa-caret-right pointer"
                                                   (click)="toggleVisibility(ind)"
                                                   *ngIf="todo.todos.length > 0 && (!closeTodos[ind])"
                                                   aria-hidden="true"></i>
                                                <i class="fa fa-caret-down pointer"
                                                   (click)="toggleVisibility(ind)"
                                                   *ngIf="todo.todos.length > 0 && closeTodos[ind]"
                                                   aria-hidden="true"></i>

                                            </td>
                                            <td width="25%" class="table-content-left">
                                                <div style="padding-bottom: 10px;">
                                                    {{ todo.name }}
                                                </div>
                                                <div style="padding-top: 10px"
                                                     [style.display]="(closeTodos[ind] ? 'contents' :'none')">
                                                    <div *ngFor="let val of todo.todos; let subind=index"
                                                         style="height: 35px;">
                                                        {{ val.name}}
                                                    </div>
                                                    <br *ngIf="subind < todo.todos.length"/>
                                                </div>
                                            </td>
                                            <td class="table-content-right">
                                                <div style="padding-bottom: 10px;">
                                                    {{ getUntilDate(todo) }}
                                                </div>
                                                <div style="padding-top: 10px"
                                                     [style.display]="(closeTodos[ind] ? 'contents' :'none')">
                                                    <div *ngFor="let val of todo.todos; let subind=index"
                                                         style="height: 35px;">
                                                        {{ getUntilDate(val) }}
                                                    </div>
                                                    <br *ngIf="subind < todo.todos.length"/>
                                                </div>
                                            </td>
                                            <td class="table-content-left">
                                                <div style="padding-bottom: 10px;">
                                                    {{ getEMails(todo.emails) }}
                                                </div>
                                                <div style="padding-top: 10px"
                                                     [style.display]="(closeTodos[ind] ? 'contents' :'none')">
                                                    <div *ngFor="let val of todo.todos; let subind=index"
                                                         style="height: 35px;">
                                                        {{ getEMails(val.emails) }}
                                                    </div>
                                                    <br *ngIf="subind < todo.todos.length"/>
                                                </div>
                                            </td>
                                            <td>
                                                <div style="padding-bottom: 10px;">
                                                    <div *ngIf="todo.todos?.length > 0">
                                                        {{ getDoneFromTotal(todo) }}
                                                        <i class="fa fa-floppy-o pointer"
                                                           *ngIf="todo.changed"
                                                           (click)="saveTodoState(todo)"
                                                           aria-hidden="true"></i>
                                                    </div>
                                                    <div *ngIf="todo.todos?.length == 0">
                                                        <i *ngIf="todo.done && todo.todos?.length == 0"
                                                           (click)="toggleDone(todo)"
                                                           class="fa fa-check-square" aria-hidden="true"></i>
                                                        <i *ngIf="!todo.done && todo.todos?.length == 0"
                                                           (click)="toggleDone(todo)"
                                                           class="fa fa-square-o" aria-hidden="true"></i>
                                                        &nbsp;
                                                        <i class="fa fa-floppy-o pointer"
                                                           *ngIf="todo.changed"
                                                           (click)="saveTodoState(todo)"
                                                           aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                                <div
                                                        [style.display]="(closeTodos[ind] ? 'contents' :'none')">
                                                    <div *ngFor="let val of todo.todos; let subind=index"
                                                         style="height: 35px;">
                                                        <i *ngIf="val.done"
                                                           (click)="toggleDone(val, todo)"
                                                           class="fa fa-check-square" aria-hidden="true"></i>
                                                        <i *ngIf="!val.done"
                                                           (click)="toggleDone(val, todo)"
                                                           class="fa fa-square-o" aria-hidden="true"></i>
                                                    </div>
                                                    <br *ngIf="subind < todo.todos.length"/>
                                                </div>

                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <div class="d-flex justify-content-center">
                                        <pagination-controls
                                                id="todo-next"
                                                autoHide="true"
                                                responsive="true"
                                                previousLabel=""
                                                nextLabel=""
                                                (pageChange)="pageNext = $event">
                                        </pagination-controls>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="messages">
                                    <p>Sonstige Vorgaben.</p>
                                    <table class="table table-striped">
                                        <thead>
                                        <th width="30px"></th>
                                        <th width="25%">Name</th>
                                        <th>Bis</th>
                                        <th>Benachrichtige</th>
                                        <th>Erledigt</th>
                                        </thead>
                                        <tbody>
                                        <tr *ngFor="let todo of otherTodos| paginate : {
                                                id: 'todo-other',
                                                itemsPerPage: tableSize,
                                                currentPage: pageOther
                                            }; let ind=index">
                                            <td>
                                                <i class="fa fa-caret-right pointer"
                                                   (click)="toggleVisibility(ind)"
                                                   *ngIf="todo.todos.length > 0 && (!closeTodos[ind])"
                                                   aria-hidden="true"></i>
                                                <i class="fa fa-caret-down pointer"
                                                   (click)="toggleVisibility(ind)"
                                                   *ngIf="todo.todos.length > 0 && closeTodos[ind]"
                                                   aria-hidden="true"></i>

                                            </td>
                                            <td width="25%" class="table-content-left">
                                                <div style="padding-bottom: 10px;">
                                                    {{ todo.name }}
                                                </div>
                                                <div style="padding-top: 10px"
                                                     [style.display]="(closeTodos[ind] ? 'contents' :'none')">
                                                    <div *ngFor="let val of todo.todos; let subind=index"
                                                         style="height: 35px;">
                                                        {{ val.name}}
                                                    </div>
                                                    <br *ngIf="subind < todo.todos.length"/>
                                                </div>
                                            </td>
                                            <td class="table-content-right">
                                                <div style="padding-bottom: 10px;">
                                                    {{ getUntilDate(todo) }}
                                                </div>
                                                <div style="padding-top: 10px"
                                                     [style.display]="(closeTodos[ind] ? 'contents' :'none')">
                                                    <div *ngFor="let val of todo.todos; let subind=index"
                                                         style="height: 35px;">
                                                        {{ getUntilDate(val) }}
                                                    </div>
                                                    <br *ngIf="subind < todo.todos.length"/>
                                                </div>
                                            </td>
                                            <td class="table-content-left">
                                                <div style="padding-bottom: 10px;">
                                                    {{ getEMails(todo.emails) }}
                                                </div>
                                                <div style="padding-top: 10px"
                                                     [style.display]="(closeTodos[ind] ? 'contents' :'none')">
                                                    <div *ngFor="let val of todo.todos; let subind=index"
                                                         style="height: 35px;">
                                                        {{ getEMails(val.emails) }}
                                                    </div>
                                                    <br *ngIf="subind < todo.todos.length"/>
                                                </div>
                                            </td>
                                            <td>
                                                <div style="padding-bottom: 10px;">
                                                    <div *ngIf="todo.todos?.length > 0">
                                                        {{ getDoneFromTotal(todo) }}
                                                        <i class="fa fa-floppy-o pointer"
                                                           *ngIf="todo.changed"
                                                           (click)="saveTodoState(todo)"
                                                           aria-hidden="true"></i>
                                                    </div>
                                                    <div *ngIf="todo.todos?.length == 0">
                                                        <i *ngIf="todo.done && todo.todos?.length == 0"
                                                           (click)="toggleDone(todo)"
                                                           class="fa fa-check-square" aria-hidden="true"></i>
                                                        <i *ngIf="!todo.done && todo.todos?.length == 0"
                                                           (click)="toggleDone(todo)"
                                                           class="fa fa-square-o" aria-hidden="true"></i>
                                                        &nbsp;
                                                        <i class="fa fa-floppy-o pointer"
                                                           *ngIf="todo.changed"
                                                           (click)="saveTodoState(todo)"
                                                           aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                                <div
                                                        [style.display]="(closeTodos[ind] ? 'contents' :'none')">
                                                    <div *ngFor="let val of todo.todos; let subind=index"
                                                         style="height: 35px;">
                                                        <i *ngIf="val.done"
                                                           (click)="toggleDone(val, todo)"
                                                           class="fa fa-check-square" aria-hidden="true"></i>
                                                        <i *ngIf="!val.done"
                                                           (click)="toggleDone(val, todo)"
                                                           class="fa fa-square-o" aria-hidden="true"></i>
                                                    </div>
                                                    <br *ngIf="subind < todo.todos.length"/>
                                                </div>

                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <div class="d-flex justify-content-center">
                                        <pagination-controls
                                                id="todo-other"
                                                autoHide="true"
                                                responsive="true"
                                                previousLabel=""
                                                nextLabel=""
                                                (pageChange)="pageOther = $event">
                                        </pagination-controls>
                                    </div>

                                </div>
                                <div class="tab-pane fade" id="done">
                                    <p>Diese Punkte wurden bereits erledigt.</p>
                                    <table class="table table-striped">
                                        <thead>
                                        <th width="30px"></th>
                                        <th width="25%">Name</th>
                                        <th>Bis</th>
                                        <th>Benachrichtige</th>
                                        <th>Erledigt</th>
                                        </thead>
                                        <tbody>
                                        <tr *ngFor="let todo of doneTodos| paginate : {
                                                id: 'todo-done',
                                                itemsPerPage: tableSize,
                                                currentPage: pageDone
                                            }; let ind=index">
                                            <td>
                                                <i class="fa fa-caret-right pointer"
                                                   (click)="toggleVisibility(ind)"
                                                   *ngIf="todo.todos.length > 0 && (!closeTodos[ind])"
                                                   aria-hidden="true"></i>
                                                <i class="fa fa-caret-down pointer"
                                                   (click)="toggleVisibility(ind)"
                                                   *ngIf="todo.todos.length > 0 && closeTodos[ind]"
                                                   aria-hidden="true"></i>

                                            </td>
                                            <td width="25%" class="table-content-left">
                                                <div style="padding-bottom: 10px;">
                                                    {{ todo.name }}
                                                </div>
                                                <div style="padding-top: 10px"
                                                     [style.display]="(closeTodos[ind] ? 'contents' :'none')">
                                                    <div *ngFor="let val of todo.todos; let subind=index"
                                                         style="height: 35px;">
                                                        {{ val.name}}
                                                    </div>
                                                    <br *ngIf="subind < todo.todos.length"/>
                                                </div>
                                            </td>
                                            <td class="table-content-right">
                                                <div style="padding-bottom: 10px;">
                                                    {{ getUntilDate(todo) }}
                                                </div>
                                                <div style="padding-top: 10px"
                                                     [style.display]="(closeTodos[ind] ? 'contents' :'none')">
                                                    <div *ngFor="let val of todo.todos; let subind=index"
                                                         style="height: 35px;">
                                                        {{ getUntilDate(val) }}
                                                    </div>
                                                    <br *ngIf="subind < todo.todos.length"/>
                                                </div>
                                            </td>
                                            <td class="table-content-left">
                                                <div style="padding-bottom: 10px;">
                                                    {{ getEMails(todo.emails) }}
                                                </div>
                                                <div style="padding-top: 10px"
                                                     [style.display]="(closeTodos[ind] ? 'contents' :'none')">
                                                    <div *ngFor="let val of todo.todos; let subind=index"
                                                         style="height: 35px;">
                                                        {{ getEMails(val.emails) }}
                                                    </div>
                                                    <br *ngIf="subind < todo.todos.length"/>
                                                </div>
                                            </td>
                                            <td>
                                                <div style="padding-bottom: 10px;">
                                                    <div *ngIf="todo.todos?.length > 0">
                                                        {{ getDoneFromTotal(todo) }}
                                                        <i class="fa fa-floppy-o pointer"
                                                           *ngIf="todo.changed"
                                                           (click)="saveTodoState(todo)"
                                                           aria-hidden="true"></i>
                                                    </div>
                                                    <div *ngIf="todo.todos?.length == 0">
                                                        <i *ngIf="todo.done && todo.todos?.length == 0"
                                                           (click)="toggleDone(todo)"
                                                           class="fa fa-check-square" aria-hidden="true"></i>
                                                        <i *ngIf="!todo.done && todo.todos?.length == 0"
                                                           (click)="toggleDone(todo)"
                                                           class="fa fa-square-o" aria-hidden="true"></i>
                                                        &nbsp;
                                                        <i class="fa fa-floppy-o pointer"
                                                           *ngIf="todo.changed"
                                                           (click)="saveTodoState(todo)"
                                                           aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                                <div
                                                        [style.display]="(closeTodos[ind] ? 'contents' :'none')">
                                                    <div *ngFor="let val of todo.todos; let subind=index"
                                                         style="height: 35px;">
                                                        <i *ngIf="val.done"
                                                           (click)="toggleDone(val, todo)"
                                                           class="fa fa-check-square" aria-hidden="true"></i>
                                                        <i *ngIf="!val.done"
                                                           (click)="toggleDone(val, todo)"
                                                           class="fa fa-square-o" aria-hidden="true"></i>
                                                    </div>
                                                    <br *ngIf="subind < todo.todos.length"/>
                                                </div>

                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <div class="d-flex justify-content-center">
                                        <pagination-controls
                                                id="todo-done"
                                                autoHide="true"
                                                responsive="true"
                                                previousLabel=""
                                                nextLabel=""
                                                (pageChange)="pageDone = $event">
                                        </pagination-controls>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
