import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler } from '@angular/core';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Global } from 'src/constants/global';

@Injectable({
  providedIn: 'root'
})
export class ErrorService extends ErrorHandler
{

  constructor(private router: Router) 
  {
      super();
  }

  public handleError(e: string | Error | HttpErrorResponse | unknown) 
  {
      console.log("Errorpage: ");
      console.log(e);
    if (sessionStorage.getItem(Global.ATTRIBUTE_USER_OBJECT) == null)
       window.location.href = "/index.html";
    else this.router.navigate(['home']);
  }

}

