import { Component, OnInit } from '@angular/core';
import { LoginComponent } from './login/login.component'
import { AuthenticationService } from '../../services/auth.service';
import { ConfigService } from '../../services/config.service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {

  showDiv = {
    login: true,
    impressum: false,
    datenschutz: false
  }

  constructor(
    private authenticationService: AuthenticationService,
    private configService: ConfigService,
  ) {

  }

  ngOnInit(): void {
    this.authenticationService.logout();
    this.configService.removeSelectedCompany();
  }


}
