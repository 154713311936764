import {Component} from '@angular/core';
import {ConfigService} from './services/config.service';
//import { AuthenticationService } from './services/auth.service';
//import { Router } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'new-frontend';

  constructor(
    private configService: ConfigService
  ) 
  {

  }

  ngOnInit(): void 
  {
  }


}
