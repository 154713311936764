import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Global} from '../../constants/global';
import {element} from 'protractor';

@Injectable({
    providedIn: 'root'
})
export class NodeService {
    apiUrl = Global.apiUrl;
    metersArray;
    meter;
    connectedMeters;

    constructor(
        private http: HttpClient,
    ) {
    }

    findAllNodes() {
        let nodes = this.http.get(this.apiUrl + `nodes`);
        return nodes;
    }

    findAllMeters() {
        let meters = this.http.get(this.apiUrl + `meters`);
        return meters;
    }

    connectNodesWithCompany(id: number, nodes: string[]) {
        let obj = {
            id: id,
            nodes: nodes
        };
        // console.log(obj);
        return this.http.post(this.apiUrl + `rev2/update/company/nodes`, obj);
    }

    getConnections(company) {
        return this.http.get(this.apiUrl + `rev2/list/` + company + `/topnodes`);

    }

    storeAllMeters(data) {
        this.metersArray = data;
    }

    getMetersArray() {
        return this.metersArray;
    }

    storeConnectedMeters(data) {
        let arr = [];
        data['assigned'].forEach(element => {
            if (element.measuringPointIds != null) {
                element.measuringPointIds.forEach(res => {
                    arr.push(res);
                });
            }
        });
        this.connectedMeters = arr;
        return arr;
    }

    getConnectedMeters() {
        return this.connectedMeters;
    }

    getMeterById(id) {
        let meter = null;
        this.metersArray.forEach(element => {
            if (element.id == id) {
                meter = element;
            }
        });
        return meter;
    }

    getConnect() {
        let arr = [];
        this.connectedMeters.forEach(meter_id => {
            let obj = this.getMeterById(meter_id);
            arr.push(obj);
        });
        this.connectedMeters = arr;
    }

}
