<!-- Messwertkontrolle -->
<div class="row" *ngIf="isInputValidation()">
    <div class="col-xl-10 col-md-12 col-12">
        <div class="card">
            <div class="card-body">
                <form class="form-detail" #alarmForm="ngForm">
                    <div class="row form-left">
                        <h2 class="titel ">Erstellen Sie einen neuen Alarm
                            <i class="fa fa-info-circle help-icon" 
                            placement="bottom" 
                            container="body"
                            popoverClass="help-popover-wide"
                            triggers="mouseenter:mouseleave"
                            [ngbPopover]="helpQueueControlContent" 
                            [popoverTitle]="helpQueueControlTitle"
                            aria-hidden="true"></i>              
                        </h2>
                        <br/>
                        <div class="errorLine">
                            <div class="alert alert-danger" *ngIf='errorMessage'>{{errorMessage}}</div>
                        </div>
                        <div class="form">
                            <div class="row form-group">
                                <div class="col-lg-6 col-md-12 col-sm-12">
                                    <label>Alarmtyp:</label>
                                    <ng-select [items]="alarmTypeArray"
                                               bindLabel="title"
                                               [multiple]="false"
                                               [clearable]="false"
                                               [(ngModel)]="selectedAlarmType"
                                               [ngModelOptions]="{standalone: true}">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="row form-group">
                                <div class="col-md-12">
                                    <label>Name:
                                        <i class="fa fa-info-circle help-icon" 
                                        placement="bottom" 
                                        container="body"
                                        popoverClass="help-popover-wide"
                                        triggers="mouseenter:mouseleave"
                                        [ngbPopover]="helpNameContent" 
                                        [popoverTitle]="helpNameTitle"
                                        aria-hidden="true"></i>                                             
                                    </label>

                                    <input type="text"
                                           name="desc"                                        
                                           size="40"
                                           [(ngModel)]="description">
                                </div>
                            </div>
                            <div class="row form-group">
                                <div class="col-md-12">
                                    <label>Maximale Toleranz in Stunden:
                                        <i class="fa fa-info-circle help-icon" 
                                        placement="bottom" 
                                        container="body"
                                        popoverClass="help-popover-wide"
                                        triggers="mouseenter:mouseleave"
                                        [ngbPopover]="helpHoursContent" 
                                        [popoverTitle]="helpHoursTitle"
                                        aria-hidden="true"></i>                                             
                                    </label>

                                    <input type="text"
                                           name="desc"                                        
                                           size="40"
                                           [imask]="testMask" 
                                           [(ngModel)]="maxHours">
                                </div>
                            </div>

                            <div class="row form-group">
                                <div class="col-md-10">
                                    <label for="meter">Wählen Sie die Messpunkte aus:
                                        <i class="fa fa-info-circle help-icon" 
                                        placement="bottom" 
                                        container="body"
                                        popoverClass="help-popover-wide"
                                        triggers="mouseenter:mouseleave"
                                        [ngbPopover]="helpMP2Content" 
                                        [popoverTitle]="helpMP2Title"
                                        aria-hidden="true"></i>     
                                    </label>
                                    <ng-select [items]="allMeters" id="meter"
                                               bindLabel="values.user0"
                                               name="meters"
                                               [multiple]="true"
                                               [clearable]="true"
                                               [(ngModel)]="selectedMeters"
                                               [ngModelOptions]="{standalone: true}">
                                               <ng-template ng-option-tmp ng-label-tmp let-item="item">
                                                <label class="icon-width">
                                                    <i *ngIf="item.values.obisid0 == '1-0:1.8.0*255'"
                                                        class="fa fa-bolt icon-width"></i>
                                                    <i *ngIf="item.values.obisid0 == '8-0:1.0.0*255'"
                                                        class="fa fa-tin icon-width"></i>
                                                    <i *ngIf="item.values.obisid0 == '6-0:1.0.0*255'"
                                                        class="fa fa-fire icon-width"></i> <!-- red -->
                                                    <i *ngIf="item.values.obisid0 == '7-0:3.0.0*255'"
                                                        class="fa fa-fire icon-width"></i> <!-- blue -->
                                                    <i *ngIf="item.values.obisid0 == '6-0:2.0.0*255'"
                                                        class="fa fa-tint icon-width"></i>
                                                    <i *ngIf="item.values.obisid0 == '8-0:1.0.0*255'"
                                                        class="fa fa-plug icon-width"></i>
                                                    <i *ngIf="item.values.obisid0 == null"
                                                        class="fa fa-question"></i>
                                                    </label>
                                                {{ item.values.user0 }}
                                            </ng-template>                                                             
                                    </ng-select>
                                </div>
                                <div class="col-md-2">
                                    <label>&nbsp;</label>
                                    <button type="button" class="btn btn-primary"
                                        (click)="addAllMeters()">
                                        Alle 
                                    </button>
                                </div>

                                <div class="col-md-12">
                                    <label>E-Mail senden:
                                        <i class="fa fa-info-circle help-icon" 
                                        placement="bottom" 
                                        container="body"
                                        popoverClass="help-popover-wide"
                                        triggers="mouseenter:mouseleave"
                                        [ngbPopover]="helpSendContent" 
                                        [popoverTitle]="helpSendTitle"
                                        aria-hidden="true"></i>                                             
                                    </label>
                                    <ng-select [items]="mailLevelsArray"
                                               (focus)="getSelectedMailLevel()"
                                               name="mailLevel"
                                               [clearable]="false"
                                               [multiple]="false"
                                               [(ngModel)]="mailLevel"
                                               [ngModelOptions]="{standalone: true}"
                                    ></ng-select>
                                </div>
                                <div class="col-md-12">
                                    <label style="width: 150px" for="mails">E-Mails:
                                        <i class="fa fa-info-circle help-icon" 
                                        placement="bottom" 
                                        container="body"
                                        popoverClass="help-popover-wide"
                                        triggers="mouseenter:mouseleave"
                                        [ngbPopover]="helpMailContent" 
                                        [popoverTitle]="helpMailTitle"
                                        aria-hidden="true"></i>     
                                    </label>
                                    <ng-select [items]="allMails" id="mails"
                                               bindLabel="name"
                                               name="mails"
                                               [multiple]="true"
                                               [clearable]="false"
                                               [(ngModel)]="selectedMails"
                                               [ngModelOptions]="{standalone: true}"
                                    >
                                    </ng-select>

                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="center">
                        <button (click)="handleCreateInputAlarm()"
                                class="submit-button"
                        >Alarm aktivieren
                        </button>
                    </div>

                </form>
            </div>
        </div>
    </div>
</div>
<!-- TodoValidation -->
<div *ngIf="isToDoValidation()">
    <div class="col-xl-10 col-md-12 col-12">
        <div class="card">
            <div class="card-body">
                <form class="form-detail" #alarmForm="ngForm">
                    <div class="row form-left">
                        <h2 class="titel ">Aktivieren Sie die Vorgabenkontrolle
                            <i class="fa fa-info-circle help-icon" 
                            placement="bottom" 
                            container="body"
                            popoverClass="help-popover-wide"
                            triggers="mouseenter:mouseleave"
                            [ngbPopover]="helpTodoContent" 
                            [popoverTitle]="helpTodoTitle"
                            aria-hidden="true"></i>                                             
                        </h2>
                        <br>
                        <p *ngIf="isAlreadyActive()">
                            Die Vorgabenkontrolle ist bereits aktiviert worden. Eine
                            nochmalige Aktivierung ist nicht nötig.                            
                        </p>
                        <div class="errorLine">
                            <div class="alert alert-danger" *ngIf='errorMessage'>{{errorMessage}}</div>
                        </div>
                        <div class="form">
                            <div class="row form-group">
                                <div class="col-lg-6 col-md-12 col-sm-12">
                                    <label>Alarmstyp:</label>
                                    <ng-select [items]="alarmTypeArray"
                                               bindLabel="title"
                                               [multiple]="false"
                                               [clearable]="false"
                                               [(ngModel)]="selectedAlarmType"
                                               [ngModelOptions]="{standalone: true}">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="row form-group">

                                <div class="col-md-12">
                                    <label for="mails">E-Mails :
                                        <i class="fa fa-info-circle help-icon" 
                                        placement="bottom" 
                                        container="body"
                                        popoverClass="help-popover-wide"
                                        triggers="mouseenter:mouseleave"
                                        [ngbPopover]="helpMailContent" 
                                        [popoverTitle]="helpMailTitle"
                                        aria-hidden="true"></i>     
                                    </label>
                                    <ng-select [items]="allMails" id="mails"
                                            bindLabel="name"
                                            name="mails"
                                            [multiple]="true"
                                            [clearable]="true"
                                            [(ngModel)]="selectedMails"
                                            [ngModelOptions]="{standalone: true}"
                                    >
                                    </ng-select>
                                </div>
                            </div>

                            <div class="center">
                                <button (click)="handleCreateTodoAlarm()"
                                        class="submit-button"
                                        [ngClass]="{ active: !isAlreadyActive() }"
                                >Alarm aktivieren
                                </button>
                            </div>

 
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- Plausibilitätsüberprüfung-->
<div *ngIf="isPlauseValidation()">
    <div class="col-xl-10 col-md-12 col-12">
        <div class="card">
            <div class="card-body">
                <form class="form-detail" #alarmForm="ngForm">
                    <div class="row form-left">
                        <h2 class="titel ">Erstellen Sie einen neuen Alarm
                            <i class="fa fa-info-circle help-icon" 
                                    placement="bottom" 
                                    container="body"
                                    popoverClass="help-popover-wide"
                                    triggers="mouseenter:mouseleave"
                                    [ngbPopover]="helpPlauseContent" 
                                    [popoverTitle]="helpPlauseTitle"
                                    aria-hidden="true"></i>     
                        </h2>
                        <br/>
                        <div class="errorLine">
                            <div class="alert alert-danger" *ngIf='errorMessage'>{{errorMessage}}</div>
                        </div>
                        <div class="form">
                            <div class="row form-group">
                                <div class="col-lg-6 col-md-12 col-sm-12">
                                    <label>Alarmtyp:</label>
                                    <ng-select [items]="alarmTypeArray"
                                               bindLabel="title"
                                               [multiple]="false"
                                               [clearable]="false"
                                               [(ngModel)]="selectedAlarmType"
                                               [ngModelOptions]="{standalone: true}">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="row form-group">
                                <div class="col-md-12">
                                    <label>Name:
                                        <i class="fa fa-info-circle help-icon" 
                                        placement="bottom" 
                                        container="body"
                                        popoverClass="help-popover-wide"
                                        triggers="mouseenter:mouseleave"
                                        [ngbPopover]="helpNameContent" 
                                        [popoverTitle]="helpNameTitle"
                                        aria-hidden="true"></i>     
                                    </label>
                                    <input type="text"
                                           name="desc"                                        
                                           size="40"
                                           [(ngModel)]="description">
                                </div>
                            </div>

                            <div class="row form-group">
                                <div class="col-md-6">
                                    <label for="meter">Wählen Sie den 1. Messpunkt aus:
                                        <i class="fa fa-info-circle help-icon" 
                                        placement="bottom" 
                                        container="body"
                                        popoverClass="help-popover-wide"
                                        triggers="mouseenter:mouseleave"
                                        [ngbPopover]="helpMPContent" 
                                        [popoverTitle]="helpMPTitle"
                                        aria-hidden="true"></i>     
                                    </label>
                                    <ng-select [items]="allMeters" id="meter"
                                               bindLabel="values.user0"
                                               name="meters"
                                               [clearable]="true"
                                               [(ngModel)]="selectedMeter1"
                                               [ngModelOptions]="{standalone: true}">
                                               <ng-template ng-option-tmp ng-label-tmp let-item="item">
                                                <label class="icon-width">
                                                    <i *ngIf="item.values.obisid0 == '1-0:1.8.0*255'"
                                                        class="fa fa-bolt icon-width"></i>
                                                    <i *ngIf="item.values.obisid0 == '8-0:1.0.0*255'"
                                                        class="fa fa-tin icon-width"></i>
                                                    <i *ngIf="item.values.obisid0 == '6-0:1.0.0*255'"
                                                        class="fa fa-fire icon-width"></i> <!-- red -->
                                                    <i *ngIf="item.values.obisid0 == '7-0:3.0.0*255'"
                                                        class="fa fa-fire icon-width"></i> <!-- blue -->
                                                    <i *ngIf="item.values.obisid0 == '6-0:2.0.0*255'"
                                                        class="fa fa-tint icon-width"></i>
                                                    <i *ngIf="item.values.obisid0 == '8-0:1.0.0*255'"
                                                        class="fa fa-plug icon-width"></i>
                                                    <i *ngIf="item.values.obisid0 == null"
                                                        class="fa fa-question"></i>
                                                    </label>
                                                {{ item.values.user0 }}
                                            </ng-template>                                               
                                    </ng-select>
                                </div>
                                <div class="col-md-6">
                                    <label for="meter">Wählen Sie den 2. Messpunkt aus:
                                        <i class="fa fa-info-circle help-icon" 
                                        placement="bottom" 
                                        container="body"
                                        popoverClass="help-popover-wide"
                                        triggers="mouseenter:mouseleave"
                                        [ngbPopover]="helpMPContent" 
                                        [popoverTitle]="helpMPTitle"
                                        aria-hidden="true"></i>     
                                    </label>
                                    <ng-select [items]="allMeters" id="meter"
                                               bindLabel="values.user0"
                                               name="meters"
                                               [clearable]="true"
                                               [(ngModel)]="selectedMeter2"
                                               [ngModelOptions]="{standalone: true}">
                                               <ng-template ng-option-tmp ng-label-tmp let-item="item">
                                                <label class="icon-width">
                                                    <i *ngIf="item.values.obisid0 == '1-0:1.8.0*255'"
                                                        class="fa fa-bolt icon-width"></i>
                                                    <i *ngIf="item.values.obisid0 == '8-0:1.0.0*255'"
                                                        class="fa fa-tint icon-width"></i>
                                                    <i *ngIf="item.values.obisid0 == '6-0:1.0.0*255'"
                                                        class="fa fa-fire icon-width"></i> <!-- red -->
                                                    <i *ngIf="item.values.obisid0 == '7-0:3.0.0*255'"
                                                        class="fa fa-fire icon-width"></i> <!-- blue -->
                                                    <i *ngIf="item.values.obisid0 == '6-0:2.0.0*255'"
                                                        class="fa fa-tint icon-width"></i>
                                                    <i *ngIf="item.values.obisid0 == '8-0:1.0.0*255'"
                                                        class="fa fa-plug icon-width"></i>
                                                    <i *ngIf="item.values.obisid0 == null"
                                                        class="fa fa-question"></i>
                                                    </label>
                                                {{ item.values.user0 }}
                                            </ng-template>                                               
                                    </ng-select>
                                </div>
                            </div>
                            <div class="row form-group" >
                                <div class="col-md-6">
                                    <label>Differenz %:
                                        <i class="fa fa-info-circle help-icon" 
                                        placement="bottom" 
                                        container="body"
                                        popoverClass="help-popover-wide"
                                        triggers="mouseenter:mouseleave"
                                        [ngbPopover]="helpDiffContent" 
                                        [popoverTitle]="helpDiffTitle"
                                        aria-hidden="true"></i>     
                                    </label>
                                    <input type="text"
                                           [(ngModel)]="diff"
                                           [imask]="this.percMask" 
                                           [ngModelOptions]="{standalone: true}"
                                            style="width: 100%">
                                </div>
                            </div>
                            <div class="row form-group">
                                <div class="col-md-12">
                                    <label for="mails">E-Mails:
                                        <i class="fa fa-info-circle help-icon" 
                                        placement="bottom" 
                                        container="body"
                                        popoverClass="help-popover-wide"
                                        triggers="mouseenter:mouseleave"
                                        [ngbPopover]="helpMailContent" 
                                        [popoverTitle]="helpMailTitle"
                                        aria-hidden="true"></i>     
                                    </label>
                                    <ng-select [items]="allMails" id="mails"
                                            bindLabel="name"
                                            name="mails"
                                            [multiple]="true"
                                            [clearable]="true"
                                            [(ngModel)]="selectedMails"
                                            [ngModelOptions]="{standalone: true}"
                                    >
                                    </ng-select>
                                </div>
                            </div>

                            <div class="center">
                                <button (click)="handleCreatePlauseAlarm()"
                                        class="submit-button"
                                >Alarm aktivieren
                                </button>
                            </div>

 
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<!-- Ausreisserkontrolle -->
<div *ngIf="isThresholdValidation()">
    <div class="col-xl-10 col-md-12 col-12">
        <div class="card">
            <div class="card-body">
                <form class="form-detail" #alarmForm="ngForm">
                    <div class="row form-left">
                        <h2 class="titel ">Erstellen Sie einen neuen Alarm 
                            <i class="fa fa-info-circle help-icon" 
                                        placement="bottom" 
                                        container="body"
                                        popoverClass="help-popover-wide"
                                        triggers="mouseenter:mouseleave"
                                        [ngbPopover]="helpAusreisserContent" 
                                        [popoverTitle]="helpAusreisserTitle"
                                        aria-hidden="true"></i>     
                        </h2><br/>
                        <div class="errorLine">
                            <div class="alert alert-danger" *ngIf='errorMessage'>{{errorMessage}}</div>
                        </div>
                        <div class="form">
                            <div class="row form-group">
                                <div class="col-lg-6 col-md-12 col-sm-12">
                                    <label>Alarmtyp:</label>
                                    <ng-select [items]="alarmTypeArray"
                                               bindLabel="title"
                                               [multiple]="false"
                                               [clearable]="false"
                                               [(ngModel)]="selectedAlarmType"
                                               [ngModelOptions]="{standalone: true}">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="row form-group">
                                <div class="col-md-12">
                                    <label>Name:
                                        <i class="fa fa-info-circle help-icon" 
                                        placement="bottom" 
                                        container="body"
                                        popoverClass="help-popover-wide"
                                        triggers="mouseenter:mouseleave"
                                        [ngbPopover]="helpNameContent" 
                                        [popoverTitle]="helpNameTitle"
                                        aria-hidden="true"></i>     
                                    </label>

                                    <input type="text"
                                           name="desc"   
                                           size="40"
                                           [(ngModel)]="description">
                                </div>
                            </div>
                            <div class="row form-group" >
                                <div class="col-md-6">
                                    <label>Prozentual:
                                        <i class="fa fa-info-circle help-icon" 
                                        placement="bottom" 
                                        container="body"
                                        popoverClass="help-popover-wide"
                                        triggers="mouseenter:mouseleave"
                                        [ngbPopover]="helpPerc2Content" 
                                        [popoverTitle]="helpPerc2Title"
                                        aria-hidden="true"></i>                                             
                                    </label>
                                    <input type="text"
                                           [(ngModel)]="perc"
                                           [imask]="this.diffMask" 
                                           [ngModelOptions]="{standalone: true}"
                                            style="width: 100%">
                                </div>
                                <div class="col-md-6">
                                    <label>Absolut:
                                        <i class="fa fa-info-circle help-icon" 
                                        placement="bottom" 
                                        container="body"
                                        popoverClass="help-popover-wide"
                                        triggers="mouseenter:mouseleave"
                                        [ngbPopover]="helpAbsContent" 
                                        [popoverTitle]="helpAbsTitle"
                                        aria-hidden="true"></i> 
                                    </label>
                                        <input type="text"
                                            [(ngModel)]="absolute"
                                            [imask]="this.diffMask" 
                                            [ngModelOptions]="{standalone: true}"
                                            style="width: 100%">
                                </div>
                            </div>
                            <div class="row form-group">
                                <div class="col-md-6">
                                    <label>Ausreisser nach Median:
                                        <i class="fa fa-info-circle help-icon" 
                                        placement="top" 
                                        container="body"
                                        popoverClass="help-popover-wide"
                                        triggers="mouseenter:mouseleave"
                                        [ngbPopover]="helpMedianContent" 
                                        [popoverTitle]="helpMedianTitle"
                                        aria-hidden="true"></i>     
                                    </label>
                                </div>
                                <div class="col-md-6">
                                    <div class="input-group">
                                        <input type="checkbox"
                                           [(ngModel)]="medianActive"
                                           [ngModelOptions]="{standalone: true}"
                                           class="input-group-append margin-top" >
                                           <span class="input-group-append ">&nbsp; aktivieren</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row form-group">
                                <div class="col-md-10">
                                    <label for="meter">Wählen Sie die Messpunkte aus:
                                        <i class="fa fa-info-circle help-icon" 
                                        placement="bottom" 
                                        container="body"
                                        popoverClass="help-popover-wide"
                                        triggers="mouseenter:mouseleave"
                                        [ngbPopover]="helpMP2Content" 
                                        [popoverTitle]="helpMP2Title"
                                        aria-hidden="true"></i>     
                                    </label>
                                    <ng-select [items]="allMeters" id="meter"
                                               bindLabel="values.user0"
                                               name="meters"
                                               [multiple]="true"
                                               [clearable]="true"
                                               [(ngModel)]="selectedMeters"
                                               [ngModelOptions]="{standalone: true}">
                                               <ng-template ng-option-tmp ng-label-tmp let-item="item">
                                                <label class="icon-width">
                                                    <i *ngIf="item.values.obisid0 == '1-0:1.8.0*255'"
                                                        class="fa fa-bolt icon-width"></i>
                                                    <i *ngIf="item.values.obisid0 == '8-0:1.0.0*255'"
                                                        class="fa fa-tin icon-width"></i>
                                                    <i *ngIf="item.values.obisid0 == '6-0:1.0.0*255'"
                                                        class="fa fa-fire icon-width"></i> <!-- red -->
                                                    <i *ngIf="item.values.obisid0 == '7-0:3.0.0*255'"
                                                        class="fa fa-fire icon-width"></i> <!-- blue -->
                                                    <i *ngIf="item.values.obisid0 == '6-0:2.0.0*255'"
                                                        class="fa fa-tint icon-width"></i>
                                                    <i *ngIf="item.values.obisid0 == '8-0:1.0.0*255'"
                                                        class="fa fa-plug icon-width"></i>
                                                    <i *ngIf="item.values.obisid0 == null"
                                                        class="fa fa-question"></i>
                                                    </label>
                                                {{ item.values.user0 }}
                                            </ng-template>                                                             
                                    </ng-select>
                                </div>
                                <div class="col-md-2">
                                    <label>&nbsp;</label>
                                    <button type="button" class="btn btn-primary"
                                        (click)="addAllMeters()">
                                        Alle 
                                    </button>
                                </div>
                            </div>
                            <div class="row form-group">
                                <div class="col-md-12">
                                    <label for="mails">E-Mails:
                                        <i class="fa fa-info-circle help-icon" 
                                        placement="bottom" 
                                        container="body"
                                        popoverClass="help-popover-wide"
                                        triggers="mouseenter:mouseleave"
                                        [ngbPopover]="helpMailContent" 
                                        [popoverTitle]="helpMailTitle"
                                        aria-hidden="true"></i>     
                                    </label>
                                    <ng-select [items]="allMails" id="mails"
                                            bindLabel="name"
                                            name="mails"
                                            [multiple]="true"
                                            [clearable]="true"
                                            [(ngModel)]="selectedMails"
                                            [ngModelOptions]="{standalone: true}"
                                    >
                                    </ng-select>
                                </div>
                            </div>

                            <div class="center">
                                <button (click)="handleCreateThresholdAlarm()"
                                        class="submit-button"
                                >Alarm aktivieren
                                </button>
                            </div>

 
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<!-- MinMaxüberprüfung-->
<div class="row" *ngIf="isMinMaxValidation()">
    <div class="col-xl-10 col-md-12 col-12">
        <div class="card">
            <div class="card-body">
                <form class="form-detail" #alarmForm="ngForm">
                    <div class="row form-left">
                        <h2 class="titel ">Erstellen Sie einen neuen Alarm
                            <i 
                                class="fa fa-info-circle help-icon" 
                                placement="bottom" 
                                container="body"
                                popoverClass="help-popover-wide"
                                triggers="mouseenter:mouseleave"
                                [ngbPopover]="helpProcessInterpolatedContent" 
                                [popoverTitle]="helpProcessInterpolatedTitle"></i>     
                        </h2>
                        <br/>
                        <div class="errorLine">
                            <div class="alert alert-danger" *ngIf='errorMessage'>{{errorMessage}}</div>
                        </div>
                        <div class="form">
                            <div class="row form-group">
                                <div class="col-lg-6 col-md-12 col-sm-12">
                                    <label>Alarmtyp:
                                        <i *ngIf="isMinMaxReportValidation()"
                                        class="fa fa-info-circle help-icon" 
                                        placement="bottom" 
                                        container="body"
                                        popoverClass="help-popover-wide"
                                        triggers="mouseenter:mouseleave"
                                        [ngbPopover]="helpAlarmReportContent" 
                                        [popoverTitle]="helpAlarmReportTitle"></i>                                        
                                    </label>
                                    <ng-select [items]="alarmTypeArray"
                                               bindLabel="title"
                                               [multiple]="false"
                                               [clearable]="false"
                                               [(ngModel)]="selectedAlarmType"
                                               [ngModelOptions]="{standalone: true}">
                                    </ng-select>
                                </div>                                    
                            </div>
                            <div class="row form-group">
                                <div class="col-md-12">
                                    <label>Name:
                                        <i class="fa fa-info-circle help-icon" 
                                        placement="bottom" 
                                        container="body"
                                        popoverClass="help-popover-wide"
                                        triggers="mouseenter:mouseleave"
                                        [ngbPopover]="helpNameContent" 
                                        [popoverTitle]="helpNameTitle"
                                        aria-hidden="true"></i>                                             
                                    </label>

                                    <input type="text"
                                           name="desc"
                                           size="40"
                                           [(ngModel)]="description">
                                </div>
                            </div>
                            <div class="row form-group" >
                                <div class="col-md-6">
                                    <label>Minimaler Wert:
                                        <i class="fa fa-info-circle help-icon" 
                                        placement="bottom" 
                                        container="body"
                                        popoverClass="help-popover-wide"
                                        triggers="mouseenter:mouseleave"
                                        [ngbPopover]="helpMinContent" 
                                        [popoverTitle]="helpMinTitle"
                                        aria-hidden="true"></i>           
                                    </label>
                                    <input type="text"
                                           [(ngModel)]="minValue"
                                           [imask]="this.diffMask"
                                           [ngModelOptions]="{standalone: true}"
                                            style="width: 100%">
                                </div>
                                <div class="col-md-6">
                                    <label>Maximaler Wert:
                                        <i class="fa fa-info-circle help-icon" 
                                        placement="bottom" 
                                        container="body"
                                        popoverClass="help-popover-wide"
                                        triggers="mouseenter:mouseleave"
                                        [ngbPopover]="helpMaxContent" 
                                        [popoverTitle]="helpMaxTitle"
                                        aria-hidden="true"></i>           
                                    </label>
                                    <input type="text"
                                           [(ngModel)]="maxValue"
                                           [imask]="this.diffMask"
                                           [ngModelOptions]="{standalone: true}"
                                            style="width: 100%">
                                </div>
                            </div>
                            <div class="row form-group"
                                *ngIf="selectedAlarmType?.name == 'minmax_report'">
                                <div class="col-md-6">
                                    <label>Erstelle Bericht:
                                        <i class="fa fa-info-circle help-icon" 
                                        placement="bottom" 
                                        container="body"
                                        popoverClass="help-popover-wide"
                                        triggers="mouseenter:mouseleave"
                                        [ngbPopover]="helpReportContent" 
                                        [popoverTitle]="helpReportTitle"
                                        aria-hidden="true"></i>           
                                    </label>
                                    <ng-select [items]="allReports"
                                        bindLabel="name"
                                        [multiple]="false"
                                        [clearable]="false"
                                        [(ngModel)]="selectedReport"
                                        [ngModelOptions]="{standalone: true}">
                                    </ng-select>

                                </div>
                            </div>
                            <div class="row form-group">
                                <div class="col-md-10">
                                    <label for="meter">Wählen Sie die Messpunkte aus:
                                        <i class="fa fa-info-circle help-icon" 
                                        placement="bottom" 
                                        container="body"
                                        popoverClass="help-popover-wide"
                                        triggers="mouseenter:mouseleave"
                                        [ngbPopover]="helpMP2Content" 
                                        [popoverTitle]="helpMP2Title"
                                        aria-hidden="true"></i>     
                                    </label>
                                    <ng-select [items]="allMeters" id="meter"
                                               bindLabel="values.user0"
                                               name="meters"
                                               [multiple]="true"
                                               [clearable]="true"
                                               [(ngModel)]="selectedMeters"
                                               [ngModelOptions]="{standalone: true}">
                                               <ng-template ng-option-tmp ng-label-tmp let-item="item">
                                                <label class="icon-width">
                                                    <i *ngIf="item.values.obisid0 == '1-0:1.8.0*255'"
                                                        class="fa fa-bolt icon-width"></i>
                                                    <i *ngIf="item.values.obisid0 == '8-0:1.0.0*255'"
                                                        class="fa fa-tin icon-width"></i>
                                                    <i *ngIf="item.values.obisid0 == '6-0:1.0.0*255'"
                                                        class="fa fa-fire icon-width"></i> <!-- red -->
                                                    <i *ngIf="item.values.obisid0 == '7-0:3.0.0*255'"
                                                        class="fa fa-fire icon-width"></i> <!-- blue -->
                                                    <i *ngIf="item.values.obisid0 == '6-0:2.0.0*255'"
                                                        class="fa fa-tint icon-width"></i>
                                                    <i *ngIf="item.values.obisid0 == '8-0:1.0.0*255'"
                                                        class="fa fa-plug icon-width"></i>
                                                    <i *ngIf="item.values.obisid0 == null"
                                                        class="fa fa-question"></i>
                                                    </label>
                                                {{ item.values.user0 }}
                                            </ng-template>                                                  </ng-select>
                                </div>
                                <div class="col-md-2">
                                    <label>&nbsp;</label>
                                    <button type="button" class="btn btn-primary"
                                        (click)="addAllMeters()">
                                        Alle 
                                    </button>
                                </div>
                                <div class="col-md-12">
                                    <label>E-Mail senden:
                                        <i class="fa fa-info-circle help-icon" 
                                        placement="bottom" 
                                        container="body"
                                        popoverClass="help-popover-wide"
                                        triggers="mouseenter:mouseleave"
                                        [ngbPopover]="helpSendContent" 
                                        [popoverTitle]="helpSendTitle"
                                        aria-hidden="true"></i>                                             
                                    </label>
                                    <ng-select [items]="mailLevelsArray"
                                               (focus)="getSelectedMailLevel()"
                                               name="mailLevel"
                                               [clearable]="false"
                                               [multiple]="false"
                                               [(ngModel)]="mailLevel"
                                               [ngModelOptions]="{standalone: true}"
                                    ></ng-select>
                                </div>
                                <div class="col-md-12">
                                    <label style="width: 150px" for="mails">E-Mails:
                                        <i class="fa fa-info-circle help-icon" 
                                        placement="bottom" 
                                        container="body"
                                        popoverClass="help-popover-wide"
                                        triggers="mouseenter:mouseleave"
                                        [ngbPopover]="helpMailContent" 
                                        [popoverTitle]="helpMailTitle"
                                        aria-hidden="true"></i>     
                                    </label>
                                    <ng-select [items]="allMails" id="mails"
                                               bindLabel="name"
                                               name="mails"
                                               [multiple]="true"
                                               [clearable]="false"
                                               [(ngModel)]="selectedMails"
                                               [ngModelOptions]="{standalone: true}"
                                    >
                                    </ng-select>

                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="center">
                        <button (click)="handleCreateMinMaxAlarm()"
                                class="submit-button"
                        >Alarm aktivieren
                        </button>
                    </div>

                </form>
            </div>
        </div>
    </div>
</div>
<!-- Budgetkontrolle -->
<div class="row" *ngIf="isBudgetValidation()">
    <div class="col-xl-10 col-md-12 col-12">
        <div class="card">
            <div class="card-body">
                <form class="form-detail" #alarmForm="ngForm">
                    <div class="row form-left">
                        <h2 class="titel ">Erstellen Sie einen neuen Alarm
                            <i class="fa fa-info-circle help-icon" 
                            placement="bottom" 
                            container="body"
                            popoverClass="help-popover-wide"
                            triggers="mouseenter:mouseleave"
                            [ngbPopover]="helpBudgetControlContent" 
                            [popoverTitle]="helpBudgetControlTitle"
                            aria-hidden="true"></i>              
                        </h2>
                        <br/>
                        <div class="errorLine">
                            <div class="alert alert-danger" *ngIf='errorMessage'>{{errorMessage}}</div>
                        </div>
                        <div class="form">
                            <div class="row form-group">
                                <div class="col-lg-6 col-md-12 col-sm-12">
                                    <label>Alarmtyp:</label>
                                    <ng-select [items]="alarmTypeArray"
                                               bindLabel="title"
                                               [multiple]="false"
                                               [clearable]="false"
                                               [(ngModel)]="selectedAlarmType"
                                               [ngModelOptions]="{standalone: true}">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="row form-group">
                                <div class="col-md-12">
                                    <label>Name:
                                        <i class="fa fa-info-circle help-icon" 
                                        placement="bottom" 
                                        container="body"
                                        popoverClass="help-popover-wide"
                                        triggers="mouseenter:mouseleave"
                                        [ngbPopover]="helpNameContent" 
                                        [popoverTitle]="helpNameTitle"
                                        aria-hidden="true"></i>                                             
                                    </label>

                                    <input type="text"
                                           name="desc"                                        
                                           size="40"
                                           [(ngModel)]="description">
                                </div>
                            </div>

                            <div class="row form-group">
                                <div class="col-md-10">
                                    <label for="meter">Wählen Sie einen Messpunkt:
                                        <i class="fa fa-info-circle help-icon" 
                                        placement="bottom" 
                                        container="body"
                                        popoverClass="help-popover-wide"
                                        triggers="mouseenter:mouseleave"
                                        [ngbPopover]="helpMP2Content" 
                                        [popoverTitle]="helpMP2Title"
                                        aria-hidden="true"></i>     
                                    </label>
                                    <ng-select [items]="allVmps" id="meter"
                                               bindLabel="values.user0"
                                               name="meters"
                                               [clearable]="true"
                                               [(ngModel)]="selectedMeter"
                                               [ngModelOptions]="{standalone: true}">
                                               <ng-template ng-option-tmp ng-label-tmp let-item="item">
                                                <label class="icon-width">
                                                    <i *ngIf="item.values.obisid0 == '1-0:1.8.0*255'"
                                                        class="fa fa-bolt icon-width"></i>
                                                    <i *ngIf="item.values.obisid0 == '8-0:1.0.0*255'"
                                                        class="fa fa-tin icon-width"></i>
                                                    <i *ngIf="item.values.obisid0 == '6-0:1.0.0*255'"
                                                        class="fa fa-fire icon-width"></i> <!-- red -->
                                                    <i *ngIf="item.values.obisid0 == '7-0:3.0.0*255'"
                                                        class="fa fa-fire icon-width"></i> <!-- blue -->
                                                    <i *ngIf="item.values.obisid0 == '6-0:2.0.0*255'"
                                                        class="fa fa-tint icon-width"></i>
                                                    <i *ngIf="item.values.obisid0 == '8-0:1.0.0*255'"
                                                        class="fa fa-plug icon-width"></i>
                                                    <i *ngIf="item.values.obisid0 == null"
                                                        class="fa fa-question"></i>
                                                    </label>
                                                {{ item.values.user0 }}
                                            </ng-template>                                                             
                                    </ng-select>
                                </div>
                                <div class="col-md-12">
                                    <label for="repeat">Kontrollzeitraum:</label>
                                    <ng-select [items]="recurrenceArray"
                                                id="repeat"
                                                [multiple]="false"
                                                [clearable]="false"
                                                [(ngModel)]="selectedPeriod"
                                                [ngModelOptions]="{standalone: true}">
                                    </ng-select>
                                </div>
                                <div class="col-md-12">
                                    <label>Budget:
                                    </label>
                                    <div class="input-group-append">
                                        <input type="text" class="input-group"
                                            [(ngModel)]="diff"
                                            [imask]="this.diffMask" 
                                            [ngModelOptions]="{standalone: true}"
                                            style="width: 100%">
                                            &nbsp;
                                            <div class="input-group-append">
                                                <select style="width: 150px" id="budget"
                                                    [(ngModel)]="budgetBase" name="budget"
                                                        #name="ngModel"
                                                        [ngModelOptions]="{standalone: true}">
                                                        >
                                                    <option value="0">Verbrauch</option>
                                                    <option value="1">Kosten</option>
                                                </select>
                                            </div>
                                    </div>
                                </div>
                                
                               

                                <div class="col-md-12">
                                    <label style="width: 150px" for="mails">E-Mails:
                                        <i class="fa fa-info-circle help-icon" 
                                        placement="bottom" 
                                        container="body"
                                        popoverClass="help-popover-wide"
                                        triggers="mouseenter:mouseleave"
                                        [ngbPopover]="helpMailContent" 
                                        [popoverTitle]="helpMailTitle"
                                        aria-hidden="true"></i>     
                                    </label>
                                    <ng-select [items]="allMails" id="mails"
                                               bindLabel="name"
                                               name="mails"
                                               [multiple]="true"
                                               [clearable]="false"
                                               [(ngModel)]="selectedMails"
                                               [ngModelOptions]="{standalone: true}"
                                    >
                                    </ng-select>

                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="center">
                        <button (click)="handleCreateBudgetAlarm()"
                                class="submit-button"
                        >Alarm aktivieren
                        </button>
                    </div>

                </form>
            </div>
        </div>
    </div>
</div>



<!-- Hilfetexte -->
<ng-template #helpNameContent>
    <p>Der Name wird in der Verwaltung (Konfiguration -> Alarme) zur Identifizierung 
       verwendet. Versendet der Alarm eine E-Mail, so entspricht der Betreff der E-Mail
       dem hier vergebenen Namen.
    </p>
    <p>Der Name kann optional einen der folgenden Platzhalter enthalten. Der Platzhalter
       wird beim Versenden der E-Mail basierend auf dem akt. Datum angepaßt: </p>
        <UL>
            <li><kbd>$&#123;DATUM}</kbd><br/>
                Wird durch das aktuelle Datum ersetzt: z.B. "02.01.2021"
            </li>
            <li><kbd>$&#123;KW}</kbd><br/>
                Entspricht der aktuellen Kalenderwoche z.B. "40/2020"
            </li>
            <li><kbd>$&#123;JAHR}</kbd><br/>
                Wird durch das aktuelle Jahr ersetzt: z.B. "2021"
            </li>
            <li><kbd>$&#123;QUARTAL}</kbd><br/>
                Wird durch das aktuelle Quartal ersetzt: z.B. "01/2021"
            </li>
            <li><kbd>$&#123;MONAT}</kbd><br/>
                Wird durch den aktuellen Monat ersetzt: z.B. "März 2021"
            </li>
            <li><kbd>$&#123;ZEIT}</kbd><br/>
                Wird durch die aktuelle Zeit ersetzt: z.B. "20:15"
            </li>
        </UL>
</ng-template>
<ng-template #helpNameTitle>Der Name...</ng-template>

<ng-template #helpMPContent>
    <p>Für einen gegebenen Zeitpunkt wird die Differenz zwischen den Messwerten zweier Messpunkte
        bestimmt. Dazu müssen in den Feldern "Messpunkt 1" und "Messpunkt 2" je ein Messpunkt 
        festgelegt werden.
    </p>
</ng-template>
<ng-template #helpMPTitle>Messpunkte</ng-template>

<ng-template #helpDiffContent>
    <p>Bestimmen Sie hier die max. prozentuale Abweichung ("PA"). Diese Abweichung wird wie folgt 
       kontrolliert:
    </p>
    <p>Für einen Zeitpunkt wird jeweils der dann gültige Messwert für Messpunkt 1 und 2 
       bestimmt. Der Wert von Messpunkt 1 (w1) entspricht 100%. Der Wert von Messpunkt 2 (w2) darf 
       dann nur +/- PA Prozent von w1 abweichen.       
    </p>
    
</ng-template>
<ng-template #helpDiffTitle>Differenz in Prozent</ng-template>

<ng-template #helpMailContent>
    <p>Bestimmen Sie hier, an wen das Ergebnis des Alarms per E-Mail verschickt werden soll.
    </p>
    
</ng-template>
<ng-template #helpMailTitle>E-Mail versenden </ng-template>

<ng-template #helpPerc2Content>
    <p>Legt fest, wie groß die Abweichung in Prozent zwischen zwei aufeinander folgenden
       Messwerten sein darf. 
    </p>
    <p><em>Beispiel:</em><br/>
       Der aktuelle Messwert entspricht 100% und der vorherige Messwert wird dagegen verglichen. 
       Haben Sie z.B. 10 eingeben, so wird der Alarm ausgelöst, wenn die  Abweichung größer als +/-10%
       ist.
    </p>
    
</ng-template>
<ng-template #helpPerc2Title>Prozentuale Abweichung</ng-template>

<ng-template #helpAbsContent>
    <p>Legt fest, wie groß die absolute Abweichung zwischen zwei aufeinander folgenden
       Messwerten eines Messpunktes maximal sein darf. 
    </p>
    <p><em>Beispiel:<br/></em>
       Sie geben als maximale Abweichung 100 ein. Messwert 1 um 8:00 Uhr beträgt 10.000, 
       Messwert 2 um 8:15 Uhr beträgt 10.250. 
    </p>
    <p>absolute Abweichung: 10.250 - 10.000 = 250  --> Alarm wird ausgelöst.</p>
</ng-template>
<ng-template #helpAbsTitle>Absolute Abweichung</ng-template>

<ng-template #helpMedianContent>
<p>Wenn aktiviert, wird der Wert bestimmt, unterhalb dessen denen 25% der niedrigsten Werte ("Q1")
   der verfügbaren Messwerte und der Wert über dem 25% der höchsten Werte zu finden sind ("Q3").
   Die Distanz zwischen den Werten ist "Dist" (Q3 - Q1).
</p>   
<p>Ausreisser sind nun Werte, die </p>
    <UL>
        <lI>Kleiner sind als  Q1 - 1,5 * Dist</lI>
        <LI>oder größer sind als Q3 + 1,5 * Dist</LI>
    </UL>
    <p>Wenn aktiviert, werden Ausreisser in die Interpolation nicht mit einbezogen.</p>
  
</ng-template>
<ng-template #helpMedianTitle>Absolute Abweichung</ng-template>

<ng-template #helpMP2Content>
    <p>Hier legen Sie fest, für welche Messpunkte der Alarm aktiviert werden soll. 
    </p>
    <p>Wenn Sie auf <kbd>Alle</kbd> klicken, werden alle <em>aktuell</em> der Firma 
       zugeordneten Messpunkte ausgewählt.</p>
</ng-template>
<ng-template #helpMP2Title>Messpunkte</ng-template>

<ng-template #helpMinContent>
    <p>Der Alarm wird ausgelöst, wenn ein Messwert diesen absoluten Wert unterschreitet.
    </p>
</ng-template>
<ng-template #helpMinTitle>Minimaler Wert</ng-template>

<ng-template #helpMaxContent>
    <p>Der Alarm wird ausgelöst, wenn ein Messwert diesen absoluten Wert überschreitet.
    </p>
</ng-template>
<ng-template #helpMaxTitle>Maximaler Wert</ng-template>

<ng-template #helpSendContent>
    <p>Ist eine Alarmkontrolle durchgelaufen, so kann Ihnen das Ergebnis zugesendet werden.
        Wann soll der Alarm Ihnen eine E-Mail senden? 
    </p>
</ng-template>
<ng-template #helpSendTitle>E-Mail senden</ng-template>

<ng-template #helpTodoContent>
    <p>Eine Vorgabe ist ein Abgabetermin einer zu erledigenden Aufgabe. Diese Aufgabe
       muss bis zum festgelegten Zeitpunkt auf "Erledigt" gesetzt werden. Vorgaben können 
       Sie unter "Konfiguration -&gt; Vorgaben" erstellen, ändern und löschen.
    </p>
    <p>Den Status der Vorgaben ändern Sie im Reiter "Vorgaben".</p>
    <p>Die Kontrolle testet einmal täglich, ob die eingetragenen Termine fällig 
       (Abgabetermin in weniger als 2 Tagen) oder überfällig sind (Abgabetermin verstrichen).
       Ist dies der Fall wird eine E-Mail an die angegebenen Adressen verschickt.
    </p>
</ng-template>
<ng-template #helpTodoTitle>Vorgabekontrolle</ng-template>

<ng-template #helpReportContent>
    <p>Wird der minimale Wert unterschritten oder der maximale Wert überschritten, so wird dieser
       Bericht erstellt. Der Berichtszeitraum umfasst dann die letzten 12 Stunden und wird für die
       ausgewählten und betroffenen Messpunkte erstellt.
    </p>
    <p><em>Bitte beachten Sie:</em><br/>
       Es wird für jeden Messpunkt ein Bericht erstellt. Haben Sie also z.B. 3 Messpunkte bestimmt und 
       zwei davon reissen die Schwellwerte, dann werden 2 Berichte verschickt! 
    </p>
</ng-template>
<ng-template #helpReportTitle>Bericht erstellen</ng-template>

<ng-template #helpAlarmReportContent>
    <p>Um einen Bericht zu erstellen, wenn ein Schwellwert verletzt wird, gehen Sie bitte 
       wie folgt vor:
    </p>
    <OL>
        <LI>Erstellen Sie einen geplanten Bericht Ihrer Wahl. Wichtig sind E-Mail, Name und
            Berichtszeitraum und stellen Sie den Bericht auf "inaktiv". Alle anderen Parameter
            werden durch den Alarm überschrieben.
        </LI>
        <LI>Legen Sie einen Alarm an und wählen den im ersten Schritt angelegten Bericht aus</LI>
        <LI>Jedesmal, wenn Daten für einen der ausgewählten Messpunkte überprüft werden und
            der/die Schwellwert(e) verletzt werden, so wird der Bericht erstellt und per E-Mail
            verschickt.
        </LI>
        <li>Die interpolierten Daten werden regelmäßig alle 90 min überprüft.</li>
        <LI>Der Bericht wird nur für den Messpunkt erstellt, bei dem die Schwellwertverletzung
            erkannt wurde. Wurden mehrere Messpunkte angegeben und alle haben eine Verletzung, 
            so wird für jeden Messpunkt ein Bericht erstellt und verschickt.
        </LI>
    </OL>
    <P><EM>Wichtig:</EM><br/>
    Es werden immer nur die letzten 90 min. bzw. die Daten seit der letzten gültigen Überprüfung
    beachtet. D.h. laufen Daten mehrmals die Stunde auf, dann wird alle 90 min geprüft, ansonsten
    seit der letzten erfolgreichen Überprüfung.
    </P>
</ng-template>
<ng-template #helpAlarmReportTitle>Schwellwerte kontrollieren und Bericht erstellen</ng-template>

<ng-template #helpProcessInterpolatedContent>
    <p>
        Die Überprüfung von Schwellwerten (mit Energiebericht):
    </p>
    <UL>
        <li>nutzt interpolierte Werte</li>
        <li>prüft die Daten, die seit dem letzten Start angefallen sind. Bei der ersten
            Kontrolle werden die Daten der letzten 24 Stunden kontrolliert, ansonsten
            die Daten der letzten 2 Stunden.
        </li>
        <li>Zwischen zwei Kontrollläufen vergehen ca. 60 min</li>
    </UL>
</ng-template>
<ng-template #helpProcessInterpolatedTitle>Bedingungen</ng-template>

<ng-template #helpQueueControlContent>
    <p>
        Die Überprüfung von Messwertkontrollen
    </p>
    <UL>
        <li>Nutzt die von den Messpunkten gelieferten Orginalwerte</li>
        <li>Sind seit mindestens 40 min keine Daten angekommen, wird
            der Alarm ausgelöst.
        </li>
        <li>Die Werte werden jedesmal kontrolliert, wenn Daten ins System
            importiert werden (manuell oder automatisch)
        </li>
    </UL>
</ng-template>
<ng-template #helpQueueControlTitle>Bedingungen</ng-template>

<ng-template #helpAusreisserContent>
    <p>
        Die Ausreisserkontrolle:
    </p>
    <UL>
        <li>nutzt die von den Messpunkten gelieferten Orginalwerte</li>
        <li>prüft die absolute Differenz zweier aufeinander folgender Werte 
            gegen einen absoluten Wert (falls eingetragen)
        </li>
        <li>und / oder prüft ob die Differenz zweier aufeinander folgender Werte
            nicht größer ist als ein eingetragener Prozentwert.
        </li>
        <li>Die Werte werden jedesmal kontrolliert, wenn Daten ins System
            importiert werden (manuell oder automatisch)
        </li>
    </UL>
</ng-template>
<ng-template #helpAusreisserTitle>Bedingungen</ng-template>

<ng-template #helpPlauseContent>
    <p>
        Die Plausibilitätsüberprüfung:
    </p>
    <UL>
        <li>nutzt die interpolierten Werte der Messpunkte.</li>
        <li>und / oder prüft ob die Differenz zweier aufeinander folgender Werte
            nicht größer ist als ein eingetragener Prozentwert.
        </li>
        <li>Die Prüfung läuft alle 60 min und prüft die Daten der letzten 120 min.
            Läuft die Prüfung zum ersten Mal, werden die Daten der letzten 24 Stunden
            kontrolliert.
        </li>
    </UL>
</ng-template>
<ng-template #helpPlauseTitle>Bedingungen</ng-template>

<ng-template #helpHoursContent>
    <p>
        Maximale Toleranz in Stunden beschreibt die Zeit in Stunden,
        die zwischen der aktuellen Zeit und dem letzten Zeitpunkt, für
        den Daten vorliegen, liegen darf.
    </p>
    <p>Der Standardwert ist 0, was bedeutet, das 40 min lang Daten fehlen
       dürfen. Der maximale Wert ist 36.
    </p>
    <p><EM>Wichtig:</EM><br/>Es wird immer nur die letzte Zeit, für die Daten
        vorliegen, als Basis für die Kontrolle genutzt. 
        Es wird nicht geprüft, ob evtl. vor diesem Zeitpunkt Daten fehlen!</p>
</ng-template>
<ng-template #helpHoursTitle>Maximale Toleranz</ng-template>