import { environment } from 'src/environments/environment.prod'
export class Global {
  // public static apiUrl : string = environment.apiUrl;       // http://localhost:5000/
  // public static legacyUrl : string = environment.legacyUrl; // "http://localhost:8081/";
  public static apiUrl : string = "https://api.enmonitor.de/"
  public static legacyUrl : string = "https://enmonitor.de/";
  public static ATTRIBUTE_USER_OBJECT : string = 'authenticatedUser';
  public static ATTRIBUTE_USER_TOKEN : string = 'usertoken';
  public static POPUP_REFRESH_TIMEOUT : number = 10000;   // Zeit in Sekunden zwischen Popup-Updates
  
  // Konstanten für Signale
  public static SIGNAL_DEFAULT_ALARMS    : string = "default_alarms";
  public static SIGNAL_DELETE_LAST_INPUT : string = "delete_last_input";    /// Bei manuellem Import: Lösche letzte Eingabe
  public static SIGNAL_METER_ID          : string = "unique_meter_id";      /// Nutzt den Bool'schen Parameter
  public static SIGNAL_REFRESH_ALARMS    : string = "refresh_alarms";
  public static SIGNAL_REFRESH_COLUMNS   : string = "refresh_columns";
  public static SIGNAL_REFRESH_COMPANY   : string = "refresh_company";      /// Wenn die Liste der Firmen geladen wurde
  public static SIGNAL_REFRESH_CONTAINER : string = "refresh_container";
  public static SIGNAL_REFRESH_COST      : string = "refresh_cost";
  public static SIGNAL_REFRESH_COST_CALC : string = "recalc_cost";
  public static SIGNAL_REFRESH_COSTUNIT  : string = "refresh_costunit";
  public static SIGNAL_REFRESH_EVENTS    : string = "refresh_events";
  public static SIGNAL_REFRESH_IMPORTS   : string = "refresh_imports";
  public static SIGNAL_REFRESH_METER     : string = "refresh_meter";
  public static SIGNAL_REFRESH_POPUPS    : string = "refresh_popups";
  public static SIGNAL_REFRESH_REPORTS   : string = "refresh_reports";
  public static SIGNAL_REFRESH_TABLES    : string = "refresh_tables";
  public static SIGNAL_REFRESH_TARIFF    : string = "refresh_tariff";
  public static SIGNAL_REFRESH_TARIFF_KO : string = "refresh_tariff_ko";
  public static SIGNAL_REFRESH_TODO      : string = "refresh_todo";
  public static SIGNAL_REFRESH_VMP       : string = "refresh_vmp";
  public static SIGNAL_REFRESH_WEATHER   : string = "refresh_weather";


  public static SIGNAL_REFRESH_COMPANY_INFO         : string = "refresh_company_info"; // Für firm-info-component
  public static SIGNAL_SET_COMPANY_CHANGE_ACTIVE    : string = "set_company_change_active";
  public static SIGNAL_COMPANY_SELECTED             : string = "company_selected";      // Wenn die Firma neu gewählt wird
  // Werte im Sessionstorage
  public static SESSION_COMPANY : string = "session_company";   // Object der akt. ausgewählten Firma
}
