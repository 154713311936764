<nav class="top-navbar navbar navbar-expand-md navbar-light  sticky-top bg-primary">
  <a class="nav-link navbar-brand" href="/home">
    <img src="assets/logo.png" width="180">
  </a>
  <!-- -->
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>


  <div class="navbarbg collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="ml-auto float-right navbar-nav">
      <li class="nav-item dropdown">
        <a class="dropdown-toggle nav-link" href="#" 
           id="navbarDropdown" role="button" data-toggle="dropdown" 
           aria-haspopup="true" aria-expanded="false">
          <i class="fa fa-bell">
            <span class="badge" style="font-family: 'Roboto', sans-serif;" *ngIf='count > 0'> {{ count }}</span>
          </i>

        </a>
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" (click)="showAll()">Alle zeigen</a>
          <a class="dropdown-item" (click)="deleteAll()">Alle l&ouml;schen</a>
        </div>
      </li>
      <li class="nav-item dropdown">
        <a class="dropdown-toggle nav-link" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i *ngIf="isUser()" ngbTooltip="Benutzerrechte"
             class="fa fa fa-user-o"></i>
          <i *ngIf="isModerator()" ngbTooltip="Moderatorrechte"
             class="fas fa fa-user"></i>
          <i *ngIf="isAdmin()" ngbTooltip="Administratorrechte"
             class="fa fa-user-secret"></i>
          {{ getUserName() }}
        </a>
        <div class="dropdown-menu dropdown-menu-right" 
            aria-labelledby="navbarDropdown">
          <a class="dropdown-item" href="#">
            <i class="fa fa-sign-out" 
                (click)="logout()"
                aria-hidden="true"></i>
            Abmelden</a>
        </div>
      </li>
    </ul>

  </div>
</nav>
