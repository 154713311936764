import { Component, OnInit } from '@angular/core';
import { Meter } from 'src/app/classes/meter';
import { MContainer } from 'src/app/classes/container';
import { ConfigEvent, ConfigService } from 'src/app/services/config.service';
import { Global } from 'src/constants/global';
import { v4 as uuid } from 'uuid';

declare var $: any;

@Component({
  selector: 'app-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss']
})
export class ContainerComponent implements OnInit 
{

    public contName     : string;
    public errorMessage : string = "";
    configService       : ConfigService;
    allContainer        : MContainer[] = [];

    page      : number = 1;
    tableSize : number = 5;

    displayTitle  : string = "";
    displayButton : string = "";


    inputActive;
    inputName;
    inputId : any;
    idValid : boolean = false;
    currContainerId : number;
    allMeters       : Meter[] = [];
    selectedMeters  : Meter[] = [];

    constructor(cs : ConfigService) 
    {
        this.configService = cs;
    }

    ngOnInit(): void 
    {
        this.configService.configEvent$.subscribe( (data : ConfigEvent) =>
        {
            if (data.name == Global.SIGNAL_REFRESH_CONTAINER)
            {
                this.page         = 1;
                this.allContainer = this.configService.getContainer();
            }
            if (data.name == Global.SIGNAL_METER_ID)
            {
                this.idValid = data.success;
            }
        }
        );
        this.configService.loadContainer();
    }

    createContainer() : void
    {
        this.currContainerId = -1;
        this.inputName     = "";
        this.inputId       = "";
        this.inputActive   = false;
        this.displayTitle  = "Erstelle Messcontainer";
        this.displayButton = "Erstellen";
    }

    editContainer(container : MContainer) : void
    {
        this.currContainerId = container.id;
        this.inputActive   = container.active;
        this.inputName     = container.name;
        this.inputId       = container.deviceId;
        this.displayTitle  = "Editiere Messcontainer";
        this.displayButton = "Ändern";
    }

    submitContainer() : void
    {
        if ((this.inputName == null) ||
            (this.inputName.length == 0))
            return;
        if ((this.inputId == null) || (this.inputId.length == 0))
            return;
        if (!this.idValid)
            return;
        let container : MContainer = new MContainer();
        container.id        = this.currContainerId;
        container.deviceId  = this.inputId;
        container.meters    = [];
        container.companyId = this.configService.getSelectedCompany().id;
        container.name      = this.inputName;
        container.active    = this.inputActive;
        if (container.active == null)
           container.active = false;
        this.configService.saveContainer(container);
        $("#containerModal").modal('hide');
    }

    createID() : void
    {
        let id : string = uuid();
        let erg =  id.split('-');
        id = erg.join("");
        this.inputId = id.substr(0, 26);
        this.idValid = false;
        this.configService.checkValidityOfId(this.inputId);
    }

    deleteContainer(container : MContainer)
    {
        if (container.id < 0)
           return;
        this.configService.deleteContainer(container);
    }

    selectContainer(container : MContainer)
    {
        for (let i = 0; i < this.allContainer.length; i++)
        {
            this.allContainer[i]['selected'] = false;
        }
        container['selected'] = true;
    }
}
